import React, { useState, useCallback, useEffect } from "react";
import { Query, Builder, Utils as QbUtils } from "react-awesome-query-builder";
import MuiConfig from "react-awesome-query-builder/lib/config/mui";
import "./css/compact_styles.scss";
import "./css/styles.scss";
import { useSelector } from "react-redux";
import { languagesOptions } from "../../../js/constants/selectOptions";
import {
    Box,
    Chip,
    Divider,
    FormHelperText,
    Grid,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import { ScreenLoading } from "../../Widgets/CustomLoadings";
import { useAxiosConfig } from "../../../js/utils/customHooks";
import Axios from "axios";
import { fetchData } from "../../../js/utils/backend";
import { useFormContext } from "react-hook-form";
import { HiddenField } from "../../Widgets/Custom Inputs/CustomInputs";
import { t, Translate } from "react-i18nify";
import { regexGuid } from "../../../js/utils/inputValidations";
import { mainRed } from "../../../themes/GlobalTheme";

/* This css will go further by applying a custom colors on buttons */
const muiTheme = {
    palette: {
        primary: {
            main: `${mainRed}`,
            light: "#000000",
            dark: "#000000",
            contrastText: "#eeeeee",
        },
        secondary: {
            main: `${mainRed}`,
            light: "#000000",
            dark: "#000000",
            contrastText: "#eeeeee",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 24,
                    "&:hover": {
                        color: mainRed,
                        backgroundColor: "#F7F9FA",
                    },
                },
                contained: {},
                containedNeutral: {
                    backgroundColor: "#A8A8A8", //"rgba(135, 154, 162, 0.6)",
                },
                text: {
                    color: mainRed,
                },
            },
        },
    },
};

/** Config per type of field for the query builder  */
const frontFieldToQueryField = (frontField, token) => {
    switch (frontField.FieldType) {
        case "Text":
            return [
                frontField.Fieldname,
                {
                    label: frontField.Label,
                    type: "text",
                    operators: [
                        "equal",
                        "not_equal",
                        "like",
                        "not_like",
                        "is_empty",
                        "is_not_empty",
                        "is_null",
                        "is_not_null",
                    ],
                    tooltip: frontField.Description,
                    valueSources: ["value"],
                },
            ];
        case "Float":
            return [
                frontField.Fieldname,
                {
                    label: frontField.Label,
                    type: "number",
                    fieldSettings: {
                        min: frontField.MinValue,
                        max: frontField.MaxValue,
                        step: 0.0000001,
                    },
                    valueSources: ["value"],
                    preferWidgets: ["number"],
                    tooltip: frontField.Description,
                },
            ];
        case "Integer":
            return [
                frontField.Fieldname,
                {
                    label: frontField.Label,
                    type: "number",
                    fieldSettings: {
                        min: frontField.MinValue,
                        max: frontField.MaxValue,
                        step: 1,
                    },
                    valueSources: ["value"],
                    preferWidgets: ["integer"],
                    tooltip: frontField.Description,
                },
            ];
        case "Bool":
        case "Alert":
            return [
                frontField.Fieldname,
                {
                    label: frontField.Label,
                    type: "select",
                    operators: ["select_equals", "is_null", "is_not_null"],
                    tooltip: frontField.Description,
                    valueSources: ["value"],
                    fieldSettings: {
                        listValues: [
                            { value: true, title: "true" },
                            { value: false, title: "false" },
                        ],
                    },
                },
            ];
        case "Lookup":
            return [
                frontField.Fieldname,
                {
                    label: frontField.Label,
                    type: "select",
                    operators: [
                        "select_equals",
                        "select_not_equals",
                        "is_null",
                        "is_not_null",
                    ],
                    tooltip: frontField.Description,
                    valueSources: ["value"],
                    fieldSettings: {
                        asyncFetch: async search => {
                            const searchIsGuid = regexGuid.test(search);
                            let result;
                            if (!searchIsGuid) {
                                result = await fetchData(
                                    frontField.Lookup,
                                    token,
                                    "Select",
                                    null,
                                    "",
                                    search ? search : ""
                                );
                                result = result.data.map(e => ({
                                    value: e.ObjectID,
                                    title: e[frontField.LookupValueField],
                                    renderTitle: {
                                        primary: e[frontField.LookupValueField],
                                        secondary:
                                            e[frontField.LookupSecondAttribute],
                                        tertiary:
                                            e[frontField.LookupThirdAttribute]
                                                ?.Value,
                                    },
                                }));
                            } else {
                                result = await fetchData(
                                    frontField.Lookup,
                                    token,
                                    "ID",
                                    search
                                );
                                result = [
                                    {
                                        value: result.data.ID,
                                        title: result.data[
                                            frontField.LookupValueField
                                        ],
                                        renderTitle: {
                                            primary:
                                                result.data[
                                                    frontField.LookupValueField
                                                ],
                                            secondary:
                                                result.data[
                                                    frontField
                                                        .LookupSecondAttribute
                                                ],
                                            tertiary:
                                                result.data[
                                                    frontField
                                                        .LookupThirdAttribute
                                                ]?.Value,
                                        },
                                    },
                                ];
                            }
                            return { values: result };
                        },

                        useAsyncSearch: true,
                    },
                    widgets: {
                        select: {
                            widgetProps: {
                                customProps: {
                                    width: "320px",
                                    filterOptions: (options, state) => {
                                        if (
                                            Boolean(
                                                frontField.LookupSecondAttribute
                                            )
                                        ) {
                                            options.filter(option =>
                                                option.renderTitle?.secondary
                                                    .toLowerCase()
                                                    .includes(
                                                        state.inputValue.toLowerCase()
                                                    )
                                            );
                                        }
                                        return options;
                                    },
                                    renderOption: (props, option) => (
                                        <ListItem
                                            sx={{ padding: 1, margin: 0 }}
                                            {...props}
                                            key={option.value}
                                        >
                                            {Boolean(
                                                frontField.LookupThirdAttribute
                                            ) && (
                                                <Box
                                                    sx={{
                                                        mr: 2,
                                                        width: "80px",
                                                    }}
                                                >
                                                    {option.renderTitle
                                                        ?.tertiary && (
                                                        <Chip
                                                            label={
                                                                option
                                                                    .renderTitle
                                                                    ?.tertiary
                                                            }
                                                            size="small"
                                                            sx={{
                                                                width: "80px",
                                                                borderRadius: 3,
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                            <ListItemText
                                                sx={{
                                                    margin: 0,
                                                    padding: 0,
                                                }}
                                                primary={
                                                    option.renderTitle?.primary
                                                }
                                                secondary={
                                                    option.renderTitle
                                                        ?.secondary
                                                }
                                            />
                                        </ListItem>
                                    ),
                                },
                            },
                        },
                    },
                },
            ];
        case "Date":
            return [
                frontField.Fieldname,
                {
                    valueSources: ["value"],
                    label: frontField.Label,
                    type: "date",
                    tooltip: frontField.Description,
                },
            ];
        case "Point":
            return [
                frontField.Fieldname,
                {
                    valueSources: ["value"],
                    label: frontField.Label,
                    type: "number",
                    tooltip: frontField.Description,
                },
            ];
        case "DateTime":
            return [
                frontField.Fieldname,
                {
                    valueSources: ["value"],
                    label: frontField.Label,
                    type: "datetime",
                    tooltip: frontField.Description,
                },
            ];
        default:
            return [
                frontField.Fieldname,
                { valueSources: ["value"], label: frontField.Label },
            ];
    }
};

const queryValue = {
    id: QbUtils.uuid(),
    type: "group",
    children1: {
        [QbUtils.uuid()]: {
            type: "rule",
            properties: {
                field: null,
                operator: null,
                value: [],
                valueSrc: [],
            },
        },
    },
    properties: {
        field: "StationSGWWellConstruction",
        operator: null,
        value: [],
        valueSrc: [],
    },
};

/** Part of StepQueryBuilder: Defines the set of rules applied to a specfic entity */
const QueryBuilder = ({
    EntityType,
    onChange = () => {
        /* In the form, it will call setValue() method of RHF to set values of queryString and queryJSON fields */
    },
    defaultValue,
}) => {
    if (!Boolean(EntityType)) {
        throw Error("No EntityType provided");
    }
    const locale = useSelector(state => state.i18n.locale);

    const [label, setLabel] = useState(undefined);
    const token = useSelector(state => state.auth.Token);

    const [isLoading, setIsLoading] = useState(true);
    const axiosConfig = useAxiosConfig();

    /** State of the config of the entire query builder */
    const [config, setConfig] = useState({
        ...MuiConfig,
    });
    const [tree, setTree] = useState();

    useEffect(() => {
        if (config.fields) {
            if (
                Boolean(defaultValue?.QueryJSON) &&
                Boolean(defaultValue?.QueryString)
            ) {
                setTree(
                    QbUtils.checkTree(
                        QbUtils.loadFromJsonLogic(
                            JSON.parse(defaultValue.QueryJSON).logic,
                            config
                        ),
                        config
                    )
                );
            } else {
                setTree(
                    QbUtils.checkTree(QbUtils.loadTree(queryValue), config)
                );
            }
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [config]);

    useEffect(() => {
        let isSubscribed = true;
        let url = `/grid/${EntityType}`;
        const language = languagesOptions.find(e => e.value === locale);
        const fetch = async () => {
            const response = await Axios.get(url, axiosConfig);
            const fields = response.data?.Fields?.sort(
                (a, b) => a?.DisplayOrder - b?.DisplayOrder
            )
                ?.filter(e => e.FieldQueryable)
                ?.map(e => frontFieldToQueryField(e, token));
            if (isSubscribed) {
                setConfig(p => {
                    for(var i=0; i<fields.length; i++){
                        if(fields[i][0]==="ResultText"){
                            fields.splice(i,1)
                        }
                    }
                    return {
                        ...p,
                        fields: Object.fromEntries(new Map(fields)),
                        settings: {
                            ...p.settings,
                            customFieldSelectProps: {
                                showSearch: true,
                            },
                            locale: {
                                material: language.Material,
                            },
                            theme: {
                                mui: muiTheme,
                            },
                            fieldPlaceholder: t(
                                "packages.reactAwesomeQueryBuilder.fieldPlaceholder"
                            ),
                            valuePlaceholder: t(
                                "packages.reactAwesomeQueryBuilder.valuePlaceholder"
                            ),
                            funcPlaceholder: t(
                                "packages.reactAwesomeQueryBuilder.funcPlaceholder"
                            ),
                            operatorPlaceholder: t(
                                "packages.reactAwesomeQueryBuilder.operatorPlaceholder"
                            ),
                            ...QBLocalizationTranslator,
                        },
                        operators: {
                            ...p.operators,
                            ...QBOperatorsTranslator(p.operators),
                        },
                        conjunctions: {
                            ...p.conjunctions,
                            ...QBConjunctionsTranslator(p.conjunctions),
                        },
                        widgets: {
                            ...p.widgets,
                            ...QBWidgetsTranslator(p.widgets),
                        },
                    };
                });
                setLabel(response.data.Label);
            }
        };
        fetch();

        return () => (isSubscribed = false);
    }, [axiosConfig, EntityType, locale, token]);

    const renderBuilder = useCallback(
        ({ ...props }) => (
            <div
                className="query-builder-container"
                style={{ padding: "10px" }}
            >
                <div className="query-builder qb-lite">
                    <Builder {...props} />
                </div>
            </div>
        ),
        []
    );
    const handleChange = useCallback(
        (immutableTree, config) => {
            const queryString = JSON.stringify(
                QbUtils.spelFormat(immutableTree, config)
            );
            const queryJSON = JSON.stringify(
                QbUtils.jsonLogicFormat(immutableTree, config)
            );
            onChange(queryString, queryJSON);
            setTree(immutableTree);
        },
        [onChange]
    );
    return isLoading ? (
        <ScreenLoading />
    ) : (
        <div>
            <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                {label}
            </Typography>
            <Query
                //sx={{ opacity: "1 !important" }}
                {...config}
                value={tree}
                onChange={handleChange}
                renderBuilder={renderBuilder}
            />
        </div>
    );
};

export default QueryBuilder;


/** Step of a FormStepper: Defines the set of rules applied to one or several entities selected in the previous step */
export const StepQueryBuilder = ({ fieldName }) => {
    const methods = useFormContext();
    const { watch, setValue, formState, trigger } = methods;
    const entities = watch(fieldName);

    const onChange = useCallback(
        (string, state, entity) => {
            setValue(`${fieldName}.${entity}.QueryString`, string);
            setValue(`${fieldName}.${entity}.QueryJSON`, state);
        },
        [setValue, fieldName]
    );

    return (
        <Grid item xs={12}>
            {Object.entries(entities)
                .sort((a, b) => {
                    return a[1].Order - b[1].Order;
                })
                .map(([key, value], i) => {
                    const error =
                        formState.errors[fieldName]?.[key]?.QueryString
                            ?.message;
                    return (
                        <Box
                            key={"Query" + key + i}
                            sx={
                                error && {
                                    p: 1,
                                    mb: 1,
                                    border: "1px solid red",
                                    borderRadius: "6px",
                                }
                            }
                        >
                            <QueryBuilder
                                EntityType={key}
                                onChange={(string, state) => {
                                    onChange(string, state, key);
                                    string &&
                                        trigger(
                                            `${fieldName}.${key}.QueryString`
                                        );
                                }}
                                defaultValue={value}
                            />
                            <HiddenField
                                fieldName={`${fieldName}.${key}.QueryJSON`}
                                validation={{
                                    required: t("input.validation.required"),
                                }}
                            />
                            <HiddenField
                                fieldName={`${fieldName}.${key}.QueryString`}
                                validation={{
                                    required: t("input.validation.required"),
                                }}
                            />
                            <FormHelperText error={true} sx={{ ml: 2 }}>
                                {error}
                            </FormHelperText>
                            {i !== entities.length - 1 && !error && (
                                <Divider sx={{ marginBottom: 1 }} />
                            )}
                        </Box>
                    );
                })}
        </Grid>
    );
};

//===================== Query builder translators =========================

const QBConjunctionsTranslator = object => {
    return {
        AND: {
            ...object.AND,
            label: t("packages.reactAwesomeQueryBuilder.and"),
        },
        OR: {
            ...object.OR,
            label: t("packages.reactAwesomeQueryBuilder.or"),
        },
    };
};

const QBOperatorsTranslator = object => {
    return {
        like: {
            ...object.like,
            label: (
                <Translate value={"packages.reactAwesomeQueryBuilder.like"} />
            ),
        },
        not_like: {
            ...object.not_like,
            label: (
                <Translate
                    value={"packages.reactAwesomeQueryBuilder.not_like"}
                />
            ),
        },
        starts_with: {
            ...object.starts_with,
            label: (
                <Translate
                    value={"packages.reactAwesomeQueryBuilder.starts_with"}
                />
            ),
        },
        ends_with: {
            ...object.ends_with,
            label: (
                <Translate
                    value={"packages.reactAwesomeQueryBuilder.ends_with"}
                />
            ),
        },
        between: {
            ...object.between,
            label: (
                <Translate
                    value={"packages.reactAwesomeQueryBuilder.between"}
                />
            ),
            textSeparators: [
                null,
                <Translate value={"packages.reactAwesomeQueryBuilder.and"} />,
            ],
        },
        not_between: {
            ...object.not_between,
            label: (
                <Translate
                    value={"packages.reactAwesomeQueryBuilder.not_between"}
                />
            ),
            textSeparators: [
                null,
                <Translate value={"packages.reactAwesomeQueryBuilder.and"} />,
            ],
        },
        is_null: {
            ...object.is_null,
            label: (
                <Translate
                    value={"packages.reactAwesomeQueryBuilder.is_null"}
                />
            ),
        },
        is_not_null: {
            ...object.is_not_null,
            label: (
                <Translate
                    value={"packages.reactAwesomeQueryBuilder.is_not_null"}
                />
            ),
        },
        is_empty: {
            ...object.is_empty,
            label: (
                <Translate
                    value={"packages.reactAwesomeQueryBuilder.is_empty"}
                />
            ),
        },
        is_not_empty: {
            ...object.is_not_empty,
            label: (
                <Translate
                    value={"packages.reactAwesomeQueryBuilder.is_not_empty"}
                />
            ),
        },
        select_any_in: {
            ...object.select_any_in,
            label: (
                <Translate
                    value={"packages.reactAwesomeQueryBuilder.select_any_in"}
                />
            ),
        },
        select_not_any_in: {
            ...object.select_not_any_in,
            label: (
                <Translate
                    value={
                        "packages.reactAwesomeQueryBuilder.select_not_any_in"
                    }
                />
            ),
        },
        proximity: {
            ...object.proximity,
            label: (
                <Translate
                    value={"packages.reactAwesomeQueryBuilder.proximity"}
                />
            ),
        },
    };
};

const QBLocalizationTranslator = {
    valueLabel: (
        <Translate value={"packages.reactAwesomeQueryBuilder.valueLabel"} />
    ),

    fieldLabel: (
        <Translate value={"packages.reactAwesomeQueryBuilder.fieldLabel"} />
    ),
    operatorLabel: (
        <Translate value={"packages.reactAwesomeQueryBuilder.operatorLabel"} />
    ),
    funcLabel: (
        <Translate value={"packages.reactAwesomeQueryBuilder.funcLabel"} />
    ),

    lockLabel: (
        <Translate value={"packages.reactAwesomeQueryBuilder.lockLabel"} />
    ),
    lockedLabel: (
        <Translate value={"packages.reactAwesomeQueryBuilder.lockedLabel"} />
    ),
    deleteLabel: (
        <Translate value={"packages.reactAwesomeQueryBuilder.deleteLabel"} />
    ),
    delGroupLabel: (
        <Translate value={"packages.reactAwesomeQueryBuilder.delGroupLabel"} />
    ),
    addGroupLabel: (
        <Translate value={"packages.reactAwesomeQueryBuilder.addGroupLabel"} />
    ),
    addRuleLabel: (
        <Translate value={"packages.reactAwesomeQueryBuilder.addRuleLabel"} />
    ),
    addSubRuleLabel: (
        <Translate
            value={"packages.reactAwesomeQueryBuilder.addSubRuleLabel"}
        />
    ),
    notLabel: (
        <Translate value={"packages.reactAwesomeQueryBuilder.notLabel"} />
    ),
    valueSourcesPopupTitle: (
        <Translate
            value={"packages.reactAwesomeQueryBuilder.valueSourcesPopupTitle"}
        />
    ),
    removeRuleConfirmOptions: {
        title: (
            <Translate
                value={
                    "packages.reactAwesomeQueryBuilder.removeRuleConfirmOptions.title"
                }
            />
        ),
        okText: (
            <Translate
                value={
                    "packages.reactAwesomeQueryBuilder.removeRuleConfirmOptions.okText"
                }
            />
        ),
        okType: (
            <Translate
                value={
                    "packages.reactAwesomeQueryBuilder.removeRuleConfirmOptions.okType"
                }
            />
        ),
        cancelText: (
            <Translate
                value={
                    "packages.reactAwesomeQueryBuilder.removeRuleConfirmOptions.cancelText"
                }
            />
        ),
    },
    removeGroupConfirmOptions: {
        title: (
            <Translate
                value={
                    "packages.reactAwesomeQueryBuilder.removeGroupConfirmOptions.title"
                }
            />
        ),
        okText: (
            <Translate
                value={
                    "packages.reactAwesomeQueryBuilder.removeGroupConfirmOptions.okText"
                }
            />
        ),
        okType: (
            <Translate
                value={
                    "packages.reactAwesomeQueryBuilder.removeGroupConfirmOptions.okType"
                }
            />
        ),
        cancelText: (
            <Translate
                value={
                    "packages.reactAwesomeQueryBuilder.removeGroupConfirmOptions.cancelText"
                }
            />
        ),
    },
};

const QBWidgetsTranslator = object => {
    return {
        text: {
            ...object.text,
            valuePlaceholder: t("packages.reactAwesomeQueryBuilder.enterText"),
        },
        textarea: {
            ...object.textarea,
            valuePlaceholder: t("packages.reactAwesomeQueryBuilder.enterText"),
        },
        number: {
            ...object.number,
            valuePlaceholder: t(
                "packages.reactAwesomeQueryBuilder.enterNumber"
            ),
            valueLabels: [
                {
                    label: t(
                        "packages.reactAwesomeQueryBuilder.enterNumberFrom"
                    ),
                    placeholder: t(
                        "packages.reactAwesomeQueryBuilder.enterNumberFrom"
                    ),
                },
                {
                    label: t("packages.reactAwesomeQueryBuilder.enterNumberTo"),
                    placeholder: t(
                        "packages.reactAwesomeQueryBuilder.enterNumberTo"
                    ),
                },
            ],
        },
        select: {
            ...object.select,
            valuePlaceholder: t(
                "packages.reactAwesomeQueryBuilder.selectValue"
            ),
        },
        multiselect: {
            ...object.multiselect,
            valuePlaceholder: t(
                "packages.reactAwesomeQueryBuilder.selectValues"
            ),
        },
        date: {
            ...object.date,
            valuePlaceholder: t("packages.reactAwesomeQueryBuilder.enterDate"),
        },
        datetime: {
            ...object.datetime,
            valuePlaceholder: t("packages.reactAwesomeQueryBuilder.enterDate"),
        },
    };
};
