import React from "react";
import { Grid } from "@mui/material";
import { t } from "react-i18nify";
import { VSelect, VSelectURL } from "../../Widgets/Custom Inputs/Select";
import { HiddenField, VTextField } from "../../Widgets/Custom Inputs/CustomInputs";
import { ObjectSelector } from "./ObjectSelector";
import { querySharingTypes } from "../../../js/constants/selectOptions";
import { useState } from "react";
import { useSelector } from "react-redux";
import { fetchData } from "../../../js/utils/backend";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

/** Step of a FormStepper: Consists of small form and an ObjectSelector to select one or several entities of interest before defining rules for them in a QueryBuilder step. */
const StepObjectSelection = ({ fieldName }) => {
    const [optionsObjects, setOptionsObjects] = useState([]);
    const { CompanyManager, HoldingManager, BranchManager } = useSelector(
        (state) => state.auth
    );
    const locale = useSelector(state => state.i18n.locale);
    const rightToShareToOT = CompanyManager || HoldingManager || BranchManager;
    const methods = useFormContext({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const { watch, unregister, setValue } = methods;
    const token = useSelector((state) => state.auth.Token);
    useEffect(() => {
        let isSubscribed = true;
        const fetching = async () => {
            const options = await fetchData("/Grid/Entities", token, "List");
            if (isSubscribed) {
                setOptionsObjects(options.data.Entities);
            }
        };
        fetching();
        return () => (isSubscribed = false);
    }, [token, locale]);
    const shareEntity = watch("Share") === "Entity";

    useEffect(() => {
        !shareEntity && unregister("OTEntityID");
        // eslint-disable-next-line
    }, [shareEntity]);

    return (
        <>
            <Grid item xs={12}>
                <VTextField
                    label={t("field.Title")}
                    fieldName={"Value"}
                    validation={{
                        required: t("input.validation.required"),
                    }}
                />
            </Grid>
            <Grid item xs={watch("Share") === "Entity" ? 6 : 12}>
                <VSelect
                    label={t("field.Share")}
                    fieldName="Share"
                    options={
                        rightToShareToOT
                            ? querySharingTypes.map(e => ({...e, label: t(e.label)}))
                            : querySharingTypes.filter(
                                  (e) => e.value !== "OneTsigane"
                              )
                    }
                    defaultValue={null}
                    validation={{
                        required: t("input.validation.required"),
                    }}
                />
            </Grid>
            {watch("Share") === "Entity" && (
                <>
                    <HiddenField fieldName="OTEntity_select" />
                    <Grid item xs={6}>
                        <VSelectURL
                            label={t("field.Entity")}
                            URL={`OTEntity`}
                            fieldName={"OTEntityID"}
                            validation={{
                                required: t("input.validation.required"),
                            }}
                            defaultValue={watch("OTEntity_select")}
                            selectorContent={(e) =>
                                setValue("OTEntity_select", e)
                            }
                        />
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <ObjectSelector
                    allOptions={optionsObjects}
                    fieldName={fieldName}
                    validation={{
                        required: t("input.validation.required"),
                    }}
                />
            </Grid>
        </>
    );
};

export default StepObjectSelection;
