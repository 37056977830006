import { InputBase, Paper, Popper } from "@mui/material";
import React, { useState,  useCallback } from "react";

export function checkText(fieldDescr, value) {
    let isString = (typeof value === 'string') //&& isNaN(value)
    if (isString) {
        if (value.length < fieldDescr.MaxLength) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

export const CustomTextareaColumnType = (props) => {

    const { id, field, value, colDef, api } = props;

    const [valueState, setValueState] = useState(value)
    const [anchorEl, setAnchorEl] = useState(null)


    const handleRef = (el) => {
        setAnchorEl(el);
    }

    const handleChange = useCallback(
        (event) => {
            const newValue = event.target.value;
            setValueState(newValue);
            api.setEditCellValue({ id, field, value: newValue }, event)
        },
        [api, field, id],
    )


    return (
        <div>
            <div
                ref={handleRef}
                style={{
                    height: 1,
                    width: colDef.computedWidth,
                    display: "block",
                    position: "absolute",
                    top: 0
                }}
            >
                {anchorEl && (
                    <Popper open anchorEl={anchorEl} placement="top-start">
                        <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
                            <InputBase
                                multiline
                                rows={4}
                                value={valueState}
                                sx={{ textarea: { resize: "both" }, width: "100%" }}
                                onChange={handleChange}
                            />
                        </Paper>
                    </Popper>
                )}

            </div>


        </div>
    )
} 