import React, { useState, useEffect, useCallback } from "react";
import {
    GridActionsCellItem,
    GridAddIcon,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { requiredCell } from "../../js/utils/inputValidations";
import DataGridComponent from "../Widgets/DataGridComponent";
import { fetchData } from "../../js/utils/backend";
import { useSelector, useDispatch } from "react-redux";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import { disableKeyCellEdit, TranslationDialog } from "./cst_DataGrid";
import { t } from "react-i18nify";
import { BasicTooltip } from "../Widgets/StyledComponents";
import { updateHighlightedRows } from "../../js/redux/actions/userPref";
import { useLocation } from "react-router-dom";
import { NodeDialog } from "../TreeEditors/TreeEditorComponents";
import * as r from "../../js/constants/routes";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material";
import { HiddenField, VTextField } from "../Widgets/Custom Inputs/CustomInputs";
import { BasicButton, ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import { FormProvider, useForm } from "react-hook-form";
import { useCheckAndRevalidateToken } from "../../js/utils/customHooks";
import { SessionVerificationDialog } from "../Layouts/CommonLayout";
import { DialogActionsForm } from "../Widgets/CustomSurfaces";

const ReferencesDataGrid = ({ label, baseURL, readOnly, specificColumns }) => {
    const token = useSelector(state => state.auth.Token);
    const { CompanyManager, HoldingManager } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const location = useLocation();
    const currentLocation = location.pathname;
    const apiRef = useGridApiRef();
    specificColumns = specificColumns ? specificColumns(apiRef) : [];

    const locale = useSelector(state => state.i18n.locale);
    const [propsEnglishValue, setOpenEnglishValue] = useState(false);
    const beforeCommitRow = useCallback(
        row => {
            if (row.isNew && locale !== "ENG") {
                return new Promise((resolve, reject) => {
                    setOpenEnglishValue({
                        open: true,
                        setOpen: setOpenEnglishValue,
                        resolve,
                        reject,
                        row,
                        label,
                    });
                }).then(englishValue => ({
                    ...row,
                    EnglishValue: englishValue,
                }));
            }
            return row;
        },
        [setOpenEnglishValue, locale, label]
    );

    const cellFocusField = "Key";
    const gridSpecificColumns = [
        {
            field: "Key",
            headerName: t("field.Key"),
            width: 200,
            editable: true,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(params, apiRef, "Key", "Key");
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "Value",
            headerName: t("field.Value"),
            width: 300,
            flex: 1,
            editable: true,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(params, apiRef, "Value", "Value");
                return { ...params.props, error: hasError };
            },
        },
        ...specificColumns,
    ];

    const [open, setOpen] = useState(false);
    const [rowParams, setRowParams] = useState();
    const [addRequest, setAddRequest] = useState(false);
    const specificActionsColumns = (params, editing, isLocalLoading) => [
        <>
            {(CompanyManager || HoldingManager) && (
                <GridActionsCellItem
                    label={t("common.Translate")}
                    icon={
                        <BasicTooltip title={t("common.Translate")}>
                            <LanguageIcon />
                        </BasicTooltip>
                    }
                    onClick={() => {
                        dispatch(
                            updateHighlightedRows({
                                CurrentLocation: currentLocation,
                                VisitedRowID: params.row.ID,
                            })
                        );
                        setOpen(true);
                        setRowParams(params.row);
                    }}
                    disabled={editing || isLocalLoading}
                />
            )}
        </>,
    ];

    const [hint, setHint] = useState();
    useEffect(() => {
        let isSubscribed = true;

        const fetching = async () => {
            const hintFetched = await fetchData(
                "LookupHint",
                token,
                "List",
                null,
                `PageSize=1&Ref_TableName=${baseURL.substring(1)}`
            );
            if (isSubscribed) {
                if (hintFetched) {
                    setHint(hintFetched?.data?.Data[0]?.Value);
                }
            }
        };
        fetching();
        return () => (isSubscribed = false);
    }, [baseURL, token]);

    return (
        <>
            <TranslationDialog
                label={label}
                open={open}
                setOpen={setOpen}
                rowParams={rowParams}
                baseURL={baseURL}
            />
            <DataGridComponent
                apiRef={apiRef}
                toolbar={{
                    toolbarFilteringToolsOnly: readOnly,
                    newAction: !CompanyManager && !HoldingManager && (
                        <>
                            {!CompanyManager && !HoldingManager && (
                                <ButtonToolbar
                                    titleTooltip={t("common.addNewRef")}
                                    title={t("common.addNewRef")}
                                    startIcon={<GridAddIcon />}
                                    onClick={() => {
                                        setAddRequest({ node: {} });
                                    }}
                                />
                            )}
                        </>
                    ),
                }}
                hideActionColumn={readOnly}
                baseURL={baseURL}
                cellFocusField={cellFocusField}
                label={label}
                gridSpecificColumns={gridSpecificColumns}
                specificActionsColumns={specificActionsColumns}
                hint={hint}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "Value", sort: "asc" }],
                    },
                }}
                isCellEditable={(params) => disableKeyCellEdit(params)}
                beforeCommitRow={beforeCommitRow}
            />
            <NewRefDialog
                reference={label}
                editNewRefOpen={addRequest}
                seteditNewRefOpen={setAddRequest}
            />
            <EnglishValueDialog {...propsEnglishValue} />
        </>
    );
};

export default ReferencesDataGrid;

export const EnglishValueDialog = ({
    open,
    setOpen,
    resolve,
    reject,
    row,
    label,
}) => {
    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });

    const { reset, handleSubmit, formState } = methods;

    ////////////////////////////////// TOKEN REVALIDATION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    const [sessionVerification, setSessionVerification] = useState(false);
    useCheckAndRevalidateToken(setSessionVerification, [
        formState.isSubmitting,
    ]);
    ////////////////////////////////// TOKEN REVALIDATION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

    const manageSubmit = async data => {
        setOpen(false);
        reset();
        resolve(data.EnglishValue);
    };
    const manageCancel = useCallback(() => {
        setOpen(false);
        reset();
        reject();
    }, [reject, setOpen, reset]);

    return (
        <Dialog open={Boolean(open)} sx={{ w: 1 }} fullWidth>
            {sessionVerification ? (
                <SessionVerificationDialog
                    sessionVerification={sessionVerification}
                />
            ) : null}
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(manageSubmit)}>
                    <DialogTitle sx={{ typography: "body1", display: "flex" }}>
                        {t("common.AddingEnglish")}{" "}
                        <Typography
                            color="secondary"
                            sx={{ ml: 1 / 2, textTransform: "lowercase" }}
                        >
                            {label}
                        </Typography>{" "}
                        : {row?.Value}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ mb: 2 }} />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <VTextField
                                    label={`${t("field.Value")}: ${t(
                                        "languages.English"
                                    )}`}
                                    fieldName={"EnglishValue"}
                                    validation={{
                                        required: {
                                            value: true,
                                            message: t(
                                                "input.validation.required"
                                            ),
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActionsForm>
                        <BasicButton
                            type="button"
                            style={{
                                position: "relative",
                                margin: 4,
                            }}
                            onClick={manageCancel}
                        >
                            {t("common.Cancel")}
                        </BasicButton>
                        <BasicButton
                            type="submit"
                            style={{ margin: 4, marginRight: 0 }}
                        >
                            {t("common.Save")}
                        </BasicButton>
                    </DialogActionsForm>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export const NewRefDialog = ({
    editNewRefOpen,
    seteditNewRefOpen,
    onFormClose,
    reference,
}) => {
    const form = useCallback(
        () => <NewRefForm reference={reference} />,
        [reference]
    );
    return Boolean(editNewRefOpen) ? (
        <NodeDialog
            Form={form}
            label={t("common.newRef").toLowerCase()}
            open={Boolean(editNewRefOpen)}
            rowParams={editNewRefOpen}
            setOpen={seteditNewRefOpen}
            URL={r.request}
            onClose={(node, isEditing) => onFormClose(node, isEditing)}
        />
    ) : null;
};

const NewRefForm = ({ reference = "" }) => {
    return (
        <Grid container spacing={2}>
            <HiddenField fieldName={"RequestType"} defaultValue="NEWREF" />
            <Grid item xs={12}>
                <VTextField
                    label={t("field.Reference")}
                    fieldName={"RequestNewRef.ReferenceTitle"}
                    defaultValue={reference}
                    disabled
                />
            </Grid>
            <Grid item xs={12}>
                <VTextField
                    label={t("field.Key")}
                    fieldName={"RequestNewRef.Key"}
                    validation={{
                        required: {
                            value: true,
                            message: t("input.validation.required"),
                        },
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <VTextField
                    label={`${t("field.Value")}: ${t("languages.English")}`}
                    fieldName={"RequestNewRef.Value"}
                    validation={{
                        required: {
                            value: true,
                            message: t("input.validation.required"),
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <VTextField
                    label={`${t("field.Value")}: ${t("languages.French")}`}
                    fieldName={"RequestNewRef.Translations.French"}
                />
            </Grid>
            <Grid item xs={12}>
                <VTextField
                    label={`${t("field.Value")}: ${t("languages.Dutch")}`}
                    fieldName={"RequestNewRef.Translations.Dutch"}
                />
            </Grid>
            <Grid item xs={12}>
                <VTextField
                    label={`${t("field.Value")}: ${t("languages.German")}`}
                    fieldName={"RequestNewRef.Translations.German"}
                />
            </Grid>
            <Grid item xs={12}>
                <VTextField
                    label={`${t("field.Value")}: ${t("languages.Portuguese")}`}
                    fieldName={"RequestNewRef.Translations.Portuguese"}
                />
            </Grid>
            <Grid item xs={12}>
                <VTextField
                    label={`${t("field.Value")}: ${t("languages.Spanish")}`}
                    fieldName={"RequestNewRef.Translations.Spanish"}
                />
            </Grid>
            <Grid item xs={12}>
                <VTextField
                    label={t("field.AdditionalExplanations")}
                    fieldName={"RequestNewRef.Note"}
                    maxCar={1000}
                />
            </Grid>
        </Grid>
    );
};
