
// All the elements to translate besides to these 2 categories :
// 1) view : general pages/views with spreaded information accross component
// 2) common : related to fields, buttons, type, elements present multiple times accross the web app 
import DEU from "./deu";
import ENG from "./eng";
import FRA from "./fra";
import NLD from "./nld";
import POR from "./por";
import SPA from "./spa";



const translations = {
    DEU,
    ENG,
    FRA,
    NLD,
    POR,
    SPA,
}

export default translations;