import React, { useCallback, useState, useEffect } from "react";
import {
    Box,
    Dialog,
    DialogContent,
    Grid,
    LinearProgress,
    Typography,
} from "@mui/material";
import {
    isArrayLength,
    niceBytes,
} from "../../../js/utils/genericMethods";
import Axios from "axios";
import { BasicButton } from "../../Widgets/Custom Inputs/Buttons";
import { t } from "react-i18nify";
import {
    deleteModel,
} from "../../../js/utils/backend";
import { DialogActionsForm } from "../../Widgets/CustomSurfaces";
import SharedSettings from "../../../common/sharedSettings.json";
import SkipIcon from '@mui/icons-material/NextPlanOutlined';
import ReplaceIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import { toastr } from "react-redux-toastr";
import { errorMessageDisplay } from "../../../js/utils/backend"
import { updateEntityTree } from "../../../js/redux/actions/userPref";
import { useDispatch } from "react-redux";
const chunkSize = 1048576 * SharedSettings.ChunkUploadSize; //Chunk upload size in megabytes (= 1048576 bytes)
export const maxFiles = 20;

export const isPhotoVideo = "PhotoVideo";
export const isDocuments = "Documents";

/** Previously, from : src/components/Views/DocumentsView.js /  */
const MediaChunksUploaderDialog = ({
    media,
    setMedia,
    token,
    setUploadedFile,
    forceGridRefresh,
    setForceGridRefresh,
    otEntityId,
    OTEntityTreeID,
    currentDocument,
    mediaIsUploaded,
    setSkipDocument,
    fileType,
    helpDocument=false,
    mediaCategory="",
    mediaLanguage="",
    isExternal=false,
    ...props
}) => {
    // MEMO WARNIGN CORN
    // eslint-disable-next-line
    const [showProgress, setShowProgress] = useState(true);

    const [counter, setCounter] = useState(1);
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
    const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize);
    const [progress, setProgress] = useState(0);
    const [chunkCount, setChunkCount] = useState(1);
    const [fileSize, setFileSize] = useState(0);

    const [batchCompleted, setBatchCompleted] = useState([]);
    const [batchCancelled, setBatchCancelled] = useState([]);

    /* 'batchDuplicatedFiles' is used to trigger popup with overwriting the file of skip it, the idea is to merge the feature in the progress dialog and show every file that needs to be skipped or overwrited (near the filename)  */
    const [batchDuplicatedFiles, setBatchDuplicatedFiles] = useState([]);
    const [showDuplication, setShowDuplication] = useState(false)

    const [currentUpload, setCurrentUpload] = useState(null);
    const [currentUploadingFile, setCurrentUploadingFile] = useState(null);

    const [cancelUpload, setCancelUpload] = useState(false);
    const [openChunkDialog, setOpenChunkDialog] = useState(false);

    /* 'batchPending' is about files remaining to be uploaded */
    const [batchPending, setBatchPending] = useState([]); // file list remaining to upload
    // (1) If we got something dropped via d&d or button dropzone  First upload of a media
    useEffect(() => {
        if (isArrayLength(media)) {
            const fileNamesArray = media?.map(({ name }) => name);
            const checking = async () => {
                try {
                    const responseCheckDuplicatedFiles = await Axios.post(
                        "media/CheckDuplicateFiles",
                        {
                            OTEntityTreeID: OTEntityTreeID,
                            FileNames: fileNamesArray,
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + token,
                            },
                        }
                    );
                    if (responseCheckDuplicatedFiles?.data?.Data?.length === 0) { // No duplicates, begin to upload
                        setBatchPending(media);
                    } else { // Some duplicated found, update array for further operation from user (Skip / Replace)
                        // We'll update batch pending files (whole set) and also update the duplicated files (just the concerned ones)
                        const _updatedBatchPending = media.filter(x => {
                            return !responseCheckDuplicatedFiles.data.Data.some(t => t.Key === x.path)
                        })
                        setBatchPending(_updatedBatchPending);
                        setBatchDuplicatedFiles(responseCheckDuplicatedFiles.data.Data); // INDEX FOR CHECK PURPOSE
                        setShowDuplication(true)
                    }
                    setOpenChunkDialog(true);
                } catch (error) {
                    toastr.error(errorMessageDisplay(error));
                }
            };
            checking();
        }
    }, [media, token, OTEntityTreeID]);



    // (2) We will continue to upload files THAT ARE NOT duplicates /
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed && isArrayLength(batchPending)) {
            const fileToUpload = batchPending.shift();
            proceedToUpload(fileToUpload);
        } 
        return () => (isSubscribed = false);
        // eslint-disable-next-line
    }, [batchPending]); // chunkSize

    // Use Effect that will proceed to overwriting wished files (replace all)
    const [batchFilesToOverwrite, setBatchFilesToOverwrite] = useState([])
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed && !isArrayLength(batchPending) && isArrayLength(batchFilesToOverwrite) && !currentUpload) { //&& !isArrayLength(batchPending)
            const fileToUpload = batchFilesToOverwrite.shift();
            batchDuplicatedFiles.filter(element => {
                if (element.Key === fileToUpload.name) {
                    if (!element.Value) {
                        return []
                    } else {
                        setMediaIDToReplace(element.Value)
                        return proceedToUpload(fileToUpload);
                    }
                } else {
                    return null
                }
            });
        } 
        return () => (isSubscribed = false);
        // eslint-disable-next-line
    }, [batchPending, batchFilesToOverwrite, batchDuplicatedFiles, currentUpload])







    /** Will set a new/next media to be uploaded:  */
    function proceedToUpload(file) {
        resetChunkProperties();
        setCurrentUpload(file);
        setFileSize(file.size);
        const totalCount =
            file.size % chunkSize === 0
                ? file.size / chunkSize
                : Math.floor(file.size / chunkSize) + 1; // Total count of chunks will had to be uploaded to finish the file
        setChunkCount(totalCount);
    }

    // Controlling what happens between every uploaded chunks
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (
                currentUpload &&
                fileSize > 0
            ) {
                // will upload next file as usually
                fileUpload();
            }
        }
        return () => (isSubscribed = false);
        // MEMO WARNING CORN
        // eslint-disable-next-line
    }, [
        currentUpload,
        cancelUpload,
        fileSize,
        progress, // documentsCreated,
    ]);

    /** Process that will upload a file, chunk by chunk */
    const fileUpload = () => {
        if (counter <= chunkCount) {
            if (currentUpload) {
                let chunk = currentUpload.slice(
                    beginingOfTheChunk,
                    endOfTheChunk
                );
                uploadChunk(chunk);
                setCounter(counter + 1);
            }
        }
    };

    const resetChunkProperties = () => {
        setShowProgress(true);
        setProgress(0);
        setCounter(1);
        setBeginingOfTheChunk(0);
        setEndOfTheChunk(chunkSize);
    };

    const [mediaIDToReplace, setMediaIDToReplace] = useState(null);
    /** Process of chunking and uploading each.  */
    const uploadChunk = async chunk => {
        let fileName = currentUpload.name;
        const _remaining = batchPending?.filter(
            element => JSON.stringify(element) !== JSON.stringify(currentUpload)
        );
        if (currentUpload) {
            try {
                let response;
                if(!helpDocument){
                    let url = `media/upload`;
                    response = await Axios.post(url, chunk, {
                        params: {
                            chunk: counter,
                            totalChunk: chunkCount,
                            fileName: fileName,
                            id: currentUploadingFile,
                            otEntityId: otEntityId,
                            replaceId: mediaIDToReplace,
                            totalSizeInBytes: fileSize,
                            photoVideo: fileType === isPhotoVideo,
                        },
                        headers: {
                            "Content-Type": "application/octet-stream",
                            "Content-Disposition": `attachment`,
                            Authorization: "Bearer " + token,
                        },
                    });
                }
                else{
                    let url = `media/uploadHelpDocument`;
                    response = await Axios.post(url, chunk, {
                        params: {
                            chunk: counter,
                            totalChunk: chunkCount,
                            fileName: fileName,
                            id: currentUploadingFile,
                            replaceId: mediaIDToReplace,
                            totalSizeInBytes: fileSize,
                            mediaCategory: mediaCategory,
                            mediaLanguage: mediaLanguage,
                            isExternal: isExternal
                        },
                        headers: {
                            "Content-Type": "application/octet-stream",
                            "Content-Disposition": `attachment`,
                            Authorization: "Bearer " + token,
                        },
                    });
                }
                const data = response.data;
                setCurrentUploadingFile(data);
                if (data) {
                    setBeginingOfTheChunk(endOfTheChunk);
                    setEndOfTheChunk(endOfTheChunk + chunkSize);

                    // The file has finished to upload, we'll reset file-workflow
                    if (counter === chunkCount) {
                        if(helpDocument){
                            toastr.success(t("view.helpPage.contact.FileUploadedSuccessfully", {
                                fileName: `${fileName}`,
                            }));
                        }
                        const _uploaded = batchCompleted;
                        function containsObject(obj, list) {
                            return list.some(elem => elem === obj)
                        }

                        if (!containsObject(currentUpload, _uploaded)) {
                            _uploaded.push(currentUpload);
                        }
                        setBatchCompleted(_uploaded);
                        let fileWithDuplicateCheck = data;
                        if (mediaIDToReplace) {
                            setSkipDocument(fileWithDuplicateCheck);
                        }
                        setMediaIDToReplace(null);
                        setUploadedFile({ MediaID: fileWithDuplicateCheck, Filename: fileName });
                        setCurrentUploadingFile(null);
                        setProgress(100);
                        if (batchPending?.length === 0) {
                            setCurrentUpload(null);
                        }
                        resetChunkProperties();
                        setBatchPending(_remaining);
                    } else {
                        const percentage = (counter / chunkCount) * 100;
                        setProgress(percentage);
                    }
                } else {
                    console.error("Error Occurred:", data.errorMessage);
                }
            } catch (error) {
                /** Will be applied to the first failing file upload */
                setChunkUploadError({
                    fileName: fileName,
                    errorType: error.response?.data,
                });
                console.error("error", error);
            }
        }
    };

    const [chunkUploadError, setChunkUploadError] = useState();

    const showCancelUpload = currentUpload && !cancelUpload;
    const showCloseDialog = !isArrayLength(batchPending) && !currentUpload

    const dispatch = useDispatch();

    const onCloseDialog = useCallback(() => {
        setMedia([]);
        setOpenChunkDialog(false);
        setBatchCompleted([]);
        setBatchCancelled([]);
        setBatchPending([]);
        setBatchDuplicatedFiles([]);
        setBatchFilesToOverwrite([])
        setCurrentUpload(null);
        /* There's a slight latency between writing a file in database - POST -  and to display it immediately in the gird (GET), which can cause a rare situation (< 1%) where the file is missing in the refreshed paginated list. So, 1000ms is added to prevent this, meanwhile a better front/back solution is found. */
        setTimeout(() => {
            setForceGridRefresh(fgr => !fgr);
        }, 1000);
        setCurrentUploadingFile(null);
        setCancelUpload(false);
        setChunkUploadError(null);
        setShowDuplication(false)
        // eslint-disable-next-line
    }, []);

    
    return (
        <>
            <Dialog open={openChunkDialog}>
                <Box>
                    {isArrayLength(batchDuplicatedFiles) && showDuplication &&
                        <>
                        <Box sx={{m: 2}}>
                            <Typography>
                                {batchDuplicatedFiles?.length === 1 && `${batchDuplicatedFiles?.length} item was not uploaded, because the file with this name already exist`}
                                {batchDuplicatedFiles?.length > 1 && `${batchDuplicatedFiles?.length} items weren't uploaded, because the files with these names already exist :`}
                            </Typography>
                            {
                                batchDuplicatedFiles.map((item, index) => {
                                    return (
                                        <Typography variant="body2">
                                            {item.Key}
                                        </Typography>
                                    )
                                })
                            }
                        </Box>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <BasicButton fullWidth color="secondary"
                                    onClick={() => {
                                        // for every item in batchduplicatedfile [], remove from 'media'the file and update batch pending with the ned 'media' /// setBatchPending(updatedPendingBatch)
                                        // for every item in batchduplicatedfile [], add it also in the batchCancelled []
                                        const _updatedBatchCanceled = media.filter(x => {
                                            return batchDuplicatedFiles.some(t => t.Key === x.path)
                                        })
                                        setBatchCancelled(_updatedBatchCanceled)
                                        setBatchDuplicatedFiles(null)
                                        setShowDuplication(false)
                                    }}
                                >
                                    <SkipIcon sx={{ mr: 1 }} />
                                    {batchDuplicatedFiles?.length === 1 && "Skip"}
                                    {batchDuplicatedFiles?.length > 1 && "Skip all"}
                                </BasicButton>
                                <BasicButton fullWidth color="secondary"
                                    onClick={() => {
                                        const _updatedBatchToOverwrite = media.filter(x => {
                                            return batchDuplicatedFiles.some(t => t.Key === x.path)
                                        })
                                        // to overwrite exceptionnally the already existing files
                                        setBatchFilesToOverwrite(_updatedBatchToOverwrite) // we should got the valueID of each file and send it to be overwritten (as one of params, so the right file is replaced in DB)
                                        setShowDuplication(false)
                                    }}
                                >
                                    <ReplaceIcon sx={{ mr: 1 }} />{" "}
                                    {batchDuplicatedFiles?.length === 1 && "Overwrite"}
                                    {batchDuplicatedFiles?.length > 1 && "Overwrite all"}
                                </BasicButton>
                            </Box>
                        </>
                    }
                </Box>
                <DialogContent>
                    {currentUpload && (
                        <Grid
                            container
                            sx={{
                                display: "flex",
                                alignItems: "baseline",
                                padding: "8px 0px",
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                sx={{ padding: "0px 16px 0px 0px" }}
                            >
                                <Typography variant="caption">
                                    {currentUpload.name} ~{" "}
                                    {niceBytes(currentUpload.size)}{" "}
                                    {chunkUploadError ? (
                                        <Box
                                            component="span"
                                            sx={{
                                                fontWeight: 700,
                                                color: "red",
                                            }}
                                        >
                                            {" "}
                                            : {chunkUploadError.errorType}{" "}
                                            Please try again
                                        </Box>
                                    ) : null}
                                    {!cancelUpload ? null : (
                                        <Box
                                            component="span"
                                            sx={{ fontWeight: 700 }}
                                        >
                                            {" "}
                                            : {t("dialog.Cancelled")}
                                        </Box>
                                    )}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{ display: "flex", alignItems: "center" }}
                            >
                                <Box sx={{ minWidth: 300, width: 600, mr: 1 }}>
                                    {!cancelUpload ? (
                                        <LinearProgress {...props} />
                                    ) : null}
                                </Box>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{ padding: "0px 8px" }}
                                >
                                    {!cancelUpload
                                        ? `${Math.round(progress)}%`
                                        : null}{" "}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {batchPending?.map((item, index) => {
                        return (
                            <Grid
                                key={"Pending-"+item.name}
                                container
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "8px 0px",
                                }}
                            >
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        {item.name} - ~ {niceBytes(item.size)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
                    {batchFilesToOverwrite?.map((item, index) => {
                        return (
                            <Grid
                                key={"Overwrite-"+item.name}
                                container
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "8px 0px",
                                }}
                            >
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        {item.name} - ~ {niceBytes(item.size)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
                    {batchCompleted?.map((item, index) => {
                        let sameItem = item === currentUpload;
                        return (
                            <Grid
                                key={item.name}
                                container
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "8px 0px",
                                }}
                            >
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        {item.name} - ~ {niceBytes(item.size)}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            minWidth: 300,
                                            width: 600,
                                            mr: 1,
                                        }}
                                    >
                                        <LinearProgress
                                            variant={
                                                sameItem ? "determinate" : null
                                            }
                                            value={100}
                                            {...props}
                                        />
                                    </Box>
                                    <Box sx={{ minWidth: 35 }}>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{ padding: "0px 8px" }}
                                        >
                                            100%
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        );
                    })}
                    {batchCancelled?.map((item, index) => {
                        return (
                            <Grid
                                key={"Cancelled-" + item.name}
                                container
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "8px 0px",
                                }}
                            >
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        {item.name} - ~ {niceBytes(item.size)}{" "}
                                        <Box
                                            component="span"
                                            sx={{ fontWeight: 700 }}
                                        >
                                            {" "}
                                            : {t("dialog.UploadSkipped")}
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
                </DialogContent>
                <DialogActionsForm>
                    {showCancelUpload ? (
                        <BasicButton
                            type="button"
                            style={{
                                position: "relative",
                                margin: 4,
                            }}
                            onClick={() => {
                                setCancelUpload(true);
                                setUploadedFile(null);
                                let url = "media";
                                if (currentUploadingFile) {
                                    deleteModel(
                                        `${currentUploadingFile}/${otEntityId}`,
                                        token,
                                        url
                                    );
                                }
                                resetChunkProperties();
                                onCloseDialog();
                            }}
                        >
                            {t("common.Cancel")}
                        </BasicButton>
                    ) : null}
                    {showCloseDialog && !showDuplication  ? (
                        <BasicButton
                            type="button"
                            style={{
                                position: "relative",
                                margin: 4,
                            }}
                            onClick={() => {
                                onCloseDialog();
                                dispatch(updateEntityTree());
                            }}
                        >
                            {t("common.Close")}
                        </BasicButton>
                    ) : null}
                </DialogActionsForm>
            </Dialog>

        </>
    );
};

export default MediaChunksUploaderDialog;