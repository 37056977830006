import React, { useEffect, useRef, useState } from "react";
import { loadModules } from "esri-loader";
import { Box } from "@mui/material";
import {
    addCommentWidget,
    addDrawingWidget,
    addMeasurementWidgets,
    addSelectionWidget,
    addUserPreferenceWidget,
    applyUserPrefs,
    CustomMapButton,
    CustomMapButtonText,
    CustomMapLegend,
    extentShape,
    generatingBasicMap,
    getCenterGeometry,
    manageServerLayers,
    managingLayerGeneration,
    sridGeoJSONToWebArcgisGraphic,
} from "../ArcGIS/cst_ArcGIS";
import { useDispatch, useSelector } from "react-redux";
import { languagesOptions } from "../../js/constants/selectOptions";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import { isArrayLength } from "../../js/utils/genericMethods";
import { mapPref } from "../../js/redux/actions/userPref";

/** Map with the ability to define an extent(setOutputGeometry) and send that extent back to its parent. Displays a legend and potential pre-existant geometries if relevant */
export const MapExtentInput = ({
    setOutputGeometry,
    existantGeometry,
    disablePoint = false,
    disablePolygon = false,
    mapServerServices,
    mapServerLink,
    editionLayerTitle = "Extent drawn",
    layerList,
    center,
    basemap,
    legend = [],
    mapName,
}) => {
    const mapRef = useRef();
    const locale = useSelector(state => state.i18n.locale);
    const mapPrefs = useSelector(state => state.userPref.MapsPrefs);
    const language = languagesOptions.find(e => e.value === locale).isoAlpha3;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const generatingMapWithSketch = async (mapRef, existantGeometry) => {
            const { map, view, layerListWidget } = await generatingBasicMap(
                mapRef,
                language,
                mapName,
                legend
            );

            //View Center
            if (existantGeometry || center?.GeoJSON) {
                view.center = await getCenterGeometry(
                    existantGeometry ? existantGeometry : center
                );
            }

            //Map Basemap
            if (basemap) {
                map.basemap = basemap;
            }
            //Potential map from server
            await manageServerLayers(mapServerServices, mapServerLink, map);

            //Adding potential extra layers
            if (isArrayLength(layerList))
                await managingLayerGeneration(layerList, map, layerListWidget);

            //Apply user preferences
            if (Boolean(mapPrefs[mapName]))
                layerListWidget.when(async () => {
                    await applyUserPrefs(map, mapPrefs[mapName]);
                });

            // -------- SKETCH WIDGET ------------
            const [Sketch, GraphicsLayer] = await loadModules([
                "esri/widgets/Sketch",
                "esri/layers/GraphicsLayer",
            ]);

            //Layer for the graphic to edit
            const graphicsLayer = await new GraphicsLayer({
                title: editionLayerTitle,
            });
            await map.add(graphicsLayer);

            const sketch = new Sketch({
                layer: graphicsLayer,
                view: view,
                snappingOptions: {
                    enabled: true,
                },
                availableCreateTools: ["polygon", "rectangle", "point"],
                creationMode: "update",
                updateOnGraphicClick: true,
                visibleElements: {
                    createTools: {
                        point: false,
                        circle: false,
                        polygon: !disablePolygon && !existantGeometry,
                        rectangle: !disablePolygon && !existantGeometry,
                    },
                    selectionTools: {
                        "lasso-selection": false,
                        "rectangle-selection": true,
                    },
                    settingsMenu: false,
                    undoRedoMenu: false,
                },
            });
            sketch.viewModel.polygonSymbol = {
                type: "simple-fill",
                ...extentShape,
            };
            view.ui.add(sketch, "bottom-left");

            sketch.on("create", async event => {
                if (event.graphic?.geometry.isSelfIntersecting) {
                    sketch.undo();
                }
            });

            sketch.on("update", async event => {
                setOutputGeometry(event.graphics[0].geometry);
                sketch.visibleElements.createTools["polygon"] = false;
                sketch.visibleElements.createTools["rectangle"] = false;
                sketch.visibleElements.createTools["point"] = false;
                if (event.graphics[0].geometry.isSelfIntersecting) {
                    sketch.undo();
                }
            });

            sketch.on("delete", function () {
                setOutputGeometry([]);
                sketch.visibleElements.createTools["polygon"] = true;
                sketch.visibleElements.createTools["rectangle"] = true;
                sketch.visibleElements.createTools["point"] = !disablePoint;
            });

            //Adding the preExistant graphic to the layer
            if (Boolean(existantGeometry)) {
                graphicsLayer.add(
                    await sridGeoJSONToWebArcgisGraphic(
                        existantGeometry,
                        extentShape
                    )
                );
            }
            setLoading(false);
        };

        //Generating the map
        mapServerServices && generatingMapWithSketch(mapRef, existantGeometry);

        // eslint-disable-next-line
    }, [
        mapRef,
        mapServerServices,
        mapServerLink,
        existantGeometry,
        disablePoint,
        disablePolygon,
        language,
        layerList,
    ]);

    return (
        <Box sx={{ position: "relative", height: "inherit" }}>
            <ScreenLoading
                sx={{
                    display: "flex",
                    height: "inherit",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    zIndex: "1000",
                    backgroundColor: "white",
                    marginRight: 2,
                    width: "100%",
                    visibility: loading ? "visible" : "hidden",
                }}
            />
            <Box sx={{ height: "100%" }} ref={mapRef}>
                <CustomMapButton
                    id="legendButton"
                    title="Legend"
                    icon="esri-icon-legend"
                />
                <CustomMapLegend legend={legend} />
            </Box>
        </Box>
    );
};

/** Map to display information only. Offers several potential functionalities and widgets (Legend, LayerList,UserPreferences,Selection,Drawings,Comments,Measurements) */

export const BasicMap = ({
    layerList,
    center,
    mapServerServices,
    mapServerLink,
    basemap,
    mapName,
    setMapIsLoaded = () => {
        return;
    },
    legend = [],
    groupFilterSelection = [],
    setSelectionGeometryAndFilter = () => {
        return;
    },
    existantSelection = null,
    hasSelectionButton = false,
    hasDrawingButton = false,
    hasCommentButton = false,
    hasSaveButton = false,
    hasMeasurementTools = false,
    popupAction = false,
}) => {
    const mapRef = useRef();
    const locale = useSelector(state => state.i18n.locale);
    const language = languagesOptions.find(e => e.value === locale).isoAlpha3;
    const layersDefined = isArrayLength(layerList);
    const mapPrefs = useSelector(state => state.userPref.MapsPrefs);
    const isFetching = useSelector(state => state.userPref.IsFetching);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [canRefresh, setCanRefresh] = useState(false);

    useEffect(() => {
        const generatingMap = async mapRef => {
            const { map, view, layerListWidget } = await generatingBasicMap(
                mapRef,
                language,
                mapName,
                legend
            );

            //View Center
            if (center?.GeoJSON && view) {
                view.center = await getCenterGeometry(center);
            }
            //Map Basemap
            if (basemap) {
                map.basemap = basemap;
            }

            // Potential Layers from server
            await manageServerLayers(mapServerServices, mapServerLink, map);

            //Layers to add
            await managingLayerGeneration(layerList, map, layerListWidget, e =>
                setCanRefresh(e)
            );

            //Actions on popups of the different graphics
            if (popupAction) {
                view.popup.on("trigger-action", event => {
                    const actionToTrigger = popupAction.find(
                        action => event.action.id === action.actionID
                    );
                    if (actionToTrigger) {
                        actionToTrigger.action();
                    }
                });
            }
            //Apply user preferences
            if (Boolean(mapPrefs[mapName]))
                layerListWidget.when(async () => {
                    await applyUserPrefs(map, mapPrefs[mapName]);
                });

            // ------- Additional widgets ----------
            // User preference widget
            if (hasSaveButton) {
                addUserPreferenceWidget(
                    map,
                    view,
                    layerListWidget,
                    userPrefPerLayer =>
                        dispatch(mapPref(mapName, userPrefPerLayer))
                );
            }

            //Selection widget
            if (hasSelectionButton) {
                await addSelectionWidget(
                    map,
                    view,
                    groupFilterSelection,
                    setSelectionGeometryAndFilter,
                    e => setCanRefresh(e),
                    existantSelection
                );
            }

            //Drawing Widget
            if (hasDrawingButton) {
                await addDrawingWidget(map, view);
            }

            //Comment widget
            if (hasCommentButton) {
                await addCommentWidget(view);
            }

            //Measurement widgets
            if (hasMeasurementTools) {
                await addMeasurementWidgets(view);
            }
            setLoading(false);
            setMapIsLoaded(true);
        };

        layersDefined && mapServerServices && generatingMap(mapRef);
        // eslint-disable-next-line
    }, [layerList, language, mapServerServices]);

    return (
        <Box sx={{ position: "relative", height: "inherit" }}>
            <ScreenLoading
                title="Loading map..."
                sx={{
                    display: "flex",
                    height: "inherit",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    zIndex: "1000",
                    marginRight: 2,
                    backgroundColor: "white",
                    width: "100%",
                    visibility: loading ? "visible" : "hidden",
                }}
            />
            <Box sx={{ height: "100%" }} ref={mapRef}>
                {hasSaveButton && (
                    <CustomMapButton
                        id="SaveButton"
                        title="Save preferences"
                        icon="esri-icon-save"
                    />
                )}
                {hasSelectionButton && (
                    <>
                        <CustomMapButton
                            id="SelectionButton"
                            title="Select"
                            icon="esri-icon-cursor-marquee"
                            className="MapEditionTool"
                        />
                        <CustomMapButtonText
                            disabled={!canRefresh || isFetching}
                            id="RefreshSelectionButton"
                            title="Select"
                            icon="esri-icon-refresh"
                            className="MapEditionTool jimu-state-disabled"
                        >
                            <Box sx={{ marginLeft: 1 }}>Refresh</Box>
                        </CustomMapButtonText>
                    </>
                )}
                {hasDrawingButton && (
                    <CustomMapButton
                        id="DrawingButton"
                        title="Draw"
                        icon="esri-icon-authorize"
                        className="MapEditionTool"
                    />
                )}
                {hasCommentButton && (
                    <>
                        <CustomMapButton
                            id="CommentButton"
                            title="Comment"
                            icon="esri-icon-comment"
                            className="MapEditionTool"
                        />
                        <input
                            style={{ visibility: "hidden", display: "none" }}
                            type="text"
                            className="esri-input"
                            placeholder="Text"
                            id="commentInput"
                        />
                    </>
                )}
                {hasMeasurementTools && (
                    <>
                        <CustomMapButton
                            id="distanceButton"
                            title="Measure distance between two or more points"
                            icon="esri-icon-measure-line"
                        />
                        <CustomMapButton
                            id="areaButton"
                            title="Measure area"
                            icon="esri-icon-measure-area"
                        />
                    </>
                )}
                <CustomMapButton
                    id="legendButton"
                    title="Legend"
                    icon="esri-icon-legend"
                />
                <CustomMapLegend legend={legend} />
            </Box>
        </Box>
    );
};
