import { oTUser } from "../../constants/routes";
import { editModel } from "../../utils/backend";
import * as actionTypes from "../actionTypes";

const initialState = {
    CurrentEntity: "",
    OpenDrawer: true,
    TreeMenuWidth: 320,
    SettingsMenuWidth: 0,
    LastEntityTreeUpdate: new Date().toUTCString(),
    QuickSearchFilter: {
        MaxResultCount: undefined,
        SearchCategories: undefined,
    },
    QuickSearch: "",
    TreeViewNodeExpand: [],
    HighlightedRows: {},
    Bookmarks: [],
    MapsPrefs: {},
    IsFetching: false,
    ClientHistory: {},
    NextClientRoute: "",
    NotificationCount: 0,
    SheetName: "eddDataValidation.Stations",
    EddDataValidationProgress: 0
};

/** Reducer handling update of states related to user's session preferences.  DOC*/
export default function UserPrefReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.CURRENTENTITY:
            return {
                ...state,
                CurrentEntity: action.payload.CurrentEntity,
            };
        case actionTypes.TREEMENUWIDTH:
            return {
                ...state,
                TreeMenuWidth: action.payload.TreeMenuWidth,
            };
        case actionTypes.SETTINGSMENUWIDTH:
            return {
                ...state,
                SettingsMenuWidth: action.payload.SettingsMenuWidth,
            };
        case actionTypes.UPDATEENTITYTREE:
            return {
                ...state,
                LastEntityTreeUpdate: new Date().toUTCString(),
            };
        case actionTypes.UPDATESPECIFICNODE:
            return {
                ...state,
                nodeToRefreshID: action.payload.nodeToRefreshID,
            };
        case actionTypes.QUICKSEARCHFILTER:
            return {
                ...state,
                QuickSearchFilter: action.payload.QuickSearchFilter,
            };
        case actionTypes.QUICKSEARCH:
            return {
                ...state,
                QuickSearch: action.payload,
            };
        case actionTypes.TREEVIEWNODEEXPAND:
            return {
                ...state,
                TreeViewNodeExpand: action.payload.TreeViewNodeExpand,
            };
        case actionTypes.UPDATEHIGHLIGHTEDROWS:
            return {
                ...state,
                HighlightedRows: {
                    ...state.HighlightedRows,
                    [Object.keys(action.payload)[0]]: Object.values(
                        action.payload
                    )[0],
                },
            };
        case actionTypes.MENUBOOKMARKED:
            const oldBookmarked = state.Bookmarks
                ? state.Bookmarks.filter(t => t !== action.payload.TreeNode)
                : [];

            const Bookmarks = [...oldBookmarked, action.payload.TreeNode];
            editModel(
                Bookmarks,
                "",
                action.payload.token,
                `${oTUser}/bookmarkPrefs`
            );
            return {
                ...state,
                Bookmarks,
            };
        case actionTypes.MENUUNBOOKMARKED:
            const oldBookmarked2 = state.Bookmarks
                ? state.Bookmarks.filter(
                      t => t.Route !== action.payload.TreeNode.Route
                  )
                : [];

            editModel(
                oldBookmarked2,
                "",
                action.payload.token,
                `${oTUser}/bookmarkPrefs`
            );

            return {
                ...state,
                Bookmarks: oldBookmarked2,
            };
        case actionTypes.MAPPREF:
            const newMapsPrefs = { ...state.MapsPrefs };
            newMapsPrefs[action.payload.map] = action.payload.mapState;
            editModel(
                newMapsPrefs,
                "",
                action.payload.token,
                `${oTUser}/mapPrefs`
            );
            return {
                ...state,
                MapsPrefs: newMapsPrefs,
            };
        case actionTypes.INITIALIZEPREFS:
            return {
                ...state,
                MapsPrefs: action.payload.MapPrefs,
                Bookmarks: action.payload.BookmarkPrefs,
            };
        case actionTypes.ISFETCHING:
            return {
                ...state,
                IsFetching: action.payload,
            };
        case actionTypes.NEXTFOLLOWEDUSERROUTE:
            return {
                ...state,
                ClientHistory: action.payload.ClientHistory,
                NextClientRoute: action.payload.NextClientRoute,
            };
        case actionTypes.NEWNOTIFCOUNT:
            return {
                ...state,
                NotificationCount: action.payload.Value,
            };
        case actionTypes.CONSOLELOG:
            console.log(action.payload.message);
            return state;
        case actionTypes.UPDATESHEETNAME:
            return {
                ...state,
                SheetName: action.payload.SheetName,
            };
        case actionTypes.UPDATEDATAVALIDATIONPROGRESS:
            return {
                ...state,
                EddDataValidationProgress: action.payload.Progress,
            };
        default:
            return state;
    }
}
