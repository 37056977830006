import React from "react";
import { useHistory } from "react-router-dom";
import * as userDiagram from "../../diagrams/userDiagram.json";
import { BasicButton } from "../Widgets/Custom Inputs/Buttons";
import StateDiagram from "./cst_Diagrams";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { t } from "react-i18nify";
import { Box } from "@mui/system";


const UserStateDiagram = () => {
    const states = userDiagram.default.States;
    const transitions = userDiagram.default.Transitions;

    const history = useHistory()

    return (
        <Box>
            <BasicButton
                type="button"
                sx={{ position: "relative", m: 4 }}
                startIcon={<ArrowBackIcon />}
                onClick={() => history.goBack()}
            >
                {t("common.Back")}
            </BasicButton>
            <StateDiagram states={states} transitions={transitions} />
        </Box>
    )
};
export default UserStateDiagram;
