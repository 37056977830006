import React from "react";
import EntityPageView from "./EntityPageView";
import { PanelInfo } from "../Widgets/CustomSurfaces";
import { useSelector } from "react-redux";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import { t } from "react-i18nify";

/** Main landing view when user is logged */
const EntityWelcomeView = () => {
    const locale = useSelector(state => state.i18n.locale);
    console.log(locale);
    const entity = useSelector(state => state.userPref.CurrentEntity);
    return  (
        typeof(entity)==='string' ?
            <ScreenLoading />
        :
        (
            entity===undefined || entity?.ObjectID === undefined ?
                <PanelInfo info={t("common.DontHaveAccessToAnyEntity")} />
                :
                <EntityPageView ID={entity.ObjectID} />
        )
    )
};

export default EntityWelcomeView;
