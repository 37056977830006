import React, { useCallback } from "react";
import { Drawer, Link, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { treeMenuWidth } from "../../js/redux/actions/userPref";

const minDrawerWidth = 30;
const maxDrawerWidth = 800;

export const MenuDrawer = ({ anchor, open, children, ...props }) => {

    const dispatch = useDispatch();
    const drawerWidth = useSelector(state => state.userPref.TreeMenuWidth)

    const handleMouseDown = e => {
        e.preventDefault()
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        const newWidth = e.clientX - document.body.offsetLeft;
        if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
            dispatch(treeMenuWidth(newWidth))
        }
    }, [dispatch]);

    return (
        <Drawer
            variant="persistent"
            open={open}
            anchor={anchor}
            elevation={16}
            sx={{
                display: "flex",
                alignItems: "center",
                padding: theme => theme.spacing(0, 1),
                justifyContent: "flex-end",
                flexShrink: 0,
            }}
            PaperProps={{ elevation: 4, style: { width: anchor === "left" ? drawerWidth : 320 } }}
        >
            <Box onMouseDown={e => handleMouseDown(e)} sx={{
                width: "8px",
                cursor: "col-resize", // 
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                zIndex: 100,
                opacity: 0,
                "&:hover": {
                    transitionDuration: "500ms",
                    transitionProperty: "opacity",
                    width: "2px",
                    border: "2px solid #ED0000",
                    opacity: 1,
                    filter: "drop-shadow(2px 4px 6px #374649)",
                    backgroundColor: "primary.light",
                },
            }} />
            {children}
        </Drawer>
    );
};


export const LiLink = ({ isComplete = true, children }) => {
    return (
        <Link underline="none">
            <Box
                component="li"
                sx={{
                    display: "grid",
                    gridTemplateColumns: "10px auto",
                    //textDecoration: "none",
                    //cursor: "pointer",
                    "&:before": {
                        content: '"▶"',
                        fontSize: "8px",
                        marginTop: "4px",
                        color: "#ED0000",
                    },
                }}
            >
                {children}
            </Box>
        </Link>
    );
};