import React from "react";
import { Grid, TextField } from "@mui/material";
import { t } from "react-i18nify";

export const ReadOnlyTextfield = ({ value, label, maxCar = 300 }) => {
    const isLargeInput = maxCar >= 500;
    return (
        <TextField
            InputProps={{
                readOnly: true,
                style: { color: "black", cursor: "not-allowed" },
                disabled: true,
                inputProps: {
                    style: { cursor: "not-allowed" },
                },
            }}
            multiline={isLargeInput}
            minRows={isLargeInput ? 4 : 1}
            maxRows={isLargeInput ? 8 : 1}
            variant="outlined"
            value={value}
            label={label}
            fullWidth
            size="small"
            InputLabelProps={{
                shrink: true,
                disabled: false,
            }}
            disabled
        />
    );
};

export const NewRefRead = ({ reference }) => {
    return (
        <Grid container mt={1} spacing={2}>
            <Grid item xs={12}>
                <ReadOnlyTextfield
                    label={t("field.Reference")}
                    value={reference.ReferenceTitle}
                />
            </Grid>
            <Grid item xs={12}>
                <ReadOnlyTextfield
                    label={t("field.Key")}
                    value={reference.Key}
                />
            </Grid>

            <Grid item xs={12}>
                <ReadOnlyTextfield
                    label={`${t("field.Value")}: ${t("languages.English")}`}
                    value={reference.Value}
                />
            </Grid>
            <Grid item xs={12}>
                <ReadOnlyTextfield
                    value={reference.Translations.French}
                    label={`${t("field.Value")}: ${t("languages.French")}`}
                />
            </Grid>
            <Grid item xs={12}>
                <ReadOnlyTextfield
                    value={reference.Translations.Dutch}
                    label={`${t("field.Value")}: ${t("languages.Dutch")}`}
                />
            </Grid>
            <Grid item xs={12}>
                <ReadOnlyTextfield
                    value={reference.Translations.German}
                    label={`${t("field.Value")}: ${t("languages.German")}`}
                />
            </Grid>
            <Grid item xs={12}>
                <ReadOnlyTextfield
                    value={reference.Translations.Portuguese}
                    label={`${t("field.Value")}: ${t("languages.Portuguese")}`}
                />
            </Grid>
            <Grid item xs={12}>
                <ReadOnlyTextfield
                    value={reference.Translations.Spanish}
                    label={`${t("field.Value")}: ${t("languages.Spanish")}`}
                />
            </Grid>
            <Grid item xs={12}>
                <ReadOnlyTextfield
                    value={reference.Note}
                    label={t("field.AdditionalExplanations")}
                    maxCar={1000}
                />
            </Grid>
        </Grid>
    );
};
