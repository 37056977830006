import React, { useCallback, useState } from "react";
import { Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { VSelectURL } from "../../Widgets/Custom Inputs/Select";
import { FormProvider, useForm } from "react-hook-form";
import EditionPanelGlobal from "./EditionPanelGlobal";
import { PageSection } from "../../Widgets/CustomSurfaces";
import { Box } from "@mui/system";
import * as r from "../../../../src/js/constants/routes";
import { t } from "react-i18nify";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { HiddenField } from "../../Widgets/Custom Inputs/CustomInputs";


const UserAssignementEditionPanel = ({
    setForceGridRefresh,
    defaultMode = "",
    isInPannel = false,
    backAction,
    userName,
    userIGG
}) => {
    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    
    const [mode, setMode] = useState(defaultMode);
    const handleChange = useCallback(
        (event, newMode) => {
            setMode(newMode);
        },
        [setMode]
    );

    const onSubmit = useCallback(() => {
        setForceGridRefresh(fgr => !fgr);
    }, [setForceGridRefresh]);

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "center", m: 1 }}>
                <ToggleButtonGroup
                    fullWidth
                    value={mode}
                    exclusive
                    color="secondary"
                    onChange={handleChange}
                    variant="contained"
                >
                    <ToggleButton fullWidth color="secondary" value="INTERNALUSER">
                        <PersonAddAlt1Icon sx={{ mr: 1 }} />
                        {t(`grid.title.InternalUsers`)}
                    </ToggleButton>
                    <ToggleButton fullWidth color="secondary" value="EXTERNALUSER">
                        <PersonAddAltIcon sx={{ mr: 1 }} />{" "}
                        {t(`grid.title.ExternalUsers`)}
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {mode === "INTERNALUSER" ? (
                <FormProvider {...methods}>
                    <PageSection>
                        <Typography>
                            <strong>IGG:</strong> {userIGG}
                        </Typography>
                        <Typography sx={{marginBottom:3}}>
                            <strong>{t("common.FullName")}:</strong> {userName}
                        </Typography>
                        <EditionPanelGlobal
                            route={"user/AssignUser"}
                            submitButton={{
                                icon: <ArrowForwardIcon />,
                                label: t("common.AssignToInternalUsers"),
                            }}
                            back={isInPannel}
                            onSubmit={onSubmit}
                            backAction={isInPannel ? backAction : undefined}
                            disabledSubmitButton={false}
                        >
                            <HiddenField
                                fieldName={"IGG"}
                                defaultValue={userIGG}
                            />
                        </EditionPanelGlobal>
                    </PageSection>
                </FormProvider>
            ) : null}
            {mode === "EXTERNALUSER" ? (
                <FormProvider {...methods}>
                    <PageSection>
                        <Typography>
                            <strong>IGG:</strong> {userIGG}
                        </Typography>
                        <Typography sx={{marginBottom:4}}>
                            <strong>{t("common.FullName")}:</strong> {userName}
                        </Typography>
                        <EditionPanelGlobal
                            route={"user/AssignUser"}
                            submitButton={{
                                icon: <ArrowForwardIcon />,
                                label: t("common.AssignToExternalUsers"),
                            }}
                            back={isInPannel}
                            onSubmit={onSubmit}
                            backAction={isInPannel ? backAction : undefined}
                        >
                            <Grid item xs={12}>
                                <HiddenField
                                    fieldName={"IGG"}
                                    defaultValue={userIGG}
                                />
                                <VSelectURL
                                    label={t("field.ExternalProvider")}
                                    URL={r.externalProvider}
                                    fieldName={"ExternalProviderID"}
                                    validation={{
                                        required: {
                                            value: true,
                                            message: t(
                                                "input.validation.required"
                                            ),
                                        },
                                    }}
                                />
                            </Grid>
                        </EditionPanelGlobal>
                    </PageSection>
                </FormProvider>
            ) : null}
        </>
    );
};

export default UserAssignementEditionPanel;
