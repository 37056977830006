import React, { useEffect, useState } from "react";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-pro";
import DataGridComponent from "../Widgets/DataGridComponent";
import { useDispatch, useSelector } from "react-redux";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import {
    TranslationDialog,
} from "./cst_DataGrid";
import { ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import { stationRoute } from "../../routers/MenuRouter";
import { useHistory, useLocation, useParams } from "react-router-dom";
import BlurCircularOutlinedIcon from "@mui/icons-material/BlurCircularOutlined";
import { FilterToolbarURL } from "../Widgets/Custom Inputs/Select";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import EditIcon from "@mui/icons-material/Edit";
import * as r from "../../js/constants/routes";
import StationsMap from "../ArcGIS/StationsMap";
import { Grid } from "@mui/material";
import { fetchData } from "../../js/utils/backend";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import { StationDialog } from "../TreeEditors/EntityTree";
import {
    updateSpecificNodeEntityTree,
    updateHighlightedRows,
} from "../../js/redux/actions/userPref";
import { t } from "react-i18nify";
import { BasicTooltip } from "../Widgets/StyledComponents";
import {
    isArrayLength,
    stationGeneralToType,
    historyPush,
} from "../../js/utils/genericMethods";
import { useEditionRightsPerEntityAccess } from "../../js/utils/customHooks";

const StationGrid = ({
    baseURL = r.station.route,
    specificToolbar = null,
    OTEntityIDViaTree,
    apiRef,
    ...props
}) => {
    const basicRef = useGridApiRef();
    apiRef = apiRef ?? basicRef;
    const token = useSelector(state => state.auth.Token);
    const history = useHistory();
    const { NodeID } = useParams();
    const [selectedGroup, setSelectedGroup] = useState([]);
    const selectedGroupIDs = selectedGroup.map(e => e?.ObjectID);
    const { CompanyManager, HoldingManager } = useSelector(state => state.auth);
    const [mapView, setMapView] = useState(false);
    const [loading, setLoading] = useState(OTEntityIDViaTree ? false : true);

    const { nodeToRefreshID } = useSelector(state => state.userPref);
    const [forceGridRefresh, setForceGridRefresh] = useState(false);

    const location = useLocation();
    const currentLocation = location.pathname;

    const [node, setNode] = useState();
    const OTEntityID = OTEntityIDViaTree ?? node?.OTEntityID;
    const groupingRights = useEditionRightsPerEntityAccess(OTEntityID, [
        "EntityManager",
    ]);

    const label = `${t("grid.title.Stations")} of ${
        node?.OTEntity_Select.Name
    }`;

    const dispatch = useDispatch();
    const [editStationOpen, setEditStationOpen] = useState(false);

    useEffect(() => {
        const fetching = async () => {
            setLoading(true);
            const nodeFetched = await fetchData(
                "OTEntityTree",
                token,
                "ID",
                NodeID
            );
            setNode(nodeFetched.data);
            setLoading(false);
        };

        !OTEntityIDViaTree && fetching();
    }, [NodeID, OTEntityIDViaTree, token]);

    useEffect(() => {
        nodeToRefreshID && setForceGridRefresh(fgr => !fgr);
    }, [nodeToRefreshID]);

    const onFormClose = () => {
        dispatch(updateSpecificNodeEntityTree(editStationOpen.ParentID));
    };

    const Filters = () => (
        <Grid item xs sx={{ display: "flex" }}>
            {mapView ? (
                <Grid item sx={{ display: "inline-flex" }}>
                    <ButtonToolbar
                        titleTooltip={t("common.AccessStationsGrid")}
                        title={t("common.GridView")}
                        startIcon={<TableRowsOutlinedIcon />}
                        onClick={() => setMapView(false)}
                    />
                </Grid>
            ) : (
                <>
                    <Grid item sx={{ display: "inline-flex" }}>
                        <ButtonToolbar
                            titleTooltip={t("common.AccessStationsMap")}
                            title={t("common.MapView")}
                            startIcon={<MyLocationOutlinedIcon />}
                            onClick={() => setMapView(true)}
                            disabled={forceGridRefresh}
                        />
                    </Grid>
                    <Grid item xs>
                        <FilterToolbarURL
                            url={"StationGroup"}
                            placeholder={t("common.Group")}
                            value={selectedGroup}
                            onChange={(_e, value) => {
                                setSelectedGroup(value);
                            }}
                            disabled={forceGridRefresh}
                            paramURL={r.filter.genericFilter(
                                "OTEntityID",
                                OTEntityID
                            )}
                            sx={{ width: "auto", p: 1, minWidth: "200px" }}
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );

    const gridSpecificColumns = [
        {
            field: "Name",
            headerName: t("field.Name"),
            width: 150,
            editable: false,
        },
        {
            field: "Value",
            headerName: t("field.Notes"),
            width: 150,
            flex: 1,
            editable: false,
        },
        {
            field: "StationType",
            headerName: t("field.StationType"),
            flex: 1,
            sortable: false,
            valueFormatter: params => {
                const stationType = stationGeneralToType(params.value);
                return !stationType ? "-" : t(`field.${stationType}`);
            },
        },
    ];

    const [open, setOpen] = useState(false);
    const [rowParams, setRowParams] = useState();
    const specificActionsColumns = (params, editing, isLocalLoading) => [
        <>
            {(CompanyManager || HoldingManager) && (
                <>
                    <GridActionsCellItem
                        label={t("common.Translate")}
                        icon={
                            <BasicTooltip title={t("common.Translate")}>
                                <LanguageIcon />
                            </BasicTooltip>
                        }
                        onClick={() => {
                            dispatch(
                                updateHighlightedRows({
                                    CurrentLocation: currentLocation,
                                    VisitedRowID: params.row.ID,
                                })
                            );
                            setOpen(true);
                            setRowParams(params.row);
                        }}
                        disabled={editing || isLocalLoading}
                    />
                    <GridActionsCellItem
                        icon={
                            <BasicTooltip title={t("common.Edit")}>
                                <EditIcon />
                            </BasicTooltip>
                        }
                        label={t("common.Edit")}
                        className="textPrimary"
                        onClick={() => {
                            dispatch(
                                updateHighlightedRows({
                                    CurrentLocation: currentLocation,
                                    VisitedRowID: params.row.ID,
                                })
                            );
                            historyPush(
                                history,
                                `${stationRoute}/${OTEntityID}/edit/${params.row.ID}`
                            );
                        }}
                        color="inherit"
                        disabled={isLocalLoading}
                    />
                </>
            )}
        </>,
    ];

    return loading ? (
        <ScreenLoading />
    ) : mapView ? (
        <StationsMap
            selectedGroupIDs={selectedGroupIDs}
            title={label}
            toolbarComponent={<Filters />}
            OTEntityID={OTEntityID}
        />
    ) : (
        <>
            <TranslationDialog
                label={label}
                open={open}
                setOpen={setOpen}
                rowParams={rowParams}
                baseURL={baseURL}
            />
            <DataGridComponent
                apiRef={apiRef}
                baseURL={baseURL}
                paramURL={
                    isArrayLength(selectedGroup)
                        ? r.filter.genericFilter("OTEntityID", OTEntityID) +
                          r.filter.genericFilter(
                              "PerStationGroups",
                              selectedGroupIDs,
                              true
                          )
                        : r.filter.genericFilter("OTEntityID", OTEntityID)
                }
                forceGridRefresh={forceGridRefresh}
                setForceGridRefresh={setForceGridRefresh}
                label={label}
                toolbar={
                    specificToolbar ?? {
                        newAction: groupingRights && (
                            <>
                                <ButtonToolbar
                                    titleTooltip={t(
                                        "view.station.CreateStationGroup"
                                    )}
                                    title={t("common.Group")}
                                    startIcon={<BlurCircularOutlinedIcon />}
                                    onClick={() =>
                                        setEditStationOpen({
                                            parentNode: { ...node },
                                            ParentID:
                                                node.ID === node.OTEntityID
                                                    ? null
                                                    : node.ID,
                                            AllowedNodeTypes:
                                                node?.AllowedNodeTypes,
                                        })
                                    }
                                    disabled={forceGridRefresh}
                                />
                            </>
                        ),
                        add: false,
                        newFilter: <Filters />,
                    }
                }
                gridSpecificColumns={gridSpecificColumns}
                specificActionsColumns={specificActionsColumns}
                isEditDisabled={true}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "Name", sort: "asc" }],
                    },
                }}
                {...props}
            />
            <StationDialog
                editStationOpen={editStationOpen}
                setEditStationOpen={setEditStationOpen}
                onFormClose={onFormClose}
            />
        </>
    );
};

export default StationGrid;
