import React, { useState, useEffect } from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    CircularProgress,
} from "@mui/material";
import {
    historyPush,
} from "../../../js/utils/genericMethods";
import Axios from "axios";
import { BasicButton } from "../../Widgets/Custom Inputs/Buttons";
import { t } from "react-i18nify";
import { useHistory} from "react-router-dom";
import { DialogActionsForm } from "../../Widgets/CustomSurfaces";
import JSONtoTreeView from "../../Widgets/JSONToTreeView";
import { entityTreeDataMapperSwitch } from "../../../routers/MenuRouter";
import * as r from "../../../js/constants/routes";
import {
    useAxiosConfig,
} from "../../../js/utils/customHooks";
import { isAddDisabledSwitch } from "../../TreeEditors/EntityTree";
import { updateEntityTree } from "../../../js/redux/actions/userPref";
import { useDispatch } from "react-redux";

// Previously, from : src/components/Views/DocumentsView.js

const MoveDialog = ({ openMoveDialog, setOpenMoveDialog, OTEntityID, onDialogClose }) => {
    const config = useAxiosConfig();
    const history = useHistory();
    const dispatch = useDispatch();

    const manageSubmit = async () => {
        try {
            await Axios.post(
                `${r.document}/move`,
                {
                    DocumentIDs: openMoveDialog,
                    OTEntityID: OTEntityID,
                    OTEntityTreeID: selected.ID,
                    CurrentStudyID: selected.StudyID,
                },
                config
            );
            historyPush(history, `/documentNode/${selected.ID}/true`);
            dispatch(updateEntityTree());
        } catch (e) {
            console.error(e);
        } finally {
            setSelected(null);
            setOpenMoveDialog(false);
            onDialogClose();
        }
    };

    const [nodes, setNodes] = useState([]);
    const [hasNodes, setHasNodes] = useState(nodes.length);
    useEffect(() => {
        const renderLabel = (e, _i) => (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Typography
                    sx={{
                        color:
                            !e.CanAddNode || isAddDisabledSwitch(e.NodeType)
                                ? "GrayText"
                                : "primary.contrastText",
                    }}
                    variant="body2"
                >
                    {e.label}
                </Typography>
            </Box>
        );
        let isSubscribed = true;
        const fetch = async () => {
            try {
                const response = await Axios.get(
                    `${r.oTEntityTreeGet}/${OTEntityID}`,
                    config
                );
                if (!isSubscribed) return;
                if (!response.data) return;
                if (response.data.map)
                    setNodes(
                        response.data.map(e => ({
                            ...entityTreeDataMapperSwitch(e, false, false, {
                                renderLabel,
                            }),
                            renderLabel,
                        }))
                    );
                else {
                    setNodes(
                        entityTreeDataMapperSwitch(response.data, false, {
                            renderLabel,
                        })
                    );
                }
            } catch (e) {
                console.error(e);
            }
            setHasNodes(true);
        };
        if (Boolean(openMoveDialog) && !hasNodes) {
            fetch();
        }
        return () => (isSubscribed = false);
    }, [openMoveDialog, hasNodes, config, OTEntityID]);

    const [selected, setSelected] = useState(null);

    return (
        <>
            <Dialog open={Boolean(openMoveDialog)} sx={{ w: 1 }} fullWidth>
                <DialogTitle sx={{ typography: "body1", display: "flex" }}>
                    Moving Documents
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mb: 2 }}>
                        <Box
                            sx={{
                                display: "flex",
                            }}
                        >
                            <Typography>Moving to :</Typography>
                            <Typography
                                color="secondary"
                                sx={{
                                    textTransform: "lowercase",
                                    ml: 1 / 2,
                                }}
                            >
                                {selected?.Value ?? ""}
                            </Typography>
                        </Box>
                        {hasNodes ? (
                            <JSONtoTreeView
                                JSONData={nodes}
                                onClick={node => {
                                    setSelected(
                                        !node.CanAddNode ||
                                            isAddDisabledSwitch(node.NodeType)
                                            ? null
                                            : node
                                    );
                                }}
                                selected={selected?.ID ?? ""}
                                isMoveDialog
                            />
                        ) : (
                            <CircularProgress size={12} color={"secondary"} />
                        )}
                    </Box>
                </DialogContent>
                <DialogActionsForm>
                    <BasicButton
                        type="button"
                        style={{
                            position: "relative",
                            margin: 4,
                        }}
                        onClick={() => {
                            setSelected(null);
                            setOpenMoveDialog(false);
                        }}
                    >
                        {t("common.Cancel")}
                    </BasicButton>
                    <BasicButton
                        style={{ margin: 4, marginRight: 0 }}
                        disabled={!Boolean(selected)}
                        onClick={async () => await manageSubmit()}
                    >
                        {t("common.Move")}
                    </BasicButton>
                </DialogActionsForm>
            </Dialog>
        </>
    );
};

export default MoveDialog;