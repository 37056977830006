import React, { useEffect } from "react";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-pro";
import DataGridComponent from "../Widgets/DataGridComponent";
import * as r from "../../js/constants/routes";
import { t } from "react-i18nify";
import { Box, CircularProgress, Grid, Tab } from "@mui/material";
import {
    historyPush,
    isArrayLength,
    stationGeneralToType,
} from "../../js/utils/genericMethods";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { BasicTooltip } from "../Widgets/StyledComponents";
import OpenLinkIcon from "@mui/icons-material/LaunchOutlined";
import { useHistory, useParams } from "react-router-dom";
import { dataGrid } from "../../routers/MenuRouter";
import { isDocuments, isPhotoVideo } from "../Views/DocumentsView";
import { fetchData } from "../../js/utils/backend";
import { useDispatch, useSelector } from "react-redux";
import { useHistoryState } from "../../js/utils/customHooks";
import { updateSearchText } from "../../js/redux/actions/searchFilter";

const resultConfig = [
    {
        label: "grid.title.Stations",
        route: (withGeometry, OTEntityID) =>
            r.station.route + (withGeometry ? `/GISInfos/${OTEntityID}` : "/pickPaginated"),
        gridSpecificColumns: [
            {
                field: "Name",
                headerName: "field.Name",
                width: 150,
                editable: false,
            },
            {
                field: "StationType",
                headerName: "field.StationType",
                flex: 1,
                sortable: false,
                valueFormatter: params => {
                    const stationType = stationGeneralToType(params.value);
                    return !stationType ? "-" : t(`field.${stationType}`);
                },
            },
            {
                field: "StationGroups_select",
                headerName: "field.StationGroups",
                sortable: false,
                width: 150,
                flex: 1,
                editable: false,
                valueGetter: params => {
                    return isArrayLength(params.value)
                        ? params.value.map(e => e.Value).join(", ")
                        : "";
                },
            },
            {
                field: "Value",
                headerName: "field.Notes",
                width: 150,
                flex: 1,
                editable: false,
            },
        ],
        redirectTooltip: "Name",
        link: (rowInfo, token, filters, dispatch) => {
            const stationType = stationGeneralToType(rowInfo.StationType);
            const stationTypeName = stationType.split('.')[stationType.split('.').length-1];
            filters.filter(d => d.BaseUrl===stationTypeName)[0].SearchText = rowInfo.Name;
            dispatch(updateSearchText(filters));
            return `${dataGrid}/${stationType}/${rowInfo.nodeID}/root/OTEntityID/${rowInfo.OTEntityID}/${rowInfo.Name}`;
        },
    },
    {
        label: "grid.title.Documents",
        route: (withGeometry, OTEntityID) =>
            r.document + (withGeometry ? `/GISInfos/${OTEntityID}` : "/pickFilter"),
        gridSpecificColumns: [
            {
                field: "Value",
                headerName: "field.Name",
                width: 300,
            },
            {
                field: "Filename",
                headerName: "field.Filename",
                flex: 1,
            },
            {
                field: "Author",
                headerName: "field.Author",
                flex: 1,
                width: 200,
            },
            {
                field: "DocumentCategory",
                headerName: "field.DocumentCategory",
                flex: 1,
                width: 150,
            },
        ],
        redirectTooltip: "Filename",
        link: async (rowInfo, token, filters, dispatch) => {
            const nodeInfosFetched = await fetchData(
                r.document + "/GetDocumentNodeInfos",
                token,
                "ID",
                rowInfo.ID
            );
            const nodeInfos = nodeInfosFetched.data;

            let fileType = rowInfo.IsPhotoVideo ? isPhotoVideo : isDocuments;
            if (Boolean(rowInfo.StationID)) {
                return `/${fileType}/${nodeInfos.StationType}/${rowInfo.StationID}/true/${rowInfo.OTEntityID}/${rowInfo.Filename}`;
            }
            if (Boolean(rowInfo.SamplingID)) {
                return `/${fileType}/${nodeInfos.SamplingType}/${rowInfo.SamplingID}/true/${rowInfo.OTEntityID}/${rowInfo.Filename}`;
            }
            return `/documentNode/${nodeInfos.ContainingNode}/true/${rowInfo.Filename}`;
        },
    },
    {
        label: "grid.title.Samplings",
        route: (withGeometry, OTEntityID) =>
            r.sampling + (withGeometry ? `/GISInfos/${OTEntityID}` : "/pickFilter"),
        gridSpecificColumns: [
            {
                field: "SubSamplingID",
                headerName: "field.SubSamplingID",
                flex: 1,
                width: 150,
                editable: false,
            },
            {
                field: "Ref_SampleType_select",
                headerName: "field.SampleType",
                width: 150,
                sortable: false,
                valueFormatter: params => {
                    return params.value.Value;
                },
            },
        ],
        redirectTooltip: "SubSamplingID",
        link: async (rowInfo, token, filters, dispatch) => {
            let value = '';
            let text = rowInfo.Ref_SampleType_select.Value.split(' ');
            for(let i=0; i<text.length; i++){
                value+=text[i];
            }
            
            let firstChar = value[0].toUpperCase();
            let sampleType = firstChar+value.substring(1);
            if(sampleType.includes('souterraine') || sampleType.includes('urface')){
                sampleType = 'Groundwater';
            }
            if(sampleType.includes('sol')){
                sampleType = 'Soil';
            }
            filters.filter(d => d.BaseUrl.includes(sampleType) && !d.BaseUrl.includes('Station'))[0].SearchText = rowInfo.SubSamplingID;
            dispatch(updateSearchText(filters));
            const nodeInfosFetched = await fetchData(
                r.sampling + "/GetSampleType",
                token,
                "ID",
                rowInfo.ID
            );
            const nodeInfos = nodeInfosFetched.data;
            return `${dataGrid}/${nodeInfos}/${rowInfo.nodeID}/root/OTEntityID/${rowInfo.OTEntityID}/${rowInfo.SubSamplingID}`;
        },
    },
];

const StationMapResultGrid = ({
    baseURL = "Station/GISInfos",
    bodyOfRequest,
    gridSpecificColumns,
    link,
    redirectTooltip,
}) => {
    const apiRef = useGridApiRef();
    const history = useHistory();
    const dispatch = useDispatch();
    let filters = useSelector(state => state.searchFilter.Filters);

    const specificActionsColumns = (params, _editing, isLocalLoading) => [
        <GridActionsCellItem
            icon={
                <BasicTooltip
                    title={`${t("common.RedirectTo")} ${
                        params.row[redirectTooltip]
                    }`}
                >
                    <OpenLinkIcon />
                </BasicTooltip>
            }
            label={`${t("common.RedirectTo")} ${params.row[redirectTooltip]}`}
            className="textPrimary"
            onClick={async () => {
                historyPush(history, await link(params.row, filters, dispatch), { withBackButton: true });
            }}
            color="inherit"
            disabled={isLocalLoading}
        />,
    ];

    return (
        <Box
            sx={{
                position: "relative",
                height: "100%",
                pb: 2,
            }}
        >
            <DataGridComponent
                apiRef={apiRef}
                baseURL={baseURL}
                disableTitle={true}
                toolbar={{
                    disableToolbar: true,
                }}
                isEditDisabled
                isDeleteDisabled
                specificActionsColumns={specificActionsColumns}
                boxStyle={{ padding: 0, margin: 0, width: "100%" }}
                gridSpecificColumns={gridSpecificColumns.map(e => ({
                    ...e,
                    headerName: t(e.headerName),
                }))}
                bodyOfRequest={bodyOfRequest}
            />
        </Box>
    );
};

const StationMapResult = ({ bodyOfRequest, withGeometry = false }) => {
    const [value, setValue] = useHistoryState("existantTab", "0");
    let { OTEntityID, elementID, nodeID } = useParams();
    OTEntityID = withGeometry ? OTEntityID : elementID;
    const token = useSelector(state => state.auth.Token);
    const isFetching = useSelector(state => state.userPref.IsFetching);

    useEffect(() => {
        if (withGeometry && bodyOfRequest?.GeometryWithSRID === null) {
            setValue("0");
        }
        // eslint-disable-next-line
    }, [bodyOfRequest, setValue]);

    return (
        <Box
            sx={{
                mt: "12px",
                border: `1px solid`,
                borderColor: "divider",
            }}
        >
            <Box sx={{ width: "100%" }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                            onChange={(e, newValue) => setValue(newValue)}
                            variant="fullWidth"
                            centered
                        >
                            {resultConfig.map((item, index) => {
                                return (
                                    <Tab
                                        key={index}
                                        value={index.toString()}
                                        label={t(item.label)}
                                        variant="fullWidth"
                                        iconPosition="end"
                                        sx={{
                                            minHeight: "72px",
                                        }}
                                        icon={
                                            value === index.toString() &&
                                            isFetching && (
                                                <Box>
                                                    <CircularProgress
                                                        color="secondary"
                                                        size={14}
                                                        thickness={3}
                                                    />
                                                </Box>
                                            )
                                        }
                                    />
                                );
                            })}
                        </TabList>
                    </Box>
                    <Grid container>
                        {resultConfig.map((item, index) => {
                            return (
                                <TabPanel
                                    value={index.toString()}
                                    key={item.label + index}
                                    sx={{ width: "100%", padding: 0 }}
                                >
                                    <StationMapResultGrid
                                        redirectTooltip={item.redirectTooltip}
                                        baseURL={item.route(
                                            withGeometry,
                                            OTEntityID
                                        )}
                                        bodyOfRequest={bodyOfRequest}
                                        gridSpecificColumns={
                                            item.gridSpecificColumns
                                        }
                                        link={async (rowInfos, filters, dispatch) =>
                                            await item.link(
                                                {
                                                    ...rowInfos,
                                                    OTEntityID,
                                                    nodeID,
                                                },
                                                token,
                                                filters,
                                                dispatch
                                            )
                                        }
                                    />
                                </TabPanel>
                            );
                        })}
                    </Grid>
                </TabContext>
            </Box>
        </Box>
    );
};

export default StationMapResult;
