import React from "react";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-pro";
import DataGridComponent from "../Widgets/DataGridComponent";
import { useHistory } from "react-router-dom";
import * as r from "../../js/constants/routes";
import { t } from "react-i18nify";
import { BasicTooltip } from "../Widgets/StyledComponents";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import ShortcutOutlinedIcon from "@mui/icons-material/ShortcutOutlined";
import { historyPush } from "../../js/utils/genericMethods";
import { useEditionRightsPerEntityAccess } from "../../js/utils/customHooks";

const AlertDetailGrid = ({
    label,
    baseURL = r.alertDetail.route,
    eddLineID,
    isAdvancedSelectorGrid,
    otEntityID,
    ...props
}) => {
    const apiRef = useGridApiRef();
    const history = useHistory();
    const editingRights = useEditionRightsPerEntityAccess(otEntityID, [
        "EntityManager",
        "Contributor",
    ]);

    let gridSpecificColumns = [
        {
            field: "Limit_Value",
            headerName: t("field.Title"),
            width: 150,
            flex: 1,
            editable: false,
            valueGetter: params => params.row.Limit_select?.Value,
        },
        {
            field: "Limit_Description",
            headerName: t("field.Description"),
            width: 150,
            editable: false,
            valueGetter: params => params.row.Limit_select?.Description,
        },
    ];

    const specificActionsColumns = (params, _editing, isLocalLoading) => [
        <>
            {editingRights && (
                <GridActionsCellItem
                    icon={
                        <BasicTooltip
                            title={`${t("common.RedirectTo")} ${
                                params.row.Limit_select.Value
                            }`}
                        >
                            <ShortcutOutlinedIcon />
                        </BasicTooltip>
                    }
                    label={`${t("common.RedirectTo")} ${
                        params.row.Limit_select.Value
                    }`}
                    className="textPrimary"
                    onClick={() => {
                        historyPush(history, "/limits", {
                            searchInput: params.row.Limit_select.Value,
                        });
                    }}
                    color="inherit"
                    disabled={isLocalLoading}
                />
            )}
        </>,
    ];

    return (
        <DataGridComponent
            apiRef={apiRef}
            baseURL={baseURL}
            label={label}
            specificScreenLoading={
                <ScreenLoading
                    sx={{
                        display: "flex",
                        height: "280px",
                        justifyContent: "center",
                    }}
                />
            }
            paramURL={
                eddLineID ? r.filter.genericFilter("EDDLineID", eddLineID) : ""
            }
            gridSpecificColumns={gridSpecificColumns}
            specificActionsColumns={specificActionsColumns}
            isEditDisabled={true}
            isDeleteDisabled={true}
            initialState={{
                sorting: {
                    sortModel: [{ field: "Limit_Value", sort: "desc" }],
                },
            }}
            isAdvancedSelectorGrid={isAdvancedSelectorGrid}
            editingRights={editingRights}
            {...props}
        />
    );
};
export default AlertDetailGrid;
