import React, { useState } from "react";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-pro";
import { requiredCell, validateEmail } from "../../js/utils/inputValidations";
import * as r from "../../js/constants/routes";
import { FilterToolbar, SelectURLGrid } from "../Widgets/Custom Inputs/Select";
import { userState } from "../../js/constants/selectOptions";
import DataGridComponent, {
    rowDisableDelete,
    conditionalEditDisabling,
} from "../Widgets/DataGridComponent";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import * as userDiagram from "../../diagrams/userDiagram.json";
import DiagramIcon from "@mui/icons-material/TransformOutlined";
import { ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import { userDiagramRoute } from "../../routers/MenuRouter";
import { t } from "react-i18nify";
import { BasicTooltip } from "../Widgets/StyledComponents";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { historyPush } from "../../js/utils/genericMethods";

/** CommentComponent */
const ExternalUsersGrid = ({
    label = t("grid.title.ExternalUsers"),
    baseURL = r.oTUser,
    specificToolbar = null,
    checkboxSelection,
    selectionModel,
    onSelectionModelChange,
    hideActionColumn = false,
    ...props
}) => {
    const apiRef = useGridApiRef();
    const cellFocusField = "IGG";
    const userID = useSelector(state => state.auth.UserID);
    const token = useSelector(state => state.auth.Token);
    const [visitedRow, setVisitedRow] = useState();
    const [selectedUserState, setSelectedUserState] = useState(null);
    const [forceGridRefresh, setForceGridRefresh] = useState(false);

    const { CompanyManager, HoldingManager } = useSelector(state => state.auth);

    const history = useHistory();

    const gridSpecificColumns = [
        {
            field: "IGG",
            headerName: "IGG",
            flex: 1,
            editable: true,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(params, apiRef, "IGG", "IGG");
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "Firstname",
            headerName: t("field.Firstname"),
            flex: 1,
            editable: true,
        },
        {
            field: "Lastname",
            headerName: t("field.Lastname"),
            flex: 1,
            editable: true,
        },
        {
            field: "EMail",
            headerName: "EMail",
            flex: 1,
            editable: true,
            preProcessEditCellProps: params => {
                const hasError = validateEmail(
                    params,
                    apiRef,
                    "EMail",
                    "EMail"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "State",
            headerName: t("field.State"),
            /* If we do not display toolbar (with proper status workflow), then hide user State (no need to show it) */
            flex: 0.5,
            valueGetter: params => {
                return (t(`diagram.${params.value}`) ?? t("diagram.Valid"))
            }
        },
        {
            field: "ExternalProvider_select",
            headerName: t("field.ExternalProvider"),
            flex: 1,
            width: 200,
            editable: true,
            valueFormatter: params => params.value?.Name,
            sortComparator: (v1, v2) => v1.Name.localeCompare(v2.Name),
            renderEditCell: params => (
                <SelectURLGrid
                    fieldName={"ExternalProviderID"}
                    URL={r.externalProvider}
                    {...params}
                />
            ),
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "External Provider",
                    "ExternalProvider_select"
                );
                return { ...params.props, error: hasError };
            },
        },
		 {
            field: "ExternalProviderID",
            valueGetter: params =>
                params.row?.ExternalProvider_select?.ObjectID,
        },
        {
            field: "External",
            headerName: t("field.External"),
            editable: true,
            valueGetter: _params => true,
            valueSetter: params => ({
                ...params.row,
                External: true,
            }),
        },
    ];

    const diagramStates = userDiagram.default.States;
    const diagramTransitions = userDiagram.default.Transitions;

    const specificActionsColumns = (params, _editing, isLocalLoading) => [
        // MEMO : duplicated code with InternalUserGrid, remember to refactor the onClick and uniformize somehow with better transition handling

        <>
            {(CompanyManager || HoldingManager) &&
                (params.row.State === "Removed" ||
                    apiRef.current.state.rows.idRowsLookup[params.id].State ===
                    "Removed") && (
                    <GridActionsCellItem
                        icon={
                            <BasicTooltip title={t("common.Add")}>
                                <PersonAddAltOutlinedIcon />
                            </BasicTooltip>
                        }
                        label={t("common.Add")}
                        className="textPrimary"
                        onClick={() => {
                            const state = diagramStates.find(
                                e => e.Name === "Removed"
                            );
                            const availableTransitions =
                                diagramTransitions.filter(
                                    e => e.From === state.Name
                                );
                            const nextTransition = availableTransitions[0].Name;
                            const config = {
                                headers: {
                                    Authorization: "Bearer " + token,
                                },
                            };
                            Axios.post(
                                `${baseURL}/transition/${params.id}/${nextTransition}`,
                                {},
                                config
                            ).then(() => {
                                setForceGridRefresh(fgr => !fgr);
                                setVisitedRow(params.id);
                            });
                        }}
                        color="inherit"
                        disabled={isLocalLoading}
                    />
                )}
            {(CompanyManager || HoldingManager) &&
                !conditionalEditDisabling(params.row) &&
                !rowDisableDelete(params.row, userID, "ID") &&
                apiRef.current.getRowMode(params.id) !== "edit" && (
                    <GridActionsCellItem
                        icon={
                            <BasicTooltip title={t("common.Remove")}>
                                <HighlightOffIcon />
                            </BasicTooltip>
                        }
                        label={t("common.Remove")}
                        className="textPrimary"
                        onClick={() => {
                            const state = diagramStates.find(
                                e => e.Name === "Valid"
                            );
                            const availableTransitions =
                                diagramTransitions.filter(
                                    e => e.From === state.Name
                                );
                            const nextTransition = availableTransitions[0].Name;
                            const config = {
                                headers: {
                                    Authorization: "Bearer " + token,
                                },
                            };
                            Axios.post(
                                `${baseURL}/transition/${params.id}/${nextTransition}`,
                                {},
                                config
                            ).then(() => {
                                setForceGridRefresh(fgr => !fgr);
                                setVisitedRow(params.id);
                            });
                        }}
                        color="inherit"
                        disabled={isLocalLoading}
                    />
                )}
        </>,
    ];

    return (
        <DataGridComponent
            apiRef={apiRef}
            selectionModel={selectionModel}
            onSelectionModelChange={onSelectionModelChange}
            baseURL={baseURL}
            cellFocusField={cellFocusField}
            label={label}
            gridSpecificColumns={gridSpecificColumns}
            initialState={{
                sorting: {
                    sortModel: [{ field: "Firstname", sort: "asc" }],
                },
            }}
            columnVisibilityModel={{
                "State": !specificToolbar?.disableToolbar,
                "External": false,
                "ExternalProviderID": false
            }}
            toolbar={
                specificToolbar ?? {
                    newAction: (
                        <ButtonToolbar
                            titleTooltip={t("common.AccessWorkflow")}
                            title={t("common.Workflow")}
                            startIcon={<DiagramIcon />}
                            onClick={() => historyPush(history, userDiagramRoute)}
                        />
                    ),
                    newFilter: (
                        <FilterToolbar
                            options={userState.map(e => ({
                                ...e,
                                label: t(`diagram.${e.label}`)
                            }))}
                            placeholder={t("placeholder.UserState")}
                            value={selectedUserState}
                            onChange={(_e, value) =>
                                value
                                    ? setSelectedUserState(value.value)
                                    : setSelectedUserState("")
                            }
                        />
                    ),
                }
            }
            paramURL={
                selectedUserState
                    ? `filter[State]=${selectedUserState}&${r.filter.externalFilter}`
                    : r.filter.externalFilter
            }
            forceGridRefresh={forceGridRefresh}
            isDeleteDisabled={true}
            isEditDisabled={false}
            specificActionsColumns={specificActionsColumns}
            visitedRow={visitedRow}
            checkboxSelection={checkboxSelection}
            hideActionColumn={hideActionColumn}
            {...props}
        />
    );
};

export default ExternalUsersGrid;
