import React, { useEffect, useMemo, useState } from "react";
import { Box, Collapse, Divider } from "@mui/material";
import { MenuDrawer } from "./CustomNavigation";
import JSONtoTreeView from "../Widgets/JSONToTreeView";
import {
    checkAccess,
    entitiesNode,
    randomID,
    useMenuTree,
} from "../../routers/MenuRouter";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { useDispatch, useSelector } from "react-redux";
import { currentEntity } from "../../js/redux/actions/userPref";
import Axios from "axios";
import * as r from "../../js/constants/routes";
import { Translate } from "react-i18nify";
import { externalMenuTree } from "../../routers/ExternalMenuRouter";
import { historyPush, isArrayLength } from "../../js/utils/genericMethods";
import { fetchTraverseExtendTreePathNodes } from "../TreeEditors/EntityTree";

/** Main component shown in the left drawer of the OT app. Has the bookmark section and all the menu with entities.  DOC*/
const TreeMenu = ({ treeMenuOpen }) => {
    const entity = useSelector(state => state.userPref.CurrentEntity);
    const updatedEntityTree = useSelector(
        state => state.userPref.LastEntityTreeUpdate
    );
    const hasEntity = Boolean(entity);
    const dispatch = useDispatch();

    const isExternalUser = useSelector(state => state.auth.External); //

    const [entities, setEntities] = useState([]);
    const token = useSelector(state => state.auth.Token);
    const internalMenuTree = useMenuTree(entities);
    const menu = useMemo(
        () => (isExternalUser ? externalMenuTree(entities) : internalMenuTree),
        [entities, isExternalUser, internalMenuTree]
    );

    useEffect(() => {
        let isSubscribed = true;
        const config = {
            headers: { Authorization: "Bearer " + token },
        };
        const fetch = async () => {
            try {
                const response = await Axios.get(
                    `${r.oTEntity}/Select?orderBy=Name&dir=desc`,
                    config
                );
                if (isSubscribed) {
                    setEntities(response.data);
                    if (!hasEntity) {
                        dispatch(currentEntity(response.data[0]));
                    }
                }
            } catch (e) {
                console.error(e);
            }
        };
        fetch();
        return () => (isSubscribed = false);
    }, [token, dispatch, hasEntity, updatedEntityTree]);

    const {
        CompanyManager,
        HoldingManager,
        BranchManager,
        EntityManager,
        Contributor,
        Reader,
    } = useSelector(state => state.auth);
    const auth = {
        CompanyManager,
        HoldingManager,
        BranchManager,
        EntityManager,
        Contributor,
        Reader,
    };

    const addedBookmarks = useSelector(state => state.userPref.Bookmarks);

    const Bookmarks = {
        ID: "bookmarks",
        label: <Translate value={"common.Bookmarks"} />,
        Icon: BookmarkBorderOutlinedIcon,
        children: addedBookmarks?.map((item, index) => {
            return {
                ID: item.ID,
                Label: item.Label,
                route: item.Route,
                isBookmark: true,
                DirectParentLabel: item.DirectParentLabel,
            };
        }),
    };
    return (
        <MenuDrawer open={treeMenuOpen} anchor="left">
            <Box
                sx={{
                    overflowX: "hidden",
                    mt: "130px",
                }}
            >
                <SubMenu
                    element={Bookmarks}
                    key={randomID()}
                    defaultIsOpen={true}
                />
                <Box
                    sx={{
                        overflowX: "hidden",
                        mt: 1,
                    }}
                />
                {checkAccess(menu, auth).map((e, i) => (
                    <Box key={`menu-${i}`}>
                        <Divider />
                        <SubMenu element={e} />
                    </Box>
                ))}
                <Divider />
            </Box>
        </MenuDrawer>
    );
};

/** A submenu renders children of an specific opened branch in the TreeMenu. It's itself a node with a label, and can have a redirection route. Otherwise, it renders children of the given node. DOC*/
export const SubMenu = ({ element, defaultIsOpen = false }) => {
    // should be open to expand Entities 
    const [isOpen, setIsOpen] = useState(defaultIsOpen); // MEMOTREE: check how to open according to nodeID ; should check the redux state ?
    const hasChildren = isArrayLength(element.children);
    const history = useHistory();
    const treeViewNodeExpand = useSelector(
        state => state.userPref.TreeViewNodeExpand
    );
    const { nodeID } = useParams()
    const location = useLocation()
    useEffect(() => {
        if (isArrayLength(treeViewNodeExpand)) {
            if (element.ID === entitiesNode) {
                setIsOpen(true);
            }
        }
        // eslint-disable-next-line
    }, [treeViewNodeExpand]);

    const token = useSelector(state => state.auth.Token);
    const dispatch = useDispatch();
    return (
        <>
            <Box
                onClick={() =>
                    hasChildren
                        ? setIsOpen(!isOpen)
                        : element?.route && historyPush(history, element.route)
                }
                sx={{
                    display: "flex",
                    alignItems: "center",
                    typography: "subtitle2",
                    ":hover": {
                        bgcolor: "primary.light",
                    },
                    cursor: "pointer",
                    fontWeight: "body1",
                    pl: 1,
                }}
            >
                {element.Icon ? (
                    <element.Icon color="secondary" fontSize="small" />
                ) : (
                    <ExpandMoreIcon
                        sx={{ visibility: "hidden" }}
                        fontSize="small"
                    />
                )}
                {hasChildren ? (
                    isOpen ? (
                        <ExpandMoreIcon fontSize="small" />
                    ) : (
                        <ChevronRightIcon fontSize="small" />
                    )
                ) : (
                    <ExpandMoreIcon
                        sx={{ visibility: "hidden" }}
                        fontSize="small"
                    />
                )}
                {element.label}
            </Box>
            {hasChildren ? (
                <Collapse in={isOpen} sx={{ pl: 4 }}>
                    {element.CustomTreeView ? (
                        <element.CustomTreeView
                            JSONData={element.children}
                            onClick={(e, _ev) => {
                                return e?.route && historyPush(history, e.route);
                            }}
                            defaultSelected={nodeID}
                        />
                    ) : (
                        <StaticTreeView
                            JSONData={element.children}
                            onClick={(e, _ev) => {
                                /* Open tree menu only for entity nodes that are bookmarkes (since the other ones are not fetched from otentitytree ...) */
                                if (e.ID && e.isBookmark) {
                                    fetchTraverseExtendTreePathNodes(e.ID, token, dispatch)
                                }
                                return e?.route && historyPush(history, e.route)
                            }}
                                defaultSelected={location.pathname}

                        />
                    )}
                </Collapse>
            ) : null}
        </>
    );
};

export default TreeMenu;

/* Static Tree View, check previous commits for exhaustive code handling Bookmarks DOC*/
const StaticTreeView = ({ baseURL = "/datagrid", JSONData, onClick, defaultSelected }) => {

    return (
        <>
            <JSONtoTreeView
                JSONData={JSONData}
                onClick={onClick}
                defaultSelected={defaultSelected}
            />
        </>
    )
}