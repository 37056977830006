import React from "react"
import { Alert, Dialog, Grid, IconButton, Typography, Box, Paper } from "@mui/material"
import { t } from "react-i18nify"
import { TooltipGenericGrid } from "../Widgets/StyledComponents"
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";
import TreeIcon from "@mui/icons-material/AccountTreeOutlined";

/** 5 - Digital Pass : a full screen dialog to display whether the redirection to validate token is being done. */
export const SessionVerificationDialog = ({ sessionVerification }) => {
    return (
        <Dialog sx={{ zIndex: 9000, position: "absolute" }} open={sessionVerification} fullScreen>
            <Box sx={{ margin: "0px auto", position: "relative", top: "20%" }}>
                <Alert severity="info" sx={{ width: '100%' }}>
                    <Typography variant="body2">
                        {t("dialog.SessionVerification")}
                    </Typography>
                </Alert>
            </Box>
        </Dialog>
    )
}

    /** Custom header renderer. Description tooltip is declared here since the 'description' prop works only in relation with 'label' prop (which is ommited because of 'renderHeader') */
    export const ColumnHeaderRender = ({ item, isReporting }) => {
        return (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                    sx={{
                        fontWeight: 500,
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: 72,
                        }}
                    >
                        <Box
                            sx={{
                                height: isReporting ? 120 : 48,
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                {isReporting && (
                                    <Typography variant="caption">
                                        {item.EntityName}
                                    </Typography>
                                )}
                                <Typography>
                                    {item.Label}{" "}
                                    <TooltipGenericGrid
                                        title={item.Description}
                                        fieldConfig={item}
                                        size="small"
                                    >
                                        <IconButton disableRipple>
                                            <InfoIcon
                                                sx={{
                                                    width: "18px",
                                                    height: "18px",
                                                }}
                                            />
                                        </IconButton>
                                    </TooltipGenericGrid>
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                fontSize: 10,
                                fontWeight: 400,
                                lineHeight: "0px",
                            }}
                        >
                            {item.Units && `(${item.Units})`}
                        </Box>
                    </Box>
                    <Grid item sx={{ flexGrow: 1 }} />
                </Box>
            </Box>
        );
    };

    /** Button to open or close the menu drawer, reusable and used in <TreeMenu/>> as well in <PrefsMenu/> component DOC*/
export const DrawerOpener = ({
    treeMenuOpen,
    handleTreeMenuOpen,
    handleTreeMenuClose

}) => {
    const drawerWidth = useSelector(state => state.userPref.TreeMenuWidth);
    return (
        <Paper
            color="primary"
            aria-label="test"
            elevation={4}
            onClick={
                treeMenuOpen ? handleTreeMenuClose : handleTreeMenuOpen
            }
            sx={{
                position: "fixed",
                top: 130,
                left: treeMenuOpen ? drawerWidth : -1,
                height: 40,
                width: 40,
                zIndex: 1300,
                borderRadius: "0px 10px 10px 0px",
                borderLeft: 0,
                clipPath: "inset(-10px -10px -10px 0px)",
                cursor: "pointer",
            }}
        >
            <Box
                sx={{
                    m: 1,
                }}
            >
                <TreeIcon color="secondary" />
            </Box>
        </Paper>
    );
};