import React from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { requiredCell, validateEmail, validatePhoneNumber } from "../../js/utils/inputValidations";
import DataGridComponent from "../Widgets/DataGridComponent";
import { t } from "react-i18nify";

const ExternalProviderGrid = ({ label = t("grid.title.ExternalProviders"), baseURL }) => {
    const apiRef = useGridApiRef();
    const cellFocusField = "Name";

    const gridSpecificColumns = [
        {
            field: "Name",
            headerName: t("field.Name"),
            flex: 1,
            editable: true,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(params, apiRef, "Name", "Name");
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "EMail",
            headerName: "EMail",
            flex: 1,
            editable: true,
            preProcessEditCellProps: params => {
                const hasError = validateEmail(
                    params,
                    apiRef,
                    "EMail",
                    "EMail"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "Phone",
            headerName: t("field.Phone"),
            flex: 1,
            editable: true,
            preProcessEditCellProps: params => {
                const hasError = validatePhoneNumber(
                    params,
                    apiRef,
                    "Phone",
                    "Phone"
                );
                return { ...params.props, error: hasError };
            },
        }
    ];

    return (
        <DataGridComponent
            apiRef={apiRef}
            baseURL={baseURL}
            cellFocusField={cellFocusField}
            label={label}
            gridSpecificColumns={gridSpecificColumns}
            initialState={{
                sorting: {
                    sortModel: [{ field: "Name", sort: "asc" }],
                },
            }}
        />
    );
};

export default ExternalProviderGrid;
