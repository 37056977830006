import React, { useEffect, useRef, useState } from "react";
import { ThemeProvider } from "@mui/styles";
import { OTAppBar } from "../Widgets/AppBar";
import { GlobalTheme } from "../../themes/GlobalTheme";
import { Grid, IconButton, Link, DialogContent, Typography, Dialog  } from "@mui/material";
import { Box } from "@mui/system";
import VersionJSON from "../../common/sharedSettings.json";
import LanguageSelector from "../Widgets/LanguageSelector";
import UserMenu from "../Account/UserMenu";
import TreeMenu from "../Widgets/TreeMenu";
import { useDispatch, useSelector } from "react-redux";
import { ContentViewport, DialogActionsForm  } from "../Widgets/CustomSurfaces";
import { BasicButton } from "../Widgets/Custom Inputs/Buttons";
import ExternalMenuRouter from "../../routers/ExternalMenuRouter";
import translations from "../../l10n/translations";
import { loadTranslations } from "../../js/redux/actions/i18n";
import { updateEntityTree } from "../../js/redux/actions/userPref";
import { DrawerOpener } from "./CommonLayout";
import NotificationMenu from "../Account/NotificationMenu";
import { BasicTooltip } from "../Widgets/StyledComponents";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import { t } from "react-i18nify";
import PrefsMenu from "../Widgets/PrefsMenu";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { hideNewFeaturesPopUp } from "../../js/redux/actions/auth";
import * as r from "../../js/constants/routes";
import Axios from "axios";
import { Link as RouterLink } from 'react-router-dom';

const ExternalUserLayout = () => {
    const appBarRef = useRef(null);
    const version = VersionJSON.Version;

    const [treeMenuOpen, setTreeMenuOpen] = useState(true);

    const handleTreeMenuOpen = () => {
        setTreeMenuOpen(true);
    };
    const handleTreeMenuClose = () => {
        setTreeMenuOpen(false);
    };

    const [features, setFeatures] = useState(null);
    const [prefsMenuOpen, setPrefsMenuOpen] = useState(false);
    const handlePrefsMenuClick = () => {
        setPrefsMenuOpen(!prefsMenuOpen);
    };
    const handlePrefsMenuClose = () => {
        setPrefsMenuOpen(false);
    };

    const dispatch = useDispatch();
    const [language, setLanguage] = useState();
    const locale = useSelector(state => state.i18n.locale);
    const token = useSelector(state => state.auth.Token);
    const userId = useSelector(state => state.auth.UserID);
    const [displayNewFeaturesPopUp,setDisplayNewFeaturesPopUp] = useState(useSelector(state => state.auth.DisplayNewFeaturesPopUp));
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };

    useEffect(() => {
        let config = {
            headers: { Authorization: "Bearer " + token },
        };
        const fetching = async () => {
            const response = await Axios.get('NewFeaturesMessage?orderBy=CreatedOn', config);
            setFeatures(response.data.Data);
        }
        fetching();
    }, [token]);

    useEffect(() => {
        if (language) {
            dispatch(loadTranslations(translations));
        }
    }, [dispatch, language]);

    useEffect(() => {
        setLanguage(locale);
        dispatch(updateEntityTree());
    }, [locale, dispatch]);

    return (
        <ThemeProvider theme={GlobalTheme}>
            <OTAppBar
                ref={appBarRef}
                UpperAppBar={
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            ml: { xs: 2 },
                        }}
                    >
                        <Box sx={{ typography: "body2", mr: 2 }}>
                            v. {version}
                        </Box>
                        <LanguageSelector />
                        <UserMenu username={null} />
                        <NotificationMenu />
                        <Box>
                            <BasicTooltip title={t("common.SettingsPanel")}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open right or prefs menu drawer"
                                    onClick={handlePrefsMenuClick}
                                    edge="start"
                                    sx={{
                                        mr: 1,
                                    }}
                                >
                                    <SettingsIcon />
                                </IconButton>
                            </BasicTooltip>
                        </Box>
                    </Box>
                }
                LowerAppBar={
                    <Box
                        sx={{
                            width: 1,
                            height: 60,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Grid item sx={{ flexGrow: 1 }} />
                        <HelpOutlineIcon sx={{ typography:"subtitleMajor", marginRight:"2px" }} />
                        <Link component={RouterLink} to="/help" sx={{ marginBottom:"2px", typography:"body1", textDecoration:"none", mr:3}}>
                            <strong>{t("common.Help")}</strong>
                        </Link>
                        <Box sx={{marginRight:"20%"}}></Box>
                    </Box>
                }
            />
            {displayNewFeaturesPopUp ? (<Dialog open={Boolean(displayNewFeaturesPopUp)} sx={{ w: 1 }} fullWidth>
                <DialogContent>
                {features && ((locale ==='ENG') && 
                    (<><Typography>
                    <Box sx={{ typography:"bodyMajor"}}>{t("common.featuresmessage")}</Box><br />
                    </Typography> 
                    <Typography>
                    {features[0].English}
                    </Typography></>))}
                {features && ((locale ==='FRA') && 
                    (<><Typography>
                    <Box sx={{ typography:"bodyMajor"}}>{t("common.featuresmessage")}</Box><br />
                    </Typography> 
                    <Typography>
                    {features[0].French}
                    </Typography></>))}
                </DialogContent>
                <DialogActionsForm>
                    <BasicButton
                        type="button"
                        style={{
                            position: "relative",
                            margin: 4,
                        }}
                        onClick={async () => {
                            var response = await Axios.post(
                                `${r.oTUser}/DisableNewFeaturesPopUp/${userId}`,
                                {},
                                config);
                            if(response!==null){
                                setDisplayNewFeaturesPopUp(false);
                                dispatch(hideNewFeaturesPopUp());
                            }
                        }}
                    >
                        {t("common.Close")}
                    </BasicButton>
                </DialogActionsForm>
            </Dialog>) : null}
            <DrawerOpener
                treeMenuOpen={treeMenuOpen}
                handleTreeMenuClose={handleTreeMenuClose}
                handleTreeMenuOpen={handleTreeMenuOpen}
            />

            <TreeMenu
                handleTreeMenuClose={handleTreeMenuClose}
                treeMenuOpen={treeMenuOpen}
            />
            <PrefsMenu
                handlePrefsMenuClose={handlePrefsMenuClose}
                prefsMenuOpen={prefsMenuOpen}
            />
            <ContentViewport
                treeMenuOpen={treeMenuOpen}
                prefsMenuOpen={false}
                appBarRef={appBarRef}
            >
                {/* Rendering & route-based content */}
                <ExternalMenuRouter />
            </ContentViewport>
        </ThemeProvider>
    );
};

export default ExternalUserLayout;
