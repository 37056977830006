import React, { useState } from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { Box, ListItem, ListItemText } from "@mui/material";
import * as r from "../../js/constants/routes";
import {
    SelectGrid,
    SelectURLGrid,
    FilterToolbarURL,
} from "../Widgets/Custom Inputs/Select";
import { requiredCell } from "../../js/utils/inputValidations";
import { entityUserSecurityType } from "../../js/constants/selectOptions";
import DataGridComponent from "../Widgets/DataGridComponent";
import { t } from "react-i18nify";
import { useEditionRightsPerUserStatus } from "../../js/utils/customHooks";
import { filterValidUser } from "./cst_DataGrid";

const EntityUserGrid = ({ label = t("grid.title.EntityUsers"), baseURL }) => {
    const apiRef = useGridApiRef();
    const cellFocusField = "OTUser_select";
    const editingRights = useEditionRightsPerUserStatus(["EntityManager"]);
    const [selectedEntity, setSelectedEntity] = useState();

    const gridSpecificColumns = [
        {
            field: "OTUser_IGG",
            sortable: false,
            headerName: "IGG",
            flex: 0.5,
            valueGetter: params => params.row.OTUser_select?.IGG,
        },
        {
            field: "OTUser_select",
            sortable: false,
            headerName: t("field.User"),
            flex: 1,
            editable: true,
            valueFormatter: params => params.value?.Name,
            renderEditCell: params => (
                <SelectURLGrid
                    fieldName={"OTUserID"}
                    URL={r.oTUser}
                    paramURL={filterValidUser}
                    selectRoute={"SelectDetail"}
                    onChange={(event, data) => {
                        const { api, id } = params;
                        const model = api.getEditRowsModel();
                        const other = model[id];
                        api.setEditRowsModel(
                            {
                                [id]: {
                                    ...other,
                                    SecurityGroupType: {
                                        value: data.External
                                            ? "External"
                                            : undefined,
                                    },
                                },
                            },
                            event
                        );
                    }}
                    getOptionLabel={option => option.Name ?? option.IGG}
                    filterOptions={options => options}
                    renderOption={(props, option) => (
                        <ListItem {...props} key={option.ObjectID}>
                            <ListItemText
                                sx={{
                                    margin: 0,
                                    padding: 0,
                                }}
                                inset
                                primary={option.Name}
                                secondary={option.IGG}
                            />
                        </ListItem>
                    )}
                    {...params}
                />
            ),
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "User",
                    "OTUser_select"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "OTUserID",
            editable: false,
        },
        {
            field: "External",
            editable: false,
            valueGetter: params => params.row.OTUser_select?.External,
        },
        {
            field: "EMail",
            sortable: false,
            headerName: "Email",
            flex: 0.5,
            valueGetter: params => params.row.OTUser_select?.EMail,
        },
        {
            field: "OTEntity_select",
            sortable: false,
            headerName: t("field.Entity"),
            flex: 1,
            width: 200,
            editable: true,
            valueFormatter: params => params.value?.Name,
            renderEditCell: params => (
                <SelectURLGrid
                    fieldName={"OTEntityID"}
                    URL={r.oTEntity}
                    {...params}
                />
            ),
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "Entity",
                    "OTEntity_select"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "OTEntityID",
            valueGetter: params => params.row?.OTEntity_select?.ObjectID,
        },
        {
            field: "SecurityGroupType",
            sortable: false,
            headerName: t("field.SecurityGroupType"),
            flex: 1,
            editable: true,
            valueFormatter: params => params.value?.Name,
            renderEditCell: params => {
                const model = params.api.getEditRowsModel();
                const row = model[params.id];
                let user = row.OTUser_select.value;
                /** Beware that if the user is external, we cannot change his security type. Otherwise, a user can be Reader or Contributor  */
                return user?.External ? (
                    <Box sx={{ px: 1 }}>External</Box>
                ) : (
                    <SelectGrid
                        options={entityUserSecurityType?.map(e => {
                            e.label = t(e.label);
                            return e;
                        })}
                        defaultValue={params?.value}
                        getOptionLabel={option => {
                            return option.value;
                        }}
                        disabled={!Boolean(user)}
                        {...params}
                    />
                );
            },
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "Security Group Type",
                    "SecurityGroupType"
                );
                return { ...params.props, error: hasError };
            },
        },
    ];

    return (
        <DataGridComponent
            apiRef={apiRef}
            baseURL={baseURL}
            columnVisibilityModel={{
                OTEntityID: false,
                External: false,
                OTUserID: false,
            }}
            cellFocusField={cellFocusField}
            label={label}
            gridSpecificColumns={gridSpecificColumns}
            initialState={{
                sorting: {
                    sortModel: [{ field: "OTUserID", sortable: false }],
                },
            }}
            editingRights={editingRights}
            paramURL={
                selectedEntity &&
                r.filter.genericFilter("OTEntityID", selectedEntity.ObjectID)
            }
            toolbar={{
                newFilter: (
                    <FilterToolbarURL
                        url={"OTEntity/all"}
                        placeholder={t("field.Entity")}
                        value={selectedEntity}
                        onChange={(_e, value) => {
                            setSelectedEntity(value);
                        }}
                        getOptionLabel={option => option.Name}
                        multiple={false}
                        renderOption={(props, option) => (
                            <ListItem {...props} key={option.ObjectID}>
                                <ListItemText
                                    sx={{
                                        margin: 0,
                                        padding: 0,
                                    }}
                                    inset
                                    primary={option.Name}
                                />
                            </ListItem>
                        )}
                        sx={{ width: "auto", p: 1, minWidth: "200px" }}
                    />
                ),
            }}
        />
    );
};

export default EntityUserGrid;
