import React, { useCallback, useEffect, useRef, useState } from "react";
import JSONtoTreeView from "../Widgets/JSONToTreeView";
import Axios from "axios";
import { Box, Grid } from "@mui/material";
import * as r from "../../js/constants/routes";
import { ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import { t } from "react-i18nify";
import { HiddenField, VTextField } from "../Widgets/Custom Inputs/CustomInputs";
import { VSelect, VSelectURL } from "../Widgets/Custom Inputs/Select";
import { entityTemplateNodeTypes } from "../../js/constants/selectOptions";
import { GridAddIcon } from "@mui/x-data-grid-pro";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import { useParams } from "react-router-dom";
import { useAxiosConfig } from "../../js/utils/customHooks";
import { ContextMenu, findNodeByID } from "./TreeEditorComponents";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateEntityTree } from "../../js/redux/actions/userPref";

const TreeTemplateEditor = ({ getLabel, baseURL, Form, getURL }) => {
    const { elementID } = useParams();
    const getRoute = elementID ? `${getURL}/${elementID}` : getURL;
    const refMenu = useRef(null);
    const [contextMenuState, setContextMenuState] = useState(null);
    const config = useAxiosConfig();
    const [tree, setTree] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [label, setLabel] = useState("");
    const dispatch = useDispatch();

    const onFormClose = (node, isEditing) => {
        if (node) {
            if (!isEditing) {
                setExpanded(e => (node.ParentID ? [...e, node.ParentID] : e));
            }
            setTree(t => {
                let newTree = JSON.parse(JSON.stringify(t));
                if (isEditing) {
                    let nodeToEdit = findNodeByID(node.ID, newTree);
                    Object.assign(nodeToEdit, node);
                } else {
                    let nodeToEdit = findNodeByID(node.ParentID, newTree);
                    if (nodeToEdit)
                        nodeToEdit.Children.push({ ...node, Children: [] });
                    else newTree.push({ ...node, Children: [] });
                }
                return newTree;
            });
            dispatch(updateEntityTree());
        }
    };

    const onDelete = node => {
        if (node) {
            setTree(t => {
                let newTree = JSON.parse(JSON.stringify(t));
                let nodeToEdit = findNodeByID(node.ParentID, newTree);
                if (nodeToEdit) {
                    nodeToEdit.Children = nodeToEdit.Children.filter(
                        n => n.ID !== node.ID
                    );
                    return newTree;
                }
                return t.filter(n => n.ID !== node.ID);
            });
            dispatch(updateEntityTree());
        }
    };

    useEffect(() => {
        let isSubscribed = true;
        const fetch = async () => {
            try {
                const response = await Axios.get(getRoute, config);
                if (isSubscribed) {
                    setTree(response.data);
                    setIsLoading(false);
                    setLabel(await getLabel(elementID));
                }
            } catch (e) {
                console.error(e);
            }
        };
        fetch();
        return () => (isSubscribed = false);
    }, [getRoute, config, elementID, getLabel]);

    const handleContextMenu = useCallback((node, event) => {
        event.preventDefault();
        setContextMenuState(c =>
            c === null
                ? {
                    mouseX: event.clientX - 2,
                    mouseY: event.clientY - 4,
                    node,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null
        );
    }, []);
    const handleClose = useCallback(() => {
        setContextMenuState(null);
    }, []);

    return (
        <>
            <Box
                sx={{
                    typography: "h3",
                    pt: 6,
                    pl: 5,
                    pb: 2,
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {label}
            </Box>
            {isLoading ? (
                <ScreenLoading />
            ) : (
                <>
                    <Box sx={{ pl: 3, pb: 1 }}>
                        <ButtonToolbar
                            titleTooltip={t("common.AddAnElement")}
                            title={t("common.Add")}
                            startIcon={<GridAddIcon />}
                            onClick={refMenu?.current?.addRootAction}
                        />
                    </Box>
                    <Box sx={{ pl: 3, pb: 2, pr: 3 }}>
                        <JSONtoTreeView
                            JSONData={tree}
                            onContextMenu={handleContextMenu}
                            childrendField="Children"
                            labelField="Value"
                            expanded={expanded}
                            onNodeToggle={(_event, exp) => {
                                setExpanded(exp);
                            }}
                        />
                    </Box>
                </>
            )}
            <ContextMenu
                fref={refMenu}
                contextMenuState={contextMenuState}
                handleClose={handleClose}
                setTree={setTree}
                setExpanded={setExpanded}
                URL={baseURL}
                Form={Form}
                nodeTypeField={"OTEntityTreeTemplateNodeType"}
                onFormClose={onFormClose}
                onDelete={onDelete}
            />
        </>
    );
};

export const EntityTemplateForm = ({ node }) => {
    const methods = useFormContext();
    const { reset, watch } = methods;
    const forbidEditNodeType = !node.ID
    useEffect(() => {
        if (node.ID)
            reset({
                ID: node.ID,
                OTEntityTreeTemplateNodeType: node.OTEntityTreeTemplateNodeType,
                Value: node.Value,
                ParentID: node.ParentID,
                StudyTypeSelector: node.StudyTypeSelector,
            });
    }, [reset, node]);
    return (
        <Grid container spacing={2}>
            <HiddenField
                fieldName={"ParentID"}
                defaultValue={node.ParentID ?? null}
            />
            <Grid item xs={12}>
                <VTextField
                    fullWidth
                    label={t("field.Value")}
                    fieldName={"Value"}
                    validation={{
                        required: {
                            value: true,
                            message: t("input.validation.required"),
                        },
                    }}
                />
            </Grid>
            {forbidEditNodeType &&
            <Grid item xs={12}>
                <VSelect
                    fullWidth
                    label={t("field.NodeType")}
                    fieldName="OTEntityTreeTemplateNodeType"
                    defaultValue={node.OTEntityTreeTemplateNodeType}
                    options={
                        node.AllowedNodeTypes
                            ? entityTemplateNodeTypes.filter(e =>
                                node.AllowedNodeTypes.includes(e.value)
                            )
                            .map(e => { 
                                return {...e, label: t(`${e.label}`)}
                            })
                            : entityTemplateNodeTypes.map(
                                e => ({
                                    ...e,
                                    label: t(`${e.label}`)
                                })
                            )
                    }
                    validation={{
                        required: {
                            value: true,
                            message: t("input.validation.required"),
                        },
                    }}
                />
            </Grid>}
            {watch("OTEntityTreeTemplateNodeType") !== "StudyType" ||
                node.ID ? null : (
                <Grid item xs={12}>
                    <VSelectURL
                        label={t("field.StudyType")}
                        fieldName="StudyTypeID"
                        defaultValue={node.StudyTypeSelector}
                        URL={r.studyType}
                        validation={{
                            required: {
                                value: true,
                                message: t("input.validation.required"),
                            },
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
};
export const StudyTemplateForm = ({ node }) => {
    const { elementID } = useParams();
    const methods = useFormContext();
    const { reset } = methods;
    useEffect(() => {
        if (node.ID)
            reset({
                ID: node.ID,
                StudyTypeID: elementID,
                Value: node.Value,
                ParentID: node.ParentID ?? null,
            });
    }, [reset, node, elementID]);
    return (
        <Grid container spacing={2}>
            <HiddenField
                fieldName={"ParentID"}
                defaultValue={node.ParentID ?? null}
            />
            <HiddenField fieldName={"StudyTypeID"} defaultValue={elementID} />
            <Grid item xs={12}>
                <VTextField
                    fullWidth
                    label={t("field.Value")}
                    fieldName={"Value"}
                    validation={{
                        required: {
                            value: true,
                            message: t("input.validation.required"),
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

export const StudyTreeTemplateEditor = () => {
    const config = useAxiosConfig();
    const getLabel = useCallback(
        async studyID => {
            const response = await Axios.get(
                `${r.studyType}/${studyID}`,
                config
            );
            return `${t('tree.StudyTemplateEditor', { value: response.data.Value })}`;
        },
        [config]
    );
    return (
        <TreeTemplateEditor
            getLabel={p => getLabel(p)}
            baseURL={r.studyTreeTemplate}
            getURL={r.studyTreeTemplateEditor}
            Form={StudyTemplateForm}
        />
    );
};



export default TreeTemplateEditor;
