import { Box, Tooltip, Zoom } from "@mui/material";
import React from "react";
import { t } from "react-i18nify";
import Icon from "@mui/material/Icon";


export const BasicTooltip = ({
    title = "",
    placement = "bottom",
    children,
    ...props
}) => {
    return (
        <Tooltip
            TransitionComponent={Zoom}
            enterDelay={500}
            arrow
            placement={placement}
            title={title}
            {...props}
        >
            {children}
        </Tooltip>
    );
};
export const TooltipGenericGrid = ({
    title = "",
    placement = "bottom",
    children,
    fieldConfig = {},
    ...props
}) => {
    const fields = Object.entries(fieldConfig).sort((a, b) =>
        a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0
    );
    const translationsFields = {
        Description: t("field.Description"),
        Mandatory: t("common.Mandatory"),
        MaxValue: t("field.MaxValue"),
        MinValue: t("field.MinValue"),
        Units: t("field.Units"),
    };
    const { MaxValue, MinValue, ...rest } = translationsFields

    /** If CheckRange is false, hide MinValue and MaxValue (both label and value) from tooltip */
    let withRangeCheck = fieldConfig.CheckRange ? translationsFields : rest;

    //remove max value if it's int.MaxValue ot float.MaxValue
    if (fieldConfig.MaxValue >= 2147483647) {
        delete withRangeCheck.MaxValue;
    }
    const BoolToYesNo = {
        true: t("common.Yes"),
        false: t("common.No"),
    };

    return (
        <Tooltip
            TransitionComponent={Zoom}
            enterDelay={250}
            arrow
            placement={placement}
            title={
                <React.Fragment>
                    <Box
                        p={1}
                        sx={{ border: "1px solid white", borderRadius: "5px", minWidth: "200px", hyphens: "auto" }}
                    >
                        <table>
                            <tbody>
                                {fields.map(([key, val], i) => {
                                    if (
                                        Object.keys(
                                            withRangeCheck
                                        ).includes(key) &&
                                        val.toString()
                                    ) {
                                        return (
                                            <tr
                                                key={i}
                                                style={{
                                                    verticalAlign: "baseline",
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {withRangeCheck[key]}:
                                                </td>
                                                <td>
                                                    {typeof val === "boolean"
                                                        ? BoolToYesNo[val]
                                                        : val}
                                                </td>
                                            </tr>
                                        );
                                    } else { return null }
                                })}
                            </tbody>
                        </table>
                    </Box>
                </React.Fragment>
            }
            {...props}
        >
            {children}
        </Tooltip>
    );
};

export const SubTitle = ({ label }) => {
    return <Box sx={{ typography: "subtitleMajor" }}>{label}</Box>;
};


/** Display mui icons from Actions array provided. Dynamic import with lazy-load won't work properly (inscrease the bundle.js by a factor of x2, webpack loads all of them). The approach is using Icon component from MUI. Icon name is converted to snakecase because of Material Design guideline (more details : https://mui.com/material-ui/icons/#icon-font-icons)  */
export const DynamicIconImport = ({ iconName, iconTooltip }) => {
    let iconNameWithOutlined = `${iconName}Outlined`;
    let convertToSnakeCase = iconNameWithOutlined
        .replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
            return "_" + y.toLowerCase();
        })
        .replace(/^_/, ""); // 🐍🧳
    return (
        <BasicTooltip title={iconTooltip}>
            <Icon>{convertToSnakeCase}</Icon>
        </BasicTooltip>
    );
};