import React, { useCallback, useEffect, useState } from "react";
import {
    GridActionsCellItem,
    useGridApiRef,
    GridAddIcon,
} from "@mui/x-data-grid-pro";
import DataGridComponent from "../Widgets/DataGridComponent";
import { useDispatch, useSelector } from "react-redux";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import { StateChip, TranslationDialog } from "./cst_DataGrid";
import { ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import { useHistory, useLocation } from "react-router-dom";
import * as r from "../../js/constants/routes";
import { t } from "react-i18nify";
import { BasicTooltip } from "../Widgets/StyledComponents";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Paper } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { addNewModel, downloadFile } from "../../js/utils/backend";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import * as queryDiagram from "../../diagrams/queryDiagram.json";
import {
    dateTimeToString,
    historyPush,
    isArrayLength,
    toMiliSeconds,
} from "../../js/utils/genericMethods";
import SharedSettings from "../../common/sharedSettings.json";
import { updateHighlightedRows } from "../../js/redux/actions/userPref";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import GridOnOutlinedIcon from "@mui/icons-material/GridOnOutlined";
import { dataGrid, queryViewReporting } from "../../routers/MenuRouter";
import { useEditionRightsPerUserStatus } from "../../js/utils/customHooks";

const QueryGrid = ({ label, baseURL = r.query.route, ...props }) => {
    const apiRef = useGridApiRef();
    const history = useHistory();
    const token = useSelector(state => state.auth.Token);
    const [open, setOpen] = useState(false);
    const [rowParams, setRowParams] = useState();
    const editingRights = useEditionRightsPerUserStatus(["EntityManager"]);
    const runningRight = useEditionRightsPerUserStatus([
        "Contributor",
        "EntityManager",
    ]);
    const queryDelayInMinutes = SharedSettings.QueryDelayInMinutes;
    const location = useLocation();
    const currentLocation = location.pathname;

    const dispatch = useDispatch();

    const DetailPanelContent = ({ row }) => {
        const [forceGridRefresh, setForceGridRefresh] = useState(false);
        const apiRefDetailPanel = useGridApiRef();

        useEffect(() => {
            const interval = setInterval(() => {
                setForceGridRefresh(fgr => !fgr);
            }, toMiliSeconds(0, queryDelayInMinutes, 0));
            return () => clearInterval(interval);
        }, []);

        const queryStatuses = queryDiagram.default.States;
        const detailGridSpecificColumns = [
            {
                field: "Status",
                headerName: t("field.State"),
                width: 140,

                renderCell: params => {
                    return (
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <StateChip
                                state={queryStatuses.find(
                                    e => e.Name === params.row.Status
                                )}
                                clickable={false}
                            />
                        </Box>
                    );
                },
            },
            {
                field: "CreatedOn",
                headerName: t("view.query.ranOn"),
                valueFormatter: params =>
                    dateTimeToString(params.value, "DateTime"),
                flex: 1,
                editable: false,
            },
        ];

        const detailSpecificActionsColumns = (
            params,
            _editing,
            isLocalLoading
        ) => [
            <>
                {params.row.Status === "Ok" && (
                    <>
                        <GridActionsCellItem
                            label={t("common.Download")}
                            icon={
                                <BasicTooltip title={t("common.Download")}>
                                    <DownloadIcon />
                                </BasicTooltip>
                            }
                            onClick={() => {
                                downloadFile(
                                    token,
                                    "download",
                                    `/${params.row.MediaID}/07a7be0c-3ab5-49fc-afc4-d180286a4a15?queryResult=true`
                                );
                            }}
                        />
                        {params.row.Stations &&
                            isArrayLength(params.row.Stations) && (
                                <GridActionsCellItem
                                    icon={
                                        <BasicTooltip
                                            title={t(
                                                "view.query.seeResultsOnMap"
                                            )}
                                        >
                                            <MyLocationOutlinedIcon />
                                        </BasicTooltip>
                                    }
                                    label={t("view.query.seeResultsOnMap")}
                                    className="textPrimary"
                                    onClick={() =>
                                        historyPush(
                                            history,
                                            `/query/${params.row.ID}/queryResultsMap`
                                        )
                                    }
                                    color="inherit"
                                />
                            )}
                    </>
                )}
            </>,
        ];
        return (
            <Paper sx={{ m: 1, p: 1 }}>
                <DataGridComponent
                    forceGridRefresh={forceGridRefresh}
                    apiRef={apiRefDetailPanel}
                    paramURL={r.filter.genericFilter("QueryID", row.ID)}
                    baseURL={r.queryResult}
                    label={label}
                    gridSpecificColumns={detailGridSpecificColumns}
                    specificActionsColumns={detailSpecificActionsColumns}
                    toolbar={{
                        disableToolbar: true,
                    }}
                    disableTitle
                    disableResearch
                    isEditDisabled
                    boxStyle={{ padding: 0, margin: 0 }}
                    isStandalone={false}
                    specificScreenLoading={
                        <ScreenLoading
                            title={t("common.Loading")}
                            sx={{
                                display: "flex",
                                height: "auto",
                                justifyContent: "center",
                            }}
                        />
                    }
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "CreatedOn", sort: "desc" }],
                        },
                    }}
                />
            </Paper>
        );
    };

    const getDetailPanelContent = useCallback(params => {
        return <DetailPanelContent row={params.row} />;
    }, []);

    let gridSpecificColumns = [
        {
            field: "Value",
            headerName: t("field.Title"),
            width: 150,
            flex: 1,
            editable: false,
        },
        {
            field: "OTUser_select",
            headerName: t("field.Owner"),
            flex: 1,
            width: 150,
            editable: false,
            sortable: false,
            valueFormatter: params => params.value?.Name,
        },
        {
            field: "Share",
            headerName: t("field.Share"),
            width: 150,
            editable: false,
        },
        {
            field: "CreatedOn",
            headerName: t("field.CreatedOn"),
            width: 150,
            editable: false,
            valueFormatter: params =>
                dateTimeToString(params?.value, "DateTime"),
        },
        {
            field: "LastUpdate",
            headerName: t("field.LastUpdate"),
            width: 150,
            editable: false,
            valueFormatter: params =>
                dateTimeToString(params?.value, "DateTime"),
        },
    ];

    const specificActionsColumns = (params, editing, isLocalLoading) => [
        <>
            {runningRight && (
                <GridActionsCellItem
                    label={t("view.query.runQuery")}
                    icon={
                        <BasicTooltip title={t("view.query.runQuery")}>
                            <PlayCircleOutlineOutlinedIcon />
                        </BasicTooltip>
                    }
                    onClick={async () => {
                        dispatch(
                            updateHighlightedRows({
                                CurrentLocation: currentLocation,
                                VisitedRowID: params.row.ID,
                            })
                        );
                        await addNewModel(
                            { QueryID: params.row.ID },
                            token,
                            r.queryResult
                        );
                        const openedDetailsPanels =
                            apiRef.current.getExpandedDetailPanels();
                        if (openedDetailsPanels.includes(params.row.ID)) {
                            apiRef.current.toggleDetailPanel(params.row.ID);
                        }
                        apiRef.current.toggleDetailPanel(params.row.ID);
                    }}
                    disabled={editing || isLocalLoading}
                />
            )}
            <GridActionsCellItem
                label={t("view.query.reporting")}
                icon={
                    <BasicTooltip title={t("view.query.reporting")}>
                        <GridOnOutlinedIcon />
                    </BasicTooltip>
                }
                onClick={() => {
                    // ONGOING : finish the route redirection + fetch daga gird generic and display frontfields
                    // -------
                    historyPush(
                        history,
                        `${dataGrid}${queryViewReporting}/${params.row.ID}/Reporting`
                    );
                }}
            />
            {editingRights && (
                <>
                    <GridActionsCellItem
                        label={t("common.Translate")}
                        icon={
                            <BasicTooltip title={t("common.Translate")}>
                                <LanguageIcon />
                            </BasicTooltip>
                        }
                        onClick={() => {
                            setOpen(true);
                            dispatch(
                                updateHighlightedRows({
                                    CurrentLocation: currentLocation,
                                    VisitedRowID: params.row.ID,
                                })
                            );
                            setRowParams(params.row);
                        }}
                        disabled={editing}
                    />
                    <GridActionsCellItem
                        icon={
                            <BasicTooltip title={t("common.Edit")}>
                                <EditOutlinedIcon />
                            </BasicTooltip>
                        }
                        label={t("common.Edit")}
                        className="textPrimary"
                        onClick={() => {
                            dispatch(
                                updateHighlightedRows({
                                    CurrentLocation: currentLocation,
                                    VisitedRowID: params.row.ID,
                                })
                            );
                            historyPush(
                                history,
                                `/query/edit/${params.row.ID}`
                            );
                        }}
                        color="inherit"
                    />
                </>
            )}
        </>,
    ];

    return (
        <>
            <TranslationDialog
                label={t("field.grid.Queries")}
                open={open}
                setOpen={setOpen}
                rowParams={rowParams}
                baseURL={baseURL}
            />
            <DataGridComponent
                apiRef={apiRef}
                baseURL={baseURL}
                label={label}
                toolbar={{
                    newAction: editingRights && (
                        <ButtonToolbar
                            titleTooltip={t(
                                "view.query.stepper.CreateNewQuery"
                            )}
                            title={t("common.Add")}
                            startIcon={<GridAddIcon />}
                            onClick={() =>
                                historyPush(history, "/query/create/new")
                            }
                        />
                    ),
                    add: false,
                }}
                gridSpecificColumns={gridSpecificColumns}
                specificActionsColumns={specificActionsColumns}
                isEditDisabled={true}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "CreatedOn", sort: "desc" }],
                    },
                }}
                editingRights={editingRights}
                getDetailPanelHeight={() => "auto"}
                getDetailPanelContent={getDetailPanelContent}
                components={{
                    DetailPanelExpandIcon: ChevronRightIcon,
                    DetailPanelCollapseIcon: ExpandMoreIcon,
                }}
                {...props}
            />
        </>
    );
};
export default QueryGrid;
