import React from "react";
import { Container, Grid } from "@mui/material";
import LoginForm from "../Account/LoginForm";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const SplashScreen = ({ screen }) => {
    const isConnected = useSelector(state => state.auth.IsConnected);

    return (
        <Container
            maxWidth={false}
            sx={{
                display: "flex",
                flexDirection: "row",
                padding: 0,
                minHeight: "100vh",
            }}
        >
            <Grid
                container
                direction={"column"}
                alignItems={"center"}
                justifyContent="center"
            >
                <Grid
                    item
                    container
                    sm={12}
                    md={6}
                    direction={"column"}
                    alignItems="center"
                    justifyContent="center"
                >
                        {screen === "login" && <LoginForm />}
                </Grid>
            </Grid>
            {isConnected ? <Redirect to={"/home"} /> : null}
        </Container>
    );
};

export default SplashScreen;
