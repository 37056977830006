import { AppBar, Box, Grid, Divider, ButtonBase } from "@mui/material";
import React from "react";
import { ReactComponent as LogoOneTsigane } from "../../assets/images/logo_one_tsigane.svg";

import { ReactComponent as LogoTotalEnergies } from "../../assets/images/logo_total_energies.svg";
import { useHistory } from "react-router-dom";
import { historyPush } from "../../js/utils/genericMethods";

export const OTAppBar = React.forwardRef(
    ({ UpperAppBar, LowerAppBar, ...props }, ref) => {
        const history = useHistory();

        return (
            <AppBar
                ref={ref}
                sx={{
                    backgroundColor: "#FFFFFF",
                    minHeight: 100,
                    display: "flex",
                    flexDirection: "row",
                }}
                position="sticky"
                elevation={3}
            >
                <Box
                    sx={{
                        display: "flex",
                        mx: 2,
                        alignItems: "center",
                        margin: 2
                    }}
                >
                    <LogoTotalEnergies width="94px" height="66px" />
                    <Divider sx={{ px: 1 }} orientation="vertical" />
                </Box>
                <Grid
                    container
                    sx={{
                        width: 1,
                        mt: 2
                    }}
                >
                    <Grid xs={11} sm={11} md={4} >
                        <ButtonBase onClick={s => historyPush(history, "/home")}>
                            <LogoOneTsigane width="175px" height="35px" />
                        </ButtonBase>
                    </Grid>
                    {/* <Grid
                        xs={8}
                        sm={7} md={4}
                        sx={{
                            typography: "body1",
                            textAlign: "center",
                            pt: 0.8,
                            backgroundColor: "#c40454",
                            color: "white",
                            border: "2px #c40454 solid",
                            borderRadius: 2
                        }}
                    >
                        {t("common.ApplicationUnderMaintenance", {startDate:"01/03", endDate:"07/04"})}
                    </Grid> */}
                    <Grid sm={11} md={8}>
                        {UpperAppBar}
                    </Grid>
                    <Grid xs={12}>
                        <Divider sx={{ mt: 1 }}/>
                    </Grid>
                    <Grid xs={12}>
                        {LowerAppBar}
                    </Grid>
                </Grid>
            </AppBar>
        );
    }
);
