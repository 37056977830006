import * as actionTypes from "../actionTypes"

import { forceComponentsUpdate } from '../store/index';

export const loadTranslations = translations => dispatch => {
    dispatch({
        type: actionTypes.LOAD_TRANSLATIONS,
        translations,
    });
    forceComponentsUpdate();
};

export const setLocale = locale => dispatch => {
    dispatch({
        type: actionTypes.SET_LOCALE,
        locale,
    });
    forceComponentsUpdate();
};