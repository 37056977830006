import React from "react";
import {
    Box,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
    IconButton,
} from "@mui/material";
import { BasicButton } from "../../Widgets/Custom Inputs/Buttons";
import { t } from "react-i18nify";
import { FormProvider, useForm } from "react-hook-form";
import {
    VDateOnlyPicker,
    VTextField,
} from "../../Widgets/Custom Inputs/CustomInputs";
import {
    VMultiSelectURL,
    VSelect,
    VSelectURL,
} from "../../Widgets/Custom Inputs/Select";
import { optionsDocumentCategory } from "../../../js/constants/selectOptions";
import { DialogActionsForm } from "../../Widgets/CustomSurfaces";
import HelpIcon from "@mui/icons-material/HelpOutlineOutlined";
import { isPhotoVideo } from "./MediaChunksUploaderDialog";
import { useParams } from "react-router-dom";

// Previously, from : src/components/Views/DocumentsView.js

const BatchMetadataDialog = ({
    openBatchMetadataDialog,
    setOpenBatchMetadataDialog,
    otEntityId,
    isEddFile,
    triggerBatchMetadataDialog,
    setTriggerBatchMetadataDialog,
    //
    batchMetadaObject,
    setBatchMetadataObject, // WILL TRIGGER FORM
    setMedia, // WILL TRIGGER FORM
    numberOfDocuments = null
    //
    // setCurrentDocument,
    // setMediaMetadata
}) => {
    const { fileType } = useParams();
    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const { handleSubmit, watch, formState } = methods;

    const manageSubmit = async data => {
        setBatchMetadataObject(data);
        closeAndClearForm();
    };

    const isDisabled = formState.isSubmitting || !formState.isDirty;

    /* After submission, will close dialog, clean state but will continue ongoing operations (chunk uploading, document creation)  */
    function closeAndClearForm() {
        setOpenBatchMetadataDialog(false);
        setMedia(triggerBatchMetadataDialog);
    }

    /* Will cancel all ongoing operations */
    function cancelAndCloseForm() {
        setOpenBatchMetadataDialog(false);
        setMedia([]);
    }

    const hasPhotoContext = fileType === isPhotoVideo;

    return (
        <Dialog open={openBatchMetadataDialog} sx={{ w: 1 }} fullWidth>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(manageSubmit)}>
                    <DialogTitle>
                        <Box>
                            <Typography variant="subtitle1">
                                Batch metadata edition -{" "}
                                {numberOfDocuments === null ? triggerBatchMetadataDialog?.length : numberOfDocuments} documents to upload` : 
                                <IconButton
                                    sx={{ ml: 1 }}
                                    //onClick={_e => setToggleInfo(!toggleInfo)} // toggle info below
                                >
                                    <HelpIcon color="primary" />
                                </IconButton>
                            </Typography>
                            <Typography variant="body1">
                                The following metadata will be applied to all
                                documents to be uploaded. You're free to
                                separately edit each document afterwards.
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText sx={{ mb: 2 }}></DialogContentText>
                        <Grid item container spacing={2}>
                            <Grid item xs={12}>
                                <VTextField
                                    label={t("field.Author")}
                                    fieldName={"Author"}
                                    validation={{
                                        required: t(
                                            "input.validation.required"
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <VDateOnlyPicker
                                    label={t("field.PublicationDate")}
                                    fieldName={"PublicationDate"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <VSelect
                                    options={optionsDocumentCategory.map(e => ({
                                        ...e,
                                        label: t(`${e.label}`)
                                    }))}
                                    label={t("field.DocumentCategory")}
                                    fieldName={"DocumentCategory"}
                                    validation={{
                                        required: {
                                            value: true,
                                            message: t(
                                                "input.validation.required"
                                            ),
                                        },
                                    }}
                                />
                            </Grid>
                            {hasPhotoContext && (
                                <Grid item xs={12}>
                                    <VSelectURL
                                        label={t("field.PhotoContext")}
                                        URL={`Ref_PhotoContext`}
                                        fieldName={"Ref_PhotoContextID"}
                                        validation={{
                                            required: {
                                                value: false,
                                                message: t(
                                                    "input.validation.required"
                                                ),
                                            },
                                        }}
                                    />
                                </Grid>
                            )}
                            {!isEddFile ? (
                                <>
                                    <Grid item xs={6}>
                                        <VMultiSelectURL
                                            URL={"Zone"}
                                            label={t("field.RelatedZones")}
                                            fieldName={"RelatedZoneIDs"}
                                            paramURL={`OTEntityIDWithStudy=${otEntityId}`}
                                            defaultValue={watch(
                                                "RelatedZones_Select"
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <VMultiSelectURL
                                            URL={"Study"}
                                            label={t("field.RelatedStudies")}
                                            fieldName={"RelatedStudyIDs"}
                                            paramURL={`OTEntityID=${otEntityId}`}
                                            defaultValue={watch(
                                                "RelatedStudies_select"
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <VMultiSelectURL
                                            URL={"Station"}
                                            label={t("field.RelatedStations")}
                                            fieldName={"RelatedStationIDs"}
                                            paramURL={`OTEntityID=${otEntityId}`}
                                            defaultValue={watch(
                                                "RelatedStations_select"
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <VMultiSelectURL
                                            URL={"StationGroup"}
                                            label={t(
                                                "field.RelatedStationGroups"
                                            )}
                                            fieldName={"RelatedStationGroupIDs"}
                                            paramURL={`OTEntityID=${otEntityId}`}
                                            defaultValue={watch(
                                                "RelatedStationGroups_select"
                                            )}
                                        />
                                    </Grid>
                                </>
                            ) : null}
                        </Grid>
                    </DialogContent>
                    <DialogActionsForm>
                        <BasicButton
                            type="button"
                            style={{
                                position: "relative",
                                margin: 4,
                            }}
                            onClick={() => {
                                cancelAndCloseForm();
                            }}
                        >
                            {t("common.Cancel")}
                        </BasicButton>
                        <BasicButton
                            type="submit"
                            style={{ margin: 4, marginRight: 0 }}
                            disabled={isDisabled}
                        >
                            {t("common.Save")}
                        </BasicButton>
                    </DialogActionsForm>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default BatchMetadataDialog;