
import * as actionTypes from "../actionTypes";


export const updateSearchText = (searchText) => dispatch => {
    dispatch({
        type: actionTypes.SEARCHTEXTCHANGED,
        payload: searchText
    });
};
