import React, { useCallback, useEffect, useState } from "react";
import {
    GridActionsCellItem,
    useGridApiRef,
    GridAddIcon,
} from "@mui/x-data-grid-pro";
import DataGridComponent from "../Widgets/DataGridComponent";
import { useDispatch, useSelector } from "react-redux";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import {
    FormDialog,
    TranslationDialog,
} from "./cst_DataGrid";
import { ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import { zoneEntityRoute, zoneStudyRoute } from "../../routers/MenuRouter";
import { useHistory, useLocation, useParams } from "react-router-dom";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import DoneIcon from "@mui/icons-material/Done";
import * as r from "../../js/constants/routes";
import ZonesMap from "../ArcGIS/ZonesMap";
import { Grid } from "@mui/material";
import { addNewModel, fetchData } from "../../js/utils/backend";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import { ColorButton } from "mui-color";
import { VSelectAdvanced } from "../Widgets/Custom Inputs/Select";
import { t } from "react-i18nify";
import { BasicTooltip } from "../Widgets/StyledComponents";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { updateHighlightedRows } from "../../js/redux/actions/userPref";
import { historyPush } from "../../js/utils/genericMethods";
import { useEditionRightsPerEntityAccess } from "../../js/utils/customHooks";

const ZoneGrid = ({
    label = "",
    baseURL = r.zone.route,
    typeZone,
    viaSelector,
    specificToolbar = null,
    apiRef,
    ...props
}) => {
    const basicRef = useGridApiRef();
    apiRef = apiRef ?? basicRef;
    const token = useSelector(state => state.auth.Token);
    const history = useHistory();
    const { NodeID } = useParams();
    const [mapView, setMapView] = useState(false);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [openCopy, setOpenCopy] = useState(false);
    const [rowParams, setRowParams] = useState();
    const location = useLocation();
    const currentLocation = location.pathname;
    
    const [node, setNode] = useState();
    const [forceGridRefresh, setForceGridRefresh] = useState(false);
    const OTEntityID = node?.OTEntityID;
    const editingRights = useEditionRightsPerEntityAccess(OTEntityID, [
        "EntityManager",
        "Contributor",
    ]);
    const StudyId = node?.StudyID;
    const dispatch = useDispatch();

    const getInfosFromType = info => {
        const infosEntity = {
            route: `${zoneEntityRoute}/${NodeID}`,
            label: node?.OTEntity_Select.Name,
            paramURL: r.filter.genericFilter("OTEntityID", OTEntityID),
        };

        const infosStudy = {
            route: `${zoneStudyRoute}/${NodeID}`,
            label: `${t("view.study.Study")} "${
                node?.Study_Select?.Value
            }" from ${node?.OTEntity_Select.Name}`,
            paramURL: r.filter.genericFilter("StudyId", StudyId),
        };

        return typeZone === "entity" ? infosEntity[info] : infosStudy[info];
    };

    label = `${t(label)} ${getInfosFromType("label")}`;

    const manageSubmitCopyForm = async data => {
        const copyZonesRequest = { StudyId, ...data };
        await addNewModel(copyZonesRequest, token, `${r.zone.route}/CopyZones`);
        setOpenCopy(false);
        setForceGridRefresh(fgr => !fgr);
    };

    const SelecteurAvanceGrid = useCallback(
        ({ fieldName }) => (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <VSelectAdvanced
                        fieldName={fieldName}
                        grid={
                            <ZoneGrid
                                specificToolbar={{
                                    disableToolbar: true,
                                }}
                            />
                        }
                        paramURL={r.filter.genericFilter(
                            "CopyingStudies",
                            StudyId
                        )}
                        duplicateToAvoid={"Code"}
                        emptyOnOpen
                    />
                </Grid>
            </Grid>
        ),
        [StudyId]
    );

    useEffect(() => {
        const fetching = async () => {
            setLoading(true);
            const nodeFetched = await fetchData(
                "OTEntityTree",
                token,
                "ID",
                NodeID
            );
            let node = nodeFetched.data;
            if (typeZone === "study") {
                const studyFetched = await fetchData(
                    "study",
                    token,
                    "ID",
                    node.StudyID
                );
                node = { ...node, Study_Select: studyFetched.data };
            }
            setNode(node);
            setLoading(false);
        };

        fetching();
    }, [NodeID, token, typeZone]);

    let gridSpecificColumns = [
        {
            field: "Code",
            headerName: t("field.Code"),
            width: 150,
            flex: 1,
            editable: false,
        },
        {
            field: "Value",
            headerName: t("field.Name"),
            width: 150,
            editable: false,
        },
    ];

    if (!viaSelector) {
        gridSpecificColumns = [
            ...gridSpecificColumns,
            {
                field: "HexColor",
                headerName: t("field.Color"),
                sortable: false,
                flex: 1,
                width: 150,
                editable: false,
                renderCell: params =>
                    params.value && (
                        <ColorButton
                            disabled
                            tooltip={params.value}
                            color={params.value}
                        />
                    ),
            },
            {
                field: "Extent",
                headerName: t("field.Valid"),
                sortable: false,
                width: 70,
                editable: false,
                renderCell: params =>
                    !params.value.GeoJSON ? (
                        <BasicTooltip title={t("view.zone.ExtendNotDefined")}>
                            <WarningOutlinedIcon color={"error"} />
                        </BasicTooltip>
                    ) : (
                        <BasicTooltip title={t("view.zone.ExtendDefined")}>
                            <DoneIcon color={"secondary"} />
                        </BasicTooltip>
                    ),
            },
        ];
    } else {
        gridSpecificColumns = [
            ...gridSpecificColumns,
            {
                field: "Study_select.Value",
                headerName: "Study",
                sortable: false,
                flex: 1,
                width: 150,
                editable: false,
                valueGetter: params => params.row.Study_select.Value,
            },
        ];
    }

    const SpecificScreenLoading = ({ title }) =>
        viaSelector ? (
            <ScreenLoading
                title={title}
                sx={{
                    display: "flex",
                    height: "280px",
                    justifyContent: "center",
                }}
            />
        ) : (
            <ScreenLoading />
        );

    const specificActionsColumns = (params, editing, isLocalLoading) => [
        <>
            {editingRights && (
                <>
                    <GridActionsCellItem
                        label={t("common.Translate")}
                        icon={
                            <BasicTooltip title={t("common.Translate")}>
                                <LanguageIcon />
                            </BasicTooltip>
                        }
                        onClick={() => {
                            setOpen(true);
                            setRowParams(params.row);
                        }}
                        disabled={editing || isLocalLoading}
                    />
                    <GridActionsCellItem
                        icon={
                            <BasicTooltip title={t("common.Edit")}>
                                <EditOutlinedIcon />
                            </BasicTooltip>
                        }
                        label={t("common.Edit")}
                        className="textPrimary"
                        onClick={() => {
                            dispatch(
                                updateHighlightedRows({
                                    CurrentLocation: currentLocation,
                                    VisitedRowID: params.row.ID,
                                })
                            );
                            historyPush(
                                history,
                                `${getInfosFromType("route")}/edit/${
                                    params.row.ID
                                }`
                            );
                        }}
                        color="inherit"
                        disabled={isLocalLoading}
                    />
                </>
            )}
        </>,
    ];

    return loading ? (
        <SpecificScreenLoading title={t("view.zone.LoadingEntity")} />
    ) : mapView ? (
        <ZonesMap
            title={label}
            toolbarComponent={
                <Grid item sx={{ display: "inline-flex" }}>
                    <ButtonToolbar
                        titleTooltip={t("common.AccessZonesGrid")}
                        title={t("common.GridView")}
                        startIcon={<TableRowsOutlinedIcon />}
                        onClick={() => setMapView(false)}
                    />
                </Grid>
            }
            OTEntityID={OTEntityID}
            StudyID={StudyId}
            typeZone={typeZone}
        />
    ) : (
        <>
            {!viaSelector && (
                <FormDialog
                    label={t("dialog.CopyZonesFromStudies")}
                    manageEdition={{ open: openCopy }}
                    closeForm={() => setOpenCopy(false)}
                    manageSubmit={manageSubmitCopyForm}
                    saveLabel={t("zone.CopyZones")}
                    fieldName={"ZoneIds"}
                    Content={SelecteurAvanceGrid}
                />
            )}
            <TranslationDialog
                label={t("field.Zone")}
                open={open}
                setOpen={setOpen}
                rowParams={rowParams}
                baseURL={baseURL}
            />
            <DataGridComponent
                apiRef={apiRef}
                baseURL={baseURL}
                label={label}
                paramURL={getInfosFromType("paramURL")}
                specificScreenLoading={
                    <SpecificScreenLoading title="Loading table..." />
                }
                toolbar={
                    specificToolbar ?? {
                        newAction: editingRights && (
                            <>
                                <ButtonToolbar
                                    titleTooltip="Create a new zone"
                                    title={t("common.Add")}
                                    startIcon={<GridAddIcon />}
                                    onClick={() =>
                                        historyPush(
                                            history,
                                            `${getInfosFromType(
                                                "route"
                                            )}/create/new`
                                        )
                                    }
                                />
                                {typeZone === "study" && (
                                    <ButtonToolbar
                                        titleTooltip={t(
                                            "view.zone.CopyZonesFromStudy"
                                        )}
                                        title={t("view.zone.CopyZones")}
                                        startIcon={<CopyAllIcon />}
                                        onClick={() => setOpenCopy(true)}
                                    />
                                )}
                            </>
                        ),
                        add: false,
                        newFilter: (
                            <Grid item sx={{ display: "inline-flex" }}>
                                <ButtonToolbar
                                    titleTooltip={t("common.AccessZonesMap")}
                                    title={t("common.MapView")}
                                    startIcon={<MyLocationOutlinedIcon />}
                                    onClick={() => setMapView(true)}
                                />
                            </Grid>
                        ),
                    }
                }
                gridSpecificColumns={gridSpecificColumns}
                specificActionsColumns={specificActionsColumns}
                isEditDisabled={true}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "Value", sort: "asc" }],
                    },
                }}
                forceGridRefresh={forceGridRefresh}
                editingRights={editingRights}
                {...props}
            />
        </>
    );
};

export default ZoneGrid;
