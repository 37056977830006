import React, { useState } from "react";
import { Paper } from "@mui/material";
import UserRequestsGrid from "../DataGrid/UserRequestsGrid";
import NewRequestPanel from "./Edition panels/NewRequestPanel";

const NewUserView = () => {
    const [forceGridRefresh, setForceGridRefresh] = useState(false);

    return (
        <>
            <Paper sx={{ width: "100%", m: 1, p: 2 }}>
                <NewRequestPanel setForceGridRefresh={setForceGridRefresh} />
            </Paper>

            <Paper sx={{ width: "100%", m: 1 }}>
                <UserRequestsGrid defaultforceGridRefresh={forceGridRefresh} />
            </Paper>
        </>
    );
};

export default NewUserView;
