import React, {  } from "react";
import { Box } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { backendURL } from "../../js/utils/backend";

const CarouselPictures = ({ images }) => {
    return (
        <Carousel
            sx={{
                width: 1,
                height: "200px",
            }}
            autoPlay={true}
            animation="slide"
            duration={800}
            navButtonsAlwaysVisible={false}
        >
            {images.map((imageID, i) => (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    key={i}
                >
                    <Box
                        key={i}
                        sx={{
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            width: "250px",
                            height: "250px",
                            backgroundImage: `url(${backendURL}/image/${imageID})`,
                        }}
                    />
                </Box>
            ))}
        </Carousel>
    );
};
export default CarouselPictures