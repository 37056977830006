import * as actionTypes from "../actionTypes";

const initialState = {
    translations: "", 
    locale: "ENG" 
};

export default function i18nReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.LOAD_TRANSLATIONS:
            return {
                ...state,
                translations: action.translations,
            };
        case actionTypes.SET_LOCALE:
            return {
                ...state,
                locale: action.locale,
            };
        default:
            return state;
    }
};