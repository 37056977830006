import React, { useEffect, useState, useCallback } from "react";
import { Grid, Box, Button, TextField } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { t } from "react-i18nify";
import { HiddenField } from "../../Widgets/Custom Inputs/CustomInputs";
import { useSelector } from "react-redux";
import Axios from "axios";
import { toastr } from "react-redux-toastr";

const NewFeaturesMessage = () => {
    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });

    const { register, handleSubmit, formState, setValue, watch } = methods;
    const { isDirty, isValid, errors } = formState;

    const [features, setFeatures] = useState({ English: "", French: "" });
    const token = useSelector((state) => state.auth.Token);

    const fetchFeatures = useCallback(async () => {
        const config = {
            headers: { Authorization: "Bearer " + token },
        };
        try {
            const response = await Axios.get('NewFeaturesMessage?orderBy=CreatedOn', config);
            const data = response.data.Data;
            if (data.length > 0) {
                const latestFeature = data[0];
                setFeatures({
                    English: latestFeature.English || "",
                    French: latestFeature.French || "",
                });
                setValue("English", latestFeature.English || "");
                setValue("French", latestFeature.French || "");
            }
        } catch (error) {
            console.error("Error fetching features:", error);
        }
    }, [token, setValue]);

    useEffect(() => {
        fetchFeatures();
    }, [fetchFeatures]);

    const onSubmit = async (data) => {
        const config = {
            headers: { Authorization: "Bearer " + token },
        };
        try {
            await Axios.post('NewFeaturesMessage', data, config);
            fetchFeatures();
            toastr.success(t('common.SavedSuccessfully'));
        } catch (error) {
            console.error("Error submitting features:", error);
        }
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", m: 1 }}>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} sx={{ mt: 5 }}>
                        <Grid item xs={12}>
                            <TextField
                                label={t("languages.English")}
                                name="English"
                                multiline
                                rows={8}
                                variant="outlined"
                                fullWidth
                                error={!!errors.English}
                                helperText={errors.English ? errors.English.message : ""}
                                {...register("English", {
                                    required: t("input.validation.required"),
                                    onChange: (e) => {
                                        setValue("English", e.target.value);
                                        setFeatures((prevFeatures) => ({
                                            ...prevFeatures,
                                            English: e.target.value,
                                        }));
                                    },
                                })}
                                value={watch("English", features.English)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={t("languages.French")}
                                name="French"
                                multiline
                                rows={8}
                                variant="outlined"
                                fullWidth
                                error={!!errors.French}
                                helperText={errors.French ? errors.French.message : ""}
                                {...register("French", {
                                    required: t("input.validation.required"),
                                    onChange: (e) => {
                                        setValue("French", e.target.value);
                                        setFeatures((prevFeatures) => ({
                                            ...prevFeatures,
                                            French: e.target.value,
                                        }));
                                    },
                                })}
                                value={watch("French", features.French)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <HiddenField
                                fieldName="CreatedOn"
                                defaultValue={new Date()}
                            />
                        </Grid>
                        <Box sx={{ margin: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={!isDirty || !isValid}
                            >
                                {t("Save")}
                            </Button>
                        </Box>
                    </Grid>
                </form>
            </FormProvider>
        </Box>
    );
};

export default NewFeaturesMessage;