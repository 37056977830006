import React, { useEffect, useState } from "react";
import { Box, Grid, Button } from "@mui/material";
import { t } from "react-i18nify";
import { FormProvider, useForm } from "react-hook-form";
import EditionPanelGlobal from "./EditionPanelGlobal";
import { ScreenLoading } from "../../Widgets/CustomLoadings";
import { fetchData } from "../../../js/utils/backend";
import { useSelector } from "react-redux";
import { VCheckBox, VTextField } from "../../Widgets/Custom Inputs/CustomInputs";
import Axios from "axios";
import CopyToClipboard from 'react-copy-to-clipboard';
import { BasicButton } from "../../Widgets/Custom Inputs/Buttons";
import { toastr } from "react-redux-toastr";

/** Page view where the user can see main data about accessible entities */
const UserPrefEditionPanel = ({ route = "User/userPrefs" }) => {
    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const { reset } = methods;
    const token = useSelector(state => state.auth.Token);
    const userID = useSelector(state => state.auth.UserID);
	const externalProvidersToken = useSelector(state => state.auth.ExternalProvidersToken);
    const [loading, setLoading] = useState(true);
	const [externalProviderToken, setExternalProviderToken] = useState(externalProvidersToken ? externalProvidersToken : '');

    useEffect(() => {
        const fetching = async () => {
            const response = await fetchData(route, token, "List");
            reset(response.data.Value);
            setLoading(false);
        };
        if (loading) {
            fetching();
        }
    }, [reset, route, token, loading]);

    const generateToken = () => {
        let config = {
            headers: { Authorization: "Bearer " + token },
        };
        const fetching = async () => {
            const response = await Axios.get('user/get-token-for-external-providers', config);
            setExternalProviderToken(response.data.Token);
            await Axios.post(`user/update-provider-token-for-external-users`, {  ID: userID, Token: response.data.Token }, config);
        }
        fetching();
    }

    return loading ? (
        <ScreenLoading />
    ) : (
        <FormProvider {...methods}>
            <Grid m={1}>
                <Box sx={{ typography: "h3", p: 2, ml: 2 }}>
                    {t("grid.title.UserPref")}
                </Box>
                <Grid p={2}>
                    <EditionPanelGlobal
                        route={route}
                        ID={userID}
                        onSubmit={() => setLoading(true)}
                        back={false}
                    >
                        <>
                            <Grid item xs={12}>
                                <VCheckBox
                                    label={t("field.EmailNotif")}
                                    fieldName={"RecieveEmail"}
                                />
                            </Grid>
                            <Box sx={{typography: "body1",fontWeight: "bold",
                                            margin: 2,}}>{t("field.ExplanatoryText")}
                                            </Box>
                            <Grid item xs={12}>
                             <VTextField
                                fieldName={"Explanatory Text"}
                                multiline
                                rows={4}
                                inputProps={
                                    { readOnly: true }
                                }
                                disabled
                                value={externalProviderToken}   
                             />
                            <div>
                                <CopyToClipboard text={externalProviderToken}
                                onCopy={() =>toastr.success(t('common.CopiedSuccessfully'))}>
                                <BasicButton size="small">{t("common.CopytoClipboard")}</BasicButton>
                                </CopyToClipboard>
                            </div>
                            </Grid>
                            <Grid item xs={12}>
                            <Button 
                                            onClick={generateToken}
                                            variant="contained"
                                            type="submit"
                                            size="small"
                                            sx={{
                                                ml: 1,
                                            }}
                                            disabled = {externalProviderToken !== ''}
                                        >
                                            {t("common.GenerateAToken")}
                            </Button>
                            </Grid>
                        </>
                        
                    </EditionPanelGlobal>
                </Grid>
            </Grid>
        </FormProvider>
    );
};

export default UserPrefEditionPanel;
