import React from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import * as r from "../../js/constants/routes";
import { SelectURLGrid } from "../Widgets/Custom Inputs/Select";
import { requiredCell } from "../../js/utils/inputValidations";
import DataGridComponent from "../Widgets/DataGridComponent";
import { t } from "react-i18nify";
import { filterValidUser } from "./cst_DataGrid";
import { ListItem, ListItemText } from "@mui/material";

const CompanyManagerGrid = ({ label = t("grid.title.CompanyManagers"), baseURL }) => {
    const apiRef = useGridApiRef();
    const cellFocusField = "OTUser_select"

    const gridSpecificColumns = [
        {
            field: "OTUser_IGG",
            headerName: "IGG",
            flex: 0.5,
            valueGetter: params => params.row.OTUser_select?.IGG,
            sortable: false,
        },
        {
            field: "OTUser_select",
            headerName: t("field.User"),
            flex: 1,
            width: 200,
            editable: true,
            valueFormatter: params => params.value?.Name,
            renderEditCell: params => (
                <SelectURLGrid
                    fieldName={"OTUserID"}
                    paramURL={filterValidUser}
                    selectRoute={"SelectDetail"}
                    URL={r.oTUser}
                    getOptionLabel={option => option.Name ?? option.IGG}
                    filterOptions={options => options}
                    renderOption={(props, option) => (
                        <ListItem {...props} key={option.ObjectID}>
                            <ListItemText
                                sx={{
                                    margin: 0,
                                    padding: 0,
                                }}
                                inset
                                primary={option.Name}
                                secondary={option.IGG}
                            />
                        </ListItem>
                    )}
                    {...params}
                />
            ),
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "User",
                    "OTUser_select"
                );
                return { ...params.props, error: hasError };
            },
            valueSetter: params => ({
                ...params.row,
            }),
            sortable: false,
        },
        {
            field: "OTUserID",
            valueGetter: params => params.row?.OTUser_select?.ObjectID,
        },
        {
            field: "EMail",
            headerName: "Email",
            flex: 0.5,
            valueGetter: params => params.row.OTUser_select?.EMail,
            sortable: false,
        },
    ];

    return (
        <DataGridComponent
            apiRef={apiRef}
            baseURL={baseURL}
            columnVisibilityModel={{
                "OTUserID": false
            }}
            cellFocusField={cellFocusField}
            label={label}
            gridSpecificColumns={gridSpecificColumns}
            isEditDisabled={true}
            initialState={{
                sorting: {
                    sortModel: [{ field: "OTUserID", sort: "asc" }],
                },
            }}
        />
    );
};

export default CompanyManagerGrid;
