import { combineReducers } from "redux";
import { reducer as toastrReducer } from "react-redux-toastr";
import AuthReducer from "./auth";
import SyncTabReducer from "./syncTab";
import i18nReducer from "./i18n"
import UserPrefReducer from "./userPref";
import SearchFilterReducer from "./searchFilter";
import storage from "redux-persist/lib/storage";
import { UNAUTHENTICATED, VERSIONCHANGE } from "../actionTypes";

const appReducer = combineReducers({
    auth: AuthReducer,
    synctab: SyncTabReducer,
    toastr: toastrReducer,
    i18n: i18nReducer,
    userPref: UserPrefReducer,
    searchFilter : SearchFilterReducer
});

/** The called method clears the redux state (even when app is open in multiple tabs: it has not side effects with TABALIVE).  */
const cleanRedux = (action, newState = undefined) => {
    storage.removeItem("persist:OneTsigane");
    return appReducer(newState, action);
};

/** Root reducer DOC*/
const rootReducer = (state, action) => {
    if (action.type === UNAUTHENTICATED) {
        return cleanRedux(action);
    }
    if (action.type === VERSIONCHANGE) {
        if (state.auth.versionNumber !== action.payload)
            return cleanRedux(action, {
                auth: { versionNumber: action.payload },
            });
    }
    return appReducer(state, action);
};

export default rootReducer;
