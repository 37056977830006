import {
    Box,
    ClickAwayListener,
    IconButton,
    Paper,
    Popper,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import KebabIcon from "@mui/icons-material/MoreVert";
import { t } from "react-i18nify";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { BasicTooltip } from "./StyledComponents";
import { isArrayLength } from "../../js/utils/genericMethods";

export const MenuKebab = ({ children, disabled }) => {
    const [canevasAnchor, setCanevasAnchor] = useState(null);

    const menuOpen = Boolean(canevasAnchor);

    const childrenWithClose = children
        ? children.map
            ? children.map(child => {
                  if (child) {
                      const newChild = {
                          ...child,
                          props: {
                              ...child.props,
                              close: () => setCanevasAnchor(null),
                          },
                      };
                      return newChild;
                  }
                  return null;
              })
            : {
                  ...children,
                  props: {
                      ...children.props,
                      close: () => setCanevasAnchor(null),
                  },
              }
        : null;
    return (
        <>
            {isArrayLength(children) && (
                <GridActionsCellItem
                    disabled={disabled}
                    label={t("common.SeeMore")}
                    icon={
                        <BasicTooltip
                            placement="top"
                            title={t("common.SeeMore")}
                        >
                            <KebabIcon />
                        </BasicTooltip>
                    }
                    onClick={event => {
                        setCanevasAnchor(
                            canevasAnchor ? null : event.currentTarget
                        );
                    }}
                />
            )}

            <Popper
                open={menuOpen}
                anchorEl={canevasAnchor}
                onClose={() => setCanevasAnchor(null)}
                style={{
                    zIndex: 99,
                    maxWidth: "20vw",
                }}
                placement={"bottom-end"}
            >
                <ClickAwayListener onClickAway={() => setCanevasAnchor(null)}>
                    <Paper sx={{ p: 1 }}>{childrenWithClose}</Paper>
                </ClickAwayListener>
            </Popper>
        </>
    );
};

export const KebabItem = ({
    icon,
    label,
    disabled = false,
    onClick,
    close,
}) => {
    return (
        <Box
            style={{ display: "flex", cursor: "pointer", alignItems: "center" }}
            onClick={e => {
                onClick(e);
                close && close();
            }}
        >
            <>
                <IconButton
                    disabled={disabled}
                    style={{ padding: 4 }}
                    disableRipple
                    disableFocusRipple
                >
                    {icon}
                </IconButton>
                <Typography
                    variant="caption"
                    style={{ marginRight: 8, textAlign: "left" }}
                >
                    {label}
                </Typography>
            </>
        </Box>
    );
};
