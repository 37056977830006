import React, { useState, useEffect } from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import DataGridComponent from "../Widgets/DataGridComponent";
import { t } from "react-i18nify";
import { ListItem, TextField } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { dateOnlyToString } from "../../js/utils/genericMethods";
import SelectURL, { SelectURLGrid } from "../Widgets/Custom Inputs/Select";
import {
    dateRangeValidation,
    requiredCell,
} from "../../js/utils/inputValidations";
import { DateOnlyPickerGrid } from "../Widgets/Custom Inputs/CustomInputs";
import { frequencyQuery } from "../../js/constants/selectOptions";
import * as r from "../../js/constants/routes";
import { Box } from "@mui/system";
import FilterListIcon from "@mui/icons-material/FilterListOutlined";
import { useEditionRightsPerUserStatus } from "../../js/utils/customHooks";
import { updateSearchText } from "../../js/redux/actions/searchFilter";
import { useDispatch, useSelector } from "react-redux";

const ReportSchedulerGrid = ({ label, baseURL, ...props }) => {
    const apiRef = useGridApiRef();
    const dispatch = useDispatch();
    let filters = useSelector(state => state.searchFilter.Filters);
    const [filterQuery, setFilterQuery] = useState(
        filters.filter(d => d.BaseUrl === baseURL)[0]
        ? filters.filter(d => d.BaseUrl === baseURL)[0].FilterQuery : null
    );

    useEffect(() => {
        if(filters.filter(d => d.BaseUrl===baseURL)[0]){
            filters.filter(d => d.BaseUrl===baseURL)[0].FilterQuery = filterQuery
            dispatch(updateSearchText(filters))
        }
    }, [filterQuery, baseURL, filters, dispatch]);

    const editingRights = useEditionRightsPerUserStatus([
        "EntityManager",
        "Contributor",
    ]);
    const gridSpecificColumns = [
        {
            field: "Query_select",
            sortable: false,
            headerName: t("field.QueryName"),
            flex: 1,
            editable: true,
            valueFormatter: params => params.value?.Title,
            renderEditCell: params => {
                return (
                    <SelectURLGrid
                        fieldName={"QueryID"}
                        URL={r.query.route}
                        getOptionLabel={option => {
                            return option?.Title;
                        }}
                        renderOption={(props, option) => {
                            return (
                                <ListItem {...props} key={option?.ObjectID}>
                                    {option?.Title}
                                </ListItem>
                            );
                        }}
                        {...params}
                    />
                );
            },
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "Query name",
                    "Query_select"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "QueryID",
            editable: true,
            sortable: false,
            valueGetter: params => params?.row?.Query_select?.ObjectID,
        },
        {
            field: "Frequency",
            headerName: t("field.Frequency"),
            width: 300,
            flex: 1,
            type: "singleSelect",
            valueFormatter: params => {
                let displayValue = frequencyQuery.filter(
                    e => e.value === params.value && e.label
                );
                return displayValue?.[0]?.label;
            },
            valueOptions: frequencyQuery.map(e => {
                e.label = t(e.label);
                return e;
            }),
            editable: true,
            sortable: false,
        },
        {
            field: "StartDate",
            headerName: t("field.StartDate"),
            flex: 1,
            editable: true,
            type: "date",
            valueFormatter: params => dateOnlyToString(params.value),
            renderEditCell: params => {
                /** No choice but to dupicate this check, to make custom component triggered by error (style) */
                const hasError = dateRangeValidation(
                    params,
                    apiRef,
                    t("field.StartEndDate"),
                    "StartDate",
                    "EndDate"
                );
                return (
                    <DateOnlyPickerGrid
                        fieldName={params.field}
                        error={hasError}
                        {...params}
                    />
                );
            },
            preProcessEditCellProps: params => {
                const hasError = dateRangeValidation(
                    params,
                    apiRef,
                    t("field.StartEndDate"),
                    "StartDate",
                    "EndDate"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "EndDate",
            headerName: t("field.EndDate"),
            flex: 1,
            editable: true,
            type: "date",
            valueFormatter: params => dateOnlyToString(params.value),
            renderEditCell: params => {
                /** No choice but to dupicate this check, to make custom component triggered by error (style) */
                const hasError = dateRangeValidation(
                    params,
                    apiRef,
                    t("field.StartEndDate"),
                    "StartDate",
                    "EndDate"
                );
                return (
                    <DateOnlyPickerGrid
                        fieldName={params.field}
                        error={hasError}
                        {...params}
                    />
                );
            },
            preProcessEditCellProps: params => {
                const hasError = dateRangeValidation(
                    params,
                    apiRef,
                    t("field.StartEndDate"),
                    "StartDate",
                    "EndDate"
                );
                return { ...params.props, error: hasError };
            },
        },
    ];

    return (
        <>
            <DataGridComponent
                apiRef={apiRef}
                baseURL={baseURL}
                label={label}
                columnVisibilityModel={{
                    QueryID: false,
                }}
                gridSpecificColumns={gridSpecificColumns}
                paramURL={
                    filterQuery ? `filter[QueryID]=${filterQuery.ObjectID}` : ``
                }
                initialState={{
                    sorting: {
                        sortModel: [{ field: "CreatedOn", sort: "desc" }],
                    },
                }}
                toolbar={{
                    newFilter: (
                        <Box
                            sx={{
                                maxWidth: "280px",
                                minWidth: "200px",
                                p: 1,
                            }}
                        >
                            <SelectURL
                                variant="outlined"
                                value={filterQuery}
                                URL={"query"}
                                getOptionLabel={option => option.Title ?? ""}
                                renderOption={(props, option) => {
                                    return (
                                        <ListItem
                                            {...props}
                                            key={option?.ObjectID}
                                        >
                                            {option?.Title}
                                        </ListItem>
                                    );
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        placeholder={t("field.QueryName")}
                                        variant="outlined"
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <FilterListIcon
                                                    fontSize="small"
                                                    sx={{
                                                        mb: "2px",
                                                        color: "red",
                                                    }}
                                                />
                                            ),
                                        }}
                                        color="primary"
                                        size="small"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )}
                                onChange={(event, data) => setFilterQuery(data)}
                                isOptionEqualToValue={(option, value) =>
                                    option.ObjectID === value.ObjectID
                                }
                                //disableClearable
                            />
                        </Box>
                    ),
                }}
                editingRights={editingRights}
                getDetailPanelHeight={() => "auto"}
                components={{
                    DetailPanelExpandIcon: ChevronRightIcon,
                    DetailPanelCollapseIcon: ExpandMoreIcon,
                }}
                {...props}
            />
        </>
    );
};
export default ReportSchedulerGrid;
