import React, { useCallback } from "react";
import EntityWelcomeView from "../components/Views/EntityWelcomeView";
import * as r from "../js/constants/routes";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import { t, Translate } from "react-i18nify";
import {
    checkAccess,
    dataGrid,
    eddFileDiagramRoute,
    entitiesNode,
    randomID,
    refRoutesNodes,
} from "./MenuRouter";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import GenericDataGrid from "../components/Views/GenericDataGrid/GenericDataGrid";
import { Box } from "@mui/material";
import WorkOrderGrid from "../components/DataGrid/WorkOrderGrid";
import EntityPageView from "../components/Views/EntityPageView";
import EDDFileGrid from "../components/DataGrid/EDDFileGrid";
import EddFileDiagram from "../components/Diagrams/EddFileDiagram";
import DocumentsView from "../components/Views/DocumentsView";
import CountryGrid from "../components/DataGrid/CountryGrid";
import BranchGrid from "../components/DataGrid/BranchGrid";
import FamilyGrid from "../components/DataGrid/FamilyGrid";
import RegionGrid from "../components/DataGrid/RegionGrid";
import AnalyteGrid from "../components/DataGrid/AnalyteGrid";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import RequestIcon from "@mui/icons-material/AddTaskOutlined";
import UserRequestsGrid from "../components/DataGrid/UserRequestsGrid";
import { updateTreeViewNodeExpand } from "../js/redux/actions/userPref";
import UserPrefEditionPanel from "../components/Views/Edition panels/UserPrefEditionPanel";
import HelpPageView from "../components/Views/HelpPageView";

/** Method, which when given an array of nodes, will be able to give to the external tree which nodes to expand (to the current node) */
export const externalTreeExtendPathNodes = async (
    containingNode,
    token,
    dispatch,
    paramURL = "otentitytreepath",
    isLocalTreeNode = false
) => {
    let nodes = [];

    nodes.push("");
    /** By using the retrieved all parent nodes, we can build an array of nodes  */
    // let searchTree = element => {
    //     for (const key in element) {
    //         if (key === "ID") {
    //             nodes.push(element.ID);
    //             searchTree(element.Parent);
    //         }
    //     }
    // };

    dispatch(updateTreeViewNodeExpand(nodes));
    return nodes;
};

const externalTreeLoadingRouteSwitch = node => {
    switch (node.NodeType) {
        case "StudyType":
            return `${r.study}/select?filter[OTEntityID]=${node.OTEntityID}&filter[StudyTypeID]=${node.ID}`;
        case "Study":
            return `${r.WorkOrder}/select?filter[StudyID]=${node.ID}`;
        case "WorkOrder":
            return `${r.eddFile.route}/select?filter[WorkOrderID]=${node.ID}`;
        case "EDDFile":
            return `${r.eddGrids}/${node.StudyType}/`;
        default:
            return ``;
    }
};
const externalTreeRouteSwitch = node => {
    switch (node.NodeType) {
        case "GenericGrid":
            return `${dataGrid}/${node.Name}/${node.OTEntityID}/root/EDDFileID/${node.EDDFileID}`;
        case "WorkOrder":
            return `${dataGrid}/WorkOrder/${node.ObjectID}${r.eddFile.route}`;
        default:
            return ``;
    }
};
const externalTreeLabelSwitch = node => {
    switch (node.NodeType) {
        case "Study":
            return `${node.StudyCode}:${node.Value}`;
        case "WorkOrder":
            return `${node.WorkOrderNumber}:${node.Value}`;
        case "GenericGrid":
            return `${node.DetailedName}`;
        default:
            return node.Value;
    }
};

const externalTreeDataMapper = e => {
    return {
        route: externalTreeRouteSwitch(e),
        label: externalTreeLabelSwitch(e),
        loadingRoute: externalTreeLoadingRouteSwitch(e),
        dataMapper: node => externalTreeDataMapperSwitch(node),
        ...e,
    };
};

export const externalTreeDataMapperSwitch = node => {
    switch (node.NodeType) {
        case "StudyType":
            return externalTreeDataMapper({
                ...node,
                ID: node.ObjectID,
                dataMapper: e =>
                    externalTreeDataMapperSwitch({
                        ...e,
                        NodeType: "Study",
                        OTEntityID: node.OTEntityID,
                        StudyType: node.Key,
                    }),
            });
        case "Study":
            return externalTreeDataMapper({
                ...node,
                ID: node.ObjectID,
                dataMapper: e =>
                    externalTreeDataMapperSwitch({
                        ...e,
                        NodeType: "WorkOrder",
                        OTEntityID: node.OTEntityID,
                        StudyType: node.StudyType,
                    }),
            });
        case "WorkOrder":
            return externalTreeDataMapper({
                ...node,
                ID: node.ObjectID,
                dataMapper: e =>
                    externalTreeDataMapperSwitch({
                        ...e,
                        NodeType: "EDDFile",
                        OTEntityID: node.OTEntityID,
                        StudyType: node.StudyType,
                    }),
            });
        case "EDDFile":
            return externalTreeDataMapper({
                ...node,
                ID: node.ObjectID,
                dataMapper: e => {
                    return e.Entities.map(grid =>
                        externalTreeDataMapperSwitch({
                            ...grid,
                            NodeType: "GenericGrid",
                            OTEntityID: node.OTEntityID,
                            StudyType: node.StudyType,
                            ID: `${node.ObjectID}-${grid.Name}`,
                            EDDFileID: node.ObjectID,
                        })
                    );
                },
            });
        default:
            return externalTreeDataMapper(node);
    }
};

export const externalMenuTree = entities => [
    {
        ID: randomID(),
        label: <Translate value={"view.entityPage.Home"} />,
        route: "/home",
        component: <EntityWelcomeView />,
        Icon: HomeOutlinedIcon,
    },
    {
        ID: randomID(),
        label: <Translate value={"tree.Requests"} />,
        route: "/requests",
        Icon: RequestIcon,

        children: [
            {
                ID: randomID(),
                label: <Translate value={"grid.title.MyRequests"} />,
                route: `${dataGrid}${r.request}/myrequests`,
                component: <UserRequestsGrid />,
                parentNode: t("grid.title.Requests"),
            },
        ],
    },
    {
        ID: entitiesNode,
        label: <Translate value={"tree.Entities"} />,
        Icon: FactoryOutlinedIcon,
        children: entities.map(e => ({
            ID: e.ObjectID,
            label: e.Name,
            OTEntityID: e.ObjectID,
            route: `/entity-page/${e.ObjectID}`,
            loadingRoute: `${r.studyType}/select?filter[IsMultiStudyType]=false&orderby=Value&dir=asc`,
            hasContextMenu: false,
            dataMapper: elem =>
                externalTreeDataMapperSwitch({
                    ...elem,
                    NodeType: "StudyType",
                    OTEntityID: e.ObjectID,
                }),
        })),
    },
    {
        ID: "References",
        nodeID: "References",
        label: <Translate value={"tree.References"} />,
        Icon: TableRowsOutlinedIcon,
        children: [
            {
                ID: `${dataGrid}${r.analytes.route}`,
                nodeID: `${r.analytes.route}`,
                label: t("gird.title.Analytes"),
                route: `${dataGrid}${r.analytes.route}`,
                component: (
                    <AnalyteGrid
                        baseURL={r.analytes.route}
                        label={r.analytes.label}
                    />
                ),
                parentNode: t("tree.References"),
            },
            {
                ID: `${dataGrid}${r.region.route}`,
                nodeID: `${r.region.route}`,

                label: t("field.Region"),
                route: `${dataGrid}${r.region.route}`,
                component: (
                    <RegionGrid
                        baseURL={r.region.route}
                        label={r.region.label}
                    />
                ),
                parentNode: t("tree.References"),
            },
            {
                ID: `${dataGrid}${r.family.route}`,
                nodeID: `${r.family.route}`,
                label: t(`field.Family`),
                route: `${dataGrid}${r.family.route}`,
                component: (
                    <FamilyGrid
                        baseURL={r.family.route}
                        label={r.family.label}
                    />
                ),
                parentNode: t("tree.References"),
            },
            {
                ID: `${dataGrid}${r.branch.route}`,
                nodeID: `${r.branch.route}`,
                label: t("field.Branch"),
                route: `${dataGrid}${r.branch.route}`,
                component: (
                    <BranchGrid
                        baseURL={r.branch.route}
                        label={r.branch.label}
                        paramURL={r.filter.allFilter}
                    />
                ),
                parentNode: t("tree.References"),
            },
            {
                ID: `${dataGrid}${r.country.route}`,
                nodeID: `${r.country.route}`,
                label: t("field.Country"),
                route: `${dataGrid}${r.country.route}`,
                component: (
                    <CountryGrid
                        baseURL={r.country.route}
                        label={r.country.label}
                    />
                ),
                parentNode: t("tree.References"),
            },
            {
                ID: "Lookups",
                nodeID: "Lookups",
                label: t("tree.Lookups"),
                Icon: null,
                children: refRoutesNodes(),
                parentNode: t("tree.References"),
            },
        ],
    },
];

const TreeRoutes = ({ menu, auth }) => {
    const GenerateRoute = (data = []) => {
        return data.map(e => {
            return (
                <Box key={randomID()}>
                    {e.route && (
                        <Route
                            exact
                            key={e}
                            path={e.route}
                            render={() => e.component}
                        />
                    )}
                    {e.children && GenerateRoute(e.children)}
                </Box>
            );
        });
    };

    return GenerateRoute(checkAccess(menu, auth));
};

export const externalMenuRoutes = [
    { label: "/home", route: "/home", component: <EntityWelcomeView /> },
    {
        label: "Entity page",
        route: "/entity-page",
        component: <EntityWelcomeView />,
    },
    {
        route: `${dataGrid}${r.study}/:elementID/:linkOrigin/OTEntityID/:otEntityID`,
        component: <WorkOrderGrid />,
    },
    {
        route: `${dataGrid}/WorkOrder/:WorkOrderID${r.eddFile.route}`,
        component: (
            <EDDFileGrid
                baseURL={r.eddFile.route}
                label={r.eddFile.label}
                external
            />
        ),
    },
    {
        route: eddFileDiagramRoute,
        component: <EddFileDiagram />,
    },
    {
        route: `/:fileType/:element/:elementID/:hasDocuments/:otEntityID`,
        component: <DocumentsView />,
    },
];

const ExternalMenuRouter = () => {
    const { CompanyManager, HoldingManager, BranchManager, EntityManager } = useSelector(
        state => state.auth
    );

    const TreeRoutesMemo = useCallback(
        () =>
            TreeRoutes({
                menu: [...externalMenuTree([])],
                auth: {
                    CompanyManager,
                    HoldingManager,
                    BranchManager,
                    EntityManager,
                },
            }),
        [CompanyManager, HoldingManager, BranchManager, EntityManager]
    );

    return (
        <Switch>
            <Route
                path="/entity-page/:UrlID"
                render={() => <EntityPageView />}
            />

            {/* MEMO: Temporary routes, rendering sample component */}
            {externalMenuRoutes.map((mr, index) => {
                return (
                    <Route
                        exact
                        key={index + "subroute"}
                        path={mr.route}
                        render={() => mr.component}
                    />
                );
            })}
            <Route
                path={`${dataGrid}/:GenericGridTypeParent/:nodeID/:GenericGridTypeChild/:element/:elementID/EDDFileID/:EDDFileID`} // subgrid related to station (lithology, etc)
                render={r => <GenericDataGrid external key={r.location.key} />}
            />
            <Route
                path={`${dataGrid}/:GenericGridTypeParent/:nodeID/:GenericGridTypeChild/:element/:elementID`} // subgrid related to station (lithology, etc)
                render={() => <GenericDataGrid external />}
            />
            <Route
                path={`/userPrefs/:mode`}
                render={() => <UserPrefEditionPanel />}
            /><Route
                path={`/help`}
                render={() => <HelpPageView />}
            />

            <TreeRoutesMemo />
            <Redirect from="/" to="/home" />
        </Switch>
    );
};
export default ExternalMenuRouter;
