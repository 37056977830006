import React, { useState } from "react";
import {
    Chip,
    Box,
    Dialog,
    DialogContent,
    Button,
    DialogActions,
    Grid,
    IconButton,
} from "@mui/material";
import TransitionIcon from "@mui/icons-material/RedoOutlined";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { FormProvider, useForm } from "react-hook-form";
import { VTextField } from "./Custom Inputs/CustomInputs";
import { mainRed } from "../../themes/GlobalTheme";
import { toastr } from "react-redux-toastr";
import { t } from "react-i18nify";
import { updateEddDataValidationProgress, updateSheetName } from "../../js/redux/actions/userPref";

export function DiagramTransitions({
    availableTransitions,
    elementID,
    baseURL,
    beforeTransition = _transition => {
        /* The parent's methods will be executed before a transition. E.g. run the loading dialog. */
    },
    afterTransition = _transition => {
        /* The parent's methods will be executed after a transition. E.g. refresh datagrid or update entity tree. */
    },
    afterTransitionFail = _transition => {
        /* The parent's methods will be executed after a transition if it fails. E.g. refresh datagrid or update entity tree. */
    },
    TransitionDialog = () => {
        return null;
    },
    rowData,
    ...props
}) {
    const token = useSelector(reduxState => reduxState.auth.Token);
    const [openDialog, setOpenDialog] = useState();
    const dispatch = useDispatch();

    const transitionAction = async transition => {
        return transition.Operation
            ? setOpenDialog(transition)
            : transitionPost(transition); // ok
    };

    const transitionPost = async (diagramState, body = {}) => {
        const config = {
            headers: {
                Authorization: "Bearer " + token,
            },
        };

        dispatch(updateEddDataValidationProgress(0));
        dispatch(updateSheetName("eddDataValidation.Stations"));

        beforeTransition(diagramState);
        Axios.post(
            `${baseURL}/transition/${elementID}/${diagramState.Name}`,
            body,
            config
        )
            .then(() => {
                afterTransition(diagramState);
            })
            .catch(error => {
                afterTransitionFail(diagramState);
                toastr.error(error.response.data);
            });
    };

    return (
        <>
            <Box>
                {availableTransitions.map((e, i) => {
                    return (
                        <Chip
                            icon={
                                <IconButton disableRipple>
                                    <TransitionIcon sx={{ color: mainRed }} />
                                </IconButton>
                            }
                            key={`${elementID}-${i}`}
                            variant="outlined"
                            onClick={() => transitionAction(e)}
                            label={t(`diagram.${e.Name}`)}
                            {...props}
                        />
                    );
                })}
            </Box>
            <TransitionDialog
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                baseURL={baseURL}
                elementID={elementID}
                transitionAction={transitionPost}
                rowData={rowData}
                availableTransitions={availableTransitions}
            />
        </>
    );
}

export const ObservationTransition = ({
    openDialog,
    setOpenDialog,
    transitionAction,
    rowData,
}) => {
    const methods = useForm();
    const { handleSubmit, watch } = methods;
    let fieldName = "Observation";

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit()}>
                <Dialog open={Boolean(openDialog)}>
                    <DialogContent style={{ padding: 8 }}>
                        <Grid item xs={12} sx={{ width: "300px", p: 1 }}>
                            <VTextField
                                fieldName={fieldName}
                                label={
                                    watch(fieldName) &&
                                    t("view.request.OptionalObservation")
                                }
                                placeholder={t(
                                    "view.request.OptionalObservation"
                                )}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => setOpenDialog(null)}
                        >
                            {t("common.Cancel")}
                        </Button>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit(data => {
                                transitionAction(openDialog, {
                                    ...rowData,
                                    [fieldName]: data.Observation,
                                });
                                setOpenDialog(null);
                            })}
                        >
                            {t("common.Continue")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        </FormProvider>
    );
};
