import React, { useState, useEffect, useCallback } from "react";
import {
    Paper,
    Popper,
    MenuList,
    ClickAwayListener,
    MenuItem,
    IconButton,
    ListItemText,
    Dialog,
    DialogActions,
    Button,
    DialogContentText,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import { useDispatch, useSelector } from "react-redux";
import { t } from "react-i18nify";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import * as r from "../../js/constants/routes";
import { deleteModel, fetchData } from "../../js/utils/backend";
import { historyPush } from "../../js/utils/genericMethods";
import { dataGrid, managementGrid } from "../../routers/MenuRouter";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import { toastr } from "react-redux-toastr";
import { BasicTooltip } from "../Widgets/StyledComponents";
import { fetchTraverseExtendTreePathNodes } from "../TreeEditors/EntityTree";
import {
    resetNotifCount,
    updateHighlightedRows,
} from "../../js/redux/actions/userPref";
import DoneIcon from "@mui/icons-material/Done";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { useAxiosConfig } from "../../js/utils/customHooks";
import {
    isPhotoVideo,
    isDocuments,
} from "../Views/DocumentView/MediaChunksUploaderDialog";

const NotificationMenu = () => {
    const [notifOpen, setNotifOpen] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = useSelector(state => state.auth.Token);
    const notifCount = useSelector(state => state.userPref.NotificationCount);
    const dispatch = useDispatch();
    useEffect(() => {
        if (Boolean(notifOpen)) {
            const fetching = async () => {
                setLoading(true);
                try {
                    const response = await fetchData(
                        r.notification,
                        token,
                        "Select"
                    );
                    setData(response.data);
                } catch {
                    toastr.error(t("notification.errorNotifictation"));
                }
                dispatch(resetNotifCount());
                setLoading(false);
            };

            fetching();
        }
    }, [notifOpen, token, dispatch]);

    const handleNotifClick = e => {
        setNotifOpen(e.target);
    };
    const handleNotifClose = () => {
        setNotifOpen(false);
    };

    return (
        <>
            <BasicTooltip
                title={!notifOpen ? t("common.OpenNotifications") : "Close"}
            >
                <IconButton
                    color="inherit"
                    sx={{ mr: 1 }}
                    onClick={handleNotifClick}
                >
                    <BadgeNotificationIcon notifCount={notifCount} />
                </IconButton>
            </BasicTooltip>
            <Popper
                open={Boolean(notifOpen)}
                anchorEl={notifOpen}
                role={undefined}
                disablePortal
                sx={{ zIndex: 1500 }}
            >
                <ClickAwayListener onClickAway={handleNotifClose}>
                    <Paper>
                        {loading ? (
                            <MenuList
                                id="menu-list-grow"
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <MenuItem key={"NoNotif"}>
                                    <ScreenLoading sx={{ h: 100 }} />
                                </MenuItem>
                            </MenuList>
                        ) : (
                            <NotificationList
                                notifications={data}
                                handleNotifClose={handleNotifClose}
                            />
                        )}
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    );
};
export default NotificationMenu;

const BadgeNotificationIcon = ({ notifCount }) => {
    return (
        <Badge badgeContent={notifCount} color={"secondary"} overlap="circular">
            <NotificationsIcon />
        </Badge>
    );
};

const MenuItemNotification = ({
    notif,
    last,
    clickNotification,
    cleanNotification,
}) => {
    return (
        <MenuItem dense divider={!last}>
            <ListItemText onClick={clickNotification}>
                {notif.DisplayMessage}
            </ListItemText>
            <IconButton onClick={cleanNotification}>
                <DoneIcon fontSize="small" color="secondary" />
            </IconButton>
        </MenuItem>
    );
};

const getEDDNodeID = async (OTEntityID, config) => {
    const res = await Axios.get(`${r.oTEntityTreeGet}/${OTEntityID}`, config);
    return res.data.find(n => n.NodeType === "OTEntityTreeTemplateEddNodeType");
};
const getLimitNodeID = async (OTEntityID, config) => {
    const res = await Axios.get(`${r.oTEntityTreeGet}/${OTEntityID}`, config);
    return res.data.find(n => n.NodeType === "OTEntityTreeTemplateEddNodeType");
};
const getEDDFileInfo = async (eddFileID, config) => {
    const res = await Axios.get(`${r.eddFile.route}/${eddFileID}`, config);
    console.log(res);
    return res.data;
};

const getDocumentRoute = async (
    documentID,
    OTEntityID,
    config,
    dispatch,
    token
) => {
    const res = await Axios.get(`${r.document}/${documentID}`, config);
    const document = res.data;
    console.log(document);
    if (document.EDDFileID !== null) {
        return `/Files/EDDFile/${document.EDDFileID}/true/${OTEntityID}/${
            document.OTEntityTreeID ?? ""
        }`;
    }
    if (document.StationID !== null) {
        return `/${
            document.IsPhotoVideo ? isPhotoVideo : isDocuments
        }/Station/${document.StationID}/true/${OTEntityID}/${
            document.OTEntityTreeID ?? ""
        }`;
    }
    if (document.SamplingID !== null) {
        return `/${
            document.IsPhotoVideo ? isPhotoVideo : isDocuments
        }/Sampling/${document.SamplingID}/true/${OTEntityID}/${
            document.OTEntityTreeID ?? ""
        }`;
    }
    if (document.OTEntityTreeID !== null) {
        fetchTraverseExtendTreePathNodes(
            document.OTEntityTreeID,
            token,
            dispatch
        );
        return `/documentNode/${document.OTEntityTreeID}/true`;
    }
};

const NotificationList = ({ notifications, handleNotifClose }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const config = useAxiosConfig();
    const token = useSelector(state => state.auth.Token);
    const isExternal = useSelector(state => state.auth.External);
    const handleCancel = useCallback(() => {
        setConfirmationOpen(false);
    }, []);
    const handleDelete = useCallback(() => {
        deleteModel(confirmationOpen.ObjectID, token, r.notification);
        handleNotifClose();
        setConfirmationOpen(false);
    }, [confirmationOpen, token, handleNotifClose]);
    const cleanNotification = useCallback(
        notif => () => {
            setConfirmationOpen(notif);
        },
        []
    );

    const clickNotification = useCallback(
        notif => async () => {
            if (isExternal) {
                switch (notif.NotificationType) {
                    case "EDDRejected":
                    case "EDDValidated":
                        const studyNode = await getEDDFileInfo(
                            notif.LinkedItemID,
                            config
                        );
                        console.log(studyNode);
                        const eddFileRoute = `${dataGrid}${r.WorkOrder}/${studyNode.WorkOrder_select.ObjectID}/eddFile`;
                        dispatch(
                            updateHighlightedRows({
                                CurrentLocation: eddFileRoute,
                                VisitedRowID: notif.LinkedItemID,
                            })
                        );
                        historyPush(history, eddFileRoute);
                        break;
                    default:
                        break;
                }
                return;
            }
            switch (notif.NotificationType) {
                case "Limit":
                    const limitNode = await getLimitNodeID(
                        notif.OTEntityID,
                        config
                    );
                    const limitNodeRoute = `${dataGrid}${r.limits}/${limitNode.ID}/OTEntityID/${notif.OTEntityID}`;
                    dispatch(
                        updateHighlightedRows({
                            CurrentLocation: limitNodeRoute,
                            VisitedRowID: notif.LinkedItemID,
                        })
                    );
                    fetchTraverseExtendTreePathNodes(
                        limitNode.ID,
                        token,
                        dispatch
                    );
                    historyPush(history, limitNodeRoute);
                    break;
                case "EDDRejected":
                case "EDDValidated":
                case "EDDSubmitted":
                    const eddNode = await getEDDNodeID(
                        notif.OTEntityID,
                        config
                    );
                    const eddFileRoute = `${dataGrid}${r.eddFile.route}/${eddNode.ID}/OTEntityID/${notif.OTEntityID}`;
                    dispatch(
                        updateHighlightedRows({
                            CurrentLocation: eddFileRoute,
                            VisitedRowID: notif.LinkedItemID,
                        })
                    );
                    fetchTraverseExtendTreePathNodes(
                        eddNode.ID,
                        token,
                        dispatch
                    );
                    historyPush(history, eddFileRoute);
                    break;
                case "NewUser":
                case "NewRef":
                case "NewEntity":
                    const requestRoute = `${managementGrid}${r.request}/tovalidate`;
                    dispatch(
                        updateHighlightedRows({
                            CurrentLocation: requestRoute,
                            VisitedRowID: notif.LinkedItemID,
                        })
                    );
                    historyPush(history, requestRoute);
                    break;
                case "DocumentToValidate":
                case "DocumentValidated":
                case "DocumentNotValidated":
                    const documentRoute = await getDocumentRoute(
                        notif.LinkedItemID,
                        notif.OTEntityID,
                        config,
                        dispatch,
                        token
                    );
                    dispatch(
                        updateHighlightedRows({
                            CurrentLocation: documentRoute,
                            VisitedRowID: notif.LinkedItemID,
                        })
                    );
                    historyPush(history, documentRoute);
                    break;
                default:
                    break;
            }
            handleNotifClose();
        },
        [handleNotifClose, history, dispatch, token, isExternal, config]
    );
    return notifications?.length ? (
        <>
            <MenuList
                id="menu-list-grow"
                sx={{
                    justifyContent: "space-between",
                }}
            >
                {notifications.map((n, i) => {
                    return (
                        <MenuItemNotification
                            key={n.ObjectID}
                            notif={n}
                            last={i + 1 === notifications.length}
                            cleanNotification={cleanNotification(n)}
                            clickNotification={clickNotification(n)}
                        />
                    );
                })}
            </MenuList>
            <Dialog open={Boolean(confirmationOpen)}>
                <DialogContentText p={1}>
                    {t("dialog.NotificationDoneConfirmation")}
                </DialogContentText>
                <DialogActions>
                    <Button onClick={handleCancel}>{t("common.Cancel")}</Button>
                    <Button onClick={handleDelete} color="secondary">
                        {t("common.Delete")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    ) : (
        <MenuList
            id="menu-list-grow"
            sx={{
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <MenuItem key={"NoNotif"}>
                {t("notification.noNotification")}
            </MenuItem>
        </MenuList>
    );
};
