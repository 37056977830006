import React, { useState, useEffect } from "react";
import {
    Box,
    Tab,
    Tabs,
    Typography,
    Paper,
    Grid,
    IconButton,
} from "@mui/material";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../js/utils/backend";
import CircularProgress from "@mui/material/CircularProgress";
import { t } from "react-i18nify";
import { BasicTooltip } from "./StyledComponents";
import { updateTreeViewNodeExpand } from "../../js/redux/actions/userPref";
import { dateTimeToString, historyPush } from "../../js/utils/genericMethods";
import Badge from "@mui/material/Badge";

import OpenLinkIcon from "@mui/icons-material/LaunchOutlined";
import { fetchTraverseExtendTreePathNodes } from "../TreeEditors/EntityTree";
import { isDocuments, isPhotoVideo } from "../Views/DocumentsView";

const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
        <Box
            sx={{ width: "inherit" }}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box component="ul" sx={{ py: 3, px: 0 }}>
                    {children}
                </Box>
            )}
        </Box>
    );
};


/** Highlight content words if there's any match with the provided array (a set of words that user is searching) */
const HighlightElement = ({ text = "", toHighlight = null }) => {
    const tagsArray = toHighlight.split(" ");

    if (!tagsArray?.length) return text;
    const matches = [
        ...text.matchAll(new RegExp(tagsArray.join("|"), "ig")),
    ];
    const startText = text.slice(0, matches[0]?.index);
    return (
        <Box component="span" sx={{ display: "inline-flex" }}>
            {startText}
            {matches.map((match, i) => {
                const startIndex = match.index;
                const currentText = match[0];
                const endIndex = startIndex + currentText.length;
                const nextIndex = matches[i + 1]?.index;
                const untilNextText = text.slice(endIndex, nextIndex);
                return (
                    <span key={i}>
                        {`\u2002`}<mark>{currentText}</mark>
                        {untilNextText}
                    </span>
                );
            })}
        </Box>
    );
};

const ResultFragment = ({ children }) => {
    return (
        <Box
            component="li"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
                p: 1,
                m: "8px 8px 8px 0px",
            }}
        >
            {children}
        </Box>
    );
};

const FirstLine = ({ children }) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "baseline",
                height: 32,
            }}
        >
            {children}
        </Box>
    );
};

const QuickSearchResultsTabs = () => {
    const [value, setValue] = useState(0);
    const { searchInput } = useParams();
    const quickSearchFilter = useSelector(
        state => state.userPref.QuickSearchFilter
    );
    const location = useLocation();

    const token = useSelector(state => state.auth.Token);

    const [entitiesResults, setEntitiesResults] = useState(undefined);
    const [documentsResults, setDocumentsResults] = useState(undefined);
    const [stationsResults, setStationsResults] = useState(undefined);
    const [zonesResults, setZonesResults] = useState(undefined);
    const [samplingsResults, setSamplingsResults] = useState(undefined);
    const [branchesResults, setBranchesResults] = useState(undefined);

    const dispatch = useDispatch();
    const history = useHistory();

    const CORRESPONDANCE_STATES = {
        Entities: entitiesResults,
        Documents: documentsResults,
        Stations: stationsResults,
        Zones: zonesResults,
        Samplings: samplingsResults,
        Branches: branchesResults,
    };

    const [loadResults, setLoadResults] = useState(false);

    useEffect(() => {
        let isSubscribed = true;
        // clean up of result states, each time when user write down a keyord to search
        if (isSubscribed && searchInput) {
            setEntitiesResults(null);
            setDocumentsResults(null);
            setStationsResults(null);
            setZonesResults(null);
            setLoadResults(true);
            setSamplingsResults(null);
            setBranchesResults(null);
        }
        return () => (isSubscribed = false);
    }, [searchInput, location]);

    useEffect(() => {
        let isSubscribed = true;
        /** We'll fetch results for each result type in the same time, and populate each tab with the number of results */
        const fetchingSearchResults = () => {
            let pageSize = quickSearchFilter.MaxResultCount.value;
            quickSearchFilter.SearchCategories.map(async (item, index) => {
                switch (item.value) {
                    case "Entities":
                        if (item.checked) {
                            const entitiesFetched = await fetchData(
                                `QuickSearch/Entities`,
                                token,
                                "List",
                                null,
                                `Keywords=${searchInput}&PageSize=${pageSize}`
                            );
                            setEntitiesResults(entitiesFetched.data);
                        } else {
                            setEntitiesResults(undefined);
                        }
                        break;
                    case "Documents":
                        if (item.checked) {
                            const documentsFetched = await fetchData(
                                `QuickSearch/Documents`,
                                token,
                                "List",
                                null,
                                `Keywords=${searchInput}&PageSize=${pageSize}`
                            );
                            setDocumentsResults(documentsFetched.data);
                        } else {
                            setDocumentsResults(undefined);
                        }
                        break;
                    case "Zones":
                        if (item.checked) {
                            const zonesFetched = await fetchData(
                                `QuickSearch/Zones`,
                                token,
                                "List",
                                null,
                                `Keywords=${searchInput}&PageSize=${pageSize}`
                            );
                            setZonesResults(zonesFetched.data);
                        } else {
                            setZonesResults(undefined);
                        }
                        break;
                    case "Stations":
                        if (item.checked) {
                            const stationsFetched = await fetchData(
                                `QuickSearch/Stations`,
                                token,
                                "List",
                                null,
                                `Keywords=${searchInput}&PageSize=${pageSize}`
                            );
                            setStationsResults(stationsFetched.data);
                        } else {
                            setStationsResults(undefined);
                        }
                        break;
                        case "Samplings":
                        if (item.checked) {
                            const samplingsFetched = await fetchData(
                                `QuickSearch/Samplings`,
                                token,
                                "List",
                                null,
                                `Keywords=${searchInput}&PageSize=${pageSize}`
                            );
                            setSamplingsResults(samplingsFetched.data);
                        } else {
                            setSamplingsResults(undefined);
                        }
                        break;
                    case "Branches":
                        if (item.checked) {
                            const branchesFetched = await fetchData(
                                `QuickSearch/Branches`,
                                token,
                                "List",
                                null,
                                `Keywords=${searchInput}&PageSize=${pageSize}`
                            );
                            setBranchesResults(branchesFetched.data);
                        } else {
                            setBranchesResults(undefined);
                        }
                        break;
                    default:
                        return null;
                }
            });
            setLoadResults(false);
        };
        if (
            isSubscribed &&
            loadResults &&
            quickSearchFilter //&& searchInput
        ) {
            fetchingSearchResults();
        }
        return () => (isSubscribed = false);
    }, [
        quickSearchFilter,
        quickSearchFilter.SearchCategories,
        searchInput,
        token,
        loadResults,
    ]);

    const handleChangeCurrentTab = (_event, newValue) => {
        setValue(newValue);
    };




    /** The component displays (in red color) the main item name, eventually highlighted (<HighlightElement/>). An IconButton will redirect to 1) the route of the element 2)   */
    const LabelValue = ({
        label,
        value,
        link,
        type,
        elementID,
        containingNode,
        bold,
        oTEntityID,
    }) => {
        return (
            <Box
                component="span"
                sx={{
                    typography: bold ? "bodyMajor" : "body1",
                    fontWeight: bold ? 500 : 400,
                    display: "block",
                    px: 1 / 2,
                }}
            >
                {link ? (
                    <>
                        <HighlightElement
                            text={value}
                            toHighlight={searchInput}
                        />
                        <BasicTooltip
                            placement="top"
                            title={`${t("common.RedirectTo")} ${type}`}
                        >
                            <IconButton
                                size={"small"}
                                sx={{ mb: 1 / 2 }}
                                onClick={() => {
                                    if (containingNode) {
                                        /**   */
                                        fetchTraverseExtendTreePathNodes(
                                            containingNode,
                                            token,
                                            dispatch
                                        );
                                    } else {
                                        // A case that happens when we need only the entity page, so it's the first element in tree, dont need to perform a request to get the ot entity tree path
                                        dispatch(
                                            updateTreeViewNodeExpand([
                                                elementID,
                                                oTEntityID,
                                            ])
                                        );
                                    }
                                    //1
                                    historyPush(history,
                                        type === "entity" || type === "branch"
                                            ? link
                                            : `${link}/${value}`,
                                        { withBackButton: true }
                                    );
                                }}
                            >
                                <OpenLinkIcon fontSize={"inherit"} />
                            </IconButton>
                        </BasicTooltip>
                    </>
                ) : (
                    <HighlightElement text={value} toHighlight={searchInput} />
                )}
            </Box>
        );
    };

    /** For each result, we display a redirection link, regarding the route/data grid where the element can be found (cf. MenuRouter) */
    const linkRedirection = (item, type) => {
        switch (type) {
            case "Entities":
                return entitiesRoutes(item);
            case "Documents":
                return documentsRoutes(item);
            case "Stations":
                return stationsRoutes(item);
            case "Zones":
                return zonesRoutes(item);
            case "Samplings":
                return samplingsRoutes(item);
            case "Branches":
                return branchesRoutes(item);
            default:
                return null;
        }
    };
    function entitiesRoutes(item) {
        return `/entity-page/${item.ObjectID}`;
    }
    /** A document can be a node, but also attached to a Station or a Sampling (element), and be a photoVideo type (fileType). Basically, we rebuild the defined route in MenuRouter.js  */
    function documentsRoutes(item) {
        if (item.StationType || item.SamplingType) {
            let fileType = item.IsPhotoVideo ? isPhotoVideo : isDocuments;
            let element = item.StationType ?? item.SamplingType;
            let elementID =
                item.StationID ?? item.SamplingID ?? item.ContainingNode;
            return `/${fileType}/${element}/${elementID}/true/${item.OTEntityID}`;
        } else {
            return `/documentNode/${item.ContainingNode}/true`;
        }
    }
    function stationsRoutes(item) {
        return `/datagrid/${item.StationType}/${item.ContainingNode}/root/OTEntityID/${item.OTEntityID}`;
    }
    /** A zone is attached to an entity, but can also be related ot a study. */
    function zonesRoutes(item) {
        let relatedElement = item.StudyID ? "study" : "entity";
        return `/datagrid/zone/${relatedElement}/${item.ContainingNode}`;
    }

    function samplingsRoutes(item) {
        return `/datagrid/${item.SampleType}Sampling/${item.ContainingNode}/root/OTEntityID/${item.OTEntityID}`;
    }

    function branchesRoutes(item) {
        return `/datagrid/Branch`;
    }

    return (
        <Paper square sx={{ padding: 5 }} elevation={0}>
            <Typography variant="subtitle1" sx={{ my: 1 }}>
                {t("common.ResultsFor", {
                    value: `"${searchInput}"`,
                })}{" "}
                :
            </Typography>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChangeCurrentTab}
                        aria-label="basic tabs example"
                        variant="fullWidth"
                        centered
                    >
                        {quickSearchFilter?.SearchCategories?.map(
                            (item, index) => {
                                let hasFetched =
                                    CORRESPONDANCE_STATES[item.label];
                                let resultsNumber = hasFetched?.length;
                                let isNotChecked =
                                    !quickSearchFilter.SearchCategories[index]
                                        .checked;

                                let showProgress =
                                    hasFetched === null &&
                                    !isNotChecked &&
                                    !loadResults;
                                return (
                                    <Tab
                                        disabled={isNotChecked}
                                        key={index}
                                        iconPosition="end"
                                        label={
                                            <Badge
                                                anchorOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                }}
                                                sx={{
                                                    right: "-12px",
                                                    top: "4px",
                                                }}
                                                showZero={true}
                                                badgeContent={resultsNumber}
                                                color={"secondary"}
                                            >
                                                <Box
                                                    sx={{
                                                        marginTop: 0.5,
                                                        marginRight: 1,
                                                    }}
                                                >
                                                    {t(
                                                        `grid.title.${item.label}`
                                                    )}
                                                </Box>
                                            </Badge>
                                        }
                                        icon={
                                            <Box>
                                                {showProgress && (
                                                    <CircularProgress
                                                        sx={{
                                                            p: 0,
                                                            m: 0,
                                                            position:
                                                                "absolute",
                                                            top: 24,
                                                            right: 0,
                                                        }}
                                                        color="secondary"
                                                        size={24}
                                                        thickness={2}
                                                    />
                                                )}
                                            </Box>
                                        }
                                    />
                                );
                            }
                        )}
                    </Tabs>
                </Box>
                <Grid container>
                    <TabPanel value={value} index={0}>
                        {entitiesResults?.map((item, _index) => {
                            const branches = item.Branches?.map(item => {
                                return item.Value;
                            });
                            const brancheString = branches
                                ?.toString()
                                .replace(/,/g, ", ");
                            return (
                                <ResultFragment key={item.ObjectID}>
                                    <FirstLine>
                                        <LabelValue
                                            label={t("field.Code")}
                                            value={item.Code}
                                            bold={true}
                                            link={linkRedirection(
                                                item,
                                                "Entities"
                                            )}
                                            type="entity"
                                            elementID={item.ObjectID}
                                        />
                                        -
                                        <LabelValue
                                            label={t("field.Entity")}
                                            value={item.Name}
                                        />
                                    </FirstLine>
                                    <LabelValue
                                        label={t("field.Branch")}
                                        value={brancheString}
                                    />
                                </ResultFragment>
                            );
                        })}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {documentsResults?.map((item, _index) => {
                            return (
                                <ResultFragment key={item.ObjectID}>
                                    <FirstLine>
                                        <LabelValue
                                            label={t("field.Filename")}
                                            value={item.Filename}
                                            link={linkRedirection(
                                                item,
                                                "Documents"
                                            )}
                                            oTEntityID={item.OTEntityID}
                                            type="document"
                                            elementID={item.ObjectID}
                                            containingNode={
                                                item.IsStationDocument
                                                    ? null
                                                    : item.ContainingNode
                                            }
                                            bold={true}
                                        />
                                        {item.Name ? "-" : null}
                                        <LabelValue
                                            label={t("field.Name")}
                                            value={item.Name}
                                        />
                                        {item.Author ? "-" : null}
                                        <LabelValue
                                            label={t("field.Author")}
                                            value={item.Author}
                                        />
                                    </FirstLine>
                                    <LabelValue
                                        label={t("field.CreatedOn")}
                                        value={dateTimeToString(
                                            item.CreatedOn,
                                            "DateTime"
                                        )}
                                    />
                                </ResultFragment>
                            );
                        })}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {stationsResults?.map((item, _index) => {
                            return (
                                <ResultFragment key={item.ObjectID}>
                                    <FirstLine>
                                        <LabelValue
                                            label={t("field.Station")}
                                            value={item.Name}
                                            type="station"
                                            bold={true}
                                            containingNode={item.ContainingNode}
                                            elementID={item.ObjectID}
                                            link={linkRedirection(
                                                item,
                                                "Stations"
                                            )}
                                        />
                                        {item.OTEntityName ? "-" : null}
                                        <LabelValue
                                            label={t("field.Entity")}
                                            value={item.OTEntityName}
                                        />
                                    </FirstLine>
                                    <LabelValue
                                        sx={{ display: "block" }}
                                        label={t("field.StationType")}
                                        value={item.StationType.replace(
                                            /([A-Z][a-z])/g,
                                            " $1"
                                        ).replace(/(\d)/g, " $1")}
                                    />
                                </ResultFragment>
                            );
                        })}
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        {zonesResults?.map((item, _index) => {
                            return (
                                <ResultFragment key={item.ObjectID}>
                                    <FirstLine>
                                        <LabelValue
                                            label={t("field.Code")}
                                            value={item.Code}
                                            bold={true}
                                            elementID={item.ObjectID}
                                            type="zone"
                                            containingNode={item.ContainingNode}
                                            link={linkRedirection(
                                                item,
                                                "Zones"
                                            )}
                                        />
                                        {item.OTEntityName ? "-" : null}
                                        <LabelValue
                                            label={t("field.Entity")}
                                            value={item.OTEntityName}
                                        />
                                    </FirstLine>
                                    <LabelValue
                                        label={t("field.Zones")}
                                        value={item.Name}
                                        type="zone"
                                    />
                                </ResultFragment>
                            );
                        })}
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        {samplingsResults?.map((item, _index) => {
                            return (
                                <ResultFragment key={item.ObjectID}>
                                    <FirstLine>
                                        <LabelValue
                                            label={t("field.SubSamplingID")}
                                            value={item.SubSamplingID}
                                            bold={true}
                                            link={linkRedirection(
                                                item,
                                                "Samplings"
                                            )}
                                            type="sampling"
                                            elementID={item.ObjectID}
                                        />
                                        -
                                        <LabelValue
                                            label={t("field.SamplingID")}
                                            value={item.SamplingID}
                                        />
                                    </FirstLine>
                                        <LabelValue
                                            label={t("field.SampleType")}
                                            value={item.SampleType}
                                        />
                                </ResultFragment>
                            );
                        })}
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        {branchesResults?.map((item, _index) => {
                            return (
                                <ResultFragment key={item.ObjectID}>
                                    <FirstLine>
                                        <LabelValue
                                            label={t("field.Code")}
                                            value={item.Code}
                                            bold={true}
                                            link={linkRedirection(
                                                item,
                                                "Branches"
                                            )}
                                            type="branch"
                                            elementID={item.ObjectID}
                                        />
                                        -
                                        <LabelValue
                                            label={t("field.Value")}
                                            value={item.Value}
                                        />
                                    </FirstLine>
                                </ResultFragment>
                            );
                        })}
                    </TabPanel>
                </Grid>
            </Box>
        </Paper>
    );
};

export default QuickSearchResultsTabs;
