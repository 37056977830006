import React, { useCallback, useState } from "react";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-pro";
import {
    ValidationChip,
    LookUpEdit,
    BasicDialog,
    DeleteAction,
} from "./cst_DataGrid";
import {
    dateTimeToString,
    historyPush,
    truncateString,
} from "../../js/utils/genericMethods";
import * as requestDiagram from "../../diagrams/requestDiagram.json";
import DiagramIcon from "@mui/icons-material/TransformOutlined";
import { useHistory } from "react-router-dom";
import { ObservationTransition } from "../Widgets/DiagramTransitions";
import LookIcon from "@mui/icons-material/ManageSearchOutlined";
import { FilterToolbar } from "../Widgets/Custom Inputs/Select";
import { requestStatesOptions } from "../../js/constants/selectOptions";
import RequestedAccessIcon from "@mui/icons-material/GroupOutlined";
import DataGridComponent from "../Widgets/DataGridComponent";
import { ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import { requestDiagramRoute } from "../../routers/MenuRouter";
import { BasicTooltip } from "../Widgets/StyledComponents";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import { useDispatch, useSelector } from "react-redux";
import { updateEntityTree } from "../../js/redux/actions/userPref";
import { t } from "react-i18nify";
import * as r from "../../js/constants/routes";
import { useEditionRightsPerUserStatus } from "../../js/utils/customHooks";
import { NewRefRead } from "../Widgets/ReadOnlyComponents";
import { deleteModel } from "../../js/utils/backend";

const RequestsGrid = ({
    label = t("grid.title.Requests"),
    baseURL = r.request,
}) => {
    const apiRef = useGridApiRef();
    const history = useHistory();
    const isCompanyManager = useSelector(state => state.auth.CompanyManager);
    const isHoldingManager = useSelector(state => state.auth.HoldingManager);
    const token = useSelector(state => state.auth.Token);
    const editingRights = useEditionRightsPerUserStatus([
        "BranchManager",
        "EntityManager",
    ]);
    const [newReference, setNewReference] = useState();
    const [newReferenceDialogOpen, setNewReferenceDialogOpen] = useState(false);
    const diagramStates = requestDiagram.default.States;
    const diagramTransitions = requestDiagram.default.Transitions;
    const dispatch = useDispatch();

    const [selectedState, setSelectedState] = useState(null);
    const [forceGridRefresh, setForceGridRefresh] = useState(false);

    const gridSpecificColumns = [
        {
            field: `NewEntity.Name`,
            headerName: t("field.Name"),
            valueGetter: params =>
                params.row.RequestType === "NEWREF"
                    ? t("common.newRef")
                    : params.row.NewEntity?.Name,
            width: 120,
            editable: false,
            sortable: false,
        },
        {
            field: "NewEntity.Code",
            headerName: t("field.Code"),
            valueGetter: params =>
                params.row.RequestType === "NEWREF"
                    ? "/"
                    : params.row.NewEntity?.Code,
            width: 120,
            editable: false,
            sortable: false,
        },
        {
            field: "State",
            headerName: t("field.State"),
            renderCell: params => {
                const state = diagramStates.find(e => e.Name === params.value);
                const availableTransitions = diagramTransitions.filter(
                    e => e.From === state.Name
                );
                return (
                    <ValidationChip
                        hasRights={editingRights}
                        state={state}
                        availableTransitions={availableTransitions}
                        rowData={params.row}
                        baseURL={baseURL}
                        transitionDialog={ObservationTransition}
                        afterTransition={transition => {
                            setForceGridRefresh(fgr => !fgr);
                            if (transition.To === "Validated") {
                                dispatch(updateEntityTree());
                            }
                        }}
                    />
                );
            },
            width: 120,
            editable: false,
        },
        {
            field: "RequestDate",
            headerName: t("field.RequestDate"),
            valueFormatter: params =>
                dateTimeToString(params.value, "DateTime"),
            width: 140,
            editable: false,
        },
        {
            field: "RequestType",
            headerName: t("field.RequestType"),
            valueFormatter: params => t(`view.request.${params.value}`),
            width: 150,
            editable: false,
        },
        {
            field: "Requestor_select",
            headerName: "Requestor IGG",
            valueFormatter: params => params.value.IGG,
            width: 150,
            editable: false,
            sortable: false,
        },
        {
            field: "Note",
            headerName: t("field.Note"),
            valueFormatter: params => truncateString(params.value, 60),
            width: 200,
            flex: 1,
            editable: false,
        },
        {
            field: "Observation",
            headerName: t("field.Observation"),
            renderCell: params => {
                if (params?.row?.Observation) {
                    return (
                        <BasicTooltip title={params.row.Observation}>
                            <IconButton disableRipple>
                                <MessageOutlinedIcon
                                    sx={{ width: 18, height: 18 }}
                                />
                            </IconButton>
                        </BasicTooltip>
                    );
                }
            },
            width: 120,
            editable: false,
        },
    ];

    const deleteAction = useCallback(
        id => async () => {
            await deleteModel(id, token, baseURL);
            setForceGridRefresh(frg => !frg);
        },
        [token, baseURL]
    );

    const specificActionsColumns = (params, _editing, isLocalLoading) => [
        <>
            {params.row.RequestType === "NEWREF" ? (
                <GridActionsCellItem
                    icon={
                        <BasicTooltip title={t("common.SeeMore")}>
                            <VisibilityIcon />
                        </BasicTooltip>
                    }
                    label={t("common.Edit")}
                    className="textPrimary"
                    onClick={() => {
                        setNewReferenceDialogOpen(true);
                        setNewReference(params.row.RequestNewRef);
                    }}
                    color="inherit"
                    disabled={isLocalLoading}
                />
            ) : null}
            {params?.row?.State !== "Validated" ? (
                params.row.RequestType === "ADDUSER" ? (
                    <LookUpEdit
                        baseURL={baseURL}
                        rowData={params.row}
                        icon={
                            <BasicTooltip title={t("field.RequestAccessType")}>
                                <RequestedAccessIcon />
                            </BasicTooltip>
                        }
                        setRefreshGrid={() => setForceGridRefresh(fgr => !fgr)}
                        disabled={isLocalLoading}
                    />
                ) : (
                    <GridActionsCellItem
                        icon={
                            <BasicTooltip title={t("common.Edit")}>
                                <LookIcon />
                            </BasicTooltip>
                        }
                        label={t("common.Edit")}
                        className="textPrimary"
                        onClick={() =>
                            historyPush(
                                history,
                                `/newEntityRequest/edit/${params.row.NewEntity.ID}`
                            )
                        }
                        color="inherit"
                        disabled={isLocalLoading}
                    />
                )
            ) : null}
            {params?.row?.State === "Rejected" && (isCompanyManager || isHoldingManager) ? (
                <DeleteAction deleteAction={deleteAction(params?.row.ID)} />
            ) : null}
        </>,
    ];

    return (
        <>
            <DataGridComponent
                apiRef={apiRef}
                baseURL={baseURL}
                label={label}
                gridSpecificColumns={gridSpecificColumns}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "RequestDate", sort: "desc" }],
                    },
                }}
                defaultActionsDisabled={true}
                toolbar={{
                    newAction: (
                        <ButtonToolbar
                            titleTooltip={t("common.AccessWorkflow")}
                            title={t("common.Workflow")}
                            startIcon={<DiagramIcon />}
                            onClick={() =>
                                historyPush(history, requestDiagramRoute)
                            }
                        />
                    ),
                    add: false,
                    newFilter: (
                        <FilterToolbar
                            options={requestStatesOptions.map(e => ({
                                ...e,
                                label: t(`diagram.${e.label}`),
                            }))}
                            placeholder={t("field.State")}
                            value={selectedState?.label}
                            onChange={(_e, value) =>
                                value
                                    ? setSelectedState(value)
                                    : setSelectedState(null)
                            }
                        />
                    ),
                }}
                paramURL={selectedState ? `State=${selectedState.value}` : ""}
                forceGridRefresh={forceGridRefresh}
                specificActionsColumns={specificActionsColumns}
                isEditDisabled={true}
                isDeleteDisabled={true}
                requestUrl="tovalidate"
            />
            <BasicDialog
                Content={() => <NewRefRead reference={newReference} />}
                label={t("common.newRef")}
                open={newReferenceDialogOpen}
                closeDialog={() => setNewReferenceDialogOpen(false)}
            />
        </>
    );
};
export default RequestsGrid;
