import * as actionTypes from "../actionTypes";

const initialState = {
    mainTab: null,
    mainTick: new Date(),
    secondaryTick: new Date(),
};

/** Reducer handling the main tab and concurrent tabs in the same browser: so each tab will be updated and wilml share a same common state, based on the mainTab DOC*/
export default function SyncTabReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.TABALIVE:
            if (action.payload === state.mainTab) {
                return {
                    ...state,
                    mainTick: new Date(),
                };
            } else {
                const mainDelay =
                    (new Date(state.secondaryTick).getTime() -
                        new Date(state.mainTick).getTime()) /
                    1000;
                if (mainDelay > 7) {
                    return {
                        ...state,
                        mainTick: new Date(),
                        mainTab: action.payload,
                    };
                }
                return {
                    ...state,
                    secondaryTick: new Date(),
                };
            }
        default:
            return state;
    }
}
