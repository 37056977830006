import React, { useEffect, useState, useCallback } from "react";
import {
    GridActionsCellItem,
    useGridApiRef,
    GridAddIcon,
} from "@mui/x-data-grid-pro";
import DataGridComponent from "../Widgets/DataGridComponent";
import { useDispatch, useSelector } from "react-redux";
import { FormDialog, ValidationChip } from "./cst_DataGrid";
import { ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import { useHistory, useLocation, useParams } from "react-router-dom";
import * as r from "../../js/constants/routes";
import { t } from "react-i18nify";
import { BasicTooltip } from "../Widgets/StyledComponents";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
    CircularProgress,
    ClickAwayListener,
    Grid,
    IconButton,
    Menu,
} from "@mui/material";
import {
    addNewModel,
    editModel,
    excelSpreadsheetMIME,
    fetchData,
    getDownload,
} from "../../js/utils/backend";
import { dateTimeToString, historyPush } from "../../js/utils/genericMethods";
import { HiddenField, VTextField } from "../Widgets/Custom Inputs/CustomInputs";
import { FilterToolbar, VSelectURL } from "../Widgets/Custom Inputs/Select";
import {
    updateEntityTree,
    updateHighlightedRows,
    updateSpecificNodeEntityTree,
} from "../../js/redux/actions/userPref";
import { updateSearchText } from "../../js/redux/actions/searchFilter";
import { useFormContext } from "react-hook-form";
import DiagramIcon from "@mui/icons-material/TransformOutlined";
import * as eddFileDiagram from "../../diagrams/eddFileDiagram.json";
import { dataGrid, eddFileDiagramRoute } from "../../routers/MenuRouter";
import DocumentsIcon from "@mui/icons-material/Article";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import { ObservationTransition } from "../Widgets/DiagramTransitions";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import { ActionMenu } from "../TreeEditors/TreeEditorComponents";
import Axios from "axios";
import { eddFileState } from "../../js/constants/selectOptions";
import { oTUser } from "../../js/constants/routes";

import ShortcutOutlinedIcon from "@mui/icons-material/ShortcutOutlined";
import { useEditionRightsPerEntityAccess } from "../../js/utils/customHooks";
import { uuidv4 } from "../../js/redux/actions/syncTab";
const EDDFileGrid = ({
    label,
    baseURL = r.query.route,
    fromWorkOrder = false,
    external = false,
    routeParams = "",
    ...props
}) => {
    const apiRef = useGridApiRef();
    const history = useHistory();
    const { OTEntityID, WorkOrderID, NodeID } = useParams();
    const { UserID } = useSelector(state => state.auth);
    let filters = useSelector(state => state.searchFilter.Filters);
    const location = useLocation();
    const currentLocation = location.pathname;
    const diagramStates = eddFileDiagram.default.States;
    const hasTransitionRight =
        useEditionRightsPerEntityAccess(OTEntityID, ["EntityManager"]) ||
        external;
    const diagramTransitions = hasTransitionRight
        ? eddFileDiagram.default.Transitions
        : [];

    const token = useSelector(state => state.auth.Token);
    const [forceGridRefresh, setForceGridRefresh] = useState(false);
    const [loading, setLoading] = useState(external);
    const [currentUser, setCurrentUser] = useState();
    const editingRights =
        useEditionRightsPerEntityAccess(OTEntityID, [
            "EntityManager",
            "Contributor",
        ]) || external;
    const [manageEdition, setManageEdition] = useState({
        open: false,
        type: null,
        editID: null,
        url: baseURL,
    });

    const specificEditionRights = (stateName, editionType) => {
        if (editionType === "edition") {
            if (stateName !== "Draft") {
                return false
            }
        } else if (external) {
            if (editionType === "transition") {
                if (stateName !== "Draft" && stateName === "Rejected") {
                    return true
                } else {
                    if (stateName !== "Draft") {
                        return false
                    }
                }
            }
        }
        return editingRights;
    };
    const filter = () => {
        let externalProvFilter = external
            ? r.filter.genericFilter(
                  "ExternalProviderID",
                  currentUser.ExternalProviderID
              )
            : "";

        for (const [key, value] of Object.entries({
            OTEntityID,
            WorkOrderID,
        })) {
            if (Boolean(value))
                externalProvFilter =
                    externalProvFilter +
                    "&" +
                    r.filter.genericFilter(key, value, external);
        }
        return externalProvFilter;
    };
    const dispatch = useDispatch();

    const editionLabel = {
        edit: t("common.Edit"),
        add: t("common.Add"),
        null: "",
    };

    let defaultValue = {
        WorkOrder_select: null,
        Description: "",
        Value: "",
    };

    if (!Boolean(WorkOrderID)) {
        defaultValue = { ...defaultValue, WorkOrderID: null };
    }
    if (!external) {
        defaultValue = {
            ...defaultValue,
            ExternalProviderID: null,
            ExternalProvider_select: null,
        };
    }
    useEffect(() => {
        let isSubscribed = true;
        const fetching = async () => {
            const userFetched = await fetchData(oTUser, token, "ID", UserID);
            if (isSubscribed) {
                setCurrentUser(userFetched.data);
                setLoading(false);
            }
        };
        external && fetching();
        return () => (isSubscribed = false);
        // eslint-disable-next-line
    }, [token, external]);

    const manageSubmitEditionForm = async (data, reset) => {
        if (manageEdition.type === "edit") {
            await editModel(
                { ID: manageEdition.editID, ...data },
                manageEdition.editID,
                token,
                baseURL
            ).then(() => {
                dispatch(
                    updateHighlightedRows({
                        CurrentLocation: currentLocation,
                        VisitedRowID: manageEdition.editID,
                    })
                );
            });
        } else {
            await addNewModel(
                { ...data, Submitter: UserID },
                token,
                baseURL + "?" + routeParams
            ).then(response => {
                dispatch(
                    updateHighlightedRows({
                        CurrentLocation: currentLocation,
                        VisitedRowID: response.data?.ObjectID,
                    })
                );
            });
        }
        setManageEdition(med => ({
            ...med,
            open: false,
            type: null,
            editID: null,
        }));
        reset(defaultValue);
        setForceGridRefresh(fgr => !fgr);
        dispatch(updateSpecificNodeEntityTree(NodeID ?? WorkOrderID));
    };

    const EditionDialogEDD = () => {
        const methods = useFormContext();
        const { watch, setValue, trigger, formState } = methods;

        /** The 'remountAutocomplete' state will reset the WorkOrder select, based of the changed Study */
        const [remountAutocomplete, setRemountAutocomplete] = useState();
        const initialStudyID = formState?.defaultValues?.Study_select?.ObjectID;
        const changedStudyID = watch("StudyID");
        useEffect(() => {
            if (changedStudyID) {
                if (changedStudyID !== initialStudyID) {
                    setValue("WorkOrderID", null);
                    setValue("WorkOrder_select", null);
                    setRemountAutocomplete(uuidv4());
                    trigger(["WorkOrderID"]);
                }
            }
        }, [initialStudyID, changedStudyID, trigger, setValue]);
        return (
            <Grid container spacing={2} sx={{ pt: 2 }}>
                {manageEdition.type === "edit" && (
                    <>
                        <HiddenField
                            fieldName={"OTEntityID"}
                            defaultValue={watch("OTEntity_select.ObjectID")}
                        />
                        <HiddenField
                            fieldName={"Submitter"}
                            defaultValue={watch("OTUser_select.ObjectID")}
                        />
                    </>
                )}

                <Grid item xs={12}>
                    <VTextField
                        label={t("field.Name")}
                        fieldName={"Value"}
                        validation={{
                            required: {
                                value: true,
                                message: t("input.validation.required"),
                            },
                        }}
                    />
                </Grid>
                {!WorkOrderID && (
                    <Grid item xs={12}>
                        <VSelectURL
                            label={t("field.Study")}
                            URL={`Study`}
                            fieldName={"StudyID"}
                            defaultValue={watch("Study_select")}
                            paramURL={`${filter()}&filter[HasWorkOrder]=true`}
                            validation={{
                                required: {
                                    value: true,
                                    message: t("input.validation.required"),
                                },
                            }}
                        />
                    </Grid>
                )}
                {!WorkOrderID ? (
                    <Grid item xs={12}>
                        <VSelectURL
                            label={t("field.WorkOrder")}
                            URL={`WorkOrder`}
                            fieldName={"WorkOrderID"}
                            key={remountAutocomplete}
                            defaultValue={watch("WorkOrder_select")}
                            disabled={!watch("StudyID")}
                            disabledMessage={t("input.validation.SelectStudy")}
                            paramURL={`&filter[StudyID]=${watch("StudyID")}`}
                            validation={{
                                required: {
                                    value: true,
                                    message: t("input.validation.required"),
                                },
                            }}
                        />
                    </Grid>
                ) : (
                    <HiddenField
                        fieldName={"WorkOrderID"}
                        defaultValue={WorkOrderID}
                    />
                )}
                {external ? (
                    <HiddenField
                        fieldName={"ExternalProviderID"}
                        defaultValue={currentUser.ExternalProviderID}
                    />
                ) : (
                    <Grid item xs={12}>
                        <VSelectURL
                            label={t("field.ExternalProvider")}
                            URL={`ExternalProvider`}
                            fieldName={"ExternalProviderID"}
                            defaultValue={watch("ExternalProvider_select")}
                            validation={{
                                required: {
                                    value: true,
                                    message: t("input.validation.required"),
                                },
                            }}
                        />
                    </Grid>
                )}
                {manageEdition.type === "edit" && (
                    <Grid item xs={12}>
                        <VTextField
                            fieldName={"OTUser_select.Name"}
                            label={t("field.Submitter")}
                            disabled={true}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <VTextField
                        label={t("field.Description")}
                        fieldName={"Description"}
                    />
                </Grid>
            </Grid>
        );
    };

    let gridSpecificColumns = [
        {
            field: "Value",
            headerName: t("field.Name"),
            width: 240,
            editable: false,
        },
        {
            field: "State",
            headerName: t("field.State"),
            renderCell: params => {
                const state = diagramStates.find(e => e?.Name === params.value);
                let availableTransitions = diagramTransitions.filter(
                    e => e.From === state?.Name
                );
                if (
                    state?.Name === "Draft" ||
                    (state?.Name === "Rejected" &&
                        params.row.OTUser_select.ObjectID === UserID)
                ) {
                    availableTransitions =
                        eddFileDiagram.default.Transitions.filter(
                            e => e.From === state?.Name
                        );
                }
                return (
                    state && (
                        <>
                            <ValidationChip
                                hasRights={
                                    state &&
                                    specificEditionRights(
                                        state.Name,
                                        "transition"
                                    )
                                }
                                state={state}
                                availableTransitions={availableTransitions}
                                transitionDialog={ObservationTransition}
                                rowData={params.row}
                                baseURL={baseURL}
                                afterTransition={() => {
                                    setForceGridRefresh(fgr => !fgr);
                                    dispatch(
                                        updateHighlightedRows({
                                            CurrentLocation: currentLocation,
                                            VisitedRowID: params.row.ID,
                                        })
                                    );
                                    dispatch(updateEntityTree());
                                }}
                            />
                            {params?.row?.Observation && (
                                <BasicTooltip title={params.row.Observation}>
                                    <IconButton disableRipple>
                                        <MessageOutlinedIcon
                                            sx={{ width: 18, height: 18 }}
                                        />
                                    </IconButton>
                                </BasicTooltip>
                            )}
                        </>
                    )
                );
            },
            width: 140,
            editable: false,
        },
        {
            field: "ExternalProvider_select",
            headerName: t("field.ExternalProvider"),
            width: 150,
            editable: false,
            valueFormatter: params => params.value?.Name,
        },
        {
            field: "OTUser_select",
            headerName: t("field.Submitter"),
            width: 100,
            editable: false,
            valueFormatter: params => params.value?.Name,
        },
        {
            field: "Description",
            headerName: t("field.Description"),
            width: 250,
            editable: false,
        },
        {
            field: "CreatedOn",
            headerName: t("field.CreatedOn"),
            width: 150,
            editable: false,
            valueFormatter: params =>
                dateTimeToString(params?.value, "DateTime"),
        },
        {
            field: "LastUpdate",
            headerName: t("field.LastUpdate"),
            width: 150,
            editable: false,
            valueFormatter: params =>
                dateTimeToString(params?.value, "DateTime"),
        },
    ];

    const specificActionsColumns = (params, _editing, isLocalLoading) => [
        <>
            {!fromWorkOrder && (
                <GridActionsCellItem
                    icon={
                        <BasicTooltip
                            title={`${t("field.WorkOrder")} (${
                                params.row?.WorkOrder_select?.Value
                            })`}
                        >
                            <ShortcutOutlinedIcon />
                        </BasicTooltip>
                    }
                    label={`${t("field.WorkOrder")} (${
                        params.row?.WorkOrder_select?.Value
                    })`}
                    className="textPrimary"
                    onClick={() => {
                        const route = `${dataGrid}${r.study}/${
                            params.row.WorkOrder_select.StudyID
                        }/redirection/OTEntityID/${
                            OTEntityID ?? params?.row?.OTEntity_select?.ObjectID
                        }`;
                        /* To highlight current EDDFile in EDDFileGrid */
                        dispatch(
                            updateHighlightedRows({
                                CurrentLocation: currentLocation,
                                VisitedRowID: params.row.ID,
                            })
                        );
                        /* To highlight current EDDFile in WorkorderGrid */
                        dispatch(
                            updateHighlightedRows({
                                CurrentLocation: route,
                                VisitedRowID:
                                    params.row.WorkOrder_select.ObjectID,
                            })
                        );
                        historyPush(history, route);
                    }}
                    disabled={isLocalLoading}
                    color="inherit"
                />
            )}
            <GridActionsCellItem
                icon={
                    <BasicTooltip title={t("grid.title.Documents")}>
                        <DocumentsIcon />
                    </BasicTooltip>
                }
                label={t("grid.title.Documents")}
                className="textPrimary"
                onClick={() => {
                    dispatch(
                        updateHighlightedRows({
                            CurrentLocation: currentLocation,
                            VisitedRowID: params.row.ID,
                        })
                    );
                    historyPush(
                        history,
                        `/Files/EDDFile/${params.row.ID}/true/${
                            params.row.OTEntity_select.ObjectID
                        }/${external ? "" : NodeID ?? ""}`
                    );
                }}
                color="inherit"
                disabled={isLocalLoading}
            />
            {specificEditionRights(params.row.State,"edition") && (
                <GridActionsCellItem
                    icon={
                        <BasicTooltip title={t("common.Edit")}>
                            <EditOutlinedIcon />
                        </BasicTooltip>
                    }
                    label={t("common.Edit")}
                    className="textPrimary"
                    onClick={() => {
                        setManageEdition(med => ({
                            ...med,
                            open: true,
                            type: "edit",
                            editID: params.row.ID,
                        }));
                    }}
                    color="inherit"
                    disabled={isLocalLoading}
                />
            )}
        </>,
    ];

    const [anchorMenuStudyTypes, setAnchorMenuStudyTypes] = useState(null);
    const openMenuStudyTypes = Boolean(anchorMenuStudyTypes);
    const [selectedUserState, setSelectedUserState] = useState(
        filters.filter(d => d.BaseUrl === baseURL)[0]
        ? filters.filter(d => d.BaseUrl === baseURL)[0].EddFileState : null
    );

    useEffect(() => {
        if(filters.filter(d => d.BaseUrl===baseURL)[0]){
            filters.filter(d => d.BaseUrl===baseURL)[0].EddFileState = selectedUserState
            dispatch(updateSearchText(filters))
        }
    }, [selectedUserState, baseURL, dispatch, filters]);

    return loading ? (
        <ScreenLoading />
    ) : (
        <>
            <FormDialog
                label={`${editionLabel[manageEdition.type]} ${t(
                    "field.EddFile"
                )}`}
                manageEdition={manageEdition}
                closeForm={reset => {
                    setManageEdition(med => ({
                        ...med,
                        open: false,
                        type: null,
                        editID: null,
                    }));
                    reset(defaultValue);
                }}
                manageSubmit={manageSubmitEditionForm}
                Content={EditionDialogEDD}
            />
            <DataGridComponent
                apiRef={apiRef}
                baseURL={baseURL}
                label={label}
                paramURL={
                    filter() +
                    (selectedUserState
                        ? `&filter[State]=${selectedUserState.value}`
                        : ``)
                }
                forceGridRefresh={forceGridRefresh}
                toolbar={{
                    newAction: (
                        <>
                            {editingRights && (
                                <>
                                    <ButtonToolbar
                                        titleTooltip={t(
                                            "view.query.stepper.CreateNewQuery"
                                        )}
                                        title={t("common.Add")}
                                        startIcon={<GridAddIcon />}
                                        onClick={() =>
                                            setManageEdition(med => ({
                                                ...med,
                                                open: true,
                                                type: "add",
                                                editID: null,
                                            }))
                                        }
                                    />
                                    <ButtonToolbar
                                        titleTooltip={t(
                                            "view.eddFiles.getTemplate"
                                        )}
                                        title={t("view.eddFiles.getTemplate")}
                                        startIcon={<DownloadOutlinedIcon />}
                                        onClick={event => {
                                            setAnchorMenuStudyTypes(c =>
                                                c === null
                                                    ? {
                                                          mouseX:
                                                              event.clientX - 8,
                                                          mouseY:
                                                              event.clientY + 8,
                                                      }
                                                    : null
                                            );
                                        }}
                                    />
                                    <ClickAwayListener
                                        onClickAway={() =>
                                            setAnchorMenuStudyTypes(null)
                                        }
                                    >
                                        <Menu
                                            open={openMenuStudyTypes}
                                            anchorReference="anchorPosition"
                                            anchorPosition={
                                                anchorMenuStudyTypes !== null
                                                    ? {
                                                          top: anchorMenuStudyTypes.mouseY,
                                                          left: anchorMenuStudyTypes.mouseX,
                                                      }
                                                    : undefined
                                            }
                                            onClose={() =>
                                                setAnchorMenuStudyTypes(null)
                                            }
                                        >
                                            <ListActionStudyTypes
                                                token={token}
                                                setAnchorMenuStudyTypes={
                                                    setAnchorMenuStudyTypes
                                                }
                                            />
                                        </Menu>
                                    </ClickAwayListener>
                                </>
                            )}
                            <ButtonToolbar
                                titleTooltip={t("common.AccessWorkflow")}
                                title={t("common.Workflow")}
                                startIcon={<DiagramIcon />}
                                onClick={() =>
                                    historyPush(history, eddFileDiagramRoute)
                                }
                            />
                        </>
                    ),
                    newFilter: (
                        <FilterToolbar
                            options={eddFileState.map(e => ({
                                ...e,
                                label: t(`diagram.${e.label}`),
                            }))}
                            placeholder={t("placeholder.EddFileState")}
                            value={selectedUserState?.label}
                            onChange={(_e, value) =>
                                value
                                    ? setSelectedUserState(value)
                                    : setSelectedUserState(null)
                            }
                        />
                    ),
                    add: false,
                }}
                gridSpecificColumns={gridSpecificColumns}
                specificActionsColumns={specificActionsColumns}
                isEditDisabled={true}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "LastUpdate", sort: "desc" }],
                    },
                    pinnedColumns: {
                        left: [`Value`],
                        right: ["actions"],
                    },
                }}
                conditionalDeleteDisabling={row =>
                    !specificEditionRights(row.State) || row.HasDocuments
                }
                editingRights={editingRights}
                withBackButton={fromWorkOrder}
                {...props}
            />
        </>
    );
};
export default EDDFileGrid;

const ListActionStudyTypes = ({ token, setAnchorMenuStudyTypes }) => {
    const [studyTypes, setStudyTypes] = useState([]);

    const fetchSudyTypes = useCallback(
        async isSubscribed => {
            const studyTypesFetched = await Axios.get(`${r.studyType}/select`, {
                headers: { Authorization: "Bearer " + token },
            });
            if (isSubscribed) {
                setStudyTypes(studyTypesFetched.data);
            }
        },
        [token]
    );

    useEffect(() => {
        let isSubscribed = true;
        fetchSudyTypes(isSubscribed);
        return () => (isSubscribed = false);
    }, [fetchSudyTypes]);

    return studyTypes.length ? (
        studyTypes.map(st => {
            return (
                <ActionMenu
                    key={`itmMenu-templateEDD-${st.ObjectID}`}
                    action={() => {
                        getDownload(
                            `EDDFile/Template/${st.Key}`,
                            token,
                            excelSpreadsheetMIME,
                            `${st.Key}_EddFileTemplate`
                        );
                        setAnchorMenuStudyTypes(null);
                    }}
                    label={st.Value}
                />
            );
        })
    ) : (
        <CircularProgress size={20} color={"secondary"} />
    );
};
