import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useHistoryState, useUpdateEffect } from "../../js/utils/customHooks";
import { fetchData } from "../../js/utils/backend";
import { useSelector } from "react-redux";
import {
    createRandomColor,
    getCleanGeometries,
    graphicShaper,
    arcgisPerStationType,
} from "./cst_ArcGIS";
import { BasicMap } from "./MapCustomInputs";
import { t } from "react-i18nify";
import * as r from "../../js/constants/routes";
import StationMapResult from "../DataGrid/StationMapResultGrid";
import { mainRed } from "../../themes/GlobalTheme";

const StationsMap = ({ title, toolbarComponent }) => {
    const token = useSelector(state => state.auth.Token);
    const locale = useSelector(state => state.i18n.locale);
    const [heightMap, setHeightMap] = useState(500);
    const { stationGroupID, OTEntityID } = useParams();
    const viaStationGroup = stationGroupID ?? false;
    const [entity, setEntity] = useState();
    const [layerList, setLayerList] = useState([]);
    const [mapIsLoaded, setMapIsLoaded] = useState(false);
    const [historicSelectionGeometry, setHistoricSelectionGeometry] =
        useHistoryState("existantSelection", null);
    const [selectionGeometryAndFilter, setSelectionGeometryAndFilter] =
        useState({ GeometryWithSRID: historicSelectionGeometry });
    const legend = [
        {
            groupLegendLabel: t(`map.stationsLayers`),
            layersLegend: arcgisPerStationType
                .map(e =>
                    Boolean(e.legend)
                        ? e.legend
                        : {
                              label: t(`field.${e.route}`),
                              symbol: e.graphicShape,
                          }
                )
                .flat(),
        },
    ];

    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        const newHeight = e.clientY - document.body.offsetTop - 224;
        if (newHeight > 200) {
            setHeightMap(newHeight);
        }
    }, []);

    const response = useCallback(
        (route, filter) => {
            return fetchData(route, token, "Select", null, filter);
        },
        [token]
    );

    useEffect(() => {
        let isSubscribed = true;
        const fetching = async () => {
            const entityFetched = await fetchData(
                "OTEntity/map",
                token,
                "ID",
                OTEntityID
            );
            if (isSubscribed) {
                setEntity(entityFetched.data);
            }
        };
        OTEntityID && fetching();
        return () => (isSubscribed = false);
    }, [token, OTEntityID]);

    useUpdateEffect(() => {
        setHistoricSelectionGeometry(
            selectionGeometryAndFilter?.GeometryWithSRID
        );
    }, [selectionGeometryAndFilter]);

    useEffect(() => {
        if (!viaStationGroup) {
            const fetching = async () => {
                //Stations Layers
                const stationLayers = await Promise.all(
                    arcgisPerStationType.map(async stationType => {
                        const stationsFetched = await response(
                            stationType.route,
                            r.filter.genericFilter("OTEntityID", OTEntityID) +
                                "&limit=false"
                        );
                        const rawStations = stationsFetched.data;
                        return {
                            layerProperties: {
                                title: t(`field.${stationType.route}`),
                                id: stationType.route,
                                visible: true,
                            },
                            graphics: {
                                geometries: getCleanGeometries(
                                    rawStations,
                                    e => {
                                        return {
                                            ...stationType.subTypesGraphicShaper(
                                                e
                                            ),
                                            attributes: {
                                                Name: e.Name,
                                                Description: `${t(
                                                    "map.coordinates"
                                                )}: ${
                                                    e.PointString ||
                                                    e.PolygonString
                                                }`,
                                            },
                                        };
                                    }
                                ),
                                graphicShape: stationType.graphicShape,
                            },
                            selectionFilterWatch: true,
                        };
                    })
                );
                const GroupOfstationTypeLayers = {
                    layerType: "group",
                    order: entity?.MapserviceList?.length + 1,
                    groupProperties: {
                        visible: true,
                        title: t("grid.title.Stations"),
                        id: "AllStationsCustomLayer",
                        routeSelectionFilter: "StationRelated",
                    },
                    layers: stationLayers,
                    open: true,
                    selectionFilterWatch: true,
                };

                // Station groups Layers
                let stationGroups = await response(
                    "stationGroup",
                    `&filter[OTEntityID]=${OTEntityID}`
                );
                const rawStationGroups = stationGroups.data;
                const stationGroupsLayers = rawStationGroups.map(e => ({
                    layerProperties: {
                        title: e.Value,
                        id: e.ObjectID,
                        visible: false,
                    },
                    graphics: {
                        geometries: getCleanGeometries(e.Station_Select),
                        graphicShape: {
                            outline: {
                                color: createRandomColor(),
                                width: 3,
                            },
                            style: "cross",
                        },
                    },
                    selectionFilterWatch: true,
                }));
                const GroupOfstationGroupsLayers = {
                    layerType: "group",
                    order: entity?.MapserviceList?.length + 2,
                    groupProperties: {
                        visible: true,
                        title: t(`field.StationGroups`),
                        id: "StationGroup",
                        routeSelectionFilter: "StationGroupsRelated",
                    },
                    layers: stationGroupsLayers,
                    open: false,
                    selectionFilterWatch: true,
                };
                //Zones layers
                const zoneFetched = await response(
                    "zone",
                    `&filter[OTEntityID]=${OTEntityID}`
                );
                const rawZone = zoneFetched.data;
                const zoneLayers = rawZone
                    .filter(e => e.Extent.GeoJSON)
                    .map(e => ({
                        layerProperties: {
                            title: e.Value,
                            id: `Zone${e.Value}`,
                        },
                        graphics: {
                            geometries: [
                                {
                                    ...e,
                                    graphicShape: graphicShaper(e.HexColor),
                                },
                            ],
                            attributes: {
                                Name: e.Value,
                                Description: `Code: ${e.Code}`,
                            },
                        },
                    }));
                const GroupOfZoneLayers = {
                    layerType: "group",
                    order: entity?.MapserviceList?.length,
                    groupProperties: {
                        visible: true,
                        title: t(`map.zonesExtents`),
                        id: `ZonesCustomLayer`,
                    },
                    layers: zoneLayers,
                };

                setLayerList([
                    GroupOfZoneLayers,
                    GroupOfstationTypeLayers,
                    GroupOfstationGroupsLayers,
                ]);
            };
            entity && fetching();
        }
        // eslint-disable-next-line
    }, [OTEntityID, response, entity, viaStationGroup, locale]);

    useEffect(() => {
        if (viaStationGroup) {
            const fetching = async () => {
                const stationGroupFetched = await fetchData(
                    "stationGroup/map",
                    token,
                    "ID",
                    stationGroupID
                );
                const stationGroup = stationGroupFetched.data;
                const { Stations: stationTypes, Value: stationGroupName } =
                    stationGroup;

                //Station Type layers
                const stationTypeLayers = Object.entries(stationTypes).map(
                    ([StationTypeTitle, stationsFromStationType]) => {
                        const stationTypeArcgisConfig =
                            arcgisPerStationType.find(
                                e => e.route === StationTypeTitle
                            );
                        return {
                            layerProperties: {
                                title: t(`field.${StationTypeTitle}`),
                                id: StationTypeTitle,
                                visible: true,
                            },
                            graphics: {
                                geometries: getCleanGeometries(
                                    stationsFromStationType,
                                    e => {
                                        return {
                                            ...stationTypeArcgisConfig.subTypesGraphicShaper(
                                                e
                                            ),
                                            attributes: {
                                                Name: e.Name,
                                                Description: `${t(
                                                    "map.coordinates"
                                                )}: ${
                                                    e.PointString ||
                                                    e.PolygonString
                                                }`,
                                            },
                                        };
                                    }
                                ),
                                graphicShape:
                                    stationTypeArcgisConfig.graphicShape,
                            },
                        };
                    }
                );

                const GroupOfstationTypeLayers = {
                    layerType: "group",
                    order: entity?.MapserviceList?.length + 1,
                    groupProperties: {
                        title: `${t(`map.stationsOf`)} ${stationGroupName}`,
                        id: `Stations${stationGroupName}`,
                    },
                    layers: stationTypeLayers,
                    open: true,
                };

                // Layers;
                setLayerList([GroupOfstationTypeLayers]);
            };
            entity && fetching();
        }
    }, [stationGroupID, viaStationGroup, entity, response, token]);
    // via station map et station group
    // TODO check token here
    return (
        <Grid p={5}>
            {mapIsLoaded && (
                <>
                    <Box
                        sx={{
                            typography: "h3",
                            pt: 1,
                            pb: 2,
                            display: "flex",
                            alignItems: "center",
                            height: "26px",
                        }}
                    >
                        {title ?? entity?.Name}
                    </Box>
                    {toolbarComponent && (
                        <Box
                            mt={6}
                            sx={{
                                border: "1px solid rgba(224, 224, 224, 1)",
                                display: "flex",
                            }}
                        >
                            {toolbarComponent}
                        </Box>
                    )}
                </>
            )}
            <Box sx={{ height: `${heightMap}px` }}>
                <BasicMap
                    layerList={layerList}
                    legend={legend}
                    center={entity?.Extent}
                    mapServerServices={entity?.MapserviceList}
                    basemap={entity?.Basemap}
                    mapServerLink={entity?.GisServer}
                    mapName={entity?.Name}
                    setMapIsLoaded={e => {
                        setMapIsLoaded(e);
                    }}
                    groupFilterSelection={[
                        "AllStationsCustomLayer",
                        "StationGroup",
                    ]}
                    setSelectionGeometryAndFilter={e => {
                        setSelectionGeometryAndFilter(e);
                    }}
                    existantSelection={historicSelectionGeometry}
                    hasSelectionButton={Boolean(!viaStationGroup)}
                    hasDrawingButton={Boolean(!viaStationGroup)}
                    hasCommentButton={Boolean(!viaStationGroup)}
                    hasSaveButton={Boolean(!viaStationGroup)}
                    hasMeasurementTools={Boolean(!viaStationGroup)}
                />
            </Box>
            {mapIsLoaded && !viaStationGroup && (
                <>
                    <Box
                        sx={{
                            height: "8px",
                            cursor: "row-resize",
                            "&:hover": {
                                boxSizing: "border-box",
                                borderTop: `2px solid ${mainRed}`,
                                borderBottom: `2px solid ${mainRed}`,
                            },
                        }}
                        draggable={true}
                        onMouseDown={e => handleMouseDown(e)}
                    />
                    {Boolean(selectionGeometryAndFilter?.GeometryWithSRID) && (
                        <StationMapResult
                            bodyOfRequest={selectionGeometryAndFilter}
                            withGeometry
                        />
                    )}
                </>
            )}
        </Grid>
    );
};

export default StationsMap;
