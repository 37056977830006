import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { t } from "react-i18nify";
import { FormProvider, useForm } from "react-hook-form";
import EditionPanelGlobal from "./EditionPanelGlobal";
import {
    HiddenField,
    VColorPicker,
    VTextField,
} from "../../Widgets/Custom Inputs/CustomInputs";
import { Box } from "@mui/system";
import { Redirect, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchData } from "../../../js/utils/backend";
import { ScreenLoading } from "../../Widgets/CustomLoadings";
import { MapExtentInput } from "../../ArcGIS/MapCustomInputs";
import {
    arcgisGeometryToSRIDGeoJSON,
    extentShape,
    graphicShaper,
} from "../../ArcGIS/cst_ArcGIS";
import { useEditionRightsPerUserStatus } from "../../../js/utils/customHooks";

const ZoneEditionPanel = ({ route = "zone" }) => {
    const { Parent, NodeID, mode, UrlID } = useParams();
    const token = useSelector(state => state.auth.Token);
    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const [outputGeometry, setOutputGeometry] = useState();
    const { reset, setValue } = methods;
    const editingRights = useEditionRightsPerUserStatus([
        "EntityManager",
        "Contributor",
    ]);
    const edition = mode === "edit";
    const [entities, setEntities] = useState();
    const [legend, setLegend] = useState([]);
    const [loadingCreation, setLoadingCreation] = useState(true);
    const [loadingEdition, setLoadingEdition] = useState(true);
    const [layerList, setLayerList] = useState();
    const [currentZone, setCurrentZone] = useState(null);
    const loadingManaged = edition
        ? !loadingCreation && !loadingEdition
        : !loadingCreation;

    useEffect(() => {
        const fetching = async () => {
            const nodeFetched = await fetchData(
                "OTEntityTree",
                token,
                "ID",
                NodeID
            );
            let node = nodeFetched.data;
            const { OTEntityID, StudyID } = node;

            const zonesFetched = await fetchData(
                route,
                token,
                "Select",
                null,
                Parent === "entity"
                    ? `&filter[OTEntityID]=${OTEntityID}`
                    : `&filter[StudyId]=${StudyID}`
            );
            const rawZone = zonesFetched.data;
            const zones = rawZone.filter(
                e => e.Extent.GeoJSON && e.ObjectID !== UrlID
            );
            const zoneLayers = zones.map(e => ({
                layerProperties: {
                    title: e.Value,
                    id: `Zone${e.Value}`,
                },
                graphics: {
                    geometries: [
                        {
                            ...e,
                            graphicShape: graphicShaper(e.HexColor),
                        },
                    ],
                    attributes: {
                        Name: e.Value,
                        Description: `Code: ${e.Code}`,
                    },
                },
            }));

            const entityFetched = await fetchData(
                "OTEntity/map",
                token,
                "ID",
                OTEntityID
            );

            let entities = { entity: entityFetched.data };
            if (Parent === "study") {
                const studyFetched = await fetchData(
                    "Study",
                    token,
                    "ID",
                    StudyID
                );
                entities = { ...entities, study: studyFetched.data };
            }

            const groupTitle = `${t("view.zone.PreExistingZones")} ${
                Parent === "study"
                    ? `${entities.study.Value} ${t("misc.From")} ${
                          entities.entity.Name
                      }`
                    : entities.entity.Name
            }`;

            const zoneGroupLayer = {
                layerType: "group",
                order: entities?.entity?.MapserviceList?.length,
                groupProperties: {
                    title: groupTitle,
                    id: `Zones${
                        Parent === "study"
                            ? entities.study.Value
                            : entities.entity.Name
                    }`,
                },
                layers: zoneLayers,
            };
            setEntities(entities);
            setLayerList([zoneGroupLayer]);
            setLegend([
                {
                    groupLegendLabel: "Zone of interest",
                    layersLegend: [
                        {
                            label: "Zone extent",
                            symbol: {
                                ...extentShape,
                                style: "square",
                            },
                        },
                    ],
                },
                {
                    groupLegendLabel: groupTitle,
                    layersLegend: zones.map(e => ({
                        label: e.Code,
                        symbol: {
                            ...graphicShaper(e.HexColor),
                            style: "square",
                        },
                    })),
                },
            ]);
            setLoadingCreation(false);
        };
        fetching();
    }, [NodeID, Parent, route, token, UrlID]);

    useEffect(() => {
        if (edition && editingRights) {
            const fetching = async () => {
                const response = await fetchData(route, token, "ID", UrlID);
                reset({
                    ...response.data,
                    Extent: response.data.Extent.GeoJSON
                        ? response.data.Extent
                        : null,
                });
                const zone = response.data;

                setCurrentZone(zone);
                setLoadingEdition(false);
            };
            fetching();
        }
    }, [reset, route, token, UrlID, edition, editingRights]);

    useEffect(() => {
        const settingExtent = async extent => {
            const sridGesoJSON =
                extent.length === 0
                    ? null
                    : await arcgisGeometryToSRIDGeoJSON(extent);
            setValue("Extent", sridGesoJSON, { shouldDirty: true });
        };
        outputGeometry && settingExtent(outputGeometry);
    }, [outputGeometry, setValue]);

    return !editingRights ? (
        <Redirect to={"/home"} />
    ) : loadingManaged ? (
        <FormProvider {...methods}>
            <Grid m={1} sx={{ height: "calc(100vh - 140px)" }}>
                <Box
                    sx={{
                        typography: "h3",
                        pt: 6,
                        pl: 4,
                        pb: 2,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    {edition
                        ? `${t("view.zone.EditZone")}: ${currentZone?.Value}`
                        : `${t("view.zone.CreateNewZone")} ${
                              Parent === "study"
                                  ? `"${entities.study.Value}", ${t(
                                        "misc.From"
                                    )} ${entities.entity.Name}`
                                  : entities.entity.Name
                          }`}
                </Box>
                <Grid mx={4} sx={{ height: "100%" }}>
                    <Box
                        sx={{
                            height: "400px",
                        }}
                    >
                        <MapExtentInput
                            center={entities?.entity?.Extent}
                            editionLayerTitle="Zone extent"
                            existantGeometry={
                                currentZone?.Extent.GeoJSON !== null &&
                                currentZone?.Extent
                            }
                            layerList={layerList}
                            setOutputGeometry={e => {
                                setOutputGeometry(e);
                            }}
                            basemap={entities?.entity?.Basemap}
                            mapServerServices={entities?.entity?.MapserviceList}
                            mapServerLink={entities?.entity?.GisServer}
                            disablePoint
                            legend={legend}
                            mapName={entities?.entity?.Name}
                        />
                    </Box>

                    <Grid my={2}>
                        <EditionPanelGlobal route={route}>
                            <HiddenField
                                fieldName={"Extent"}
                                defaultValue={null}
                            />
                            {Parent === "study" ? (
                                <HiddenField
                                    fieldName="StudyId"
                                    defaultValue={entities.study.ID}
                                />
                            ) : (
                                <HiddenField
                                    fieldName="OTEntityID"
                                    defaultValue={entities.entity.ID}
                                />
                            )}

                            <HiddenField
                                fieldName="isStudyZone"
                                defaultValue={Parent === "study"}
                            />
                            <Grid item xs={12}>
                                <VTextField
                                    label={t("field.Name")}
                                    fieldName={"Value"}
                                    validation={{
                                        required: t(
                                            "input.validation.required"
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <VTextField
                                    label={t("field.Code")}
                                    fieldName={"Code"}
                                    validation={{
                                        required: t(
                                            "input.validation.required"
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <VColorPicker
                                    fieldName={"HexColor"}
                                    label={t("field.Color")}
                                    validation={{
                                        required: {
                                            value: true,
                                            message: t(
                                                "input.validation.required"
                                            ),
                                        },
                                    }}
                                />
                            </Grid>
                        </EditionPanelGlobal>
                    </Grid>
                </Grid>
            </Grid>
        </FormProvider>
    ) : (
        <ScreenLoading />
    );
};

export default ZoneEditionPanel;
