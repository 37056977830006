import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import AuthRouter from "./routers/AuthRouter";
import { GlobalTheme } from "./themes/GlobalTheme";
import axios from "axios";
import { backendURL } from "./js/utils/backend";

/** Main file with auth router, theme provider and an useEffect to refresh entire app when changing language. */
const App = () => {
    axios.defaults.baseURL = backendURL;

    return (
        <ThemeProvider theme={GlobalTheme}>
            <AuthRouter />
        </ThemeProvider>
    );
};

export default App;
