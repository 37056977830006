import React from "react";
import { Grid } from "@mui/material";
import { t } from "react-i18nify";
import { Box } from "@mui/system";
import { Redirect, useParams } from "react-router-dom";
import * as r from "../../../js/constants/routes";
import { FormStepper } from "../../Widgets/CustomStepper";
import StepObjectSelection from "./StepObjectSelection";
import StepFieldSelection from "./StepFieldSelection";
import { StepQueryBuilder } from "./QueryBuilder";
import { useEditionRightsPerUserStatus } from "../../../js/utils/customHooks";

/** Form Stepper to create or edit a query. Consists of three steps:
 * StepObjectSelection: selection of the entities of interest
 * StepQueryBuilder: defining the set of rules for each entity selected
 * StepFieldSelection: selecting the fields of interest for each entity selected */
const EditionPanelQueryStepper = ({ baseURL = r.query.route }) => {
    const { mode } = useParams();
    const edition = mode === "edit";
    const editingRights = useEditionRightsPerUserStatus(["EntityManager"]);
    const fieldName = "EntityQueries";
    const steps = [
        {
            name: t("view.query.objectSelection.objectSelection"),
            form: <StepObjectSelection fieldName={fieldName} />,
        },
        {
            name: t("view.query.conditions.conditions"),
            form: <StepQueryBuilder fieldName={fieldName} />,
            formEditOnNext: methods => {
                const { clearErrors, watch } = methods;
                clearErrors(
                    Object.entries(watch(fieldName)).map(
                        ([key, value]) => `${fieldName}.${key}.SelectedFields`
                    )
                );
            },
        },
        {
            name: t("view.query.outputSelection.outputSelection"),
            form: <StepFieldSelection fieldName={fieldName} />,
        },
    ];

    return !editingRights ? (
        <Redirect to={"/home"} />
    ) : (
        <Grid m={1} p={2} sx={{ minHeight: "calc(100vh - 140px)" }}>
            <Box
                sx={{
                    typography: "h3",
                    pt: 6,
                    pl: 4,
                    pb: 2,
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {edition
                    ? `${t("view.query.stepper.EditQuery")}:`
                    : t("view.query.stepper.CreateNewQuery")}
            </Box>
            <Grid mx={4} sx={{ height: "100%" }}>
                <FormStepper
                    steps={steps}
                    baseURL={baseURL}
                    currentLocation={r.queries}
                />
            </Grid>
        </Grid>
    );
};

export default EditionPanelQueryStepper;
