import React, { useCallback, useEffect, useRef, useState } from "react";
import JSONtoTreeView from "../Widgets/JSONToTreeView";
import { Box, Grid } from "@mui/material";
import { t } from "react-i18nify";
import {
    HiddenField,
    VDateOnlyPicker,
    VTextField,
    VYearPickerNumber,
} from "../Widgets/Custom Inputs/CustomInputs";
import { VSelectAdvanced, VSelectURL } from "../Widgets/Custom Inputs/Select";
import { entityNodeTypesWithTemplate } from "../../js/constants/selectOptions";
import * as r from "../../js/constants/routes";
import { ActionMenu, ContextMenu, NodeDialog } from "./TreeEditorComponents";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    updateSpecificNodeEntityTree,
    updateTreeViewNodeExpand,
} from "../../js/redux/actions/userPref";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import Axios from "axios";
import { useAxiosConfig } from "../../js/utils/customHooks";
import ScienceOutlinedIcon from "@mui/icons-material/ScienceOutlined";
import StationGrid from "../DataGrid/StationGrid";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import { useHistory, useLocation } from "react-router-dom";
import { DateOnlyToJSDate, isArrayLength } from "../../js/utils/genericMethods";
import { fetchData } from "../../js/utils/backend";

export const isAddDisabledSwitch = NodeType => {
    switch (NodeType) {
        case "OTEntityTreeTemplateStudyNodeType":
            return true;
        case "OTEntityTreeTemplateStationGroupNodeType":
            return true;
        case "OTEntityTreeTemplateStationNodeType":
            return true;
        case "GenericGrid":
            return true;
        default:
            return false;
    }
};

/** Method, which when has a given id node, will fetch all the parents nodes ID until the top of the tree, in this way we will be able to give to the entity tree which nodes to expand (to the current node) */
export const fetchTraverseExtendTreePathNodes = async (
    containingNode,
    token,
    dispatch,
    paramURL = "otentitytreepath",
    isLocalTreeNode = false
) => {
    if (!isLocalTreeNode) {
        let url = `/OTEntityTree/${paramURL}`;
        let nodes = [];
        const fetchAllParentNodes = await fetchData(
            url,
            token,
            "ID",
            containingNode,
            "",
            "",
            true
        );
        nodes.push(fetchAllParentNodes?.data?.OTEntityID);
        /** By using the retrieved all parent nodes, we can build an array of nodes  */
        let searchTree = element => {
            for (const key in element) {
                if (key === "ID") {
                    nodes.push(element.ID);
                    searchTree(element.Parent);
                }
            }
        };
        searchTree(fetchAllParentNodes.data);
        nodes.push(containingNode); //
        dispatch(updateTreeViewNodeExpand(nodes));
        return nodes;
    } else {
        return;
    }
};

const EntityTree = ({ baseURL = r.oTEntityTree, JSONData, ...props }) => {
    const refMenu = useRef(null);
    const [contextMenuState, setContextMenuState] = useState(null);
    const [tree, setTree] = useState(JSONData);
    const treeViewNodeExpand = useSelector(
        state => state.userPref.TreeViewNodeExpand
    );
    const [expanded, setExpanded] = useState([]);
    const [editStudyOpen, setEditStudyOpen] = useState(false);
    const [editStationOpen, setEditStationOpen] = useState(false);
    const updatedEntityTree = useSelector(
        state => state.userPref.LastEntityTreeUpdate
    );
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const nodeTitle = contextMenuState?.node?.Value;

    useEffect(() => {
        if (isArrayLength(treeViewNodeExpand)) {
            setExpanded(treeViewNodeExpand);
        }
        // eslint-disable-next-line
    }, [treeViewNodeExpand]);

    const CustomActionsSwitch = NodeType => {
        switch (NodeType) {
            case "OTEntityTreeTemplateStudyNodeType":
                return (
                    <ActionMenu
                        action={() => editStudyAction(contextMenuState)}
                        Icon={<AssignmentOutlinedIcon />}
                        label={t("tree.AddStudy", { value: nodeTitle })}
                    />
                );
            case "OTEntityTreeTemplateStationGroupNodeType":
                return (
                    <ActionMenu
                        action={() => editStationAction(contextMenuState)}
                        Icon={<ScienceOutlinedIcon />}
                        label={t("tree.AddStationGroup", { value: nodeTitle })}
                    />
                );
            default:
                return null;
        }
    };

    const [URL, setURL] = useState(baseURL);
    const [idFied, setIdField] = useState("ID");
    const [form, setForm] = useState(() => EntityForm);

    const StatesSwitch = useCallback(
        NodeType => {
            switch (NodeType) {
                case "StudyNodeType":
                    setURL(r.study);
                    setIdField("TemplateReferenceID");
                    setForm(() => StudyForm);
                    break;
                case "StationGroupNodeType":
                    setURL(r.StationGroup);
                    setIdField("ID");
                    setForm(() => StationGroupForm);
                    break;
                default:
                    setURL(baseURL);
                    setIdField("ID");
                    setForm(() => EntityForm);
                    break;
            }
        },
        [baseURL]
    );

    useEffect(() => {
        if (contextMenuState) StatesSwitch(contextMenuState?.node?.NodeType);
    }, [contextMenuState, StatesSwitch]);

    const handleClose = useCallback(() => {
        setContextMenuState(null);
    }, []);

    useEffect(() => {
        setTree(JSONData);
    }, [JSONData]);
    useEffect(() => {
        setExpanded(t => t);
    }, [updatedEntityTree]);

    const onFormClose = (node, isEditing) => {
        if (node) {
            if (!isEditing) {
                setExpanded(e => (node.ParentID ? [...e, node.ParentID] : e));
                if (node?.parentNode?.ParentID) {
                    dispatch(
                        updateSpecificNodeEntityTree(node?.parentNode?.ParentID)
                    );
                } else {
                    dispatch(updateSpecificNodeEntityTree(node.OTEntityID));
                }
            } else {
                dispatch(updateSpecificNodeEntityTree(node?.ParentID));
            }
        }
    };

    const onDelete = node => {
        if (node.parentNode?.ParentID) {
            dispatch(updateSpecificNodeEntityTree(node.parentNode?.ParentID));
        } else {
            dispatch(updateSpecificNodeEntityTree(node.OTEntityID));
        }
        if (location.pathname === node.route) {
            history.replace(`/entity-page/${node.OTEntityID}`);
        }
    };

    const editStudyAction = useCallback(
        contextMenuState => {
            const { node } = contextMenuState;
            setEditStudyOpen({
                parentNode: node,
            });
            handleClose();
        },
        [handleClose]
    );
    const editStationAction = useCallback(
        contextMenuState => {
            const { node } = contextMenuState;
            setEditStationOpen({
                parentNode: node,
            });
            handleClose();
        },
        [handleClose]
    );
    const handleContextMenu = useCallback((node, event) => {
        event.preventDefault();
        if (node.hasContextMenu !== false) {
            setContextMenuState(c =>
                c === null
                    ? {
                          mouseX: event.clientX - 2,
                          mouseY: event.clientY - 4,
                          node,
                      }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                      // Other native context menus might behave different.
                      // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                      null
            );
        }
    }, []);

    return (
        <Box>
            <JSONtoTreeView
                JSONData={tree}
                onContextMenu={handleContextMenu}
                expanded={expanded}
                onNodeToggle={(_event, exp) => {
                    setExpanded(exp);
                }}
                {...props}
            />
            <ContextMenu
                fref={refMenu}
                contextMenuState={contextMenuState}
                handleClose={handleClose}
                setTree={setTree}
                setExpanded={setExpanded}
                URL={URL}
                baseURL={baseURL}
                Form={form}
                BaseForm={EntityForm}
                onDelete={onDelete}
                onFormClose={onFormClose}
                nodeIDFIeld={idFied}
                isAddDisable={contextMenuState =>
                    isAddDisabledSwitch(contextMenuState.node?.NodeType)
                }
                CustomActions={contextMenuState => {
                    return contextMenuState.node?.CanAddNode
                        ? CustomActionsSwitch(contextMenuState.node?.NodeType)
                        : null;
                }}
            />
            <StudyDialog
                editStudyOpen={editStudyOpen}
                setEditStudyOpen={setEditStudyOpen}
                onFormClose={onFormClose}
            />
            <StationDialog
                editStationOpen={editStationOpen}
                setEditStationOpen={setEditStationOpen}
                onFormClose={onFormClose}
            />
        </Box>
    );
};

const StudyDialog = ({ editStudyOpen, setEditStudyOpen, onFormClose }) => {
    return Boolean(editStudyOpen) ? (
        <NodeDialog
            Form={StudyForm}
            label={t("view.study.Study").toLowerCase()}
            open={Boolean(editStudyOpen)}
            rowParams={editStudyOpen}
            setOpen={setEditStudyOpen}
            URL={r.study}
            onClose={(node, isEditing) => onFormClose(node, isEditing)}
        />
    ) : null;
};
export const StationDialog = ({
    editStationOpen,
    setEditStationOpen,
    onFormClose,
}) => {
    return Boolean(editStationOpen) ? (
        <NodeDialog
            Form={StationGroupForm}
            label={t("view.station.StationGroup").toLowerCase()}
            open={Boolean(editStationOpen)}
            rowParams={editStationOpen}
            setOpen={setEditStationOpen}
            URL={r.StationGroup}
            onClose={(node, isEditing) => onFormClose(node, isEditing)}
        />
    ) : null;
};

const EntityForm = ({ node }) => {
    const methods = useFormContext();
    const { reset } = methods;
    useEffect(() => {
        if (node.ID)
            reset({
                ID: node.ID,
                NodeType: node.NodeType,
                Value: node.Value,
                ParentID: node.ParentID,
                OTEntityID: node.OTEntityID,
                ParentNodeType: node.ParentNodeType,
            });
        else {
            reset({
                ParentID: node.ParentID,
                OTEntityID: node.parentNode.OTEntityID,
                ParentNodeType: node.parentNode.NodeType,
            });
        }
    }, [reset, node]);
    return (
        <Grid container spacing={2}>
            <HiddenField
                fieldName={"ParentID"}
                defaultValue={node.ParentID ?? null}
            />
            <HiddenField
                fieldName={"OTEntityID"}
                defaultValue={node.parentNode?.OTEntityID ?? node.OTEntityID}
            />
            <HiddenField
                fieldName={"ParentNodeType"}
                defaultValue={node.parentNode?.NodeType ?? node.ParentNodeType}
            />
            <Grid item xs={12}>
                <VTextField
                    fullWidth
                    label={t("field.Value")}
                    fieldName={"Value"}
                    validation={{
                        required: {
                            value: true,
                            message: t("input.validation.required"),
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};
const StudyForm = ({ node }) => {
    const methods = useFormContext();
    const { reset, watch, trigger } = methods;
    const config = useAxiosConfig();
    useEffect(() => {
        if (node.ID) {
            let isSubscribed = true;
            const fetch = async () => {
                try {
                    const response = await Axios.get(
                        `${r.study}/${node.ID}`,
                        config
                    );
                    if (isSubscribed) {
                        reset({
                            ID: node.ID,
                            NodeType: entityNodeTypesWithTemplate.find(
                                e => e.value === node.NodeType
                            ),
                            ParentID: node.ParentID,
                            OTEntityID: node.OTEntityID,
                            ParentNodeType: node.ParentNodeType,
                            StudyTypeID: node.StudyTypeSelector.ObjectID,
                            ...response.data,
                        });
                    }
                } catch (e) {
                    console.error(e);
                }
            };
            fetch();
            return () => (isSubscribed = false);
        } else {
            reset({
                ParentID: node.parentNode.ID,
                OTEntityID: node.parentNode.OTEntityID,
                ParentNodeType: node.parentNode.NodeType,
                Year: new Date().getFullYear(),
                StudyTypeID: node.parentNode.StudyTypeSelector
                    ? node.parentNode.StudyTypeSelector.ObjectID
                    : null,
                StartDate: null,
                EndDate: null,
            });
        }
    }, [reset, node, config]);

    const startDate = watch("StartDate");
    const endDate = watch("EndDate");
    useEffect(() => {
        if (startDate) {
            trigger(["StartDate"]);
        }
        if (endDate) {
            trigger(["EndDate"]);
        }
    }, [startDate, endDate, trigger]);

    return (
        <Grid container spacing={2}>
            <HiddenField
                fieldName={"OTEntityID"}
                defaultValue={node.parentNode.OTEntityID}
            />
            <HiddenField
                fieldName={"ParentNodeType"}
                defaultValue={node.parentNode.NodeType}
            />
            <HiddenField
                fieldName={"ParentID"}
                defaultValue={node.parentNode.ID}
            />

            <Grid item xs={4}>
                <VYearPickerNumber
                    label={t("field.StudyYear")}
                    fieldName={"Year"}
                    validation={{
                        required: {
                            value: true,
                            message: t("input.validation.required"),
                        },
                    }}
                />
            </Grid>
            <Grid item xs={8}>
                <VTextField
                    fullWidth
                    label={t("field.Title")}
                    fieldName={"Value"}
                    validation={{
                        required: {
                            value: true,
                            message: t("input.validation.required"),
                        },
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <VDateOnlyPicker
                    label={t("field.StartDate")}
                    fieldName={"StartDate"}
                    removeTodayAsDefault
                    validation={{
                        required: {
                            value: true,
                            message: t("input.validation.required"),
                        },
                        validate: () =>
                            watch("StartDate") &&
                            watch("EndDate") &&
                            DateOnlyToJSDate(watch("StartDate")) >
                                DateOnlyToJSDate(watch("EndDate"))
                                ? t("input.validation.InvalidStartingDate")
                                : true,
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <VDateOnlyPicker
                    label={t("field.EndDate")}
                    fieldName={"EndDate"}
                    removeTodayAsDefault
                    validation={{
                        required: {
                            value: true,
                            message: t("input.validation.required"),
                        },
                        validate: () =>
                            watch("StartDate") &&
                            watch("EndDate") &&
                            DateOnlyToJSDate(watch("StartDate")) >
                                DateOnlyToJSDate(watch("EndDate"))
                                ? t("input.validation.InvalidEndingDate")
                                : true,
                    }}
                />
            </Grid>
            {!node.parentNode.StudyTypeSelector?.IsMultiStudyType || node.ID ? (
                <HiddenField fieldName={"StudyTypeID"} />
            ) : (
                <Grid item xs={12}>
                    <VSelectURL
                        label={t("field.StudyType")}
                        fieldName="StudyTypeID"
                        defaultValue={watch("StudyTypeSelector")}
                        URL={r.studyType}
                        validation={{
                            required: {
                                value: true,
                                message: t("input.validation.required"),
                            },
                        }}
                        paramURL={r.filter.genericFilter(
                            "IsMultiStudyType",
                            false
                        )}
                    />
                </Grid>
            )}
        </Grid>
    );
};
const StationGroupForm = ({ node }) => {
    const methods = useFormContext();
    const { reset } = methods;
    const config = useAxiosConfig();
    const [OTEntityID, setOTEntityID] = useState(undefined);
    useEffect(() => {
        if (node.ID) {
            let isSubscribed = true;
            const fetch = async () => {
                try {
                    const response = await Axios.get(
                        `${r.StationGroup}/${node.ID}`,
                        config
                    );
                    if (isSubscribed) {
                        reset({
                            ID: node.ID,
                            ParentID: node.ParentID,
                            OTEntityID: node.OTEntityID,
                            StationIds: response.data.StationModel.map(
                                e => e.ID
                            ),
                            ...response.data,
                        });
                        setOTEntityID(node.OTEntityID);
                    }
                } catch (e) {
                    console.error(e);
                }
            };
            fetch();
            return () => (isSubscribed = false);
        } else {
            reset({
                ParentID: node.parentNode.ID,
                OTEntityID: node.parentNode.OTEntityID,
            });
            setOTEntityID(node.parentNode.OTEntityID);
        }
    }, [reset, node, config]);
    return OTEntityID ? (
        <Grid container spacing={2}>
            <HiddenField
                fieldName={"OTEntityID"}
                defaultValue={node.parentNode.OTEntityID}
            />
            <HiddenField
                fieldName={"ParentID"}
                defaultValue={node.parentNode.ID}
            />
            <Grid item xs={12}>
                <VTextField
                    fullWidth
                    label={t("field.Title")}
                    fieldName={"Value"}
                    validation={{
                        required: {
                            value: true,
                            message: t("input.validation.required"),
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12}
                sx={{
                    overflowY: "scroll",
                    height:660, mt: "8px"
                }}
            >
                <VSelectAdvanced
                    fieldName={"StationIds"}
                    grid={
                        <StationGrid
                            specificToolbar={{ disableToolbar: true }}
                            OTEntityIDViaTree={OTEntityID}
                        />
                    }
                    paramURL={`OTEntityID=${OTEntityID}`}
                />
            </Grid>
        </Grid>
    ) : (
        <ScreenLoading />
    );
};

export default EntityTree;
