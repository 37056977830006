import * as actionTypes from "../actionTypes";

const initialState = {
    IsConnected: false,
    IsValidated: false,
    Since: new Date(),
    errorMessage: "",
    Token: "",
    originalPath: "/",
    versionNumber: "0.0",
    External: true,
    DisplayNewFeaturesPopUp: false,
    ExternalProvidersToken: ""
};

/** Reducer handling all states related to authentication, logout, token and change version (reconnecting the user) DOC*/
export default function AuthReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.RESETERROR:
            return {
                ...state,
                errorMessage: "",
            };
        case actionTypes.UPDATEENTITIESLIST:
            return {
                ...state,
                EntityManagerEntityIDs: Object.values(action.payload)
            };
        case actionTypes.AUTHENTICATED:
            return {
                ...state,
                IsConnected: true,
                IsValidated: action.payload.validated,
                UserState: action.payload.State,
                EntityManager: action.payload.EntityManager,
                GisManager: action.payload.GisManager,
                CompanyManager: action.payload.CompanyManager,
                HoldingManager: action.payload.HoldingManager,
                BranchManager: action.payload.BranchManager,
                Contributor: action.payload.Contributor,
                Reader: action.payload.Reader,
                BranchManagerEntityIDs: action.payload.BranchManagerEntityIDs,
                EntityManagerEntityIDs: action.payload.EntityManagerEntityIDs,
                ReaderEntityIDs: action.payload.ReaderEntityIDs,
                ExternalEntityIDs: action.payload.ExternalEntityIDs,
                ContributorEntityIDs: action.payload.ContributorEntityIDs,
                UserID: action.payload.UserID,
                UserName: action.payload.UserName,
                External: action.payload.External,
                DisplayNewFeaturesPopUp: action.payload.DisplayNewFeaturesPopUp,
                Since: new Date(),
                errorMessage: "",
                Token: action.payload.Token,
                FromDigitalPass: action.payload.FromDigitalPass,
                ExternalProvidersToken: action.payload.ExternalProvidersToken
            };
        case actionTypes.UNAUTHENTICATED:
            return {
                ...state,
                IsConnected: false,
                IsValidated: false,
                errorMessage: "",
                Token: "",
            };
        case actionTypes.AUTHENTICATION_ERROR:
            return {
                ...state,
                errorMessage: action.payload,
            };
        case actionTypes.ENTERED:
            return {
                ...state,
                originalPath: action.payload,
            };
        case actionTypes.VERSIONCHANGE:
            if (state.versionNumber !== action.payload) {
                return {
                    ...state,
                    IsConnected: false,
                    IsValidated: false,
                    errorMessage: "",
                    Token: "",
                };
            } else {
                return state;
            }
        case actionTypes.REFRESHTOKEN:
            return { ...state, Since: new Date(2000, 1, 1) };
        case actionTypes.HIDENEWFEATURESPOPUP:
            return { ...state, DisplayNewFeaturesPopUp: false };
        default:
            return state;
    }
}
