import React, { useState, useEffect, useRef, useMemo } from "react";
import { convertToRaw } from "draft-js";
import MUIRichTextEditor from "mui-rte";
import { Box, FormHelperText } from "@mui/material";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { useFormContext } from "react-hook-form";
import { searchForErrors } from "./CustomInputs";

export const RichTextEditor = ({
    onChange = () => {},
    onSave = () => {},
    error = false,
    readOnly = false,
    defaultValue,
    isShort = false,
    fref,
    placeholder = "Start writing content here...",
    ...props
}) => {
    const muiEditorRef = fref ?? React.createRef();

    return (
        <div onClick={e => muiEditorRef.current.focus()}>
            <MUIRichTextEditor
                onSave={onSave}
                defaultValue={defaultValue}
                label={placeholder}
                controls={[
                    "bold",
                    "normal",
                    "underline",
                    "clear",
                    "undo",
                    "redo",
                    "bulletList",
                ]}
                id={"mui-rte"}
                onChange={data => {
                    onChange(data);
                }}
                inlineToolbar={false}
                ref={muiEditorRef}
                draftEditorProps={{
                    spellCheck: true,
                }}
                {...props}
            />
        </div>
    );
};

const VRichTextEditor = ({
    isShort,
    fieldName,
    label,
    fref,
    defaultValue = "",
    ...props
}) => {
    defaultValue = defaultValue ?? "";
    const baseRef = useRef();
    fref = fref ?? baseRef;
    const methods = useFormContext();
    const { watch, errors, setValue } = methods;
    const formValue = watch(fieldName);
    const formValueTEMP = watch(`${fieldName}_TEMP`);

    const [content, setContent] = useState(
        JSON.stringify(
            convertToRaw(stateFromHTML(formValue ? formValue : defaultValue))
        )
    );

    useEffect(() => {
        if (formValueTEMP) {
            setContent(
                JSON.stringify(convertToRaw(stateFromHTML(formValueTEMP)))
            );
            fref.current.focus();
        }
    }, [formValueTEMP, fref]);

    const error = useMemo(
        () => searchForErrors(fieldName, errors),
        [fieldName, errors]
    );
    const isError = Boolean(error?.message);

    return (
        <Box sx={{ mt: 1 }}>
            {label && (
                <Box
                    sx={{
                        transform: "translate(-4px, -11px) scale(1)",
                        fontSize: "18px",
                        paddingLeft: "6px",
                        paddingRight: "6px",
                        zIndex: 1,
                        margin: "2px 0px 8px 14px",
                        color: props.isError ? "red" : "rgba(0, 0, 0, 0.54)",
                        position: "absolute",
                        backgroundColor: "white",
                        typography: "body2",
                    }}
                >
                    {label}
                </Box>
            )}
            <RichTextEditor
                readOnly={false}
                defaultValue={content}
                onChange={data => {
                    //onChange(data);
                    let empty =
                        stateToHTML(data.getCurrentContent()) === "<p><br></p>";
                    setValue(
                        fieldName,
                        empty ? null : stateToHTML(data.getCurrentContent()),
                        {
                            shouldDirty: !empty,
                            shouldValidate: !empty,
                        }
                    );
                }}
                isShort={isShort}
                fref={fref}
                error={isError}
                {...props}
            />
            {isError ? (
                <FormHelperText error={isError}>{error.message}</FormHelperText>
            ) : null}
        </Box>
    );
};

export default VRichTextEditor;
