import React from "react";
import { Grid } from "@mui/material";
import { t } from "react-i18nify";
import { VSelectURL } from "../../Widgets/Custom Inputs/Select";
import {
    HiddenField,
    VTextField,
} from "../../Widgets/Custom Inputs/CustomInputs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { fetchData } from "../../../js/utils/backend";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ObjectSelector } from "../QueryBuilder/ObjectSelector";

/** Step of a FormStepper: Consists of small form and an ObjectSelector to select an entity of interest before defining rules for it in a QueryBuilder step. */
const StepLimitDefinitionAndSelection = ({ fieldName }) => {
    const [optionsObjects, setOptionsObjects] = useState([]);
    const methods = useFormContext({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const locale = useSelector(state => state.i18n.locale);
    const { watch, setValue } = methods;
    const token = useSelector((state) => state.auth.Token);
    useEffect(() => {
        let isSubscribed = true;
        const fetching = async () => {
            const options = await fetchData("/Grid/Entities/true", token, "List");
            if (isSubscribed) {
                setOptionsObjects(options.data.Entities);
            }
        };
        fetching();
        return () => (isSubscribed = false);
    }, [token, locale]);

    return (
        <>
            <Grid item xs={12}>
                <VTextField
                    label={t("field.Title")}
                    fieldName={"Value"}
                    validation={{
                        required: t("input.validation.required"),
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <VTextField
                    label={t("field.Description")}
                    fieldName={"Description"}
                    maxCar={505}
                />
            </Grid>
            <HiddenField fieldName="Country_select" />
            <Grid item xs={12}>
                <VSelectURL
                    label={t("field.Country")}
                    URL={`Country`}
                    fieldName={"CountryID"}
                    defaultValue={watch("Country_select")}
                    selectorContent={(e) => {
                        setValue("Country_select", e);
                    }}
                    validation={{
                        required: t("input.validation.required"),
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <ObjectSelector
                    onlyFirstLevel
                    fieldNameFirstLevel="RelatedClass"
                    parentLabel={t("view.limit.objectSelection.parentObject")}
                    allOptions={optionsObjects}
                    fieldName={fieldName}
                    validation={{
                        required: t("input.validation.required"),
                    }}
                />
            </Grid>
        </>
    );
};

export default StepLimitDefinitionAndSelection;
