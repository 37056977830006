import React, {
    useEffect, useRef, useState
} from "react";
import { BrowserRouter, Switch, Route, Redirect, useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    entry,
    loadVersion,
    authorizeDigitalPass,
} from "../js/redux/actions/auth";
import SplashScreen from "../components/Layouts/SplashScreen";
import MainLayout from "../components/Layouts/MainLayout";
import { updateTab } from "../js/redux/actions/syncTab";
import NewUserLayout from "../components/Layouts/NewUserLayout";
import VersionJSON from "../common/sharedSettings.json";
import ExternalUserLayout from "../components/Layouts/ExternalUserLayout";
import AppUnderMaintenance from "../components/Layouts/AppUnderMaintenance";
import { useCheckAndRevalidateToken } from "../js/utils/customHooks";
import { SessionVerificationDialog } from "../components/Layouts/CommonLayout";

const version = VersionJSON.Version;


/** 3 - Digital Pass : when redirection on '/OTUser' occurs, this component will trigger identification of digital pass for user. The guid "code" is used to get user data authentification from OT backend.  Next step on : ../js/redux/actions/auth.js.  */
export const GettingDigitalPass = () => {
    const { search } = useLocation();
    const dispatch = useDispatch();

    /** Getting search from location (after '?'). return instance utility with URLSearchParams API  (returns key value pairs). Then return instance utility with URLSearchParams API  (returns key value pairs)  */
    const refCode = useRef(new URLSearchParams(search).get("code"))

    const { NextClientRoute } = useSelector(state => state.userPref)

    useEffect(() => {
        const dispatching = async () => {
            return dispatch(authorizeDigitalPass(refCode.current))
        }
        if (refCode.current)
            return dispatching()
    }, [dispatch])

    return (
        <Redirect to={NextClientRoute} />
    )
}

/** The main route component, which once the user is recognized, will redirect to the right layout (and router), and if route changes, updates and expands the tree menu, revalidates token. The route renders a layout according to user's state. DOC*/
const PrivateRoute = ({ component: Component, restrictedTo, ...rest }) => {
    const dispatch = useDispatch();
    const history = useHistory()

    const isConnected = useSelector(state => state.auth.IsConnected);
    if (!isConnected) {
        dispatch(entry(document.location.pathname));
    }

    const activeRole = useSelector(state => state.auth.role);
    const appropriateRole = restrictedTo ? activeRole === restrictedTo : true;

    const newUserState = useSelector(state => state.auth.UserState);
    const isNewUser = newUserState === "New";
    const isExternalUser = useSelector(state => state.auth.External);

    let versionNumber = useSelector(state => state.auth.versionNumber);
    if (versionNumber !== version) {
        dispatch(loadVersion());
    }

    ////////////////////////////////// TOKEN REVALIDATION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    const nextClientRoute = useSelector(state => state.userPref.NextClientRoute)
    const [sessionVerification, setSessionVerification] = useState(false)
    useCheckAndRevalidateToken(setSessionVerification, [nextClientRoute, history])
    ////////////////////////////////// TOKEN REVALIDATION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

    return (
        <>
            <Route
                render={props => sessionVerification ? <SessionVerificationDialog sessionVerification={sessionVerification}  /> :
                    isConnected === true ? (
                        isNewUser ? (
                            <NewUserLayout {...props} />
                        ) : isExternalUser ? (
                            <ExternalUserLayout {...props} />
                        ) : appropriateRole === true ? (
                            <Component {...props} />
                        ) : (
                            <Redirect
                                to={{
                                    pathname: "/",
                                    state: { from: "/" },
                                }}
                            />
                        )
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location },
                            }}
                        />
                    )
                }
                {...rest}
            />
        </>
    );
};

/** Main entry point of the app: has the redirection route /OTuser, needed for token authentication process, then PrivateRoute, where all routing logic and permission besides.  DOC*/
const AuthRouter = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        setInterval(() => {
            dispatch(updateTab());
        }, 5000);
    }, [dispatch]);

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/AppUnderMaintenance" component={AppUnderMaintenance} />
                {/*  Route accessible when redirect back from Total Passport Hub, while user is authenticated (with cookies on the browser), or when the token is refreshed from front initiative, every 'X hours' or 'X minutes' (same logic). */}
                <Route path='/OTuser' render={props => <GettingDigitalPass />}
                />
                <Route
                    exact
                    path="/login"
                    render={props => <SplashScreen {...props} screen="login" />}
                />
                <Route
                    exact
                    path="/"
                    render={props => <Redirect to={"/home"} />}
                />
                <PrivateRoute path="/" component={MainLayout} />
            </Switch>
        </BrowserRouter>
    );
};

export default AuthRouter;
