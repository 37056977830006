
import * as actionTypes from "../actionTypes";


const initialState = {
    Filters: [
        { BaseUrl: '/request/tovalidate', SearchText: '' },
        { BaseUrl: '/request/myrequests', SearchText: '' },
        { BaseUrl: '/limit', SearchText: '' },
        { BaseUrl: '/query', SearchText: '' },
        { BaseUrl: '/ReportScheduler', SearchText: '', FilterQuery: null },
        { BaseUrl: 'StationAir', SearchText: '' },
        { BaseUrl: 'StationNoise', SearchText: '' },
        { BaseUrl: 'StationSedimentWater', SearchText: '' },
        { BaseUrl: 'StationBiodiversity', SearchText: '' },
        { BaseUrl: 'StationSoilGroundwater', SearchText: '' },
        { BaseUrl: 'AirSampling', SearchText: '' },
        { BaseUrl: 'WaterSampling', SearchText: '' },
        { BaseUrl: 'SedimentSampling', SearchText: '' },
        { BaseUrl: 'BiologicalSampling', SearchText: '' },
        { BaseUrl: 'SoilAndSoilVapourSampling', SearchText: '' },
        { BaseUrl: 'GroundwaterSampling', SearchText: '' },
        { BaseUrl: 'LaboResult', SearchText: '' },
        { BaseUrl: '/eddFile', SearchText: '', EddFileState: null },
        { BaseUrl: '/zone', SearchText: '' },
        { BaseUrl: '/activelimit', SearchText: '' },
        { BaseUrl: 'Document', SearchText: '' },
        { BaseUrl: '/ref_analyte', SearchText: '' },
        { BaseUrl: '/Region', SearchText: '' },
        { BaseUrl: '/Family', SearchText: '' },
        { BaseUrl: '/Branch', SearchText: '' },
        { BaseUrl: '/Country', SearchText: '' },
        { BaseUrl: '/Ref_AirType', SearchText: '' },
        { BaseUrl: '/Ref_AnalysisGroup', SearchText: '' },
        { BaseUrl: '/Ref_AquiferStatus', SearchText: '' },
        { BaseUrl: '/Ref_BiodiversityType', SearchText: '' },
        { BaseUrl: '/Ref_BoreholeStatus', SearchText: '' },
        { BaseUrl: '/Ref_BoreholeType', SearchText: '' },
        { BaseUrl: '/Ref_CapType', SearchText: '' },
        { BaseUrl: '/Ref_ConstructionType', SearchText: '' },
        { BaseUrl: '/Ref_Context', SearchText: '' },
        { BaseUrl: '/Ref_DayNight', SearchText: '' },
        { BaseUrl: '/Ref_DrillingFluid', SearchText: '' },
        { BaseUrl: '/Ref_DrillingMethod', SearchText: '' },
        { BaseUrl: '/Ref_EcologicalGroup', SearchText: '' },
        { BaseUrl: '/Ref_EcologicalIntegrity', SearchText: '' },
        { BaseUrl: '/Ref_EmissionSource', SearchText: '' },
        { BaseUrl: '/Ref_EquipmentType', SearchText: '' },
        { BaseUrl: '/Ref_FaunaUse', SearchText: '' },
        { BaseUrl: '/Ref_GeometryType', SearchText: '' },
        { BaseUrl: '/Ref_HabitatType', SearchText: '' },
        { BaseUrl: '/Ref_HumanUse', SearchText: '' },
        { BaseUrl: '/Ref_Installation', SearchText: '' },
        { BaseUrl: '/Ref_IUCNStatus', SearchText: '' },
        { BaseUrl: '/Ref_LithologyType', SearchText: '' },
        { BaseUrl: '/Ref_Matrix', SearchText: '' },
        { BaseUrl: '/Ref_ObservationMode', SearchText: '' },
        { BaseUrl: '/Ref_ObservationType', SearchText: '' },
        { BaseUrl: '/Ref_ObsMeasure', SearchText: '' },
        { BaseUrl: '/Ref_Parameter', SearchText: '' },
        { BaseUrl: '/Ref_PhotoContext', SearchText: '' },
        { BaseUrl: '/Ref_PiezometerGroup', SearchText: '' },
        { BaseUrl: '/Ref_PresenceProof', SearchText: '' },
        { BaseUrl: '/Ref_QAQCType', SearchText: '' },
        { BaseUrl: '/Ref_ReadingType', SearchText: '' },
        { BaseUrl: '/Ref_RecordPeriod', SearchText: '' },
        { BaseUrl: '/Ref_RelativePosition', SearchText: '' },
        { BaseUrl: '/Ref_ReleaseLocation', SearchText: '' },
        { BaseUrl: '/Ref_SampleCollectionType', SearchText: '' },
        { BaseUrl: '/Ref_SampleType', SearchText: '' },
        { BaseUrl: '/Ref_SensorType', SearchText: '' },
        { BaseUrl: '/Ref_StationBiodiversityType', SearchText: '' },
        { BaseUrl: '/Ref_StationSedimentWaterType', SearchText: '' },
        { BaseUrl: '/Ref_StratigraphicClassification', SearchText: '' },
        { BaseUrl: '/Ref_TrophicGroup', SearchText: '' },
        { BaseUrl: '/Ref_Unit', SearchText: '' },
        { BaseUrl: '/Ref_Vulnerability', SearchText: '' },
        { BaseUrl: '/Ref_WaterIntermittence', SearchText: '' },
        { BaseUrl: '/Ref_WaterLocation', SearchText: '' },
        { BaseUrl: '/Ref_WaterSource', SearchText: '' },
        { BaseUrl: '/Ref_WaterType', SearchText: '' },
        { BaseUrl: '/Ref_WeightBasis', SearchText: '' }
    ]
};


export default function SearchFilterReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SEARCHTEXTCHANGED:
            return {
                ...state,
                Filters: action.payload,
            };
        default:
            return state;
    }
}