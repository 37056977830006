import React, { useCallback, useState, useEffect } from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import DataGridComponent from "../Widgets/DataGridComponent";
import { useSelector } from "react-redux";
import { FormDialog } from "./cst_DataGrid";
import { ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import { useParams } from "react-router-dom";
import * as r from "../../js/constants/routes";
import { t } from "react-i18nify";
import LibraryAddCheckOutlinedIcon from "@mui/icons-material/LibraryAddCheckOutlined";
import { addNewModel, fetchData } from "../../js/utils/backend";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import { VSelectAdvanced } from "../Widgets/Custom Inputs/Select";
import { Grid } from "@mui/material";
import LimitGrid from "./LimitGrid";
import { useEditionRightsPerEntityAccess } from "../../js/utils/customHooks";

const ActiveLimitGrid = ({ baseURL = r.activeLimit.route, ...props }) => {
    const apiRef = useGridApiRef();
    const { Token } = useSelector(state => state.auth);
    const { NodeID } = useParams();
    const [loading, setLoading] = useState(true);
    const [node, setNode] = useState();
    const OTEntityID = node?.OTEntityID;
    const editingRights = useEditionRightsPerEntityAccess(OTEntityID, ["EntityManager"]);
    const label = `${t("grid.title.Limits")} ${node?.OTEntity_Select.Name}`;
    const [limitsDialogOpen, setLimitsDialogOpen] = useState(false);
    const [forceGridRefresh, setForceGridRefresh] = useState(false);

    const manageSubmitCopyForm = async data => {
        const newActiveLimits = { OTEntityID, ...data };
        await addNewModel(newActiveLimits, Token, `${r.activeLimit.route}/AddActiveLimits`);
        setLimitsDialogOpen(false);
        setForceGridRefresh(fgr => !fgr);
    };

    const SelecteurAvanceGrid = useCallback(
        ({ fieldName }) => {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <VSelectAdvanced
                            fieldName={fieldName}
                            grid={
                                <LimitGrid
                                    label={t("view.limit")} // Ensure the label prop is passed
                                    baseURL={r.limit.route}
                                    specificToolbar={{
                                        disableToolbar: true,
                                    }}
                                    isAdvancedSelectorGrid={true} // Ensure this prop is set
                                    specificScreenLoading={
                                        <ScreenLoading
                                            sx={{
                                                display: "flex",
                                                height: "280px",
                                                justifyContent: "center",
                                            }}
                                        />
                                    }
                                />
                            }
                            defaultValue={Object.values(
                                apiRef.current.state.rows.idRowsLookup
                            ).map(e => e.Limit_select.ObjectID)}
                            paramURL="External=true"
                        />
                    </Grid>
                </Grid>
            );
        },
        [apiRef]
    );

    useEffect(() => {
        const fetching = async () => {
            const nodeFetched = await fetchData("OTEntityTree", Token, "ID", NodeID);
            setNode(nodeFetched.data);
            setLoading(false);
        };

        fetching();
    }, [NodeID, Token]);

    let gridSpecificColumns = [
        {
            field: "Limit_Value",
            headerName: t("field.Title"),
            width: 150,
            flex: 1,
            editable: false,
            valueGetter: params => params.row.Limit_select?.Value,
        },
        {
            field: "Limit_Description",
            headerName: t("field.Description"),
            width: 150,
            flex: 1,
            editable: false,
            valueGetter: params => params.row.Limit_select?.Description,
        },
        {
            field: "Limit_Country",
            headerName: t("field.Country"),
            width: 150,
            editable: false,
            valueGetter: params => params.row.Limit_select?.Country_select?.Value,
        },
    ];

    return loading ? (
        <ScreenLoading />
    ) : (
        <>
            <FormDialog
                label={t("dialog.AddNewLimits")}
                manageEdition={{ open: limitsDialogOpen }}
                closeForm={() => setLimitsDialogOpen(false)}
                manageSubmit={manageSubmitCopyForm}
                fieldName={"LimitIDs"}
                Content={SelecteurAvanceGrid}
            />
            <DataGridComponent
                apiRef={apiRef}
                baseURL={baseURL}
                paramURL={r.filter.genericFilter("OTEntityID", OTEntityID)}
                label={label}
                toolbar={{
                    newAction: editingRights && (
                        <ButtonToolbar
                            titleTooltip={t("view.limit.addNewLimits")}
                            title={t("view.limit.addNewLimits")}
                            startIcon={<LibraryAddCheckOutlinedIcon />}
                            onClick={() => setLimitsDialogOpen(true)}
                        />
                    ),
                    add: false,
                }}
                gridSpecificColumns={gridSpecificColumns}
                isEditDisabled={true}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "CreatedOn", sort: "desc" }],
                    },
                }}
                forceGridRefresh={forceGridRefresh}
                editingRights={editingRights}
                rowHighlightPropName="LimitID"
                {...props}
            />
        </>
    );
};

export default ActiveLimitGrid;
