import React, { useState, useCallback } from "react";
import {
    GridActionsCellItem,
    useGridApiRef,
    GridAddIcon,
} from "@mui/x-data-grid-pro";
import DataGridComponent from "../Widgets/DataGridComponent";
import { useDispatch, useSelector } from "react-redux";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import { TranslationDialog, DeleteAction } from "./cst_DataGrid";
import { ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import { useHistory, useLocation } from "react-router-dom";
import { t } from "react-i18nify";
import { BasicTooltip } from "../Widgets/StyledComponents";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { dateTimeToString, historyPush } from "../../js/utils/genericMethods";
import { updateHighlightedRows, updateSpecificNodeEntityTree } from "../../js/redux/actions/userPref";
import { useEditionRightsPerUserStatus } from "../../js/utils/customHooks";
import { deleteModel } from "../../js/utils/backend";
import Axios from "axios";

export const rowDisableDelete = (row, userID, idToCompare = "OTUserID") => {
    if (row) {
        return row[idToCompare] === userID;
    }
};

const LimitGrid = ({
    label,
    baseURL,
    isAdvancedSelectorGrid,
    conditionalDeleteDisabling = rowDisableDelete,
    onDelete = () => {
        /* called after delete completion */
    },
    ...props
}) => {
    const apiRef = useGridApiRef();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [rowParams, setRowParams] = useState();
    const editingRights = useEditionRightsPerUserStatus(["EntityManager"]);
    const location = useLocation();
    const currentLocation = location.pathname;
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.Token);
    const userID = useSelector(state => state.auth.UserID);
    const isCompanyManager = useSelector(state => state.auth.CompanyManager);
    const isHoldingManager = useSelector(state => state.auth.HoldingManager);

    const currentUser = useSelector(state => state.auth.UserName);

    const deleteRow = useCallback(
        async id => {
            try {
                if (id !== "NEW") {
                    await deleteModel(id, token, baseURL);
                }
                apiRef?.current.updateRows([{ ID: id, _action: "delete" }]);
                onDelete();
            } catch (error) {
                console.error(error);
            }
        },
        [baseURL, token, apiRef, onDelete]
    );
    const advancedSelectorColumns = [
        {
            field: "Value",
            headerName: t("field.Title"),
            width: 150,
            flex: 1,
            editable: false,
        },
        {
            field: "Description",
            headerName: t("field.Description"),
            width: 150,
            flex: 1,
            editable: false,
        },
        {
            field: "Country_select",
            headerName: t("field.Country"),
            width: 150,
            editable: false,
            sortable: false,
            valueFormatter: params => params.value?.Value,
        },
    ];

    let gridSpecificColumns = isAdvancedSelectorGrid
        ? advancedSelectorColumns
        : [
              ...advancedSelectorColumns,
              {
                  field: "OTUser_select",
                  headerName: t("field.Owner"),
                  flex: 1,
                  width: 150,
                  editable: false,
                  sortable: false,
                  valueFormatter: params => params.value?.Name,
              },
              {
                  field: "CreatedOn",
                  headerName: t("field.CreatedOn"),
                  width: 150,
                  flex: 1,
                  editable: false,
                  valueFormatter: params =>
                      dateTimeToString(params?.value, "DateTime"),
              },
              {
                  field: "LastUpdate",
                  headerName: t("field.LastUpdate"),
                  width: 150,
                  flex: 1,
                  editable: false,
                  valueFormatter: params =>
                      dateTimeToString(params?.value, "DateTime"),
              },
          ];

    const specificActionsColumns = (params, editing, isLocalLoading) => {
        const owner = params.row.OTUser_select?.Name.split(' ').reverse().join(' ');
        const isActionDisabled = 
            !isCompanyManager && !isHoldingManager &&
            owner !== currentUser && editingRights;

        return [
            <>
                {editingRights && (
                    <>
                        <GridActionsCellItem
                            label={t("common.Translate")}
                            icon={
                                <BasicTooltip title={t("common.Translate")}>
                                    <LanguageIcon />
                                </BasicTooltip>
                            }
                            onClick={() => {
                                setOpen(true);
                                dispatch(
                                    updateHighlightedRows({
                                        CurrentLocation: currentLocation,
                                        VisitedRowID: params.row.ID,
                                    })
                                );
                                setRowParams(params.row);
                            }}
                            disabled={editing || isLocalLoading || isActionDisabled}
                        />
                        <GridActionsCellItem
                            icon={
                                <BasicTooltip title={t("common.Edit")}>
                                    <EditOutlinedIcon />
                                </BasicTooltip>
                            }
                            label={t("common.Edit")}
                            className="textPrimary"
                            onClick={() => {
                                dispatch(
                                    updateHighlightedRows({
                                        CurrentLocation: currentLocation,
                                        VisitedRowID: params.row.ID,
                                    })
                                );
                                historyPush(
                                    history,
                                    `/limit/edit/${params.row.ID}`
                                );
                            }}
                            color="inherit"
                            disabled={isLocalLoading || isActionDisabled}
                        />
                        
                        {conditionalDeleteDisabling(params.row, userID) ? null : (
                        <DeleteAction
                            icon={
                                <BasicTooltip title={t("common.Delete")}>
                                    <DeleteIcon />
                                </BasicTooltip>
                            }
                            label={t("common.Delete")}
                            className="textPrimary"
                            deleteAction={async () => {
                                deleteRow(params.id);
                                if(baseURL==='Document'){
                                const response = await Axios.get(
                                    `OTEntityTree/GetEntityID/${params.row.OTEntityTreeID}`,
                                    { headers: { Authorization: "Bearer " + token } }
                                )
                                
                                if(response !== null)
                                    dispatch(updateSpecificNodeEntityTree(response.data))
                                    
                                }
                            }}
                            color="inherit"
                            disabled={isLocalLoading || isActionDisabled}
                        />
                        )}
                    </>      
                )}
            </>,
        ];
    };

    return (
        <>
            <TranslationDialog
                label={t("field.grid.Queries")}
                open={open}
                setOpen={setOpen}
                rowParams={rowParams}
                baseURL={baseURL}
            />
            <DataGridComponent
                apiRef={apiRef}
                baseURL={baseURL}
                label={label}
                toolbar={{
                    newAction: editingRights && (
                        <ButtonToolbar
                            titleTooltip={t("view.limit")}
                            title={t("common.Add")}
                            startIcon={<GridAddIcon />}
                            onClick={() =>
                                historyPush(history, "/limit/create/new")
                            }
                        />
                    ),
                    add: false,
                }}
                gridSpecificColumns={gridSpecificColumns}
                specificActionsColumns={specificActionsColumns}
                isEditDisabled={true}
                isDeleteDisabled={true}
                initialState={{
                    sorting: {
                        sortModel: isAdvancedSelectorGrid
                            ? [{ field: "Value", sort: "asc" }]
                            : [{ field: "CreatedOn", sort: "desc" }],
                    },
                }}
                isAdvancedSelectorGrid={isAdvancedSelectorGrid}
                editingRights={editingRights}
                {...props}
            />
        </>
    );
};
export default LimitGrid;
