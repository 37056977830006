import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { fetchData } from "../../js/utils/backend";
import { useDispatch, useSelector } from "react-redux";
import { arcgisPerStationType, getCleanGeometries } from "./cst_ArcGIS";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import { dateTimeToString, historyPush } from "../../js/utils/genericMethods";
import { BasicMap } from "./MapCustomInputs";
import { mainRed } from "../../themes/GlobalTheme";
import { t } from "react-i18nify";
import { BasicButton } from "../Widgets/Custom Inputs/Buttons";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { dataGrid } from "../../routers/MenuRouter";
import { fetchTraverseExtendTreePathNodes } from "../TreeEditors/EntityTree";

function MapQueryResultPerEntity({
    stations,
    entity,
    value,
    index,
    nodeIDPerStationType,
}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.Token);
    const stationsRedirectionActions = Object.entries(stations)
        .map(([stationType, stations]) => {
            return stations.map(station => ({
                actionID: "redirectToStation" + station.ObjectID,
                action: () => {
                    historyPush(
                        history,
                        `${dataGrid}/${stationType}/${nodeIDPerStationType[stationType]}/root/OTEntityID/${entity?.ID}/${station.Name}`,
                        { withBackButton: true }
                    );
                    fetchTraverseExtendTreePathNodes(
                        nodeIDPerStationType[stationType],
                        token,
                        dispatch
                    );
                },
            }));
        })
        .flat();

    const stationTypeLayers = Object.entries(stations).map(
        ([stationType, stations]) => {
            const stationTypeShaper = arcgisPerStationType.find(
                e => e.route === stationType
            );
            return {
                layerProperties: {
                    title: t(`field.${stationType}`),
                    id: stationType,
                    visible: true,
                },
                graphics: {
                    geometries: getCleanGeometries(stations, e => {
                        const template = {
                            title: "{Name}",
                            content: "{Description}",
                            actions: [
                                {
                                    title: t("map.redirectToStation"),
                                    id: "redirectToStation" + e.ObjectID,
                                    className: "esri-icon-link",
                                },
                            ],
                        };
                        return {
                            ...stationTypeShaper.subTypesGraphicShaper(e),
                            attributes: {
                                Name: e.Name,
                                Description: `${t("map.coordinates")}: ${
                                    e.PointString || e.PolygonString
                                }`,
                            },
                            template,
                        };
                    }),
                    graphicShape: stationTypeShaper.graphicShape,
                },
            };
        }
    );

    const GroupOfStationTypeLayers = [
        {
            layerType: "group",
            order: entity?.MapserviceList?.length + 1,
            groupProperties: {
                visible: true,
                title: t(`grid.Stations`),
                id: "AllStationsQueryResult",
            },
            layers: stationTypeLayers,
            open: true,
        },
    ];

    const legend = [
        {
            groupLegendLabel: t(`map.stationsLayers`),
            layersLegend: arcgisPerStationType
                .map(e =>
                    Boolean(e.legend)
                        ? e.legend
                        : {
                              label: t(`field.${e.route}`),
                              symbol: e.graphicShape,
                          }
                )
                .flat(),
        },
    ];

    return (
        <Box
            sx={{ width: "100%" }}
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
        >
            {value === index && (
                <Box sx={{ height: "-webkit-fill-available", padding: 2 }}>
                    <BasicMap
                        layerList={GroupOfStationTypeLayers}
                        center={entity?.Extent}
                        mapServerServices={entity?.MapserviceList}
                        mapServerLink={entity?.GisServer}
                        basemap={entity?.Basemap}
                        mapName={entity?.Name}
                        legend={legend}
                        popupAction={stationsRedirectionActions}
                    />
                </Box>
            )}
        </Box>
    );
}

MapQueryResultPerEntity.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const QueryResultsMap = ({ baseURL }) => {
    const token = useSelector(state => state.auth.Token);
    const [queryResult, setQueryResult] = useState();
    const { UrlID } = useParams();
    const [queryResultsMap, setQueryResultsMap] = useState(500);
    const history = useHistory();

    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        const newHeight = e.clientY - document.body.offsetTop - 224;
        if (newHeight > 200) {
            setQueryResultsMap(newHeight);
        }
    }, []);

    useEffect(() => {
        let isSubscribed = true;
        const fetching = async () => {
            const queryResultFetched = await fetchData(
                baseURL,
                token,
                "ID",
                UrlID
            );
            if (isSubscribed) {
                setQueryResult(queryResultFetched.data);
            }
        };
        fetching();
        return () => (isSubscribed = false);
    }, [UrlID, token, baseURL]);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid m={1} sx={{ height: "calc(100vh - 140px)" }}>
            {!queryResult ? (
                <ScreenLoading />
            ) : (
                <Box sx={{ height: "inherit" }}>
                    <Box
                        sx={{
                            pt: 6,
                            px: 4,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box
                            sx={{
                                typography: "h3",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {`${t(`view.query.resultQuery`)} "${
                                queryResult.QueryName
                            }" ${t(`view.query.ranOnThe`)} ${dateTimeToString(
                                queryResult.QueryResultCreatedOn,
                                "Date"
                            )}`}
                        </Box>

                        <BasicButton
                            size="small"
                            sx={{
                                mr: 0,
                            }}
                            onClick={() => {
                                history.goBack();
                            }}
                            startIcon={<ArrowBackIcon />}
                        >
                            {t("common.Back")}
                        </BasicButton>
                    </Box>

                    <Box
                        sx={{
                            border: "1px solid",
                            borderColor: "divider",
                            borderRadius: "5px",
                            position: "relative",
                            marginX: 4,
                            marginTop: 2,
                            height: queryResultsMap,
                            display: "flex",
                        }}
                    >
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{
                                borderRight: 1,
                                borderColor: "divider",
                                width: "200px",
                                height: "inherit",
                            }}
                        >
                            {queryResult.StationsPerOTEntities.map(
                                (StationsPerOTEntity, index) => (
                                    <Tab
                                        label={
                                            StationsPerOTEntity
                                                .OTEntityExtentModel.Name
                                        }
                                        key={index}
                                        {...a11yProps(index)}
                                    />
                                )
                            )}
                        </Tabs>
                        {queryResult.StationsPerOTEntities.map(
                            (StationsPerOTEntity, index) => (
                                <MapQueryResultPerEntity
                                    stations={StationsPerOTEntity.Stations}
                                    nodeIDPerStationType={
                                        StationsPerOTEntity.NodeIDPerStationType
                                    }
                                    key={index}
                                    entity={
                                        StationsPerOTEntity.OTEntityExtentModel
                                    }
                                    value={value}
                                    index={index}
                                />
                            )
                        )}
                    </Box>
                    <Box
                        sx={{
                            marginX: 4,
                            height: "8px",
                            cursor: "row-resize",
                            "&:hover": {
                                boxSizing: "border-box",
                                borderTop: `2px solid ${mainRed}`,
                                borderBottom: `2px solid ${mainRed}`,
                            },
                        }}
                        draggable={true}
                        onMouseDown={e => handleMouseDown(e)}
                    />
                </Box>
            )}
        </Grid>
    );
};

export default QueryResultsMap;
