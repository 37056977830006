import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
// https://mui.com/styles/basics/#using-the-theme-context
// https://mui.com/customization/default-theme/

const nunitoFont = "Nunito, sans-serif";
const robotoFont = "Roboto, sans-serif";

export const mainRed = "#ED0000";
export const mainContrastText = "#374649";
const primaryLight = "#F7F9FA";

export const themesettings = {
    status: {
        danger: "#e53e3e",
    },
    palette: {
        primary: {
            main: "#879aa2",
            light: "#F7F9FA",
            dark: "#5a6c73",
            contrastText: mainContrastText,
        },
        secondary: {
            main: mainRed,
            contrastText: "#FFFFFF",
        },
        neutral: {
            main: "rgba(0, 0, 0, 0.12)",
            light: "#ccc",
            dark: "#bbba",
            contrastText: "#0000",
        },
        background: {
            paper: "#FFFFFF",
            default: "#bbba",
        },
    },

    typography: {
        h1: { fontFamily: nunitoFont, fontSize: 40, color: mainContrastText },
        h2: { fontFamily: nunitoFont, fontSize: 30, color: mainContrastText },
        h3: { fontFamily: robotoFont, fontSize: 26, color: mainContrastText },
        subtitle1: {
            fontFamily: robotoFont,
            fontSize: 18,
            color: mainContrastText,
        },
        subtitle2: {
            fontFamily: robotoFont,
            fontSize: 16,
            color: mainContrastText,
        },
        subtitleMajor: {
            fontFamily: robotoFont,
            fontSize: 18,
            color: mainRed,
        },
        body1: {
            fontFamily: robotoFont,
            fontSize: 14,
            color: mainContrastText,
        },
        body2: {
            fontFamily: robotoFont,
            fontSize: 12,
            color: mainContrastText,
        },
        bodyMajor: { fontFamily: robotoFont, fontSize: 14, color: mainRed },
        button: {
            fontFamily: robotoFont,
            fontSize: 14,
            color: mainContrastText,
        },
        caption: { fontFamily: robotoFont, color: mainContrastText },
        overline: { fontFamily: robotoFont, color: mainContrastText },
    },

    components: {
        MuiAutocomplete: {
            styleOverrides: {
                listbox: {
                    maxHeight: "20vh",
                },
                popper: {
                    zIndex: 9999,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: mainRed,
                },
            },
        },

        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    border: `1px solid rgba(0, 0, 0, 0.12)`,
                    color: mainContrastText,
                },
            },
        },
        MuiBox: {
            variants: [
                {
                    props: { variant: "boxError" },
                    style: {
                        border: `1px solid red`,
                    },
                },
                {
                    props: { variant: "boxClassic" },
                    style: {
                        "&:hover": {
                            border: "1px solid black",
                        },
                    },
                },
            ],
        },
        MuiTreeItem: {
            styleOverrides: {
                label: { fontSize: 13 },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: "#474444",
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    margin: "0px 40px 0px 40px",
                    borderRadius: 0,
                    "& .MuiDataGrid-row--editing .MuiDataGrid-cell": {
                        backgroundColor: "rgba(0, 0, 0, 0.12)",
                        "&.actions": {
                            backgroundColor: "#FFFFFF",
                        },
                    },
                },
                pinnedColumns: {
                    backgroundColor: "white",
                    boxShadow:
                        "0px 4px 8px 0px rgba(0,0,0,0.3), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                },
                footerContainer: {
                    border: "0px solid black",
                    backgroundColor: primaryLight,
                },
                columnHeader: {
                    backgroundColor: primaryLight,
                    color: mainContrastText,
                    fontSize: 14,
                    fontWeight: "900",
                },
                cell: {
                    "&.MuiDataGrid-cell--editing": {
                        backgroundColor: "#FFFFFF !important",
                    },
                    "& .Mui-error": {
                        color: "#d32f2f", // default error color (from MUI default theme)
                        border: "solid 1px #d32f2f",
                        height: "100%",
                    },
                },
                iconButtonContainer: {
                    position: "absolute",
                    top: "10px",
                    right: "4px",
                },
                toolbarContainer: {
                    paddingTop: 0,
                },
            },
        },

        MUIRichTextEditor: {
            styleOverrides: {
                root: {
                    height: "15rem",
                    border: "1px solid #bbba",
                    borderRadius: 4,
                    "&:hover": {
                        border: "1px solid black",
                    },
                },
                editorContainer: {
                    height: "90%",
                    paddingBottom: "18px",
                    overflowX: "auto",
                    overflowY: "auto",
                    cursor: "initial",
                },
                container: {
                    height: "90%",
                    "&:focus": {
                        borderWidth: "1px",
                    },
                },
                editor: {
                    height: "calc(100% - 48px)",
                    paddingRight: "18px",
                    paddingLeft: "18px",
                },
                placeHolder: {
                    paddingLeft: "10px",
                    fontSize: 14,
                    color: "hsl(0, 0%, 50%)",
                },
                toolbar: {
                    backgroundColor: "#ededed",
                    display: "flex",
                    marginTop: "-8px",
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    alignItems: "baseline",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "24px",
                    '&[data-state="active]': {
                        backgroundColor: "yellow !important",
                    },
                    '&[data-state="inactive]': {
                        backgroundColor: "grey !important",
                    },
                },
                contained: {
                    borderRadius: "21px",
                    backgroundColor: mainRed,
                    fontSize: 14,
                    textTransform: "none",
                    color: "#FFFFFF",
                    '&[data-state="active]': {
                        backgroundColor: "yellow !important",
                    },
                    '&[data-state="inactive]': {
                        backgroundColor: "grey !important",
                    },
                    "&:hover": {
                        backgroundColor: "#FFFFFF",
                        color: mainRed,
                    },
                    "&.Mui-disabled": {
                        backgroundColor: "#B7CBD3",
                        color: mainContrastText,
                        opacity: "0.7",
                    },
                },
                textSecondary: {
                    borderRadius: "21px",
                },
            },
        },
        // MuiButtonGroup: {
        //     //
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: "black", //
        //         },
        //     },
        // },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    borderRadius: 24,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    color: mainRed,
                    marginBottom: 1,
                },
            },
        },
        MuiDialog: {
            root: {
                width: 1800,
            },
        },

        ///
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    fontSize: 32,
                    //color: "red",
                },
                text: {
                    fill: "white",
                },
            },
        },
    },
    zIndex: {
        drawer: 1100,
        appBar: 1200,
    },
};
export const GlobalTheme = createTheme(themesettings);

const GlobalStylesAppend = (newStyle, theme) => ({
    ...newStyle(theme),
});

export const makeStylesGlobal = newStyle =>
    makeStyles(theme => GlobalStylesAppend(newStyle, theme));
