import * as actionTypes from "../actionTypes";
import { HttpTransportType, HubConnectionBuilder } from "@microsoft/signalr";
import { tabID } from "../actions/syncTab";
import { backendURL } from "../../utils/backend";

let hubBroadcastConnect = null;

const serverDispatched = (action, payload) => ({
    type: action,
    payload: { ...payload },
});

const doDispatch = (action, payload) => {
    return dispatch => {
        dispatch(serverDispatched(action, payload));
    };
};

const setupBroadcastHub = async (dispatch, keepGoing, getState) => {
    const token = getState().auth.Token;
    

    hubBroadcastConnect = new HubConnectionBuilder()
        .withUrl(`${backendURL}/signalr/broadcast`, {
            accessTokenFactory: () => token,
            transport: HttpTransportType.WebSockets,
        })
        .build();

    hubBroadcastConnect.on("Dispatch", (action, payload) => {
        dispatch(doDispatch(action, payload));
    });

    hubBroadcastConnect.onclose(async () => {
        await loopstart(
            hubBroadcastConnect,
            2000,
            keepGoing,
            getState,
            dispatch,
            onReconnectBroadcast
        );
    });
    loopstart(
        hubBroadcastConnect,
        500,
        keepGoing,
        getState,
        dispatch,
        onReconnectBroadcast
    );
};

const onReconnectBroadcast = async (getState, dispatch) => {
    //Place here anything that needs to be done to refresh after reconnect
};

async function loopstart(
    hub,
    currentDelay,
    keepGoing,
    getState,
    dispatch,
    whenDone
) {
    if (keepGoing()) {
        try {
            await hub.start();
            whenDone(getState, dispatch);
        } catch (err) {
            console.error(err);
            const newDelay =
                currentDelay < 60000 ? currentDelay * 2 : currentDelay;
            setTimeout(
                () =>
                    loopstart(
                        hub,
                        newDelay,
                        keepGoing,
                        getState,
                        dispatch,
                        whenDone
                    ),
                currentDelay
            );
        }
    }
}

const transition = async (keepGoing, setHub, getHub, setupHub) => {
    if (getHub() !== null) {
        try {
            await getHub().stop();
        } catch {}
        setHub(null);
    }
    if (keepGoing()) {
        setupHub(keepGoing);
    }
};

let shouldConnect = false;
const signalRMiddleware =
    ({ getState, dispatch }) =>
    next =>
    action => {
        if (
            action.type === actionTypes.TABALIVE ||
            action.type === actionTypes.AUTHENTICATED
        ) {
            const isMain = getState().synctab.mainTab === tabID;
            if (isMain !== shouldConnect) {
                shouldConnect = isMain && getState().auth.IsConnected;
                transition(
                    () => shouldConnect,
                    newHub => (hubBroadcastConnect = newHub),
                    () => hubBroadcastConnect,
                    keepGoing =>
                        setupBroadcastHub(dispatch, keepGoing, getState)
                );
            }
        } else if (action.type === actionTypes.UNAUTHENTICATED) {
            shouldConnect = false;
            transition(
                () => shouldConnect,
                newHub => (hubBroadcastConnect = newHub),
                () => hubBroadcastConnect,
                () => null
            );
        }
        return next(action);
    };

export default signalRMiddleware;
