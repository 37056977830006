import React, { useEffect } from "react";
import { Box, Divider, Drawer, IconButton } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SubMenu } from "./TreeMenu";
import { checkAccess, menuPrefs } from "../../routers/MenuRouter";
import { useDispatch, useSelector } from "react-redux";
import { settingsMenuWidth } from "../../js/redux/actions/userPref";
import { t } from "react-i18nify";

const PrefsMenu = ({ handlePrefsMenuClose, prefsMenuOpen }) => {
    const {
        CompanyManager,
        HoldingManager,
        BranchManager,
        EntityManager,
        Contributor,
        Reader,
    } = useSelector(state => state.auth);
    const auth = {
        CompanyManager,
        HoldingManager,
        BranchManager,
        EntityManager,
        Contributor,
        Reader,
    };

    const prefsDrawerWidth = 320;

    const dispatch = useDispatch();

    useEffect(() => {
        if (prefsMenuOpen) {
            dispatch(settingsMenuWidth(320));
        } else {
            dispatch(settingsMenuWidth(0));
        }
    }, [prefsMenuOpen, dispatch]);

    return (
        <Drawer
            variant="persistent"
            open={prefsMenuOpen}
            anchor={"right"}
            elevation={16}
            sx={{
                display: "flex",
                alignItems: "center",
                padding: theme => theme.spacing(0, 1),
                justifyContent: "flex-end",
                width: prefsDrawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: prefsDrawerWidth,
                    boxSizing: "border-box",
                },
            }}
            PaperProps={{ elevation: 4 }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    mt: "130px",
                }}
            >
                <IconButton onClick={handlePrefsMenuClose}>
                    <ChevronRightIcon />
                </IconButton>
                <Box
                    sx={{
                        typography: "subtitle1",
                        textAlign: "center",
                    }}
                >
                    {t("grid.title.Settings")}
                </Box>
            </Box>
            <Box sx={{ overflow: "auto" }}>
                {checkAccess(menuPrefs, auth).map((e, i) => (
                    <Box key={`menu-pref-${i}`}>
                        <Divider />
                        <SubMenu element={e} />
                    </Box>
                ))}
                <Divider />
            </Box>
        </Drawer>
    );
};

export default PrefsMenu;
