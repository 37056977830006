import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useHistory, useParams } from "react-router";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { BasicButton } from "../../Widgets/Custom Inputs/Buttons";
import { addNewModel, editModel } from "../../../js/utils/backend";
import { t } from "react-i18nify";

const EditionPanelGlobal = ({
    route,
    routeParams = "",
    ButtonsComponent = null,
    submitButton = { icon: <SaveIcon />, label: t("common.Save") },
    back = true,
    backAction,
    children,
    ID,
    onSubmit,
    disabledSubmitButton = true
}) => {
    const history = useHistory();
    const { mode, UrlID } = useParams();

    const [editing, setEditing] = useState(false);
    const methods = useFormContext();
    const token = useSelector(state => state.auth.Token);
    const { handleSubmit, formState } = methods;

    const handleBackAction = useCallback(() => {
        backAction ? backAction() : history.goBack();
    }, [backAction, history]);

    const isFormEdited = Object.keys(formState.dirtyFields).length > 0;

    useEffect(() => {
        if (formState.isSubmitting) {
            setEditing(false);
        }
        if (formState.isSubmitSuccessful) {
            setEditing(false);
        }
        if (isFormEdited) {
            setEditing(true);
        }
    }, [formState, isFormEdited]);

    const editMode = mode === "edit";

    const manageSubmit = async data => {
        if (!formState.isSubmitting) {
            history.replace(history.location.pathname);
            if (editMode) {
                await editModel(
                    { ID: ID ?? UrlID, ...data },
                    ID ?? UrlID,
                    token,
                    route
                );
                back && handleBackAction();
                onSubmit && onSubmit();
            } else {
                await addNewModel(
                    { ...data },
                    token,
                    route + "?" + routeParams
                );
                back && handleBackAction();
                onSubmit && onSubmit();
            }
            if(route==="user/AssignUser")
                history.push('/datagrid/allUser')
        }
    };

    const submitForm = async e => {
        e.preventDefault();
        try {
            handleSubmit(manageSubmit)(e);
        } catch (error) {
            toastr.error(
                "The form was not submitted properly. Please contact the administrator"
            );
        }
    };

    return (
        <form onSubmit={submitForm} autoComplete="off">
            <Grid container spacing={2}>
                {children}
                <Grid item container sx={{ p: 1, pt: 0, mb: 1 }}>
                    <Grid item sx={{ flexGrow: 1 }} />
                    <Grid
                        item
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mr: 1,
                        }}
                    >
                        {ButtonsComponent ? (
                            <ButtonsComponent
                                type="submit"
                                disabled={
                                    formState.isSubmitting ||
                                    (!editing && !formState.isDirty)
                                }
                            />
                        ) : (
                            <>
                                {back && (
                                    <BasicButton
                                        type="button"
                                        style={{
                                            position: "relative",
                                            margin: 4,
                                        }}
                                        onClick={handleBackAction}
                                    >
                                        {t("common.Cancel")}
                                    </BasicButton>
                                )}
                                <BasicButton
                                    type="submit"
                                    style={{ margin: 4, marginRight: 0 }}
                                    disabled={
                                        disabledSubmitButton ? (formState.isSubmitting || !editing) :
                                        disabledSubmitButton
                                    }
                                    startIcon={submitButton.icon}
                                >
                                    {submitButton.label}
                                </BasicButton>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default EditionPanelGlobal;
