import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { BasicButton } from "../../Widgets/Custom Inputs/Buttons";
import SaveIcon from "@mui/icons-material/Save";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { editModel, fetchData } from "../../../js/utils/backend";
import { useSelector } from "react-redux";
import {
    arcgisGeometryToSRIDGeoJSON,
    extentShape,
} from "../../ArcGIS/cst_ArcGIS";
import { ScreenLoading } from "../../Widgets/CustomLoadings";
import { MapExtentInput } from "../../ArcGIS/MapCustomInputs";
import { t } from "react-i18nify";
import { useEditionRightsPerEntityAccess } from "../../../js/utils/customHooks";
import { mainRed } from "../../../themes/GlobalTheme";

export const EntityMapEditor = ({ route = "OTEntity/map", title }) => {
    const history = useHistory();
    const token = useSelector(state => state.auth.Token);
    const [polygon, setPolygon] = useState(null);
    const [entity, setEntity] = useState();
    const { UrlID } = useParams();
    const editingRights = useEditionRightsPerEntityAccess(UrlID, [
        "EntityManager",
        "Contributor",
    ]);
    const [queryResultsMap, setQueryResultsMap] = useState(500);
    const minDrawerHeight = 200;
    const maxDrawerHeight = 600;

    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        const newHeight = e.clientY - document.body.offsetTop - 224;
        if (newHeight > minDrawerHeight && newHeight < maxDrawerHeight) {
            setQueryResultsMap(newHeight);
        }
    }, []);

    const handleSave = async data => {
        const sridGesoJSON =
            data.length === 0 ? null : await arcgisGeometryToSRIDGeoJSON(data);
        await editModel(
            { ID: UrlID, Extent: sridGesoJSON },
            UrlID,
            token,
            route
        );
        history.goBack();
    };

    useEffect(() => {
        let isSubscribed = true;
        const fetching = async () => {
            const entityFetched = await fetchData(route, token, "ID", UrlID);
            if (isSubscribed) {
                setEntity(entityFetched.data);
            }
        };
        UrlID && editingRights && fetching();
        return () => (isSubscribed = false);
    }, [token, UrlID, route, editingRights]);

    return !editingRights ? (
        <Redirect to={"/home"} />
    ) : !entity ? (
        <ScreenLoading />
    ) : (
        <Grid m={1} sx={{ height: "calc(100vh - 140px)" }}>
            <Box
                sx={{
                    typography: "h3",
                    pt: 6,
                    pl: 5,
                    pb: 2,
                    display: "flex",
                    alignItems: "baseline",
                }}
            >{`${title}${entity ? `: ${entity.Name}` : ""}`}</Box>
            <Box p={1} px={5} sx={{ height: queryResultsMap }}>
                <MapExtentInput
                    editionLayerTitle={`${t("map.extentOf")} ${entity?.Name}`}
                    existantGeometry={
                        entity?.Extent.GeoJSON !== null && entity.Extent
                    }
                    setOutputGeometry={e => {
                        setPolygon(e);
                    }}
                    legend={[
                        {
                            groupLegendLabel: t("map.entityOfInterest"),
                            layersLegend: [
                                {
                                    label: `${t("map.extentOf")} ${
                                        entity?.Name
                                    }`,
                                    symbol: { ...extentShape, style: "square" },
                                },
                            ],
                        },
                    ]}
                    mapName={entity?.Name}
                    mapServerServices={entity?.MapserviceList}
                    basemap={entity?.Basemap}
                    mapServerLink={entity?.GisServer}
                    disablePoint
                />
            </Box>
            <Box
                sx={{
                    marginX: 5,
                    height: "8px",
                    cursor: "row-resize",
                    "&:hover": {
                        boxSizing: "border-box",
                        borderTop: `2px solid ${mainRed}`,
                        borderBottom: `2px solid ${mainRed}`,
                    },
                }}
                draggable={true}
                onMouseDown={e => handleMouseDown(e)}
            />

            <Grid
                item
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    height: "10%",
                    p: 1,
                    pb: 2,
                    px: 4,
                }}
            >
                <BasicButton
                    type="button"
                    style={{
                        position: "relative",
                        margin: 4,
                    }}
                    onClick={() => history.goBack()}
                >
                    Cancel
                </BasicButton>
                <BasicButton
                    type="submit"
                    style={{ margin: 4, marginRight: 0 }}
                    startIcon={<SaveIcon />}
                    disabled={!polygon}
                    onClick={() => {
                        handleSave(polygon);
                    }}
                >
                    {t("common.Save")}
                </BasicButton>
            </Grid>
        </Grid>
    );
};
