import React, { useCallback, useState } from "react";
import ReactFlow, { ReactFlowProvider, Handle } from "react-flow-renderer";
import { Paper } from "@mui/material";
import { t } from "react-i18nify";
import { useSelector } from "react-redux";

const customNodeStyles = {
    width: "100px",
    padding: 10,
    fontSize: 12,
    textAlign: "center",
};

export const NodeConnectionAdaptable = ({ data }) => {
    return (
        <div style={customNodeStyles}>
            {data.end.map(e => {
                return (
                    <Handle
                        type="target"
                        position={e}
                        id={e}
                        key={`${data.label}-target-${e}`}
                    />
                );
            })}
            {data.start.map(e => {
                return (
                    <Handle
                        type="source"
                        position={e}
                        id={e}
                        key={`${data.label}-target-${e}`}
                    />
                );
            })}
            <div>{data.label}</div>
        </div>
    );
};


/** Main configured component to render a workflow diagram.  DOC*/
const StateDiagram = ({ states, transitions }) => {
    const [refInstance, setRefInstance] = useState();

    const { locale } = useSelector(state => state.i18n);
    const onLoad = useCallback(instance => {
        instance.fitView();
        setRefInstance(instance);
    }, []);

    const handleSizeChange = useCallback(
        () => refInstance?.fitView(),
        [refInstance]
    );

    window.addEventListener("resize", handleSizeChange);

    const diagramStates = states.map((element, index) => {
        return {
            id: element.Name,
            type: element.Initial
                ? "input"
                : element.Final
                ? "output"
                : "connectionAdaptable",
            data: {
                label: t(`diagram.${element.Name}`),
                start: element.Start ?? ["bottom"],
                end: element.End ?? ["top"],
            },
            style: {
                backgroundColor: element.Color,
                border: "2px solid rgba(0, 0, 0, 0.2)", 
                color: element.TextColor,
                borderRadius: 4,
            },
            position: element.Position,
            sourcePosition: "bottom",
        };
    });

    const diagramTransitions = transitions.map((element, index) => {
        return {
            id: `e${element.From}-${element.To}`,
            type: "smoothstep",
            arrowHeadType: "arrowclosed",
            label: t(`diagram.${element.Name}`),
            source: element.From,
            target: element.To,
            sourceHandle: element.Start,
            targetHandle: element.End,
        };
    });

    const nodeTypes = {
        connectionAdaptable: NodeConnectionAdaptable,
    };

    /** Displaying diagram with a dependency to locale user language, to re-render and redraw labels according to the language that was setted. */
    const DiagramDraw = useCallback(() => {
        return (
            <ReactFlowProvider>
                <ReactFlow
                    onLoad={onLoad}
                    elements={[...diagramStates, ...diagramTransitions]}
                    nodesDraggable={false}
                    nodesConnectable={false}
                    nodeTypes={nodeTypes}
                />
            </ReactFlowProvider>
        );
        // eslint-disable-next-line
    }, [locale]);

    return (
        <Paper
            sx={{
                height: "400px",
                width: "auto",
                fontFamily: ["Nunito"],
            }}
        >
            <DiagramDraw />
        </Paper>
    );
};

export default StateDiagram;
