import React, { useState, useEffect } from "react";
import {
    TextField,
    Box,
    Button,
    IconButton,
    Grid,
    InputAdornment,
    Divider,
    Paper
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { authorize, reseterror } from "../../js/redux/actions/auth";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ReactComponent as LogoOneTsigane } from "../../assets/images/logo_one_tsigane.svg";
import KeyIcon from '@mui/icons-material/Key';
import Axios from "axios";
import * as r from "../../js/constants/routes";

const LoginForm = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(false);
    const basculeAffichageMdp = () => setShowPassword(!showPassword);

    const lastError = useSelector(state => state.auth.errorMessage);

    const handleSubmit = () => {
        dispatch(authorize(email, password));
    };

    const isConnected = useSelector(state => state.auth.IsConnected);
    const [urlAuthRedirection, setUrlAuthRedirection] = useState()
    useEffect(() => {
        let isSubscribed = true;
        /** 1 - Digital Pass : First step of to-go authentifiction with Digital Pass from Memority OAuth system. Get the redirection URL immediately when user is on LoginForm  */
        const fetching = async () => {
            try {
                const url = await Axios.get(`${r.oTUser}/DigitalPass`)
                if (isSubscribed && url) {
                    return setUrlAuthRedirection(url.data)
                }
            } catch (e) {
                console.error(e);
            }
        }
        fetching();
        return () => (isSubscribed = false);
    }, []);

    useEffect(() => {
        dispatch(reseterror());
    }, [dispatch]);

    const { REACT_APP_ENVIRONMENT  } = process.env;
    
    const isLoginProd = REACT_APP_ENVIRONMENT === "PROD";

    /** 2 - Digital Pass : Once the redirection link saved, it can be appended a Button, which will once clicked, redirect to Total auth. hub. (in production there's no button, programmatic redirect) : then redirect again to OT web app on '/OTUser'. */
    useEffect(() => {
        if (isLoginProd && urlAuthRedirection && !isConnected) {
            setTimeout(() => {
                return window.location.replace(urlAuthRedirection)
            }, 2000);
        }
    }, [isLoginProd, urlAuthRedirection, isConnected])
    

    /** Nothing to display when the app will be in PROD, because there's already a login splash screen from Total. Otherwise it will be redundant for the end user. */
    if (isLoginProd) {
        return (
            <Box sx={{ position: "relative", bottom: 100, margin: "0px auto" }}>
            </Box>
        )
    } else {
        return (
    <Paper
        elevation={6}
        sx={{ width: 300, height: "400px", p: 8 }}
    >
        <Grid item container spacing={2}>
            <LogoOneTsigane height="100px" />
            <Grid item xs={12} sx={{ typography: "h3", mb: 2 }}>Sign in </Grid>
            <Box sx={{ margin: "0px auto", width: "100%", position: "relative", left: 10, mb: 2 }}>
                <Button
                    fullWidth
                    disabled={!urlAuthRedirection}
                    href={urlAuthRedirection}
                    size="large" variant="contained"
                    startIcon={<KeyIcon />}
                >
                    Digital Pass
                </Button>
            </Box>
            <Box sx={{ width: "100%", margin: "0px 8px" }}>
                <Divider sx={{ position: "relative", left: 8 }}>
                    <Box sx={{ position: "relative" }}>or</Box>
                </Divider>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
            </Box>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"
                    label="Username"
                    size="medium"
                    onChange={event => setEmail(event.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onKeyPress={e => {
                        if (e.key === "Enter") {
                            handleSubmit();
                        }
                    }}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                    type="button"
                                    aria-label="toggle password visibility"
                                    onClick={basculeAffichageMdp}
                                >
                                    {showPassword ? (
                                        <VisibilityIcon />
                                    ) : (
                                        <VisibilityOffIcon />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                    label="Password"
                    onChange={event => setPassword(event.target.value)}
                />
            </Grid>
            {lastError ? (
                <Box sx={{ typography: "body2", mt: 1, ml: 2 }}>{lastError}</Box>
            ) : null}
            <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
            >
                <Button onClick={handleSubmit} variant="contained">
                    Connect
                </Button>
            </Grid>
        </Grid>
    </Paper>
    )
    };
};

export default LoginForm;
