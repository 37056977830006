import React, { useCallback, useEffect, useState } from "react";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-pro";
import * as r from "../../js/constants/routes";
import {
    dateOnlyRangeValidation,
    requiredCell,
} from "../../js/utils/inputValidations";
import DataGridComponent from "../Widgets/DataGridComponent";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import { TranslationDialog } from "./cst_DataGrid";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAxiosConfig, useEditionRightsPerEntityAccess } from "../../js/utils/customHooks";
import Axios from "axios";
import { t } from "react-i18nify";
import { BasicTooltip } from "../Widgets/StyledComponents";
import { dateOnlyToString, historyPush } from "../../js/utils/genericMethods";
import { dataGrid } from "../../routers/MenuRouter";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import { useDispatch, useSelector } from "react-redux";
import { updateHighlightedRows } from "../../js/redux/actions/userPref";
import { DateOnlyPickerGrid } from "../Widgets/Custom Inputs/CustomInputs";

const WorkOrderGrid = ({ baseURL = r.WorkOrder }) => {
    const apiRef = useGridApiRef();
    const { elementID, linkOrigin, otEntityID } = useParams();
    const history = useHistory();
    const cellFocusField = "WorkOrderNumber";
    const dispatch = useDispatch();
    const location = useLocation();
    const currentLocation = location.pathname;

    const { Reader, External } = useSelector(state => state.auth);

    const config = useAxiosConfig();
    const [label, setLabel] = useState(t("view.study.NoStudyWorkOrder"));
    const getLabel = useCallback(async () => {
        const response = await Axios.get(`${r.study}/${elementID}`, config);
        return t("view.study.StudyWorkOrder", { value: response.data.Value });
    }, [config, elementID]);

    const editingRights = useEditionRightsPerEntityAccess(otEntityID, [
        "EntityManager",
    ]);
    useEffect(() => {
        let isSubscribed = true;
        const fetch = async () => {
            try {
                if (isSubscribed) {
                    setLabel(await getLabel(elementID));
                }
            } catch (e) {
                console.error(e);
            }
        };
        fetch();
        return () => (isSubscribed = false);
    }, [config, elementID, getLabel]);

    const gridSpecificColumns = [
        {
            field: "WorkOrderNumber",
            headerName: t("field.Number"),
            flex: 0.5,
            editable: true,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "Number",
                    "WorkOrderNumber"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "Value",
            headerName: t("field.Description"),
            width: 300,
            flex: 1,
            editable: true,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "Description",
                    "Value"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "StartDate",
            headerName: t("field.StartDate"),
            flex: 1,
            editable: true,
            type: "date",
            valueFormatter: params => dateOnlyToString(params.value),
            renderEditCell: params => {
                /** No choice but to dupicate this check, to make custom component triggered by error (style) */
                const hasError = dateOnlyRangeValidation(
                    params,
                    apiRef,
                    t("field.StartEndDate"),
                    "StartDate",
                    "EndDate"
                );
                return (
                    <DateOnlyPickerGrid
                        fieldName={params.field}
                        error={hasError}
                        {...params}
                    />
                );
            },
            preProcessEditCellProps: params => {
                const hasError = dateOnlyRangeValidation(
                    params,
                    apiRef,
                    t("field.StartEndDate"),
                    "StartDate",
                    "EndDate"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "EndDate",
            headerName: t("field.EndDate"),
            flex: 1,
            editable: true,
            type: "date",
            valueFormatter: params => dateOnlyToString(params.value),
            renderEditCell: params => {
                /** No choice but to dupicate this check, to make custom component triggered by error (style) */
                const hasError = dateOnlyRangeValidation(
                    params,
                    apiRef,
                    t("field.StartEndDate"),
                    "StartDate",
                    "EndDate"
                );
                return (
                    <DateOnlyPickerGrid
                        fieldName={params.field}
                        error={hasError}
                        {...params}
                    />
                );
            },
            preProcessEditCellProps: params => {
                const hasError = dateOnlyRangeValidation(
                    params,
                    apiRef,
                    t("field.StartEndDate"),
                    "StartDate",
                    "EndDate"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "StudyID",
            editable: true,
            valueGetter: params => elementID,
            valueSetter: params => ({
                ...params.row,
                StudyID: elementID,
            }),
        },
    ];
    const [open, setOpen] = useState(false);
    const [rowParams, setRowParams] = useState();

    const forbidUserAction = Reader || External
    const specificActionsColumns = (params, editing, isLocalLoading) => [
        <>
            {!Reader && (
                <GridActionsCellItem
                    label={t("common.Translate")}
                    icon={
                        <BasicTooltip title={t("common.Translate")}>
                            <LanguageIcon />
                        </BasicTooltip>
                    }
                    onClick={() => {
                        dispatch(
                            updateHighlightedRows({
                                CurrentLocation: currentLocation,
                                VisitedRowID: params.row.ID,
                            })
                        );
                        setOpen(true);
                        setRowParams(params.row);
                    }}
                    disabled={editing || isLocalLoading}
                />
            )}
            <GridActionsCellItem
                label={t("view.workOrder.EDDFiles")}
                icon={
                    <BasicTooltip title={t("view.workOrder.EDDFiles")}>
                        <BookOutlinedIcon />
                    </BasicTooltip>
                }
                onClick={() => {
                    historyPush(
                        history,
                        `/eddFile/WorkOrder/${params.row.ID}/OTEntityID/${otEntityID}`
                    );
                }}
                disabled={editing}
            />
            {forbidUserAction ? null : (
                <GridActionsCellItem
                    label={t("view.workOrder.ExternalUsersPermissions")}
                    icon={
                        <BasicTooltip
                            title={t("view.workOrder.ExternalUsersPermissions")}
                        >
                            <GroupsIcon />
                        </BasicTooltip>
                    }
                    onClick={() => {
                        historyPush(
                            history,
                            `${dataGrid}/WorkOrder/${params.row.ID}${r.externalUser}`
                        );
                    }}
                    disabled={editing}
                />
            )}
        </>,
    ];
    return (
        <>
            <TranslationDialog
                label={label}
                open={open}
                setOpen={setOpen}
                baseURL={baseURL}
                rowParams={rowParams}
            />
            <DataGridComponent
                apiRef={apiRef}
                baseURL={baseURL}
                columnVisibilityModel={{
                    StudyID: false,
                }}
                paramURL={`filter[StudyID]=${elementID}`}
                cellFocusField={cellFocusField}
                label={label}
                gridSpecificColumns={gridSpecificColumns}
                specificActionsColumns={specificActionsColumns}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "WorkOrderNumber", sort: "asc" }],
                    },
                }}
                withBackButton={linkOrigin === "redirection"}
                editingRights={editingRights}
            />
        </>
    );
};

export default WorkOrderGrid;
