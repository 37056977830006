import { t } from "react-i18nify";

export function rangeCheck(checkRange, minValue, maxValue) {
    if (!checkRange) {
        return true;
    } else if (checkRange && minValue && maxValue) {
        return true;
    } else {
        return t("input.validation.InvalidRange");
    }
}

export function checkFloat(fieldDescr, value, isMandatory) {
    let checkRange = fieldDescr.CheckRange;
    let minValue = value >= fieldDescr.MinValue;
    let maxValue = value <= fieldDescr.MaxValue;

    if (isMandatory) {
        if (Boolean(value) || value === 0) {
            return rangeCheck(checkRange, minValue, maxValue);
        } else if (!Boolean(value)) {
            return t("input.validation.required");
        }
    } else if (!isMandatory) {
        return rangeCheck(checkRange, minValue, maxValue);
    }
}
