import React, { useEffect, useState } from "react";
import { Divider, Grid } from "@mui/material";
import { t } from "react-i18nify";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import EditionPanelGlobal from "./EditionPanelGlobal";
import {
    HiddenField,
    VTextField,
} from "../../Widgets/Custom Inputs/CustomInputs";
import {
    VMultiSelectURL,
    VSelect,
    VSelectURL,
} from "../../Widgets/Custom Inputs/Select";
import {
    basemapsArcgisOptions,
    languagesOptions,
} from "../../../js/constants/selectOptions";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../js/utils/backend";
import { ScreenLoading } from "../../Widgets/CustomLoadings";
import { filter } from "../../../js/constants/routes";
import { queryParam } from "../../../js/constants/queryParam";
import { updateEntityTree } from "../../../js/redux/actions/userPref";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import * as r from "../../../js/constants/routes";

/** Page view where the user can see or edit main data about accessible entities. */
export const EntityEditionForm = ({ viaRequest, creatingViaRequest = false }) => {
    const methods = useFormContext({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const { UrlID, mode } = useParams();
    const creating = mode === "create";
    const userState = useSelector(state => state.auth.UserState);
    const isNewUser = userState === "New";
    const { UserID, CompanyManager, HoldingManager, BranchManager, EntityManager } = useSelector(
        state => state.auth
    );
    const isSuper = CompanyManager || HoldingManager || BranchManager;
    const { watch } = methods;

    return (
        <>
            {(isNewUser || creatingViaRequest) && (
                <>
                    <HiddenField
                        fieldName={"RequestorID"}
                        defaultValue={UserID}
                    />
                    <HiddenField
                        fieldName={"RequestType"}
                        defaultValue="NEWENTITY"
                    />
                </>
            )}
            {!isNewUser && EntityManager && (
                <>
                    <Grid item xs={12} sx={{ mt:2 }}>
                        <VSelectURL
                            label={t("field.Parent")}
                            defaultValue={watch("Parent_select")}
                            URL={"OTEntity"}
                            paramURL={queryParam.onlyParent}
                            fieldName={"ParentEntityID"}
                            getOptionLabel={option => option.Name}
                            validation={{
                                required: {
                                    value: !isSuper && creating,
                                    message: t("input.validation.required"),
                                },
                            }}
                            filterOptions={options =>
                                options.filter(o => o.ObjectID !== UrlID)
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ typography:"body2", mb:5 }}>
                            <InfoIcon
                                sx={{
                                    width: "18px",
                                    height: "18px",
                                    marginRight: "4px"
                                }}
                            />{t("common.ParentFieldIsRequired")}
                        </Box>
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <VTextField
                    label={t("field.Code")}
                    fieldName={
                        isNewUser || creatingViaRequest
                            ? "NewEntity.Code"
                            : "Code"
                    }
                    validation={{
                        validate: value =>
                            value.trim().length > 0
                                ? value.length <= 4
                                    ? true
                                    : t("input.validation.NumberChar") + 4
                                : t("input.validation.required"),
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <VTextField
                    label={t("field.Name")}
                    fieldName={
                        isNewUser || creatingViaRequest
                            ? "NewEntity.Name"
                            : "Name"
                    }
                    validation={{
                        required: t("input.validation.required"),
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <VMultiSelectURL
                    URL={"Branch"}
                    label={t("field.Branch")}
                    paramURL={filter.allFilter}
                    defaultValue={watch("Branch_select")}
                    fieldName={
                        isNewUser || creatingViaRequest
                            ? "NewEntity.BranchesID"
                            : "BranchesID"
                    }
                    validation={{
                        required: t("input.validation.required"),
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <VSelectURL
                    URL={"Family"}
                    label={t("field.Family")}
                    color="primary"
                    defaultValue={watch("Family_select")}
                    fieldName={
                        isNewUser || creatingViaRequest
                            ? "NewEntity.FamilyID"
                            : "FamilyID"
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <VSelectURL
                    URL={"Country"}
                    label={t("field.Country")}
                    color="primary"
                    defaultValue={watch("Country_select")}
                    fieldName={
                        isNewUser || creatingViaRequest
                            ? "NewEntity.CountryID"
                            : "CountryID"
                    }
                    validation={{
                        required: t("input.validation.required"),
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <VSelect
                    label={t("field.Language")}
                    options={languagesOptions.map(e => {
                        e.label = t(e.label);
                        return e;
                    })}
                    validation={{
                        required: t("input.validation.required"),
                    }}
                    fieldName={
                        isNewUser || creatingViaRequest
                            ? "NewEntity.Language"
                            : "Language"
                    }
                />
            </Grid>
            {!isNewUser && (
                <>
                    {!EntityManager &&
                        <Grid item xs={12}>
                            <VSelectURL
                                label={t("field.Parent")}
                                defaultValue={watch("Parent_select")}
                                URL={"OTEntity"}
                                paramURL={queryParam.onlyParent}
                                fieldName={"ParentEntityID"}
                                getOptionLabel={option => option.Name}
                                validation={{
                                    required: {
                                        value: !isSuper && creating,
                                        message: t("input.validation.required"),
                                    },
                                }}
                                filterOptions={options =>
                                    options.filter(o => o.ObjectID !== UrlID)
                                }
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <VMultiSelectURL
                            label={t("field.GisManager")}
                            defaultValue={watch("GisManager_select")}
                            URL={r.oTUser}
                            fieldName={"GisManagerIDs"}
                            paramURL="GisManager"
                            validation={{
                                required: viaRequest
                                    ? false
                                    : t("input.validation.required"),
                            }}
                            getOptionLabel={option => option.Name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <VTextField
                            label={t("field.MapService")}
                            fieldName={"Mapservice"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <VSelect
                            label={t("field.Basemap")}
                            options={basemapsArcgisOptions}
                            fieldName={"Basemap"}
                        />
                    </Grid>
                </>
            )}

            {isNewUser && (
                <>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <VTextField
                            label={t("field.Note")}
                            fieldName={"Note"}
                        />
                    </Grid>
                </>
            )}
        </>
    );
};

const EntityCompleteEditionPanel = ({ route = "OTEntity", ...props }) => {
    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const { mode, UrlID } = useParams();
    const token = useSelector(state => state.auth.Token);
    const EntityManager  = useSelector(state => state.auth.EntityManager);
    const edition = mode === "edit";
    const [loading, setLoading] = useState(edition);
    const { reset } = methods;
    const title = edition ? t("panel.EditEntity") : (!EntityManager ? t("panel.NewEntity") : t("panel.NewSubEntity"));

    const dispatch = useDispatch();
    useEffect(() => {
        if (edition) {
            const fetching = async () => {
                const response = await fetchData(route, token, "ID", UrlID);
                reset({
                    ...response.data,
                    BranchesID: response.data.Branch_select.map(
                        e => e?.ObjectID
                    ),
                    GisManagerIDs: response.data.GisManager_select.map(
                        e => e?.ObjectID
                    ),
                    LanguageCurrent: languagesOptions.filter(
                        e => e.value === response.data.Language
                    )[0],
                });
                setLoading(false);
            };
            fetching();
        }
    }, [reset, route, token, UrlID, edition]);

    return loading ? (
        <ScreenLoading />
    ) : (
        <FormProvider {...methods}>
            <Grid m={1}>
                {title && (
                    <Box sx={{ typography: "h3", p: 2, ml: 2 }}>{title}</Box>
                )}
                <Grid p={2} sx={{ display: "flex", justifyContent: "center" }}>
                    <EditionPanelGlobal
                        route={route}
                        onSubmit={() => dispatch(updateEntityTree())}
                    >
                        <EntityEditionForm {...props} />
                    </EditionPanelGlobal>
                </Grid>
            </Grid>
        </FormProvider>
    );
};

export default EntityCompleteEditionPanel;
