import React, { useEffect, useState } from "react";
import * as r from "../../js/constants/routes";
import { useAxiosConfig, useCheckAndRevalidateToken } from "../../js/utils/customHooks";
import Axios from "axios";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { requiredCell } from "../../js/utils/inputValidations";
import DataGridComponent from "../Widgets/DataGridComponent";
import { useSelector } from "react-redux";
import { ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import { useParams } from "react-router-dom";
import StationsMap from "../ArcGIS/StationsMap";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import { t } from "react-i18nify";
import { stationGeneralToType } from "../../js/utils/genericMethods";
import { SessionVerificationDialog } from "../Layouts/CommonLayout";

const StationGroupGrid = ({ ...props }) => {

    const { stationGroupID, OTEntityID } = useParams();
    const config = useAxiosConfig();
    const [label, setLabel] = useState(t("view.station.StationGroupLoading"));
    const apiRef = useGridApiRef();
    const baseURL = r.station.route;
    const [mapView, setMapView] = useState(false);
    const [forceGridRefresh, setForceGridRefresh] = useState(false);
    const { nodeToRefreshID } = useSelector((state) => state.userPref);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let isSubscribed = true;
        const fetch = async () => {
            if (isSubscribed) {
                setLoading(true);
                const stationGroupFetched = await Axios.get(
                    `${r.StationGroup}/${stationGroupID}`,
                    config
                );
                setLabel(
                    `${t("view.station.StationStationGroup")} ${
                        stationGroupFetched.data.Value
                    }`
                );
                setLoading(false);
            }
        };
        fetch();
        return () => (isSubscribed = false);
    }, [config, stationGroupID, nodeToRefreshID]);

    useEffect(() => {
        nodeToRefreshID && setForceGridRefresh((fgr) => !fgr);
    }, [nodeToRefreshID]);

    const gridSpecificColumns = [
        {
            field: "Name",
            headerName: t("field.Name"),
            width: 150,
            editable: true,
            preProcessEditCellProps: (params) => {
                const hasError = requiredCell(params, apiRef, "Name", "Name");
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "StationType",
            headerName: t("field.StationType"),
            flex: 1,
            sortable: false,
            valueFormatter: (params) => {
                const stationType = stationGeneralToType(params.value);
                return !stationType ? "-" : t(`field.${stationType}`);
            },
        },
        {
            field: "PointString",
            headerName: t("field.PointCoordinates"),
            width: 300,
            flex: 1,
            editable: false,
        },
        {
            field: "PolygonString",
            headerName: t("field.PolygonCoordinates"),
            width: 300,
            flex: 1,
            editable: false,
        },
        {
            field: "Value",
            headerName: t("field.Notes"),
            width: 300,
            flex: 1,
            editable: true,
            preProcessEditCellProps: (params) => {
                const hasError = requiredCell(params, apiRef, "Value", "Value");
                return { ...params.props, error: hasError };
            },
        },
    ];

    ////////////////////////////////// TOKEN REVALIDATION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    const [sessionVerification, setSessionVerification] = useState(false)
    useCheckAndRevalidateToken(setSessionVerification, [mapView])
    if (sessionVerification) {
        return <SessionVerificationDialog sessionVerification={sessionVerification} />
    } 
    ////////////////////////////////// TOKEN REVALIDATION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

    return loading ? (
        <ScreenLoading />
    ) : mapView ? (
        <StationsMap
            title={label}
            OTEntityID={OTEntityID}
            toolbarComponent={
                <ButtonToolbar
                    titleTooltip={t("common.AccessStationsGrid")}
                    title={t("common.GridView")}
                    startIcon={<TableRowsOutlinedIcon />}
                    onClick={() => setMapView(false)}
                />
            }
        />
    ) : (
        <DataGridComponent
            apiRef={apiRef}
            baseURL={baseURL}
            paramURL={r.filter.genericFilter("StationGroupID", stationGroupID)}
            label={label}
            toolbar={{
                add: false,
                newFilter: (
                    <>
                        <ButtonToolbar
                            titleTooltip={t("common.AccessStationsMap")}
                            title={t("common.MapView")}
                            startIcon={<MyLocationOutlinedIcon />}
                            onClick={() => setMapView(true)}
                            disabled={forceGridRefresh}
                        />
                    </>
                ),
            }}
            gridSpecificColumns={gridSpecificColumns}
            hideActionColumn
            forceGridRefresh={forceGridRefresh}
            initialState={{
                sorting: {
                    sortModel: [{ field: "Name", sort: "asc" }],
                },
            }}
            {...props}
        />
    );
};

export default StationGroupGrid;
