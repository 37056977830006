import React, { useEffect, useState } from "react";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-pro";
import * as r from "../../js/constants/routes";
import DataGridComponent from "../Widgets/DataGridComponent";
import { useParams } from "react-router-dom";
import { t } from "react-i18nify";
import { useSelector } from "react-redux";
import { BasicButton, ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import GroupsIcon from "@mui/icons-material/Groups";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { addNewModel, deleteModel, fetchData } from "../../js/utils/backend";
import { DialogActionsForm } from "../Widgets/CustomSurfaces";
import { VSelectAdvanced } from "../Widgets/Custom Inputs/Select";
import { HiddenField } from "../Widgets/Custom Inputs/CustomInputs";
import ExternalUsersGrid from "./ExternalUsersGrid";
import { BasicTooltip } from "../Widgets/StyledComponents";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useEditionRightsPerEntityAccess } from "../../js/utils/customHooks";

const ExternalUserWorkOrderGrid = ({ baseURL = r.externalUserWorkOrder }) => {
    const apiRef = useGridApiRef();
    const { WorkOrderID } = useParams();
    const cellFocusField = "IGG";
    const token = useSelector(state => state.auth.Token);

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const { handleSubmit } = methods;

    const [editExternalUsersOpen, setEditExternalUsersOpen] = useState(false);
    const [forceGridRefresh, setForceGridRefresh] = useState(false);

    function closeAndClearForm() {
        setEditExternalUsersOpen(false);
    }

    const manageSubmit = async data => {
        await addNewModel(data, token, baseURL);
        closeAndClearForm();
        setForceGridRefresh(fgr => !fgr);
    };

    const gridSpecificColumns = [
        {
            field: "OTUserModel_IGG",
            headerName: "IGG",
            flex: 1,
            valueGetter: params => params.row.OTUserModel?.IGG,
        },
        {
            field: "OTUserModel.Firstname",
            headerName: t("field.Firstname"),
            flex: 1,
            valueGetter: params => params.row.OTUserModel?.Firstname,
        },
        {
            field: "OTUserModel.Lastname",
            headerName: t("field.Lastname"),
            flex: 1,
            valueGetter: params => params.row.OTUserModel?.Lastname,
        },
        {
            field: "OTUserModel.EMail",
            headerName: t("field.EMail"),
            flex: 1,
            valueGetter: params => params.row.OTUserModel?.EMail,
        },
        {
            field: "OTUserModel.ExternalProvider_select.Name",
            headerName: t("field.ExternalProvider"),
            flex: 1,
            valueGetter: params =>
                params.row.OTUserModel?.ExternalProvider_select.Name,
        },
    ];

    const [elementData, setElementData] = useState();
    const editingRights = useEditionRightsPerEntityAccess(
        elementData?.StudySelector.OTEntityID,
        ["EntityManager"]
    );
    console.log(editingRights);

    useEffect(
        () => {
            let isSubscribed = true;
            /** Getting parent information (Name,value, etc) to display on children's title */
            const fetching = async () => {
                const response = await fetchData(
                    r.WorkOrder,
                    token,
                    "ID",
                    WorkOrderID
                );
                setElementData(response.data);
            };

            if (WorkOrderID && isSubscribed) {
                fetching();
            } else {
                setElementData("");
            }
            return () => (isSubscribed = false);
        },
        // eslint-disable-next-line
        [WorkOrderID, token]
    );

    const specificActionsColumns = (params, _editing, isLocalLoading) => [
        <>
            {apiRef.current.getRowMode(params.id) !== "edit" &&
                editingRights && (
                    <GridActionsCellItem
                        icon={
                            <BasicTooltip title={t("common.RemoveAccessRight")}>
                                <HighlightOffIcon />
                            </BasicTooltip>
                        }
                        label={t("common.RemoveAccessRight")}
                        className="textPrimary"
                        onClick={async () => {
                            await deleteModel(params.row.ID, token, baseURL);
                            setForceGridRefresh(fgr => !fgr);
                        }}
                        color="inherit"
                        disabled={isLocalLoading}
                    />
                )}
        </>,
    ];

    const dataGridLabel = `${t("grid.title.WorkorderExternalUsers", {
        value: elementData?.StudySelector?.Value,
        value2: elementData?.WorkOrderNumber,
    })}`; //

    /* TODO : this dialog was reworked to be responsive and big enough for grids, think about to do a reusable component for further grid displayed in this way (e.g. Station picking ? ) */
    return (
        <>
            <Dialog
                open={Boolean(editExternalUsersOpen)}
                maxWidth={1}
                fullWidth={true}
            >
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(manageSubmit)}>
                        <DialogTitle
                            sx={{
                                position: "sticky",
                                top: 0,
                                backgroundColor: "white",
                                zIndex: 1500,
                            }}
                        >
                            <Typography color="secondary" sx={{ ml: 1 / 2 }}>
                                {`${t("dialog.AssignTo", {
                                    value: t(
                                        "grid.title.ExternalUsers"
                                    ).toLowerCase(),
                                    value2: t("field.WorkOrder").toLowerCase(),
                                })}`}
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText
                                sx={{ mb: 2 }}
                            ></DialogContentText>
                            <Grid container sx={{ height: "70vh" }}>
                                <HiddenField
                                    fieldName={"WorkOrderID"}
                                    defaultValue={WorkOrderID}
                                />
                                <Grid item xs={12}>
                                    <VSelectAdvanced
                                        fieldName={"ExternalUserIDs"}
                                        grid={
                                            <ExternalUsersGrid
                                                specificToolbar={{
                                                    disableToolbar: true,
                                                }}
                                                hideActionColumn={true}
                                            />
                                        }
                                        paramURL="External=true"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActionsForm>
                            <BasicButton
                                type="button"
                                style={{
                                    position: "relative",
                                    margin: 4,
                                }}
                                onClick={() => closeAndClearForm()}
                            >
                                {t("common.Cancel")}
                            </BasicButton>
                            <BasicButton
                                type="submit"
                                style={{ margin: 4, marginRight: 0 }}
                            >
                                {t("common.Save")}
                            </BasicButton>
                        </DialogActionsForm>
                    </form>
                </FormProvider>
            </Dialog>
            {elementData && (
                <DataGridComponent
                    apiRef={apiRef}
                    baseURL={baseURL}
                    paramURL={`filter[WorkOrderID]=${WorkOrderID}`}
                    cellFocusField={cellFocusField}
                    label={dataGridLabel}
                    toolbar={
                        editingRights
                            ? {
                                  newAction: (
                                      <>
                                          <ButtonToolbar
                                              titleTooltip={t(
                                                  "view.station.CreateStationGroup"
                                              )}
                                              title={t("common.Add")}
                                              startIcon={<GroupsIcon />}
                                              onClick={() => {
                                                  setEditExternalUsersOpen(
                                                      true
                                                  );
                                              }}
                                          />
                                      </>
                                  ),
                                  add: false,
                              }
                            : null
                    }
                    forceGridRefresh={forceGridRefresh}
                    gridSpecificColumns={gridSpecificColumns}
                    specificActionsColumns={specificActionsColumns}
                    isEditDisabled={true}
                    isDeleteDisabled={true}
                    withBackButton
                    editingRights={editingRights}
                />
            )}
        </>
    );
};

export default ExternalUserWorkOrderGrid;
