import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useEffect } from "react";
import { Box } from "@mui/system";
import { useFormContext } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import {
    CircularProgress,
    Divider,
    FormHelperText,
    IconButton,
} from "@mui/material";
import { t } from "react-i18nify";
import { BasicTooltip } from "../../Widgets/StyledComponents";
import { isArrayLength } from "../../../js/utils/genericMethods";

/** Used to define a selection of entities, using a succession of MUI Radiogroups. All selectable options are defined through the prop allOption. Allows to select a child and grand child of a parent entity if onlyFirstLevel is false. */
export const ObjectSelector = ({
    allOptions,
    fieldName,
    onlyFirstLevel = false,
    validation = {},
    fieldNameFirstLevel,
    parentLabel = t("view.query.objectSelection.parentObject"),
}) => {
    const methods = useFormContext({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const { setValue, watch, formState, register } = methods;
    const value = watch(fieldName) ?? {};

    const valueArr = Object.entries(value)?.sort((a, b) => {
        return a[1].Order - b[1].Order;
    });

    useEffect(() => {
        register(fieldName, validation);
    }, [fieldName, register, validation]);

    const ObjectSelection = ({ SubOptions, level }) => {
        const handleChange = (newValue, level) => {
            if (fieldNameFirstLevel) {
                setValue(fieldNameFirstLevel, newValue);
            }
            const optionSelected = allOptions.find(e => e.Name === newValue);
            const SubOptions = optionSelected
                ? optionSelected.PossiblePath.filter(e =>
                      level !== 0 ? !valueArr.map(y => y[0]).includes(e) : e
                  ).map(e => {
                      return (
                          allOptions.find(c => c.Name === e) ?? {
                              Name: e,
                              PossiblePath: [],
                          }
                      );
                  })
                : [];

            const updatingValue = [...valueArr];
            updatingValue[level] = [newValue, { SubOptions, Order: level }];
            updatingValue.length = level + 1;

            setValue(fieldName, Object.fromEntries(updatingValue), {
                shouldValidate: true,
            });
        };

        return (
            <FormControl
                error={Boolean(formState.errors[fieldName])}
                sx={{ width: "100%" }}
            >
                <FormLabel id="demo-controlled-radio-buttons-group">
                    <Box
                        sx={{
                            typography: "bodyMajor",
                            mt: 1,
                            ml: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Box>
                            {level === 0
                                ? parentLabel
                                : `${t(
                                      "view.query.objectSelection.childObject"
                                  )} ${level}`}
                        </Box>
                        <Box sx={{ height: "40px" }}>
                            {level > 0 && valueArr[level] && (
                                <BasicTooltip
                                    title={t(
                                        "view.query.objectSelection.removeChild"
                                    )}
                                    placement="top"
                                >
                                    <IconButton
                                        onClick={() => {
                                            valueArr.length = level;
                                            setValue(
                                                fieldName,
                                                Object.fromEntries(valueArr)
                                            );
                                        }}
                                    >
                                        <ClearIcon size="small" />
                                    </IconButton>
                                </BasicTooltip>
                            )}
                        </Box>
                    </Box>
                </FormLabel>
                {isArrayLength(SubOptions) ? (
                    <RadioGroup
                        sx={{ margin: 1 }}
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={valueArr[level] && valueArr[level][0]}
                        onChange={e => handleChange(e.target.value, level)}
                    >
                        {SubOptions.map((e, i) => (
                            <FormControlLabel
                                key={i}
                                value={e.Name}
                                control={<Radio />}
                                label={e.DetailedName ?? e.Name}
                            />
                        ))}
                    </RadioGroup>
                ) : (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress
                            size={60}
                            sx={{
                                mt: 2,
                                mb: 2,
                            }}
                            color={"secondary"}
                        />
                    </Box>
                )}
                <FormHelperText>
                    {formState.errors[fieldName]?.message}
                </FormHelperText>
            </FormControl>
        );
    };

    return (
        <Box
            sx={{
                p: 1,
                display: "flex",
                flexDirection: "column",
                border: `1px solid ${
                    formState.errors[fieldName] && !watch(fieldName)
                        ? "red"
                        : "#bbba"
                }`,
                borderRadius: "6px",
            }}
        >
            <ObjectSelection SubOptions={allOptions} level={0} />
            {!onlyFirstLevel &&
                valueArr.map(
                    ([key, value], i) =>
                        isArrayLength(value.SubOptions) && (
                            <Box key={key + i}>
                                <Divider sx={{ mb: 1, mx: 1 }} />
                                <ObjectSelection
                                    SubOptions={value.SubOptions}
                                    level={i + 1}
                                />
                            </Box>
                        )
                )}
        </Box>
    );
};
