import SharedSettings from "../common/sharedSettings.json";

const ENG = {
    common: {
        AccessWorkflow: "Access workflow",
        AccessStationsGrid: "Access the stations grid",
        AccessStationsMap: "Access the stations map",
        AccessZonesGrid: "Access the zones grid",
        AccessZonesMap: "Access the zones map",
        Actions: "Actions",
        Add: "Add",
        AddAnElement: "Add an element",
        AddSubEntity: "Add Sub Entity",
        Assign: "Assign a role",
        AssignToInternalUsers: "Assign to internal users",
        AssignToExternalUsers: "Assign to external users",
        AlertsOnly: "Alerts only",
        AlertsToSee:
            "There's some alerts about this element. More info on the grid.",
        ApplicationDown: "Application is currently under maintenance",
        ApplicationUnderMaintenance: "App will be under maintenance from %{startDate} to %{endDate}",
        Back: "Back",
        Bookmarks: "Bookmarks",
        Cancel: "Cancel",
        CannotAccessThisPage: "Cannot access this page",
        Continue: "Continue",
        ChangesHistory: "Changes history",
        CoordinateSystem: "Coordinate system",
        CreatingNew: "Creating a new",
        DefineA: "Define a",
        Delete: "Delete",
        DeselectAll: "Deselect All",
        DontHaveAccessToAnyEntity: "You currently do not have access to any entity",
        Download: "Download",
        Edit: "Edit",
        Editing: "Editing the",
        AddingEnglish: "Adding english value for",
        EditEntityExtent: "Edit entity extent",
        EditZone: "Edit zone",
        EditTreeVisibility: "Edit Tree visibility",
        ExcelExport: "Excel Export",
        False: "False",
        FullName: "Full Name",
        GetResults: "Get results",
        GINTExport: "gINT Export",
        GridView: "Grid View",
        Group: "Group",
        Help: "Help",
        Hidden: "Hidden",
        Loading: "Loading...",
        Location: "Location",
        Mandatory: "Mandatory",
        MapView: "Map View",
        Move: "Move",
        New: "New",
        NewFeaturesAreAdded: "New features are added",
        Next: "Next",
        NoAlerts: "No alerts to display",
        NoResults: "No results to display",
        OneTsigane: "One Tsigane",
        OpenNotifications: "Open notifications",
        Overwrite: "Overwrite",
        QuickSearchInto: "Quick search into :",
        QuickSearchFilter: "Quick search filter options",
        ParentFieldIsRequired: "The Parent field is required.",
        PreviewFile: "Preview the file",
        Private: "Private",
        RedirectTo: "Redirect to :",
        Refresh: "Refresh",
        ResultsFor: "Results for %{value} ",
        RemoveAccessRight: "Remove access rights",
        Search: "Search",
        SearchInTable: "Search in table",
        SeeAlerts: "See alerts",
        SeeMore: "See more",
        Select: "Select",
        SelectAll: "Select All",
        SettingsPanel: "Settings panel",
        Skip: "Skip",
        SkipUpload: "Skip upload",
        Close: "Close",
        Remove: "Remove",
        Request: "Request",
        Role: "Role",
        newRef: "Request for a new reference",
        addNewRef: "Request a new reference",
        Save: "Save",
        Translate: "Translate",
        Tree: "Tree",
        True: "True",
        Workflow: "Workflow",
        GenerateAToken: "Generate a Token",
        CopytoClipboard: "Copy to Clipboard",
        CopiedSuccessfully: "Copied Sucessfully",
        SavedSuccessfully: "Saved Successfully",
        featuresmessage: `A new version (version ${SharedSettings.Version}) of the application has been released. It includes the following new features:`,
        entitydelete: "This will delete the entity",
        operationundone: "This operation cannot be undone.",
        entitycode: "To confirm, please type the Code of the Entity:",
		Error: "Error Report",
		Upload: "Upload",
    },
    contact: {
        FileUploadedSuccessfully: "%{fileName} uploaded successfully",
        NoFileToDownload: "No file to download !",
        YouHaveAProblem: "You have a problem",
        ContactForm: "Contact form",
        YouHaveAnIdea: "You have an idea",
        QuestionAboutTheApp: "A question about the app",
        WantToTrackAProblem: "Want to track a problem",
        QuestionAboutUsingTheToolWithBranches: "A question about using the tool with branches",
        BranchManagers: "Branch managers"
    },
    diagram: {
        Draft: "Draft",
        Failed: "Failed",
        Final: "Final",
        Finalize: "Finalize",
        ForValidation: "For Validation",
        New: "New",
        NotValidated: "Not Validated",
        NotValid: "Not Valid",
        OK: "OK",
        Ok: "Ok",
        Processing: "Processing",
        Reject: "Reject",
        Rejected: "Rejected",
        Remove: "Remove",
        Removed: "Removed",
        Requested: "Requested",
        Reset: "Reset",
        Rework: "Rework",
        Reviewed: "Reviewed",
        Submit: "Submit",
        Submitted: "Submitted",
        UnderValidation: "Under Validation",
        Validated: "Validated",
        Validate: "Validate",
        Valid: "Valid",
        Waiting: "Waiting",
    },
    eddDataValidation: {
        ValidatingSheetsData: `Validating "%{sheetName}" data`,
        Stations: "Stations",
        Samplings: "Samplings",
        InSituUndergroundMeasObs: "In Situ Underground Meas Obs",
        LaboResult: "Labo Results",
        SpeciesIdentification: "Species Identification",
        EddValidationMayTakeSomeMinutes: "Loading may take a few minutes depending on the amount of data present in the EDD."
    },
    enum: {
        unitCategories: {
            NONE: "None",
            CONDUCTANCE: "Conductance",
            CONDUCTIVITY: "Conductivity",
            FLAMMABILITY: "Flammability",
            HIGHT_DEPTH: "Hight/Depth",
            LENGTH: "Length",
            MASS: "Mass",
            MASS_CONCENTRATIONS: "Mass concentrations",
            MASS_FRACTIONS: "Mass fractions",
            MICROBIAL_CELLS_CONCENTRATION: "Microbial cells concentration",
            PARTS_PER_MASS: "Parts per : Mass",
            PARTS_PER_VOLUME: "Parts per : Volume",
            PERCENTAGE: "Percentage",
            PRESSURE: "Pressure",
            SPEED: "Speed",
            TEMPERATURE: "Temperature",
            VOLTAGE: "Voltage",
            VOLUME: "Volume",
            VOLUME_FLOW_RATE: "Volume flow rate",
            WATER_HARDNESS: "Water hardness",
            SPECIFIC_VOLUME: "Specific volume",
            SOUND_INTENSITY: "Sound intensity",
            RADIOACTIVITY_DESINTEGRATION_RATE: "Radioactivity desintegration rate",
            RADIOACTIVITY_PARTICLE_FLUX: "Radioactivity particle flux"
        },
        frequencyQuery: {
            Monday: "Each monday",
            Friday: "Each friday",
            BeginningMonth: "Every beginning of the month",
            EndMonth: "Every end of the month",
        },
        rights: {
            COMPANYMANAGER: "Company manager",
            HOLDINGMANAGER: "Holding manager",
            ENTITYMANAGER: "Entity manager",
            BRANCHMANAGER: "Branch manager",
            CONTRIBUTOR: "Contributor",
            READER: "Reader",
            EXTERNAL: "External",
            GISMANAGER: "GIS manager",
        },
    },
    field: {
        AdditionalExplanations: "Additional explanations",
        AnalysisGroup: "Analysis group",
        Author: "Author",
        Basemap: "Basemap",
        Branch: "Branch",
        Cas: "CAS number",
        Category: "Category",
        Code: "Code",
        Color: "Color",
        Confidential: "Confidential",
        Country: "Country",
        CreatedOn: "Creation date",
        CreatedByUser: "Created by user",
        CreatedByUserDescription: "Identity of the user who created the item",
        Description: "Description",
        DocumentCategory: " Document Category",
        EddFile: "EDD File",
        EndDate: "End Date",
        Entity: "Entity",
        EntityCode: "Entity code",
        EntityName: "Entity name",
        External: "External",
        ExternalProvider: "External Provider",
        Explaination: "Explaination",
        Family: "Family",
        File: "File",
        Filename: "Filename",
        Firstname: "Firstname",
        Frequency: "Frequency",
        GisManager: "Gis Manager",
        GroundAltitude: "Ground Altitude",
        InitialStudy: "Initial study",
        IsPrimaryEDDFile: "Primary document of the EDD",
        Key: "Key",
        Language: "Language",
        Lastname: "Lastname",
        LastUpdate: "Last update",
        LastUpdateDescription: "Date time of when last update occured",
        LookUpTableName: "Lookup tablename",
        MapService: "Map Service",
        MaxValue: "Maximum Value",
        MinValue: "Minimum Value",
        Name: "Name",
        No: "No",
        NodeType: "Node Type",
        Note: "Note",
        Number: "Number",
        Observation: "Observation",
        Owner: "Owner",
        Parent: "Parent",
        Phone: "Phone",
        PhotoContext: "Photo context",
        PointCoordinates: "Point coordinates",
        PolygonCoordinates: "Polygon coordinates",
        PublicationDate: "Publication date",
        Public: "Public",
        QueryName: "Query name",
        Reference: "Reference",
        Region: "Region",
        RelatedZones: "Related zones",
        RelatedStations: "Related stations",
        RelatedStationGroups: "Related station groups",
        RelatedStudies: "Related studies",
        RequestedAccess: "Requested Access",
        RequestAccessType: "Request access type",
        RequestDate: "Request date",
        RequestorIGG: "Requestor IGG",
        RequestType: "Request Type",
        ResultsSize: "Results size",
        SamplingID: "Sampling id",
        SubSamplingID: "Sub-Sampling id",
        SampleType: "Sample type",
        SecurityGroupType: "Security Group Type",
        Share: "Share",
        StartDate: "Start Date",
        StartEndDate: "Start / End date",
        State: "State",
        Station: "Station",
        StationAir: "Air",
        StationBiodiversity: "Biodiversity",
        StationCode: "Station code",
        StationGroups: "Station groups",
        StationNoise: "Noise",
        StationSedimentWater: "Water & Sediment",
        StationSediment: "Sediment",
        StationWater: "Water",
        StationSoilGroundwater: "Soil-Groundwater",
        StationType: "Station Type",
        Study: "Study",
        StudyType: "Study Type",
        StudyYear: "Study Year",
        Submitter: "Submitter",
        Title: "Title",
        UpdatedByUser: "Updated by user",
        UpdatedByUserDescription: "Identity of the last user who updated the element",
        User: "User",
        Units: "Units",
        Value: "Value",
        Valid: "Valid",
        WorkOrder: "Work order",
        Yes: "Yes",
        Zones: "Zone",
        EmailNotif: "Receive notifications by e-mail",
        ExplanatoryText: "Technical token allowing access to One-Tsigane's APIs. This token is strictly personal: Your identity will be referenced when used. It is valid 365 days:",
    },
    grid: {
        title: {
            Administration: "Administration",
            Alerts: "Alerts",
            AllUsers: "All Users",
            Analytes: "Analytes",
            Branches: "Branches",
            BranchManagers: "Branch Managers",
            CompanyManagers: "Company Managers",
            HoldingManagers: "Holding Managers",
            Configuration: "Configuration",
            UserPref: "User Preferences",
            Countries: "Countries",
            Documents: "Documents",
            EDDFiles: "EDD Files",
            Entities: "Entities",
            EntityManagers: "Entity Managers",
            EntityUsers: "Entity Users",
            ExternalUsers: "External Users",
            ExternalProviders: "External Providers",
            Families: "Families",
            Files: "Files",
            GISManagers: "GIS Managers",
            InternalUsers: "Internal Users",
            LimitsOf: "Limits of",
            LookupHints: "Lookup Hints",
            NewFeaturesMessage: "New Features Message",
            PhotoVideo: "Photos and Videos",
            Queries: "Queries",
            Regions: "Regions",
            Requests: "Requests to validate",
            MyRequests: "My requests",
            ReportScheduler: "Reporting scheduler",
            Settings: "Settings",
            Samplings: "Samplings",
            Stations: "Stations",
            VisibilityEditor: "Visibility Editor",
            WorkorderExternalUsers: `Manage external user permissions on Study "%{value}", Workorder "%{value2}" `,
            Zones: "Zones",
			BulkUpload: "Bulk Upload",
        },
    },
    tree: {
        Add: "Add child node to %{value}",
        AddBookmark: "Add to bookmarks",
        AddStudy: "Add a study to %{value}",
        AddStationGroup: "Add a Station Group",
        Delete: "Delete %{value}",
        Edit: "Edit %{value}",
        EditTranslation: "Edit %{value} translations",
        Entities: "Entities",
        EntityTreeTemplate: "Entity Tree Template",
        EntityTreeTemplateEditor: "Entity Tree Template Editor",
        Limits: "Limits",
        Lookups: "Lookups",
        Maps: "Maps",
        References: "References",
        RemoveFromBookmarks: "Remove from bookmarks",
        Security: "Security",
        StationGroupNodeEmpty:
            "Currently, there's not any Station group yet to display. Click here to create a new one",
        StudyTemplate: "Study Template",
        StudyTemplateEditor: "Study %{value} template editor",
        Users: "Users",
        Requests: "Requests",
        StudyType: "Study Type",
        TextType: "Text Type"
    },
    dialog: {
        Alerts: "Alerts",
        AssignTo: "Assign %{value} to a %{value2}",
        AddNewLimits: "Add new limits.",
        CopyZonesFromStudies: "Copy zones from other studies.",
        OverwriteFileConfirmation:
            "This file already exists, are you sure to overwrite the ongoing upload ?",
        PromptChangesHasOccured:
            "Are you sure you want to navigate away from this page?\n\nYou have started writing or editing a row in the current data grid.\n\nPress OK to continue or Cancel to stay on the current page and commit your changes.",
        DeleteConfirmationRow: "Are you sure you want to delete this row ?",
        DeleteConfirmationNode: "Are you sure you want to delete this node ?",
        Cancelled: "Cancelled",
        SessionVerification:
            "Your session is being verified. Thank you for your patience.",
        UploadSkipped: "Upload skipped",
        NotificationDoneConfirmation:
            "Are you sure you want to validate this notification ?",
    },
    input: {
        validation: {
            required: "This field is required.",
            DateRangeValidation: "The start date shoud be before the end date",
            DropFileAccepted: "Drop the files",
            DropFileRejected: `Sorry, only ${SharedSettings.MaxFilesToUpload} files are allowed at once`,
            NumberChar: "The maximum number of characters in this field is : ",
            WrongDocumentExtension: `This is a document folder: the accepted files cannot be images or videos, therefore their upload will be rejected.`,
            WrongPhotoVideoExtension: `This is a photo/video folder: the accepted files should be images or videos, otherwise their upload will be rejected.`,
            InvalidStartingDate:
                "The starting date cannot be after the ending date.",
            InvalidEndingDate:
                "The ending date cannot be before the starting date.",
            InvalidRange: "The value does not match the range",
            InvalidTime: "The entered time is incorrect. Please check again. The valid time range is from 00:00 to 23:59.",
            SelectStudy: "Please select a study first",
        },
    },
    languages: {
        Dutch: "Dutch",
        English: "English",
        French: "French",
        German: "German",
        Portuguese: "Portuguese",
        Spanish: "Spanish",
    },
    map: {
        addAPoint: "Add a new point",
        coordinates: "Coordinates",
        errorSIG: "The connection to SIG could not be made.",
        errorLayerSIG:
            'The connection to the SIG layer "%{layerName}" could not be made.',
        entityOfInterest: "Entity of interest",
        extentOf: "Extent of",
        latitude: "Latitude",
        longitude: "Longitude",
        mapServerLayers: "Map server layers",
        point: "Point",
        polygon: "Polygon",
        removeAPoint: "Remove a point",
        redirectToStation: "See details of the station",
        mapServerLayersTypes: {
            LAYER_PICTURE: "Pictures",
            LAYER_PLAN: "Plans",
            LAYER_TOPO: "Topographies",
            LAYER_PIPELINES: "Pipelines",
            LAYER_MISC: "Miscellaneous",
            LAYER_CADASTRE: "Cadastres",
            LAYER_ZONE: "Zones",
        },
        selectedStations: "Selected stations",
        stationGroupsLayers: "Station groups layers",
        stationsLayers: "Stations layers",
        stationsOf: "Stations of",
        stationsOfGroup: "Stations of group",
        zonesOf: "Zones of",
        zonesExtents: "Zone extents",
    },
    notification: {
        noNotification: "You don't have notifications !",
        errorNotifictation: "Notifications are not available at the moment",
    },
    misc: {
        lambdaUser: "Lambda user",
        From: "from :",
    },
    panel: {
        EditEntity: "Edit Entity",
        NewEntity: "New Entity",
        NewSubEntity: "New Sub Entity"
    },
    placeholder: {
        Logout: "Logout",
        NoEntry: "No entry",
        UserState: "User State",
        EddFileState: "EDD file state",
    },
    select: {
        Contributor: "Contributor",
        Reader: "Reader",
        External: "External",
    },
    view: {
        entityPage: {
            Code: "Code",
            Name: "Name",
            Country: "Country",
            Branch: "Branch",
            Parent: "Parent",
            Contact: "Contacts",
            Documents: "Documents",
            History: "History",
            Home: "Home",
            Location: "Location",
            Operations: "Operations",
            Photo: "Photo",
        },
        helpPage: {
            Contact: "Contact",
            Documents: "Documents",
            InternalUsers: "Internal Users",
            ExternalUsers: "External Users",
            contact: {
                FileUploadedSuccessfully: "%{fileName} uploaded successfully",
                NoFileToDownload: "No file to download !",
                AnyTechnicalIssues: "Any technical issues",
                PleaseClickHere: "Please click here",
                AnyFurtherFunctionalities: "Would you like any further functionalities on the system",
                PleaseReferToThisLink: "Please refer to this link",
                QuestionAboutTheApp: "A question about the app",
                WantToTrackAProblem: "Want to track a problem",
                ForSupportAtBranchLevel: "For support at Branch Manager level please contact",
            },
        },
        newUser: {
            CurrentRequests: "Current requests",
            EntityAccessRequest: "Request access to an existing entity",
            EntityCreateRequest: "Request to create a new entity",
            Welcome: "Welcome",
        },
        document: {
            AddMediaMetadata: "Add metadata to :",
            AddDocument: "Add document",
            CancelMove: "Cancel move",
            CurrentStudy: "Current Study:",
            Destination: "Destination",
            DragAndDropFile: `Drag and drop a file over the screen to upload, or use the 'Add' button on the datagrid toolbar.`,
            Extension: "Extension",
            FilesRejected: "Files are rejected.",
            HelpFileUpload: "Help about the file upload",
            MaxFilesAllowed: `An upload action allows maximum ${SharedSettings.MaxFilesToUpload} files at once.`,
            MoveDocument: "Move a document",
            OriginalStudy: "Original Study :",
            MoreInfo: "More info",
            ListAllowedExtensions: "List of allowed file extensions",
            SelectDestination: "Select destination",
            UploadedBy: "Uploaded by :",
        },
        eddFiles: {
            getTemplate: "Get file template",
        },
        limit: {
            add: "Add a new limit",
            addNewLimits: "Add new limits",
            limitSelection: "Definition and Selection of object",
            stepper: {
                CreateNewLimit: "Create a new limit",
                EditLimit: "Edit limit",
            },
            objectSelection: {
                object: "Object",
                parentObject: "Object",
            },
        },
        query: {
            ranOn: "Ran on",
            ranOnThe: "ran on the",
            reporting: "Reporting",
            resultQuery: "Result of the Query",
            runQuery: "Run the query",
            seeResultsOnMap: "See these results on a map",
            stepper: {
                CreateNewQuery: "Create a new query",
                EditQuery: "Edit query",
            },
            objectSelection: {
                objectSelection: "Object Selection",
                object: "Object",
                parentObject: "Parent object",
                childObject: "Child object",
                removeChild: "Remove child",
            },
            conditions: {
                conditions: "Conditions",
            },
            outputSelection: {
                outputSelection: "Output Selection",
                Fieldname: "Fieldname",
                Label: "Label",
                Description: "Description",
                Show: "Show",
            },
        },
        request: {
            ADDUSER: "Add a user",
            NEWENTITY: "New entity",
            NEWREF: "New reference",
            OptionalObservation: "Optional observation",
        },
        station: {
            CreateNewStation: "Create new station",
            CreateStationGroup: "Create a group of stations",
            EditStation: "Edit station",
            StationGroup: "Station group",
            StationStationGroup: "Stations of station group :",
            StationGroupLoading: "Stations of station group: loading...",
        },
        study: {
            Study: "the study",
            StudyWorkOrder: "Study %{value} : Work Orders",
            NoStudyWorkOrder: "Study ... : Work Orders",
        },
        workOrder: {
            EDDFiles: "EDD Files",
            ExternalUsersPermissions: "External users permissions",
        },
        zone: {
            CopyZones: "Copy zones",
            CopyZonesFromStudy: "Copy zones from another study",
            CreateNewZone: "Create new zone for",
            EditExtent: "Edit the extent of",
            EditZone: "Edit zone",
            ExtendDefined: "Extent defined",
            ExtendNotDefined: "Extent not defined",
            LoadingEntity: "Loading entity ...",
            PreExistingZones: "Pre-existing zones of",
        },
    },
    packages: {
        colorPicker: {
            language: "ENG",
            color: "color",
            rgb: "rgb",
            R: "R",
            G: "G",
            B: "B",
            hsv: "hsv",
            H: "H",
            S: "S",
            V: "V",
            hsl: "hsl",
            WrongFormat: "Wrong format.",
            NotHexFormat: "Not an hex value.",
            transparent: "Transparent",
            none: "Invalid",
        },
        reactAwesomeQueryBuilder: {
            and: "and",
            or: "or",
            valueLabel: "Value",
            valuePlaceholder: "Value",
            fieldLabel: "Field",
            operatorLabel: "Operator",
            funcLabel: "Function",
            fieldPlaceholder: "Select field",
            funcPlaceholder: "Select function",
            operatorPlaceholder: "Select operator",
            lockLabel: "Lock",
            lockedLabel: "Locked",
            deleteLabel: "",
            delGroupLabel: "",
            addGroupLabel: "Add group",
            addRuleLabel: "Add rule",
            addSubRuleLabel: "Add sub rule",
            notLabel: "Not",
            valueSourcesPopupTitle: "Select value source",
            removeRuleConfirmOptions: {
                title: "Are you sure delete this rule?",
                okText: "Yes",
                okType: "danger",
                cancelText: "Cancel",
            },
            removeGroupConfirmOptions: {
                title: "Are you sure delete this group?",
                okText: "Yes",
                okType: "danger",
                cancelText: "Cancel",
            },
            like: "like",
            not_like: "not like",
            starts_with: "starts with",
            ends_with: "ends with",
            between: "between",
            not_between: "not between",
            is_null: "is null",
            is_not_null: "is not null",
            is_empty: "is empty",
            is_not_empty: "is not empty",
            select_any_in: "any in",
            select_not_any_in: "not in",
            proximity: "proximity",
            enterValue: "Enter Value",
            enterNumber: "Enter number",
            enterNumberFrom: "Enter number from",
            enterNumberTo: "Enter number to",
            enterDate: "Enter date",
            enterInteger: "Enter integer",
            enterText: "Enter text",
            selectValue: "Select value",
            selectValues: "Select values",
        },
    },
    references: {
        Ref_AirType: "Air Type",
        Ref_AnalysisGroup: "Analysis Group",
        Ref_AquiferStatus: "Aquifer Status",
        Ref_BiodiversityType: "Biodiversity Type",
        Ref_BoreholeStatus: "Borehole Status",
        Ref_BoreholeType: "Borehole Type",
        Ref_CapType: "Cap Type",
        Ref_ConstructionType: "Construction Type",
        Ref_Context: "Context",
        Ref_DayNight: "Day and Night",
        Ref_DrillingFluid: "Drilling Fluid",
        Ref_DrillingMethod: "Drilling Method",
        Ref_EcologicalGroup: "Ecological Group",
        Ref_EcologicalIntegrity: "Ecological Integrity",
        Ref_EmissionSource: "Emission Source",
        Ref_EquipmentType: "Equipment Type",
        Ref_FaunaUse: "Fauna Use",
        Ref_GeometryType: "Geometry Type",
        Ref_HabitatType: "Habitat Type",
        Ref_HumanUse: "Human Use",
        Ref_Installation: "Installation",
        Ref_IUCNStatus: "IUCN Status",
        Ref_LithologyType: "Lithology Type",
        Ref_Matrix: "Matrix",
        Ref_ObsMeasure: "Obs Measure",
        Ref_ObservationMode: "Observation Mode",
        Ref_ObservationType: "Observation Type",
        Ref_PhotoContext: "Photo Context",
        Ref_Parameter: "Parameter",
        Ref_PiezometerGroup: "Piezometer Group",
        Ref_PresenceProof: "Presence Proof",
        Ref_QAQCType: "QAQC Type",
        Ref_ReadingType: "Reading Type",
        Ref_RecordPeriod: "Record Period",
        Ref_RelativePosition: "Relative Position",
        Ref_ReleaseLocation: "Release Location",
        Ref_SampleCollectionType: "Sample Collection Type",
        Ref_SampleType: "Sample Type",
        Ref_SensorType: "Sensor Type",
        Ref_StationBiodiversityType: "Station Biodiversity",
        Ref_StationSedimentWaterType: "Station Sediment Water",
        Ref_StratigraphicClassification: "Stratigraphic Classification",
        Ref_TrophicGroup: "Trophic Group",
        Ref_Unit: "Units",
        Ref_Vulnerability: "Vulnerability",
        Ref_WaterIntermittence: "Water Intermittence",
        Ref_WaterLocation: "Water Location",
        Ref_WaterSource: "Water Source",
        Ref_WaterType: "Water Type",
        Ref_WeightBasis: "Weight Basis",
    },
};
export default ENG;
