import { loadModules } from "esri-loader";
import { arcgisToGeoJSON, geojsonToArcGIS } from "@terraformer/arcgis";
import { mainRed } from "../../themes/GlobalTheme";
import { t } from "react-i18nify";
import { toastr } from "react-redux-toastr";
import { isArrayLength } from "../../js/utils/genericMethods";
import { Typography } from "@mui/material";
import { store } from "../../js/redux/store/index";
import { Box } from "@mui/system";
import * as r from "../../js/constants/routes";

/** Getting the center of arcgis geometry */
export const getCenterGeometry = async sridGeoJSON => {
    const geometry = await sridGeoJSONToWebArcgisGeometry(sridGeoJSON);
    const geometryType = {
        point: geometry,
        polygon: geometry.centroid,
    };
    const { longitude, latitude } = geometryType[geometry.type];
    return [longitude, latitude];
};

/** Convert a GeoJSON with a srid included to a web arcgis geometry */
export const sridGeoJSONToWebArcgisGeometry = async sridGeoJSON => {
    const [Polygon, Point] = await loadModules([
        "esri/geometry/Polygon",
        "esri/geometry/Point",
    ]);
    let webEsriGeometry;
    let esriJson = geojsonToArcGIS(sridGeoJSON.GeoJSON);
    esriJson.spatialReference = {
        wkid: sridGeoJSON.SRID,
    };
    const geometries = {
        Polygon: Polygon.fromJSON(esriJson),
        Point: Point.fromJSON(esriJson),
    };
    const esriGeometry = geometries[sridGeoJSON.GeoJSON.type];

    if (sridGeoJSON.SRID === 4326) {
        const [webMercatorUtils] = await loadModules([
            "esri/geometry/support/webMercatorUtils",
        ]);
        webEsriGeometry = await webMercatorUtils.geographicToWebMercator(
            esriGeometry
        );
    } else {
        const [projection, SpatialReference] = await loadModules([
            "esri/geometry/projection",
            "esri/geometry/SpatialReference",
        ]);
        await projection.load();

        const webEsriSpatialReference = new SpatialReference({
            wkid: 3857,
        });

        webEsriGeometry = await projection.project(
            esriGeometry,
            webEsriSpatialReference
        );
    }

    return webEsriGeometry;
};

/** Default style of the shape of a graphic */
export const basicShape = {
    color: [237, 0, 0, 0.5],
    outline: {
        color: "#363432",
        width: 1,
    },
};

/** Default style of the extent of graphic */
export const extentShape = {
    color: [237, 0, 0, 0.5],
    outline: {
        color: mainRed,
        width: 2,
    },
};

/** Convert a GeoJSON with a srid included to a web arcgis graphic */
export const sridGeoJSONToWebArcgisGraphic = async (
    sridGeoJSON,
    graphicShape,
    attributes = {},
    popupTemplate
) => {
    const [Graphic] = await loadModules(["esri/Graphic"]);

    popupTemplate = popupTemplate ?? {
        title: "{Name}",
        content: "{Description}",
    };

    const getGraphicShape = type => {
        let shape = {};
        if (graphicShape) shape = { ...basicShape, ...graphicShape };
        if (type === "point") return { ...shape, type: "simple-marker" };
        if (type === "polygon") return { ...shape, type: "simple-fill" };
    };
    const webEsriGeometry = await sridGeoJSONToWebArcgisGeometry(sridGeoJSON);

    const webEsriGraphicGeometry = {
        type: sridGeoJSON.GeoJSON.type.toLowerCase(),
        ...webEsriGeometry.toJSON(),
    };

    const newGraphic = new Graphic({
        geometry: webEsriGraphicGeometry,
        symbol: getGraphicShape(webEsriGraphicGeometry.type),
        attributes,
        popupTemplate,
    });

    return newGraphic;
};

/** Convert a web arcgis geometry to a GeoJSON with a srid included */
export const arcgisGeometryToSRIDGeoJSON = async (
    esriGeometry,
    wkid = 4326
) => {
    let outputGeometry;
    if (wkid === 4326) {
        const [webMercatorUtils] = await loadModules([
            "esri/geometry/support/webMercatorUtils",
        ]);
        outputGeometry = webMercatorUtils.webMercatorToGeographic(esriGeometry);
    } else {
        //if wkid is not of WGS84 standard, a projection of the geometry has to done
        const [projection, SpatialReference] = await loadModules([
            "esri/geometry/projection",
            "esri/geometry/SpatialReference",
        ]);

        await projection.load();

        const outSpatialReference = new SpatialReference({
            wkid,
        });

        outputGeometry = projection.project(esriGeometry, outSpatialReference);
    }

    const outputEsriJson = outputGeometry.toJSON();
    const outputGeoJson = arcgisToGeoJSON(outputEsriJson);

    return {
        SRID: wkid,
        GeoJSON: outputGeoJson,
    };
};

/** Generating a default map with the basic functionalities */
export const generatingBasicMap = async (
    mapRef,
    language = "en",
    mapName,
    specificLegendLayers
) => {
    let map, view, layerListWidget;
    await loadModules(
        [
            "esri/config",
            "esri/Map",
            "esri/views/MapView",
            "esri/widgets/Search",
            "esri/widgets/ScaleBar",
            "esri/widgets/LayerList",
            "esri/widgets/Slider",
            "esri/intl",
            "esri/widgets/Fullscreen",
            "esri/widgets/Print",
            "esri/widgets/Expand",
            "esri/widgets/Legend",
            "esri/widgets/Compass",
            "esri/symbols/SimpleMarkerSymbol",
            "esri/symbols/support/symbolUtils",
        ],
        { css: true }
    ).then(
        async ([
            esriConfig,
            Map,
            MapView,
            Search,
            ScaleBar,
            LayerList,
            Slider,
            intl,
            Fullscreen,
            Print,
            Expand,
            Legend,
            Compass,
            SimpleMarkerSymbol,
            symbolUtils,
        ]) => {
            // API
            esriConfig.apiKey = process.env.REACT_APP_ESRI_KEY;

            //language
            await intl.setLocale(language);

            // Map, View and Graphic layer
            map = new Map({
                basemap: "topo-vector",
            });

            view = new MapView({
                container: mapRef.current,
                map: map,
                center: [2.3522, 48.8566],
                zoom: 12,
            });

            // WIDGETS TOP-LEFT MAP ------------------------
            //Search widget
            const search = new Search({
                view: view,
                popupEnabled: false,
            });
            view.ui.add(search, {
                position: "top-left",
                index: 0,
            });

            //FullScreen widget
            const fullscreen = new Fullscreen({
                view: view,
                index: 2,
            });
            view.ui.add(fullscreen, "top-left");
            // Compass widget
            const compassWidget = new Compass({
                view: view,
            });
            view.ui.add(compassWidget, { position: "top-left", index: 1 });

            // WIDGETS TOP-RIGHT MAP ------------------------
            //Layer List
            layerListWidget = new LayerList({
                view: view,
            });

            layerListWidget.listItemCreatedFunction = event => {
                const item = event.item;

                item.actionsSections = Boolean(item.layer.type) &&
                    item.parent?.layer.type !== "map-image" && [
                        [
                            {
                                title: "Bring closer to the top",
                                className: "esri-icon-up",
                                id: "closer-to-top",
                            },
                            {
                                title: "Bring closer to the bottom",
                                className: "esri-icon-down",
                                id: "further-from-top",
                            },
                        ],
                    ];

                const slider = new Slider({
                    label: "Opacity",
                    min: 0,
                    max: 1,
                    precision: 2,
                    values: [item.layer.opacity],
                    visibleElements: {
                        labels: true,
                        rangeLabels: true,
                    },
                });

                item.panel = item.layer.type && {
                    content: ["Opacity", slider],
                    className: "esri-icon-sliders-horizontal",
                    title: "Change layer opacity",
                };

                slider.on("thumb-drag", event => {
                    const { value } = event;
                    item.layer.opacity = value;
                });

                //User preferences of Opened layers
                const mapPrefs = store.getState().userPref.MapsPrefs[mapName];
                if (Boolean(mapPrefs)) {
                    const layerPref = mapPrefs.find(
                        p => p.title === item.layer.title
                    );
                    if (layerPref) {
                        item.open = layerPref.open;
                        // item.layer.opacity = layerPref.opacity;
                        // item.layer.visible = layerPref.visibility;
                    }
                }
            };

            layerListWidget.on("trigger-action", event => {
                const visibleLayer = event.item.layer;
                const id = event.action.id;

                const parentLayer = event.item.parent
                    ? event.item.parent.layer
                    : map;

                const index = parentLayer.layers.findIndex(
                    l => l.id === visibleLayer.id
                );

                if (id === "closer-to-top") {
                    parentLayer.reorder(visibleLayer, index + 1);
                } else if (id === "further-from-top") {
                    if (index !== 0) {
                        parentLayer.reorder(visibleLayer, index - 1);
                    }
                }
            });

            const layerListExpand = new Expand({
                expandIconClass: "esri-icon-layer-list",
                expandTooltip: "Expand Layer List",
                view: view,
                content: layerListWidget,
            });
            view.ui.add(layerListExpand, {
                position: "top-right",
                index: 0,
            });

            //Print widget
            const print = new Print({
                view: view,
                printServiceUrl: process.env.REACT_APP_ESRI_PRINT_URL,
            });
            const printExpand = new Expand({
                expandIconClass: "esri-icon-printer",
                expandTooltip: "Expand Print",
                view: view,
                content: print,
            });
            view.ui.add(printExpand, {
                position: "top-right",
            });

            // Legend widget
            const legendPopup = document.getElementById("legend");
            const legendButton = document.getElementById("legendButton");
            const legend = new Legend({
                view: view,
            });
            legend.container = legendPopup;

            view.ui.add(legendButton, {
                position: "top-right",
            });
            view.ui.add("legend", {
                position: "top-left",
            });

            if (isArrayLength(specificLegendLayers)) {
                for (const group of specificLegendLayers) {
                    if (isArrayLength(group.layersLegend)) {
                        for (const [key, layer] of Object.entries(
                            group.layersLegend
                        )) {
                            const hasPreviousSymbol =
                                document.getElementsByClassName(
                                    `symbol${key}Class`
                                ).length > 0;
                            if (!hasPreviousSymbol) {
                                const symbol = new SimpleMarkerSymbol(
                                    layer.symbol
                                );
                                const symbolHtml =
                                    await symbolUtils.renderPreviewHTML(symbol);
                                symbolHtml.className = `symbol${key}Class`;
                                document
                                    .getElementById(`symbol${layer.label}`)
                                    .append(symbolHtml);
                            }
                        }
                    }
                }
            }

            legendButton.addEventListener("click", () => {
                if (legendPopup.style.visibility === "visible") {
                    legendPopup.style.visibility = "hidden";
                } else {
                    legendPopup.style.visibility = "visible";
                }
            });
            legendButton.style.visibility = "visible";
            legendButton.style.display = "flex";

            // WIDGETS BOTTOM-RIGHT MAP ------------------------

            //Scale Bar widget
            const scalebar = new ScaleBar({
                view: view,
                unit: "metric",
            });
            view.ui.add(scalebar, {
                position: "bottom-right",
                index: 0,
            });
        }
    );
    return { map, view, layerListWidget };
};

/** Get the geometry type (eg. "Point"/"Polygon"/etc) corresponding to the field of an object having a valid GeoJSON */
export const getNameTypeOfGeometry = (dataFetched, potentialGeometries) => {
    let nameTypeGeometry = potentialGeometries.filter(
        e => dataFetched[e] && dataFetched[e].GeoJSON !== null
    )[0];
    return nameTypeGeometry;
};

/** Get the GeoJSON from the valid geometry type */
export const getTypeOfGeometry = (dataFetched, potentialGeometries) => {
    let typeGeometry = getNameTypeOfGeometry(dataFetched, potentialGeometries);
    return dataFetched[typeGeometry];
};

/** Generate a GraphicLayer from a list of geometries and properties for this layer */
export const generatingLayerOfGraphics = async (graphics, layerProperties) => {
    const [GraphicsLayer] = await loadModules(["esri/layers/GraphicsLayer"]);
    const graphicsLayer = await new GraphicsLayer(layerProperties);
    const addingGraphics = async () => {
        graphics.geometries.forEach(async e => {
            const geometryFromType = getTypeOfGeometry(e, [
                "Polygon",
                "Point",
                "Extent",
            ]);
            geometryFromType &&
                graphicsLayer.add(
                    await sridGeoJSONToWebArcgisGraphic(
                        geometryFromType,
                        e.graphicShape ?? graphics.graphicShape ?? {},
                        e.attributes ??
                            graphics.attributes ?? {
                                Name: e.Name,
                                Description: e.Value && `Value: ${e.Value}`,
                            },
                        e.template
                    )
                );
        });
    };
    await addingGraphics();

    return graphicsLayer;
};

/** Generate all layers (GroupLayer and GraphicLayer) based on a discriptive list of Layers */
export const managingLayerGeneration = async (
    layerList,
    parent,
    layerListWidget,
    setCanRefresh
) => {
    const [GroupLayer] = await loadModules(["esri/layers/GroupLayer"]);

    for (const layerItem of layerList) {
        switch (layerItem.layerType) {
            case "group":
                if (isArrayLength(layerItem.layers)) {
                    const groupLayer = await new GroupLayer(
                        layerItem.groupProperties
                    );
                    await managingLayerGeneration(
                        layerItem.layers,
                        groupLayer,
                        layerListWidget,
                        setCanRefresh
                    );
                    await parent.add(groupLayer);
                    await reOrderingLayer(groupLayer, parent, layerItem?.order);
                    if (layerItem.open) {
                        groupLayer.on("layerview-create", () => {
                            layerListWidget.operationalItems.items.find(
                                listItem => listItem.layer === groupLayer
                            ).open = true;
                        });
                    }
                    if (layerItem.selectionFilterWatch) {
                        groupLayer.watch("visible", () => {
                            setCanRefresh(true);
                        });
                    }
                }
                break;

            default:
                const addedLayerItem = await generatingLayerOfGraphics(
                    layerItem.graphics,
                    layerItem.layerProperties
                );
                await parent.add(addedLayerItem);
                await reOrderingLayer(addedLayerItem, parent, layerItem?.order);
                if (layerItem.selectionFilterWatch) {
                    addedLayerItem.watch("visible", () => setCanRefresh(true));
                }
        }
    }
};

/** Get only the geometries of Points and Polygons with a valid GeoJSON. Has a callback to add information per geometries if needed */
export const getCleanGeometries = (data, addToGeometry = () => ({})) => {
    const pointGeometries = data
        .filter(e => e.Point.GeoJSON !== null)
        .map(e => {
            const { Polygon, Point, ...rest } = e;
            return { ...rest, Extent: Point, ...addToGeometry(e) };
        });

    const polygonGeometries = data
        .filter(e => e.Polygon.GeoJSON !== null)
        .map(e => {
            const { Polygon, Point, ...rest } = e;
            return { ...rest, Extent: Polygon, ...addToGeometry(e) };
        });

    return [...pointGeometries, ...polygonGeometries];
};

/** Reorder a specific layer to an index */
export const reOrderingLayer = async (layerToMove, parentToReorder, index) => {
    if (index) await parentToReorder.reorder(layerToMove, index);
};

/** Create a random color */
export const createRandomColor = () => {
    const selectColor = (colorNum, colors) => {
        if (colors < 1) colors = 1;
        return "hsla(" + ((colorNum * (360 / colors)) % 360) + ",100%,80%,1)";
    };

    return selectColor(Math.floor(Math.random() * 10), 10);
};

/** Get a default style of the shape of a graphic based on a color */
export const graphicShaper = color =>
    color ? { ...basicShape, color } : basicShape;

/** Implementating MapImageLayers for the GIS layers of Total GIS server */
export const manageServerLayers = async (folder, link, map) => {
    const [MapImageLayer] = await loadModules(["esri/layers/MapImageLayer"]);

    if (folder.length === 0) {
        toastr.error(t("map.errorSIG"));
    } else {
        for (const file of folder) {
            const url = `${link}/${file}`;
            if (file.includes("MapServer")) {
                const serverServicesLayers = await new MapImageLayer({
                    url,
                });

                try {
                    await serverServicesLayers.load();
                    const translatedMapTitle =
                        mapServerTranslator[
                            serverServicesLayers.sourceJSON.mapName
                        ];
                    if (translatedMapTitle) {
                        serverServicesLayers.title = t(translatedMapTitle);
                    }
                    await map.add(serverServicesLayers);
                } catch (error) {
                    toastr.error(
                        t("map.errorLayerSIG", {
                            layerName: serverServicesLayers.title,
                        })
                    );
                    continue;
                }
            } else {
                continue;
            }
        }
    }
};

/** Apply the user preferences for the opacity, order and visibilty of the different layers */
export const applyUserPrefs = async (map, mapPrefs) => {
    const applyLayerPref = async (levelMapLayers, parentLayer = false) => {
        for (const p of mapPrefs) {
            const layer = levelMapLayers.find(e => e.title === p.title);
            if (Boolean(layer)) {
                layer.opacity = p.opacity;
                layer.visible = p.visibility;

                if (parentLayer) {
                    parentLayer.reorder(layer, p.order);
                }
            }
        }
    };
    await applyLayerPref(map.layers.items, map);
};

/** Custom Component for adding a button on the map */
export const CustomMapButton = ({ id, title, icon, className, ...props }) => {
    return (
        <div
            style={{ visibility: "hidden", display: "none", minWidth: "32px" }}
            role="button"
            className={`esri-component esri-widget--button esri-widget customButton ${className}`}
            id={id}
            title={title}
            {...props}
        >
            <span title={title} id={id + "-span"} className={icon}></span>
        </div>
    );
};

/** Custom Component for adding a button with a Text on the map */
export const CustomMapButtonText = ({
    id,
    title,
    icon,
    className,
    children,
    disabled,
    ...props
}) => {
    return (
        <button
            style={{
                visibility: "hidden",
                display: "none",
                minWidth: "90px",
                color: !disabled ? "#2e2e2e" : "rgba(16, 16, 16, 0.3)",
                cursor: !disabled ? "pointer" : "not-allowed",
                border: "none",
            }}
            disabled={disabled}
            className={`esri-component esri-widget--button esri-widget customButton ${className}`}
            id={id}
            title={title}
            {...props}
        >
            <span title={title} id={id + "-span"} className={icon}></span>
            {children}
        </button>
    );
};

/** Custom Component for adding a custom made legend on top of the Arcgis Legend widget */
export const CustomMapLegend = ({ legend }) => (
    <Box
        id="legend"
        className="esri-popup__main-container esri-widget esri-popup--shadow"
        sx={{ padding: 1, visibility: "hidden" }}
    >
        {isArrayLength(legend) && (
            <>
                <Box sx={{ paddingX: 1 }}>
                    {legend.map((group, index) => (
                        <Box key={group.groupLegendLabel + index}>
                            <Typography
                                sx={{
                                    margin: 1,
                                    borderBottom: "1px grey solid",
                                }}
                                className="esri-widget__heading esri-legend__service-label"
                            >
                                {group.groupLegendLabel}
                            </Typography>
                            {isArrayLength(group.layersLegend) &&
                                group.layersLegend.map((e, i) => (
                                    <Box
                                        key={i}
                                        sx={{ display: "flex", margin: 1 }}
                                    >
                                        <Box
                                            id={`symbol${e.label}`}
                                            sx={{ marginRight: 1 }}
                                        ></Box>
                                        <Box>{e.label} </Box>
                                    </Box>
                                ))}
                        </Box>
                    ))}
                    <Typography
                        sx={{ margin: 0, borderBottom: "1px grey solid" }}
                        className="esri-widget__heading esri-legend__service-label"
                    >
                        {t("map.mapServerLayers")}
                    </Typography>
                </Box>
            </>
        )}
    </Box>
);

/** Get the list of layers open in the LayerList widget of Arcgis */
export const getLayersOpenInLayerList = parentLayer => {
    let layersOpenArr = [];
    const getChildrenLayersOpen = (parentLayer, arr) => {
        for (const sublayer of parentLayer) {
            arr.push({
                title: sublayer.title,
                open: sublayer.open,
            });
            if (sublayer.children.items.length > 0)
                getChildrenLayersOpen(sublayer.children.items, arr);
        }
    };

    getChildrenLayersOpen(parentLayer, layersOpenArr);
    return layersOpenArr;
};

/** Get the user preference (opacity, visibility, order) for each layer in the LayerList widget of Arcgis */
export const getUserPrefPerLayer = (parentLayer, layersOpenInLayerList) => {
    let userPrefs = [];
    const fillUserPrefs = (parentLayer, arr) => {
        parentLayer.forEach((e, i) => {
            arr.push({
                id: e.id,
                opacity: e.opacity,
                visibility: e.visible,
                open: layersOpenInLayerList.find(l => l.title === e.title)
                    ?.open,
                title: e.title,
                parent: e.parent,
                order: i,
            });
            if (e.sublayers) fillUserPrefs(e.sublayers.items, arr);
        });
    };

    fillUserPrefs(parentLayer, userPrefs);
    return userPrefs;
};

/** Give a style to the button base on if it is selected or not */
const buttonSelectedStyle = (button, isSelected) => {
    button.firstChild.style.color = isSelected ? "white" : "#6e6e6e";
    button.style.backgroundColor = isSelected ? "#12A2FE" : "white";
};

/** User preference button widget on map, to save the user preferences for the opacity, order and visibilty of the different layers in Redux */
export const addUserPreferenceWidget = (
    map,
    view,
    layerListWidget,
    updateUserPref
) => {
    const saveButton = document.getElementById("SaveButton");
    saveButton.addEventListener("click", () => {
        try {
            const layersOpenInLayerList = getLayersOpenInLayerList(
                layerListWidget.operationalItems.items
            );
            const userPrefPerLayer = getUserPrefPerLayer(
                map.layers.items,
                layersOpenInLayerList
            );
            updateUserPref(userPrefPerLayer);
            toastr.success("Map preferences saved");
        } catch (error) {
            console.error(error);
            toastr.error(
                "Impossible to save map preferences. Please try again later"
            );
        }
    });
    saveButton.style.visibility = "visible";
    saveButton.style.display = "flex";
    view.ui.add(saveButton, {
        position: "top-right",
    });
};

/** Selection button and widget to select objects on the map and send back the selection */
export const addSelectionWidget = async (
    map,
    view,
    groupFilterSelection,
    setSelectionGeometryAndFilter,
    setCanRefresh,
    existantSelection
) => {
    const [GraphicsLayer, SketchViewModel, Graphic] = await loadModules([
        "esri/layers/GraphicsLayer",
        "esri/widgets/Sketch/SketchViewModel",
        "esri/Graphic",
    ]);
    const polygonStyle = {
        type: "simple-fill",
        color: [24, 237, 205, 0.4],
        outline: {
            color: "#0B6E5F",
            width: 2,
            style: "dash",
        },
    };
    const graphicsLayer = await new GraphicsLayer({
        listMode: "hide",
        id: "selectionCustomLayer",
    });
    await map.add(graphicsLayer);

    const sketchViewModel = new SketchViewModel({
        view: view,
        layer: graphicsLayer,
    });

    sketchViewModel.polygonSymbol = polygonStyle;
    sketchViewModel.on("create", async event => {
        if (event.state === "complete") {
            const GeometryWithSRID = await arcgisGeometryToSRIDGeoJSON(
                event.graphic.geometry
            );
            setSelectionGeometryAndFilter({
                GeometryWithSRID,
                Filters: createFilterSelection(groupFilterSelection, map),
            });
            setCanRefresh(false);
            buttonSelectedStyle(selectButton, false);
            refreshSelectionButton.style.display = "flex";
        }
        if (event.state === "cancel") {
            buttonSelectedStyle(selectButton, false);
            refreshSelectionButton.style.display = "none";
        }
    });

    let isDeleted = false;
    sketchViewModel.on("update", async event => {
        buttonSelectedStyle(selectButton, true);
        if (event.state === "complete") {
            if (!isDeleted) {
                setCanRefresh(true);
            }
            buttonSelectedStyle(selectButton, false);
            isDeleted = false;
        }
    });
    sketchViewModel.on("delete", event => {
        refreshSelectionButton.style.display = "none";
        setSelectionGeometryAndFilter(gf => ({
            ...gf,
            GeometryWithSRID: null,
        }));
        setCanRefresh(false);
        isDeleted = true;
    });

    const selectButton = document.getElementById("SelectionButton");
    const refreshSelectionButton = document.getElementById(
        "RefreshSelectionButton"
    );

    managingOtherEditionButtonBehaviour("MapEditionTool", selectButton);

    selectButton.addEventListener("click", () => {
        buttonSelectedStyle(selectButton, true);
        graphicsLayer.removeAll();
        setSelectionGeometryAndFilter(gf => ({
            ...gf,
            GeometryWithSRID: null,
        }));
        view.popup.close();
        if (sketchViewModel.state === "active") {
            sketchViewModel.cancel();
            buttonSelectedStyle(selectButton, false);
            refreshSelectionButton.style.display = "none";
        } else {
            sketchViewModel.create("polygon");
        }
    });

    refreshSelectionButton.addEventListener("click", async () => {
        const GeometryWithSRID = await arcgisGeometryToSRIDGeoJSON(
            graphicsLayer.graphics.items[0].geometry
        );
        setSelectionGeometryAndFilter({
            GeometryWithSRID,
            Filters: createFilterSelection(groupFilterSelection, map),
        });
        setCanRefresh(false);
    });

    if (Boolean(existantSelection)) {
        refreshSelectionButton.style.display = "flex";
        const geometry = await sridGeoJSONToWebArcgisGeometry(
            existantSelection
        );
        const newTextGraphic = new Graphic({
            geometry,
            symbol: polygonStyle,
        });
        graphicsLayer.add(newTextGraphic);
    }
    view.ui.add(selectButton, "bottom-left");
    view.ui.add(refreshSelectionButton, "bottom-left");
    selectButton.style.visibility = "visible";
    refreshSelectionButton.style.visibility = "visible";
    selectButton.style.display = "flex";
};

/** Drawing widget to draw shapes on the map */
export const addDrawingWidget = async (map, view) => {
    const [Sketch, GraphicsLayer] = await loadModules([
        "esri/widgets/Sketch",
        "esri/layers/GraphicsLayer",
    ]);

    //Layer for the graphics to edit
    const graphicsLayer = await new GraphicsLayer({
        listMode: "hide",
        id: "drawingCustomLayer",
    });
    await map.add(graphicsLayer);

    //Edition (Sketch) widget
    const sketch = new Sketch({
        layer: graphicsLayer,
        id: "drawingWidget",
        view: view,
        creationMode: "single",
        visibleElements: {
            selectionTools: {
                "lasso-selection": false,
                "rectangle-selection": false,
            },
            settingsMenu: false,
            undoRedoMenu: false,
        },
        visible: false,
    });
    sketch.viewModel.pointSymbol = { ...extentShape, type: "simple-marker" };
    sketch.viewModel.polylineSymbol = {
        color: [237, 0, 0],
        width: 4,
        type: "simple-line",
    };
    sketch.viewModel.polygonSymbol = { ...extentShape, type: "simple-fill" };

    sketch.on("update", async event => {
        if (event.state === "start") {
            buttonSelectedStyle(drawingButton, true);
            sketch.visible = true;
        }
    });

    const drawingButton = document.getElementById("DrawingButton");

    managingOtherEditionButtonBehaviour("MapEditionTool", drawingButton, () => {
        sketch.viewModel.cancel();
        sketch.visible = false;
    });
    drawingButton.addEventListener("click", () => {
        sketch.visible = !sketch.visible;
        buttonSelectedStyle(drawingButton, sketch.visible);
        if (sketch.visible) {
            sketch.viewModel.create("polygon");
        } else {
            sketch.viewModel.cancel();
        }
    });
    drawingButton.style.visibility = "visible";
    drawingButton.style.display = "flex";
    view.ui.add(drawingButton, "bottom-left");
    view.ui.add(sketch, { position: "bottom-left", index: 5 });
};

/** Comments widget to write texts on the map */
export const addCommentWidget = async view => {
    const textSymbolConfig = {
        color: [255, 255, 255],
        haloColor: [0, 0, 0],
        haloSize: 2,
        font: {
            family: "Arial Unicode MS",
            size: 12,
        },
    };

    const [SketchViewModel, Graphic, GraphicsLayer, TextSymbol] =
        await loadModules([
            "esri/widgets/Sketch/SketchViewModel",
            "esri/Graphic",
            "esri/layers/GraphicsLayer",
            "esri/symbols/TextSymbol",
        ]);
    const layer = new GraphicsLayer({
        listMode: "hide",
        id: "commentCustomLayer",
    });
    await view.map.add(layer);

    const sketchVM = new SketchViewModel({
        view,
        layer,
        updateOnGraphicClick: true,
        id: "commentWidget",
    });
    sketchVM.on("create", event => {
        if (event.state === "complete") {
            layer.remove(event.graphic);
            const newTextGraphic = new Graphic({
                geometry: event.graphic.geometry,
                symbol: { type: "text", text: "Text", ...textSymbolConfig },
            });
            layer.add(newTextGraphic);
            buttonSelectedStyle(commentButton, false);
        }
        if (event.state === "cancel") {
            buttonSelectedStyle(commentButton, false);
        }
    });

    sketchVM.on(["update"], function (event) {
        commentInput.style.visibility = "visible";
        buttonSelectedStyle(commentButton, true);
        let currentGraphic = event.graphics[0];
        if (event.state === "complete") {
            commentInput.style.visibility = "hidden";
            buttonSelectedStyle(commentButton, false);
            currentGraphic.symbol = new TextSymbol({
                text: commentInput.value,
                ...textSymbolConfig,
            });
        }
        if (event.state === "start") {
            if (currentGraphic.symbol.type === "text") {
                let newTextSymbol = currentGraphic.symbol;
                commentInput.value = newTextSymbol.text;
            }
        }
    });

    const commentButton = document.getElementById("CommentButton");

    managingOtherEditionButtonBehaviour("MapEditionTool", commentButton, () => {
        sketchVM.cancel();
        commentInput.style.visibility = "hidden";
    });
    commentButton.addEventListener("click", () => {
        //Activating or de-activating comment widget
        if (sketchVM.state === "active") {
            sketchVM.cancel();
            buttonSelectedStyle(commentButton, false);
        } else {
            sketchVM.create("point");
            buttonSelectedStyle(commentButton, true);
        }
    });
    const commentInput = document.getElementById("commentInput");
    commentInput.addEventListener("keyup", event => {
        if (event.key === "Enter") {
            event.preventDefault();
            sketchVM.complete();
        }
    });

    commentButton.style.visibility = "visible";
    commentButton.style.display = "flex";
    commentInput.style.display = "block";
    view.ui.add([commentButton, commentInput], "bottom-left");
};

/** Measurement widgets to measure distances/Areas/Perimeters on the map */
export const addMeasurementWidgets = async view => {
    const [AreaMeasurement2D, DistanceMeasurement2D] = await loadModules([
        "esri/widgets/AreaMeasurement2D",
        "esri/widgets/DistanceMeasurement2D",
    ]);
    const distanceButton = document.getElementById("distanceButton");
    const areaButton = document.getElementById("areaButton");

    let distanceButtonActive = false;
    let areaActive = false;
    const distanceMeasurement = new DistanceMeasurement2D({
        view: view,
        visible: false,
    });
    const areaMeasurement = new AreaMeasurement2D({
        view: view,
        visible: false,
    });
    distanceButton.style.visibility = "visible";
    distanceButton.style.display = "flex";
    areaButton.style.visibility = "visible";
    areaButton.style.display = "flex";
    view.ui.add([areaButton, distanceButton], {
        position: "bottom-right",
        index: 1,
    });
    view.ui.add([distanceMeasurement, areaMeasurement], {
        position: "bottom-right",
        index: 3,
    });

    distanceButton.addEventListener("click", () => {
        setMeasurementWidget(
            distanceButtonActive,
            distanceMeasurement,
            areaMeasurement
        );
        distanceButtonActive = !distanceButtonActive;
        areaActive = false;
    });

    areaButton.addEventListener("click", function () {
        setMeasurementWidget(areaActive, areaMeasurement, distanceMeasurement);
        areaActive = !areaActive;
        distanceButtonActive = false;
    });

    const setMeasurementWidget = (
        buttonActive,
        activeWidget,
        oppositeWidget
    ) => {
        if (!buttonActive) {
            activeWidget.viewModel.start();
            activeWidget.visible = true;
            view.focus();
        } else {
            activeWidget.visible = false;
            activeWidget.viewModel.clear();
        }
        oppositeWidget.visible = false;
        oppositeWidget.viewModel.clear();
    };
};

/** Adapt styles and actions to the other custom made buttons on the map based on a specific button */
const managingOtherEditionButtonBehaviour = (
    classOfInterest,
    buttonExcepted,
    callback = () => {
        return;
    }
) => {
    [...document.getElementsByClassName(classOfInterest)]
        .filter(e => e !== buttonExcepted)
        .forEach(node =>
            node.addEventListener("click", () => {
                buttonSelectedStyle(buttonExcepted, false);
                callback();
            })
        );
};

/** Create the correct filtering string to apply to our request url to the back-end based on the layers visibility on the map.
(e.g.if a layer is not visible, we shouldn't be able to fetch the stations related to this layer when selecting an area on the map. Thus, we must filter these stations out. ) */
const createFilterSelection = (groupFilterSelection, currentMap) => {
    const filterSelection = currentMap.layers.items
        .filter(e => groupFilterSelection.includes(e.id))
        .map(e => ({
            route: e.routeSelectionFilter,
            visible: e.visible,
            children: e.layers.items.map(sl => ({
                visible: sl.visible,
                id: sl.id,
            })),
        }));

    const emptyFilterSelection = !isArrayLength(filterSelection);
    if (emptyFilterSelection) {
        return "";
    }

    const hasParentsVisible = isArrayLength(
        filterSelection.filter(e => e.visible)
    );
    if (!hasParentsVisible) {
        return r.filter.genericFilter("MapEmptyFilterSelection", true);
    }

    const allStationsVisible = isArrayLength(
        filterSelection.filter(e => {
            const children = e.children;
            const childrenVisible = children
                .filter(e => e.visible)
                .map(e => e.id);
            const allChildrenVisible =
                childrenVisible.length === children.length;
            return (
                e.route === "StationRelated" && e.visible && allChildrenVisible
            );
        })
    );
    if (allStationsVisible) {
        return "";
    }

    let countInvisibleFilters = 0;
    let filterString = "";
    filterSelection.forEach((filter, index) => {
        if (filter.visible) {
            const children = filter.children;
            const childrenVisible = children
                .filter(e => e.visible)
                .map(e => e.id);

            if (isArrayLength(childrenVisible)) {
                filterString += r.filter.genericFilter(
                    filter.route,
                    childrenVisible,
                    index !== 0 && Boolean(filterString)
                );
                return;
            }
            countInvisibleFilters += 1;
            return;
        }
        countInvisibleFilters += 1;
    });

    if (filterSelection.length === countInvisibleFilters) {
        filterString = r.filter.genericFilter("MapEmptyFilterSelection", true);
    }
    return filterString;
};

/** Object to translate the names of the Gis layers from Total Gis server */
export const mapServerTranslator = {
    LAYER_PICTURE: "map.mapServerLayersTypes.LAYER_PICTURE",
    LAYER_PLAN: "map.mapServerLayersTypes.LAYER_PLAN",
    LAYER_TOPO: "map.mapServerLayersTypes.LAYER_TOPO",
    LAYER_PIPELINES: "map.mapServerLayersTypes.LAYER_PIPELINES",
    LAYER_MISC: "map.mapServerLayersTypes.LAYER_MISC",
    LAYER_CADASTRE: "map.mapServerLayersTypes.LAYER_CADASTRE",
    LAYER_ZONE: "map.mapServerLayersTypes.LAYER_ZONE",
};

/** Specific styles to apply to the different station on the map based on their type */
export const arcgisPerStationType = [
    {
        route: "StationAir",
        graphicShape: {
            ...graphicShaper("#838b8e"),
            style: "diamond",
        },
        subTypesGraphicShaper: () => ({}),
    },

    {
        route: "StationNoise",
        graphicShape: {
            outline: {
                color: "#23d3ff",
                width: 4,
            },
            style: "cross",
        },
        subTypesGraphicShaper: () => ({}),
    },

    {
        route: "StationSedimentWater",
        graphicShape: {},
        subTypesGraphicShapes: {
            SED: {
                label: "field.StationSediment",
                symbol: {
                    outline: {
                        color: "#ff9000",
                        width: 4,
                    },
                    style: "x",
                },
            },
            WAT: {
                label: "field.StationWater",
                symbol: {
                    ...graphicShaper("#276AE8"),
                    style: "circle",
                },
            },
            WAS: {},
        },
        get subTypesGraphicShaper() {
            return stationSW => ({
                graphicShape:
                    this.subTypesGraphicShapes[stationSW.SubType_select.Key]
                        .symbol,
            });
        },
        get legend() {
            return Object.values(this.subTypesGraphicShapes)
                .filter(e => isArrayLength(Object.keys(e)))
                .map(e => ({ ...e, label: t(e.label) }));
        },
    },
    {
        route: "StationBiodiversity",
        graphicShape: {
            ...graphicShaper("#62ED18"),
            style: "triangle",
        },
        subTypesGraphicShaper: () => ({}),
    },
    {
        route: "StationSoilGroundwater",
        graphicShape: {
            ...graphicShaper("#994E2E"),
            style: "square",
        },
        subTypesGraphicShaper: stationSGW => {
            if (stationSGW.SubType_select) {
                const subTypes = stationSGW.SubType_select.Key;
                const stationSGWShaper = color => ({
                    graphicShape: {
                        ...graphicShaper(color),
                        style: "square",
                    },
                });
                switch (subTypes) {
                    case "PZ":
                    case "WPW":
                        return stationSGWShaper("#994E2E");

                    case "P":
                    case "GS":
                    case "SBH":
                        return stationSGWShaper("#852800");

                    case "SVEIW":
                    case "WEIW":
                        return stationSGWShaper("#D16B3F");

                    case "UNKNOWN":
                    case "PP":
                        return stationSGWShaper("#521800");

                    case "SVMW":
                        return stationSGWShaper("#BA3800");

                    default:
                        return {};
                }
            }
            return {};
        },
    },
];
