import React, { useCallback, useState, useEffect } from "react";
import { Box, Grid, Link, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { t } from "react-i18nify";
import { useSelector } from "react-redux";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { BasicButton } from "../Widgets/Custom Inputs/Buttons";
import { useDropzone } from "react-dropzone";
import SharedSettings from "../../common/sharedSettings.json";
import { mimeTypeCategories } from "./DocumentView/cst_DocumentsView";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import MediaChunksUploaderDialog from "./DocumentView/MediaChunksUploaderDialog";
import { isArrayLength } from "../../js/utils/genericMethods";
import { downloadFile, fetchData } from "../../js/utils/backend";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { toastr } from "react-redux-toastr";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import Axios from "axios";
import { uuidv4 } from "../../js/redux/actions/syncTab";
export const isPhotoVideo = "PhotoVideo";
export const isDocuments = "Documents";


const HelpPageView = ({ route = "OTEntity" }) => {

    const isUploadDisabled = false;
    const { fileType } = useParams();
    const [editMode, setEditMode] = useState(false);
    const [mediaCategory, setMediaCategory] = useState("");
    const [mediaLanguage, setMediaLanguage] = useState("");
    const [isExternal, setIsExternal] = useState(false);
    const onHoverDisableDropzone = false;

    const [media, setMedia] = useState([]);
    const currentDocument = null;
    const [uploadedFile, setUploadedFile] = useState();

    const token = useSelector(state => state.auth.Token);
    const [skipDocument, setSkipDocument] = useState(null);
    console.log(skipDocument)

    const mediaIsUploaded = uploadedFile?.MediaID && !uploadedFile?.Replacing;

    const { HoldingManager, External } = useSelector(state => state.auth);
    const entity = useSelector(state => state.userPref.CurrentEntity);
    const [branchManagers, setBranchManagers] = useState(null);

    const hasBranchManagers = branchManagers &&
    (branchManagers.some(bm => bm.BranchCode === "EP") ||
     branchManagers.some(bm => bm.BranchCode === "RC" || bm.BranchCode === "RETIA") ||
     branchManagers.some(bm => bm.BranchCode === "MS") ||
     branchManagers.some(bm => bm.BranchCode === "GRP"));

    useEffect(() => {
        const fetching = async () => {
            const entityFetched = await fetchData(route, token, "ID", entity?.ObjectID);
            setBranchManagers(entityFetched.data.EntityContacts.BranchManagers);
        }
        fetching();
    }, [route, token, entity?.ObjectID]);

    function fileTypeValidator(file) {
        if (fileType === isDocuments) {
            if (file.type.includes("image") || file.type.includes("video")) {
                const allowedDocuments = Object.values(mimeTypeCategories.Documents)
                console.log("")
                return {
                    code: "wrong-document-file",
                    message: t("input.validation.WrongDocumentExtension", {
                        value: allowedDocuments
                    }),
                };
            }
        } else if (fileType === isPhotoVideo) {
            if (!file.type.includes("image") && !file.type.includes("video")) {
                const allowedPhotoVideo = Object.values(mimeTypeCategories.PhotoVideo)
                return {
                    code: "wrong-photovideo-file",
                    message: t("input.validation.WrongPhotoVideoExtension", {
                        value: allowedPhotoVideo
                    }),
                };
            }
        }
    }

    const onDrop = useCallback(async acceptedFiles => {
        let sortedArray = null;
        sortedArray = acceptedFiles?.sort((a, b) =>
            a.name.toString().localeCompare(b.name)
        );
        if (sortedArray.length > 1) {
            // We'll upload several files, but before we'll add batch metadata (BatchMetadataDialog)
            // setTriggerBatchMetadataDialog(sortedArray);
            // setOpenBatchMetadataDialog(true);
        } else {
            // We'll upload only one file
            // setTriggerEditDialog(true);
            setMedia(sortedArray);
        }
    }, []);

    const { open } = useDropzone({
        maxFiles: SharedSettings.MaxFilesToUpload,
        maxSize: 2147483648, // 2GB
        multiple: true,
        noClick: true,
        noKeyboard: true,
        onDrop,
        label: null,
        validator: fileTypeValidator, 
        disabled: isUploadDisabled || onHoverDisableDropzone,
    });

    const useStyles = makeStyles(theme => ({
        document: {
            width: 200,
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: theme.palette.grey[100]
            }
        }
    }));

    const Document = ({ title, mediaCategory, mediaLanguage, isExternal }) => {
        const classes = useStyles();
        return (
            editMode ?
                <Box
                    className={classes.document}
                    onClick={open}
                    onMouseOver={() => {
                        setMediaCategory(mediaCategory);
                        setMediaLanguage(mediaLanguage);
                        setIsExternal(isExternal);
                    }}
                >
                    <CloudUploadIcon sx={{ typography:'subtitleMajor' }}/>   {title}
                </Box>
                :
                <Box
                    className={classes.document}
                    onClick={async () => {
                        const helpDocumentExist = await Axios.get(`media/helpDocumentExist/${mediaCategory}/${mediaLanguage}/${isExternal}`, {
                            headers: { Authorization: "Bearer " + token },
                        });
                        if(helpDocumentExist.data){
                            downloadFile(
                                token,
                                "downloadHelpDocument",
                                `/${mediaCategory}/${mediaLanguage}/${isExternal}`
                            );
                        }
                        else{
                            toastr.error(t("view.helpPage.contact.NoFileToDownload"));
                        }
                    }}
                >
                    <ArrowForwardIosIcon sx={{ fontSize:"14px" }}/> {title}
                </Box>
        )
    };

    const DocumentsContainer = ({ isExternal }) => {
        return (
            <>
                {!isExternal ?
                    <Box sx={{ textAlign:"center", typography:"subtitle2", border:"solid 1px", borderRadius:"3px", mb:3 }}>
                        {t("view.helpPage.InternalUsers")}
                    </Box>
                    :
                    (!External && <Box sx={{ textAlign:"center", typography:"subtitle2", border:"solid 1px", borderRadius:"3px", mb:3 }}>
                        {t("view.helpPage.ExternalUsers")}
                    </Box>)
                }
                <Box sx={{ display:"flex", flexDirection:"column", mb:4 }}>
                    <Box sx={{ fontWeight:"600", mb:2 }}>
                        <Box sx={{ typography:"subtitle2" }}>
                            <img src={require('../../assets/images/france_flag.png')} width="20" height="13" alt=""/>
                            <span> </span>{t("languages.French")}
                        </Box>
                    </Box>
                    <Box sx={{ typography:"body1" }}>
                        <Document title="Guide d'utilisateur" mediaCategory="UserGuide" mediaLanguage="French" isExternal={isExternal}/>
                        <Document title="Support de formation" mediaCategory="Training" mediaLanguage="French" isExternal={isExternal}/>
                        <Document title="Onboarding" mediaCategory="Onboarding" mediaLanguage="French" isExternal={isExternal}/>
                    </Box>
                </Box>
                <Box sx={{ display:"flex", flexDirection:"column", mb:5 }}>
                    <Box sx={{ fontWeight:"600", mb:2}}>
                        <Box sx={{ typography:"subtitle2" }}>
                            <img src={require('../../assets/images/uk_flag.jpg')} width="20" height="13" alt=""/>
                            <span> </span>{t("languages.English")}
                        </Box>
                    </Box>
                    <Box sx={{ typography:"body1" }}>
                        <Document title="User guide" mediaCategory="UserGuide" mediaLanguage="English" isExternal={isExternal}/>
                        <Document title="Training" mediaCategory="Training" mediaLanguage="English" isExternal={isExternal}/>
                        <Document title="Onboarding" mediaCategory="Onboarding" mediaLanguage="English" isExternal={isExternal}/>
                    </Box>
                </Box>
            </>
        )
    }

    const BranchManagersList = ({ contactType, branchName }) => (
        <Grid container xs={12}>
            <Grid xs={12}>
                <Box sx={{ typography:'body1', ml:1 }}><strong>{branchName}:</strong></Box>
            </Grid>
            {contactType.map(contact => (
                <Grid item xs={12} lg={4} xl={4} p={1} key={uuidv4()}>
                    <Paper sx={{ p: 2, width: "auto" }} elevation={3}>
                        <Grid>
                            <Grid
                                item
                                sx={{
                                    typography: "body1",
                                }}
                            >
                                {`${contact.Firstname} ${contact.Lastname}`}
                            </Grid>

                            {contact.EMail && (
                                <Grid
                                    item
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        typography: "body2",
                                    }}
                                >
                                    <EmailOutlinedIcon
                                        fontSize="small"
                                        sx={{ mr: 1 }}
                                    />
                                    <Link
                                        href={`mailto:${contact.EMail}`}
                                        underline="hover"
                                    >
                                        {contact.EMail}
                                    </Link>
                                </Grid>
                            )}

                            <Grid
                                item
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    typography: "body2",
                                }}
                            >
                                <BadgeOutlinedIcon
                                    fontSize="small"
                                    sx={{ mr: 1 }}
                                />
                                <Link
                                    href={
                                        process.env
                                            .REACT_APP_TOTAL_USER_URL +
                                        `/${contact.IGG}`
                                    }
                                    underline="hover"
                                    target="_blank"
                                >
                                    {contact.IGG}
                                </Link>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );

    return (
        <>
            {isArrayLength(media) && (
                <MediaChunksUploaderDialog
                    media={media}
                    setMedia={setMedia}
                    token={token}
                    setUploadedFile={e => setUploadedFile(e)}
                    currentDocument={currentDocument}
                    mediaIsUploaded={mediaIsUploaded}
                    setSkipDocument={e => setSkipDocument(e)}
                    fileType={fileType}
                    helpDocument={true}
                    mediaCategory={mediaCategory}
                    mediaLanguage={mediaLanguage}
                    isExternal={isExternal}
                />
            )}
            <Box sx={{ typography:"h3", p:2, ml:2 }}>
                <Box sx={{ mt:3 }}>{t("common.Help")}</Box>
            </Box>
            <Grid container>
                <Grid xs={11}>
                    <Box sx={{ mb:1, ml:3, display:"flex", justifyContent:"space-between" }}>
                        <Box sx={{ typography: "subtitleMajor", mt:2 }}>{t("view.helpPage.Documents")}</Box>
                        {HoldingManager && !External &&
                            (!editMode ? 
                                <BasicButton onClick={() => {setEditMode(true)}}>
                                    {t("common.Edit")}
                                </BasicButton>
                                :
                                <BasicButton onClick={() => {setEditMode(false)}}>
                                    {t("common.Back")}
                                </BasicButton>
                            )
                        }
                    </Box>
                </Grid>
                {!External &&
                    <Grid sm={10} md={5} sx={{ ml:3, mb:2 }}>
                        <Paper sx={{ p:1 }}>
                            <DocumentsContainer isExternal={false} />
                        </Paper>
                    </Grid>
                }
                {!External ?
                    <Grid sm={10} md={5} sx={{ ml:3 }}>
                        <Paper sx={{ p:1 }}>
                            <DocumentsContainer isExternal={true} />
                        </Paper>
                    </Grid>
                    :
                    <Grid sm={10} md={5} sx={{ ml:2 }}>
                        <Paper sx={{ p:1 }}>
                            <DocumentsContainer isExternal={true} />
                        </Paper>
                    </Grid>
                }
                <Paper sx={{ p:1, mb:5, ml:3, mr:2, mt:2 }}>
                    <Grid container sx={{ typography:"body1" }}>
                        <Grid xs={12} sx={{ typography: "subtitleMajor", mb:3 }}>
                            {t("view.helpPage.Contact")}
                        </Grid>
                        <Grid sm={10} md={5}>
                            {t("view.helpPage.contact.AnyTechnicalIssues")} ?<br />
                            <a href="https://itsm.hubtotal.net/now/nav/ui/classic/params/target/com.glideapp.servicecatalog_cat_item_view.do%3Fv%3D1%26sysparm_id%3D31e3a09c1bbd2590160cff31dd4bcb6d%26sysparm_link_parent%3D590d237c98be7100ea0759edd0555287%26sysparm_catalog%3De0d08b13c3330100c8b837659bba8fb4%26sysparm_catalog_view%3Dcatalog_default%26sysparm_view%3Dtext_search">
                                {t("view.helpPage.contact.PleaseClickHere")}
                            </a><br /><br />
                            {t("view.helpPage.contact.AnyFurtherFunctionalities")} ?<br />
                            <a href="https://itsm.hubtotal.net/now/nav/ui/classic/params/target/com.glideapp.servicecatalog_cat_item_view.do%3Fv%3D1%26sysparm_id%3D31e3a09c1bbd2590160cff31dd4bcb6d%26sysparm_link_parent%3D590d237c98be7100ea0759edd0555287%26sysparm_catalog%3De0d08b13c3330100c8b837659bba8fb4%26sysparm_catalog_view%3Dcatalog_default%26sysparm_view%3Dtext_search">
                                {t("view.helpPage.contact.PleaseReferToThisLink")}
                            </a><br /><br />
                        </Grid>
                        <Grid md={1}></Grid>
                        <Grid sm={10} md={5} sx={{ mb:6 }}>
                            {t("view.helpPage.contact.QuestionAboutTheApp")} ?<br />
                            support_onetsigane_hse@totalenergies.com<br /><br />
                            {t("view.helpPage.contact.WantToTrackAProblem")} ?<br />
                            luc.wendjaneh@external.totalenergies.com
                        </Grid>
                        {hasBranchManagers && 
                        (
                                <Grid xs={11}>
                                    {t("view.helpPage.contact.ForSupportAtBranchLevel")} :<br /><br />
                                </Grid>
                        )}
                        {branchManagers &&
                            <>  
                                {branchManagers.filter(bm => bm.BranchCode==="EP").length > 0 &&
                                    <BranchManagersList
                                        contactType={branchManagers.filter(bm => bm.BranchCode==="EP")}
                                        branchName={"EP"}
                                    />
                                }
                                {branchManagers.filter(bm => bm.BranchCode==="RC" || bm.BranchCode==="RETIA").length > 0 &&
                                    <BranchManagersList
                                        contactType={branchManagers.filter(bm => bm.BranchCode==="RC" || bm.BranchCode==="RETIA")}
                                        branchName={"RC & RETIA"}
                                    />
                                }
                                {branchManagers.filter(bm => bm.BranchCode==="MS").length > 0 &&
                                    <BranchManagersList
                                        contactType={branchManagers.filter(bm => bm.BranchCode==="MS")}
                                        branchName={"MS"}
                                    />
                                }
                                {branchManagers.filter(bm => bm.BranchCode==="GRP").length > 0 &&
                                    <BranchManagersList
                                        contactType={branchManagers.filter(bm => bm.BranchCode==="GRP")}
                                        branchName={"GRP"}
                                    />
                                }
                            </>
                        }
                    </Grid>
                </Paper>
            </Grid>
        </>
    );
};

export default HelpPageView;