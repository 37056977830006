import React, { useCallback, useEffect, useState } from "react";
import JSONtoTreeView from "../Widgets/JSONToTreeView";
import { Menu, Typography } from "@mui/material";
import * as r from "../../js/constants/routes";
import { ActionMenu } from "./TreeEditorComponents";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { useAxiosConfig } from "../../js/utils/customHooks";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { entityTreeDataMapperSwitch } from "../../routers/MenuRouter";
import { editModel } from "../../js/utils/backend";
import {
    updateEntityTree,
    updateSpecificNodeEntityTree,
} from "../../js/redux/actions/userPref";
import { t } from "react-i18nify";


const LabelHidden = ({ node }) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
            }}
        >
            <Typography
                sx={{
                    color: node.Hidden ? "GrayText" : "primary.contrastText",
                }}
                variant="body2"
            >
                {node.label}
            </Typography>
            {node.Hidden ? (
                <Typography sx={{ color: "GrayText" }} variant="body2">
                    {`\u00A0(${t("common.Hidden")})`}
                </Typography>
            ) : null}
        </Box>
    );
};

const EntityTreeVisibilityEditor = ({
    baseURL = r.oTEntityTree,
    JSONData,
    ...props
}) => {
    const { elementID } = useParams();
    const [label, setLabel] = useState("");
    const [contextMenuState, setContextMenuState] = useState(null);
    const [tree, setTree] = useState(JSONData);
    const [expanded, setExpanded] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const config = useAxiosConfig();

    const getLabel = useCallback(async () => {
        const response = await Axios.get(`${r.oTEntity}/${elementID}`, config);
        return `${t("common.Tree")} ${response.data.Name}: ${t(
            "grid.title.VisibilityEditor"
        )}`;
    }, [config, elementID]);

    useEffect(() => {
        let isSubscribed = true;
        const fetch = async () => {
            try {
                const response = await Axios.get(
                    `${baseURL}/otentitytrees/${elementID}?filter[getOnlyTemplates]`,
                    config
                );
                if (isSubscribed) {
                    setTree(
                        response.data.map(e => {
                            return {
                                ...entityTreeDataMapperSwitch(e, true, true, {
                                    renderLabel: (e, i) => (
                                        <LabelHidden node={e} />
                                    ),
                                }),
                            };
                        })
                    );
                    setIsLoading(false);
                    setLabel(await getLabel());
                }
            } catch (e) {
                console.error(e);
            }
        };
        if (isLoading) fetch();
        return () => (isSubscribed = false);
    }, [config, baseURL, getLabel, elementID, isLoading]);

    useEffect(() => {
        setTree(JSONData);
    }, [JSONData]);

    const handleClose = useCallback(() => {
        setContextMenuState(null);
    }, []);

    const handleContextMenu = useCallback((node, event) => {
        event.preventDefault();
        if (node.hasContextMenu !== false) {
            setContextMenuState(c =>
                c === null
                    ? {
                          mouseX: event.clientX - 2,
                          mouseY: event.clientY - 4,
                          node,
                      }
                    : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                      // Other native context menus might behave different.
                      // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                      null
            );
        }
    }, []);

    return (
        <>
            <Box
                sx={{
                    typography: "h3",
                    pt: 6,
                    pl: 5,
                    pb: 2,
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {label}
            </Box>
            {isLoading ? (
                <ScreenLoading />
            ) : (
                <Box sx={{ pl: 3, pb: 2, pr: 3 }}>
                    <JSONtoTreeView
                        JSONData={tree}
                        onContextMenu={handleContextMenu}
                        expanded={expanded}
                        onNodeToggle={(_event, exp) => {
                            setExpanded(exp);
                        }}
                        {...props}
                    />
                </Box>
            )}
            <VisibilityContextMenu
                contextMenuState={contextMenuState}
                handleClose={handleClose}
                setTree={setTree}
                setExpanded={setExpanded}
                URL={baseURL}
                refetch={() => setIsLoading(true)}
            />
        </>
    );
};

const VisibilityContextMenu = ({
    contextMenuState,
    handleClose,
    URL,
    refetch,
}) => {
    const token = useSelector(state => state.auth.Token);
    const dispatch = useDispatch();
    const hideAction = useCallback(() => {
        const { node } = contextMenuState;
        if (node.ParentNodeType === null) delete node.ParentNodeType;
        editModel({ ...node, Hidden: true }, node.ID, token, URL).then(() => {
            if (node.parentNode)
                dispatch(updateSpecificNodeEntityTree(node.parentNode.ID));
            else refetch();
            dispatch(updateEntityTree());
        });
        handleClose();
    }, [handleClose, contextMenuState, token, URL, dispatch, refetch]);
    const showAction = useCallback(() => {
        const { node } = contextMenuState;
        if (node.ParentNodeType === null) delete node.ParentNodeType;
        editModel({ ...node, Hidden: false }, node.ID, token, URL).then(() => {
            if (node.parentNode)
                dispatch(updateSpecificNodeEntityTree(node.parentNode.ID));
            else refetch();
            dispatch(updateEntityTree());
        });
        handleClose();
    }, [handleClose, contextMenuState, token, URL, dispatch, refetch]);
    return (
        <>
            {contextMenuState?.node ? (
                <Menu
                    onContextMenu={ev => {
                        ev.stopPropagation();
                        ev.preventDefault();
                    }}
                    open={contextMenuState !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenuState !== null
                            ? {
                                  top: contextMenuState.mouseY,
                                  left: contextMenuState.mouseX,
                              }
                            : undefined
                    }
                >
                    {contextMenuState?.node.Hidden ? (
                        <ActionMenu
                            action={showAction}
                            Icon={<VisibilityOutlinedIcon />}
                            label="Show"
                        />
                    ) : (
                        <ActionMenu
                            action={hideAction}
                            Icon={<VisibilityOffOutlinedIcon />}
                            label="Hide"
                        />
                    )}
                </Menu>
            ) : null}
        </>
    );
};

export default EntityTreeVisibilityEditor;
