import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { VSelectURL, VSelect } from "../../Widgets/Custom Inputs/Select";
import { FormProvider, useForm } from "react-hook-form";
import EditionPanelGlobal from "./EditionPanelGlobal";
import {
    HiddenField,
    VTextField,
} from "../../Widgets/Custom Inputs/CustomInputs";
import { PageSection } from "../../Widgets/CustomSurfaces";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/AddCircleOutlined";
import RequestIcon from "@mui/icons-material/AddTaskOutlined";
import { requestedAccessOptions } from "../../../js/constants/selectOptions";
import SendIcon from "@mui/icons-material/Send";
import { EntityEditionForm } from "./EntityCompleteEditionPanel";
import { t } from "react-i18nify";

// Previously, from NewUserView.js 

const NewRequestPanel = ({
    setForceGridRefresh,
    defaultMode = "",
    isInPannel = false,
    backAction,
}) => {
    const userID = useSelector(state => state.auth.UserID);

    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const { reset } = methods;
    const [mode, setMode] = useState(defaultMode);
    const handleChange = useCallback(
        (event, newMode) => {
            setMode(newMode);
        },
        [setMode]
    );
    useEffect(() => {
        reset({ RequestType: mode });
        setForceGridRefresh(fgr => !fgr);
    }, [mode, reset, setForceGridRefresh]);

    const onSubmit = useCallback(() => {
        setForceGridRefresh(fgr => !fgr);
        return setMode("");
    }, [setForceGridRefresh]);

    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "center", m: 1 }}>
                <ToggleButtonGroup
                    fullWidth
                    value={mode}
                    exclusive
                    color="secondary"
                    onChange={handleChange}
                    variant="contained"
                >
                    <ToggleButton fullWidth color="secondary" value="ADDUSER">
                        <RequestIcon sx={{ mr: 1 }} />
                        {t(`view.newUser.EntityAccessRequest`)}
                    </ToggleButton>
                    <ToggleButton fullWidth color="secondary" value="NEWENTITY">
                        <AddIcon sx={{ mr: 1 }} />{" "}
                        {t(`view.newUser.EntityCreateRequest`)}
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {mode === "ADDUSER" ? (
                <FormProvider {...methods}>
                    <PageSection>
                        <EditionPanelGlobal
                            route={"Request"}
                            submitButton={{
                                icon: <SendIcon />,
                                label: t("common.Request"),
                            }}
                            back={isInPannel}
                            onSubmit={onSubmit}
                            backAction={isInPannel ? backAction : undefined}
                        >
                            <HiddenField
                                fieldName={"RequestorID"}
                                defaultValue={userID}
                            />
                            <HiddenField
                                fieldName={"RequestType"}
                                defaultValue="ADDUSER"
                            />
                            <HiddenField
                                fieldName={"RequestorID"}
                                defaultValue={userID}
                            />
                            <Grid item xs={12}>
                                <VSelectURL
                                    label={t("field.Entity")}
                                    URL={`OTEntity/all`}
                                    paramURL={`Draft=false`}
                                    fieldName={"EntityID"}
                                    getOptionLabel={option => option.Name}
                                    validation={{
                                        required: {
                                            value: true,
                                            message: t(
                                                "input.validation.required"
                                            ),
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <VTextField
                                    label={t("field.Note")}
                                    fieldName={"Note"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <VSelect
                                    options={requestedAccessOptions.map(e => ({
                                        ...e,
                                        label: t(`select.${e.label}`),
                                    }))}
                                    label={t("field.RequestAccessType")}
                                    fieldName="RequestedAccess"
                                    validation={{
                                        required: {
                                            value: true,
                                            message: t(
                                                "input.validation.required"
                                            ),
                                        },
                                    }}
                                />
                            </Grid>
                        </EditionPanelGlobal>
                    </PageSection>
                </FormProvider>
            ) : null}
            {mode === "NEWENTITY" ? (
                <FormProvider {...methods}>
                    <PageSection>
                        <EditionPanelGlobal
                            route={"Request"}
                            submitButton={{
                                icon: <SendIcon />,
                                label: t("common.Request"),
                            }}
                            back={isInPannel}
                            onSubmit={onSubmit}
                            backAction={isInPannel ? backAction : undefined}
                        >
                            <EntityEditionForm viaRequest creatingViaRequest />
                        </EditionPanelGlobal>
                    </PageSection>
                </FormProvider>
            ) : null}
        </>
    );
};

export default NewRequestPanel;
