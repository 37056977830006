import React, { useCallback, useState, useEffect } from "react";
import {
    Box,
    IconButton,
    Button,
    Paper
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import {
    isArrayLength,
    dateTimeToString,
    toMiliSeconds,
} from "../../js/utils/genericMethods";
import * as bulkUploadDiagram from "../../diagrams/bulkUploadDiagram.json";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { mainRed } from "../../themes/GlobalTheme";
import { ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import { t } from "react-i18nify";
import { addNewModel, fetchData, downloadFile, getErrorMessage } from "../../js/utils/backend";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-pro";
import DataGridComponent from "../Widgets/DataGridComponent";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import DocumentsIcon from "@mui/icons-material/Article";
import { requiredCell } from "../../js/utils/inputValidations";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as r from "../../js/constants/routes";
import {
    useEditionRightsPerEntityAccess,
    useHasRightPerEntityAccess,
} from "../../js/utils/customHooks";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import HelpIcon from "@mui/icons-material/HelpOutlineOutlined";
import { updateEntityTree, updateHighlightedRows } from "../../js/redux/actions/userPref";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import { StateChip, TranslationDialog } from "../DataGrid/cst_DataGrid";
import SharedSettings from "../../common/sharedSettings.json";
import { BasicTooltip } from "../Widgets/StyledComponents";
import MediaChunksUploaderDialog from "../Views/DocumentView/MediaChunksUploaderDialog";
import MoveDialog from "../Views/DocumentView/MoveDialog";
import EditMediaDialog from "../Views/DocumentView/EditMediaDialog";
import BatchMetadataDialog from "../Views/DocumentView/BatchMetadataDialog";
import { KebabItem, MenuKebab } from "../Widgets/MenuKebab";
import { BulkUploadInstructions, bulkUploadFilesCategories } from "../Views/DocumentView/cst_DocumentsView";
import axios from "axios";
import { toastr } from "react-redux-toastr";

export const isPhotoVideo = "PhotoVideo";
export const isDocuments = "Documents";

/** First utility of documents view is to display a Document grid along a dropzone feature to drop and upload files for the node that we're viewing (nodeID). Second purpose is to do the same with a station : add documents to a station (stationID). */
const BulkUploadGrid = ({
    label,
    baseURL,
    isHistoryViewer = false,
}) => {
    const {
        historyItem,
        fileType,
        nodeID,
        otEntityID,
        element,
        elementID,
        otEntityTreeID,
    } = useParams();

    const apiRef = useGridApiRef();
    const cellFocusField = "Code";
    const isEddFile = Boolean(element) && element === "EDDFile";
    const [forceGridRefresh, setForceGridRefresh] = useState(false);
    const [treeNode, setTreeNode] = useState();
    const [parentField, setParentField] = useState();
    const notDraft = parentField?.State !== "Draft";
    const entityID = treeNode?.OTEntityID ?? otEntityID;
    const basicEditionRights = useEditionRightsPerEntityAccess(entityID, [
        "EntityManager",
        "Contributor",
    ]);
    const isReaderOfEntity = useHasRightPerEntityAccess(entityID, ["Reader"]);
    const isExternalOfEntity = useHasRightPerEntityAccess(entityID, [
        "External",
    ]);
    const readingRightsOnly = (isEddFile && notDraft) || isReaderOfEntity;
    const hasExternalEditionRights =
        isEddFile && isExternalOfEntity && !readingRightsOnly;
    const editingRights = hasExternalEditionRights || basicEditionRights;

    const [isLoading, setIsLoading] = useState(element || nodeID);
    const [bulkUploadsAlreadyExecuted, setBulkUploadAlreadyExecuted] = useState([]);

    const token = useSelector(state => state.auth.Token);
    const dispatch = useDispatch();
    const location = useLocation();
    const currentLocation = location.pathname;

    /** The locale is used to refresh the title of the document grid, by fetching and refreshing again the 'treeNode' */
    const locale = useSelector(state => state.i18n.locale);
    const [errorMessage, setErrorMessage] = useState("");

    let gridSpecificColumns = [
        {
            field: "CreatedOn",
            headerName: t("field.CreatedOn"),
            width: 150,
            editable: false,
            valueFormatter: params =>
                dateTimeToString(params.value, "DateTime"),
        },
        {
            field: "Value",
            headerName: t("field.Name"),
            width: 150,
            editable: false,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(params, apiRef, "Value", "Value");
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "IsPrimaryEDDFile",
            headerName: t("field.IsPrimaryEDDFile"),
            width: 120,
            editable: false,
            type: "boolean",
        },
        {
            field: "Filename",
            headerName: t("field.Filename"),
            flex: 1,
            editable: false,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "FileName",
                    "FileName"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "Author",
            headerName: t("field.Author"),
            width: 200,
            editable: false,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "Author",
                    "Author"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "DocumentCategory",
            headerName: t("field.DocumentCategory"),
            width: 150,
            editable: false,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "DocumentCategory",
                    "DocumentCategory"
                );
                return { ...params.props, error: hasError };
            },
            valueFormatter: params => t(`field.${params.value}`),
        },
    ];

    if (isHistoryViewer) {
        gridSpecificColumns = [
            {
                headerName: t("field.UpdatedByUser"),
                field: "UpdatedByUser",
                width: 150,
                valueFormatter: params => {
                    return `${params?.value?.Name} ${
                        params?.value?.IGG ? `| ${params?.value?.IGG}` : ""
                    } ${params?.value?.External ? "| External" : ""}`;
                },
            },
            {
                headerName: t("field.LastUpdate"),
                field: "LastUpdate",
                width: 150,
                valueFormatter: params =>
                    dateTimeToString(params.value, "DateTime"),
            },
            {
                headerName: t("field.CreatedByUser"),
                field: "CreatedByUser",
                width: 150,
                valueFormatter: params => {
                    return `${params?.value?.Name} ${
                        params?.value?.IGG ? `| ${params?.value?.IGG}` : ""
                    } ${params?.value?.External ? "| External" : ""}`;
                },
            },
            {
                headerName: t("common.Location"),
                field: "OTEntityTree",
                width: 150,
            },

            ...gridSpecificColumns,
        ];
    }

    const DetailPanelContent = ({ row }) => {
        const [forceGridRefresh, setForceGridRefresh] = useState(false);
        const apiRefDetailPanel = useGridApiRef();

        useEffect(() => {
            const interval = setInterval(() => {
                setForceGridRefresh(fgr => !fgr);
            }, toMiliSeconds(0, queryDelayInMinutes, 0));
            return () => clearInterval(interval);
        }, []);

        const StatusCell = ({ params }) => {
            useEffect(() => {
                if (params.row.Status === "Ok") {
                    dispatch(updateEntityTree());
                }
            }, [params.row.Status]);
        
            return (
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <StateChip
                        state={bulkUploadStatuses.find(
                            e => e.Name === params.row.Status
                        )}
                        clickable={false}
                    />
                </Box>
            );
        };


        const bulkUploadStatuses = bulkUploadDiagram.default.States;
        const detailGridSpecificColumns = [
            {
                field: "Status",
                headerName: t("field.State"),
                width: 140,
                renderCell: (params) => <StatusCell params={params}/>
            },            
            {
                field: "CreatedOn",
                headerName: t("view.query.ranOn"),
                valueFormatter: params =>
                    dateTimeToString(params.value, "DateTime"),
                flex: 1,
                editable: false,
            },
        ];

        const detailSpecificActionsColumns = (
            params,
            _editing
        ) => [
            <>
                {params.row.Status === "Error" && (
                    <>
                        <GridActionsCellItem
                            label={t("common.Error")}
                            icon={
                                <BasicTooltip title={t("common.Error")}>
                                    <DocumentsIcon />
                                </BasicTooltip>
                            }
                            onClick={async () => {
                                var result = await getErrorMessage(token, params.row.BulkUploadID);
                                setErrorMessage(result.data);
                                setErrorMessage("");
                            }}
                        />
                    </>
                )}
            </>,
        ];
        return (
            <Paper sx={{ m: 1, p: 1 }}>
                <DataGridComponent
                    forceGridRefresh={forceGridRefresh}
                    apiRef={apiRefDetailPanel}
                    paramURL={r.filter.genericFilter("BulkUploadID", row.ID)}
                    baseURL={r.bulkUploadResult}
                    label={label}
                    gridSpecificColumns={detailGridSpecificColumns}
                    specificActionsColumns={detailSpecificActionsColumns}
                    toolbar={{
                        disableToolbar: true,
                    }}
                    disableTitle
                    disableResearch
                    isEditDisabled
                    boxStyle={{ padding: 0, margin: 0 }}
                    isStandalone={false}
                    specificScreenLoading={
                        <ScreenLoading
                            title={t("common.Loading")}
                            sx={{
                                display: "flex",
                                height: "auto",
                                justifyContent: "center",
                            }}
                        />
                    }
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "CreatedOn", sort: "desc" }],
                        },
                    }}
                />
            </Paper>
        );
    };

    const getDetailPanelContent = useCallback(params => {
        return <DetailPanelContent row={params.row} />;
    }, []);


    const specificActionsColumns = (params, editing, isLocalLoading) => [
        <>
            <MenuKebab disabled={editing || isLocalLoading}>
                {/* REMPLACE THIS BY A DOWNLOAD FILE FUNCTION (e.g. downloadFile), exactly as downloading a file but in a tab (showing it) */}
                <Button
                    size="small"
                    sx={{
                        textTransform: "capitalize",
                        width: "100%",
                        display: "block",
                    }}
                    disabled={isLocalLoading}
                >
                    <KebabItem
                        disabled={isLocalLoading}
                        label="Download file"
                        icon={
                            <BasicTooltip title={`${t("common.Download")}`}>
                                <DownloadOutlinedIcon />
                            </BasicTooltip>
                        }
                        onClick={() => {
                            dispatch(
                                updateHighlightedRows({
                                    CurrentLocation: currentLocation,
                                    VisitedRowID: params.row.ID,
                                })
                            );
                            downloadFile(
                                token,
                                "download",
                                `/${params.row.MediaID}/${params.row.OTEntitySelector.ObjectID}`
                            );
                        }}
                    />
                </Button>
            </MenuKebab>

            {!editingRights || readingRightsOnly ? null : (
                <>
                    {params.row.BulkUploadFileAlreadyExecuted===false &&<GridActionsCellItem
                        label={t("common.Upload")}
                        icon={
                            <BasicTooltip title={t("common.Upload")}>
                                <PlayCircleOutlineOutlinedIcon />
                            </BasicTooltip>
                        }
                        onClick={async () => {
                            dispatch(
                                updateHighlightedRows({
                                    CurrentLocation: currentLocation,
                                    VisitedRowID: params.row.ID,
                                })
                            );
                            if(!bulkUploadsAlreadyExecuted.includes(params.row.ID)){
                                let url = `media/get-number-of-docs/${params.row.MediaID}`;
                                const config = {
                                    headers: { Authorization: "Bearer " + token },
                                };
                                var response = await axios.get(url, config);
                                setNumberOfDocuments(response.data);
                                setSelectedFileID(params.row.ID);
                                setOpenBatchMetadataDialog(true);
                            }
                        }}
                        disabled={editing || isLocalLoading}
                    />}
                    <GridActionsCellItem
                        label={t("common.Translate")}
                        icon={
                            <BasicTooltip title={t("common.Translate")}>
                                <LanguageIcon />
                            </BasicTooltip>
                        }
                        onClick={() => {
                            dispatch(
                                updateHighlightedRows({
                                    CurrentLocation: currentLocation,
                                    VisitedRowID: params.row.ID,
                                })
                            );
                            setOpenTranslateDialog(true);
                            setRowParams(params.row);
                        }}
                        disabled={editing || isLocalLoading}
                    />
                </>
            )}
        </>,
    ];

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openMoveDialog, setOpenMoveDialog] = useState(false);
    const [openTranslateDialog, setOpenTranslateDialog] = useState(false);
    const [rowParams, setRowParams] = useState();
    const [openBatchMetadataDialog, setOpenBatchMetadataDialog] = useState();
    const [media, setMedia] = useState([]);
    const [currentDocument, setCurrentDocument] = useState(null);

    const [mediaMetadata, setMediaMetadata] = useState(null); // important, will trigger process of upload

    const [triggerEditDialog, setTriggerEditDialog] = useState(false); // this is used only for single file upload, to trigger the edit popup programmatically
    /** For single filed upload only. Will open EditMediaDialog if we are on a EDDFile document folder. So the user can immediately edit (barely forced, but he can cancel) metadata of recently uploaded document  */
    useEffect(() => {
        if (currentDocument && triggerEditDialog) {
            // (isEddFile && !openDialog && !isArrayLength(media)
            editMedia(currentDocument);
            setTriggerEditDialog(false);
        }
        // eslint-disable-next-line
    }, [currentDocument]); // mediaMetadata, openDialog, isEddFile, media

    const [uploadedFile, setUploadedFile] = useState();

    const mediaIsUploaded = uploadedFile?.MediaID && !uploadedFile?.Replacing;

    const FileDownload = require('js-file-download');
    const handleDownload = () => {
        axios({
            url: `${baseURL}/download-template/${entityID}`,
            method: 'GET',
            responseType: 'blob',
            headers: { Authorization: "Bearer " + token },
        }).then((response) => {
            // Trigger file download
            FileDownload(response.data, 'OtEntityTreeTemplates.zip');
        }).catch((error) => {
            console.error("There was an error downloading the file!", error);
        });
    };


    const [skipDocument, setSkipDocument] = useState(null);
    const filterIsReader = readingRightsOnly ? `&DocumentCategory=Public` : "";
    const entityToFieldWanted = {
        sampling: {
            parentField: "SamplingID",
            valueField: "SubSamplingID",
            url: `filter[SamplingID]=${elementID}&filter[IsPhotoVideo]=true${filterIsReader}`,
        },

        eddfile: {
            parentField: "EDDFileID",
            valueField: "Value",
            url: `filter[EDDFileID]=${elementID}${filterIsReader}`,
        },
        station: {
            parentField: "StationID",
            valueField: "Name",
            url: `filter[StationID]=${elementID}&filter[IsPhotoVideo]=${
                fileType === isPhotoVideo
            }${filterIsReader}`,
        },
    };
    const getElementInfos = fieldWanted => {
        for (const key in entityToFieldWanted) {
            if (Boolean(element) && element.toLowerCase().includes(key))
                return entityToFieldWanted[key][fieldWanted];
        }
    };

    const [triggerBatchMetadataDialog, setTriggerBatchMetadataDialog] =
        useState();
    const [batchMetadaObject, setBatchMetadataObject] = useState(null);
    const [numberOfDocuments, setNumberOfDocuments] = useState(null);
    const [selectedFileID, setSelectedFileID] = useState(null);
    const [bulkUploadIsRunning, setBulkUploadIsRunning] = useState(false);

    // Create a document with default metadata (POST), but should skip if the file is overwriting an existing media in document.
    useEffect(() => {
        if (uploadedFile && !skipDocument && !batchMetadaObject) {
            // single file upload then metadata to encode for PUT
            const data = {
                MediaID: uploadedFile.MediaID,
                Created: true,
                Hidden: false,
                Value: "",
                FileName: media?.name,
                Author: "",
                DocumentCategory: "Public",
                OTEntityTreeID: treeNode?.ID ?? otEntityTreeID,
                OTEntityID: treeNode?.OTEntityID ?? otEntityID, //*
                [getElementInfos("parentField")]: elementID, //*
                StudyID: treeNode?.StudyID,
                IsBulkUploadFile: true,
                BulkUploadFileAlreadyExecuted: false
            };
            addNewModel(data, token, r.document)
                .then(response => {
                    setCurrentDocument(response.data.ObjectID);
                })
                .then(() => {
                    setSkipDocument(null);
                });
        }
        // eslint-disable-next-line
    }, [uploadedFile, skipDocument]);

    // Before creating a document, the user has registered some metadata, that will be applied to the document to create :
    useEffect(() => {
        if (batchMetadaObject && !bulkUploadIsRunning) {
            const dataFromBatchMetadata = {
                BulkUploadID: selectedFileID,
                Author: batchMetadaObject.Author ?? "",
                DocumentCategory:
                    batchMetadaObject.DocumentCategory ?? "Public",
                PublicationDate: batchMetadaObject.PublicationDate ?? undefined,
                RelatedZonesIDs: batchMetadaObject.RelatedZoneIDs.join(';'),
                RelatedStudiesIDs: batchMetadaObject.RelatedStudyIDs.join(';'),
                RelatedStationsIDs: batchMetadaObject.RelatedStationIDs.join(';'),
                RelatedStationGroupsIDs:
                    batchMetadaObject.RelatedStationGroupIDs.join(';'),
            };
            setBulkUploadIsRunning(true);
            setBulkUploadAlreadyExecuted(
                [...bulkUploadsAlreadyExecuted, selectedFileID]
            )
            addNewModel(dataFromBatchMetadata, token, r.bulkUploadResult)
                .then(response => {
                    setCurrentDocument(response.data.ObjectID);
                })
                .then(() => {
                    setSkipDocument(null);
                });

            const openedDetailsPanels =
                apiRef.current.getExpandedDetailPanels();
            if (openedDetailsPanels.includes(selectedFileID)) {
                apiRef.current.toggleDetailPanel(selectedFileID);
            }
            apiRef.current.toggleDetailPanel(selectedFileID);
        }
        // MEMO WARNIGN CORN
        // eslint-disable-next-line
    }, [uploadedFile, skipDocument, batchMetadaObject]);

    /** We will fetch the tree node, which is necessary further to POST / PUT a media file  */
    useEffect(() => {
        let isSubscribed = true;
        const fetching = async () => {
            let route = "/OTEntityTree";
            const nodeFetched = await fetchData(route, token, "ID", nodeID);
            if (isSubscribed) {
                setTreeNode(nodeFetched.data);
                setIsLoading(false);
            }
        };
        if (nodeID) {
            fetching();
        }
        return () => (isSubscribed = false);
    }, [nodeID, token, setTreeNode, locale]);
    /*  In case we're in the documents/photo videos/eddfiles of a parent entity (e.g. stationAir), we fetch the infos of the parent to display its name on screen */
    useEffect(() => {
        let isSubscribed = true;
        const fetching = async () => {
            const parentFieldFetched = await fetchData(
                element,
                token,
                "ID",
                elementID
            );
            if (isSubscribed) {
                setParentField(parentFieldFetched.data);
                setIsLoading(false);
            }
        };
        if (element && elementID) {
            fetching();
        }
        return () => (isSubscribed = false);
    }, [element, token, elementID]);

    /** When user want to edit metadatas of a document, we'll fetch it and pass data to Dialog */
    function editMedia(id) {
        const fetching = () => {
            fetchData("Document/getDocument", token, "ID", id).then(
                response => {
                    setMediaMetadata(response.data);
                    setOpenEditDialog(true);
                }
            );
        };
        fetching();
    }

    /**  First step where user interacts with dropzone or add a document; onDrop will get the list of files and sort them, for further use */
    const onDrop = useCallback(async acceptedFiles => {
        let sortedArray = null;
        sortedArray = acceptedFiles?.sort((a, b) =>
            a.name.toString().localeCompare(b.name)
        );
        if (sortedArray.length > 1) {
            // We'll upload several files, but before we'll add batch metadata (BatchMetadataDialog)
            setTriggerBatchMetadataDialog(sortedArray);
            setOpenBatchMetadataDialog(true);
        } else {
            // We'll upload only one file
            setTriggerEditDialog(true);
            setMedia(sortedArray);
        }
    }, []);

    /** We have to disable dropzone while hovering on a Datagrid toolbar button (= Move), for some reason, dropzone don't allow click there... see more : https://github.com/react-dropzone/react-dropzone/issues/1127 */
    const [onHoverDisableDropzone] = useState(false);
    const queryDelayInMinutes = SharedSettings.QueryDelayInMinutes;


    const documentNameToLabel = type => {
        const typeToLabel = {
            Documents: t("grid.title.Documents"),
            PhotoVideo: t("grid.title.PhotoVideo"),
            Files: t("grid.title.Files"),
        };

        return typeToLabel[type] ? typeToLabel[type] + " : " : "";
    };

    function fileTypeValidator(file) {
        if (!file.type.includes("zip") && !file.type.includes("7z")) {
            const allowedDocuments = Object.values(bulkUploadFilesCategories.ZipArchives)
            return {
                code: "wrong-document-file",
                message: t("input.validation.WrongDocumentExtension", {
                    value: allowedDocuments
                }),
            };
        }
    }

    /** Destructuring needed parameters for react-dropzone to work. And setting wished specifications. */
    const isUploadDisabled = !editingRights || readingRightsOnly;
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        fileRejections,
        isDragReject,
        open,
    } = useDropzone({
        maxFiles: SharedSettings.MaxFilesToUpload,
        maxSize: 2147483648, // 2GB
        multiple: true,
        noClick: true,
        noKeyboard: true,
        onDrop,
        label: label,
        validator: fileTypeValidator, 
        disabled: isUploadDisabled || onHoverDisableDropzone,
    });
    const [inSelection, setInSelection] = useState(false);
    const [selection, setSelection] = useState([]);
    /** When a document is moved to another node, we'll set again the inSelection to false (so only 'Move' button is displayed) */
    useEffect(() => {
        if (nodeID) {
            handleCancelMoveClick();
        }
    }, [nodeID, setInSelection]);

    const handleCancelMoveClick = () => {
        setInSelection(false);
        setSelection([]);
    };

    const nodeDocumentParamURL =
        nodeID && `OTEntityTreeID=${nodeID}${filterIsReader}`;

    const [toggleInfo, setToggleInfo] = useState(false);
    useEffect(() => {
        if (!toggleInfo && fileRejections[0]?.errors?.[0]?.message) {
            setToggleInfo(true);
        }
    }, [fileRejections, toggleInfo]);

    if ((element && !parentField) || isLoading) {
        return <ScreenLoading />;
    }

    return (
        <Box
            {...getRootProps({
            })}
            sx={{
                border: isDragActive
                    ? `2px dashed ${mainRed}`
                    : `2px solid transparent`,
                backgroundColor: isDragActive ? "#eee" : null,
                filter: isDragActive && "brightness(0.85)",
            }}
        >
            <Box
                sx={{
                    mx: 5,
                }}
            >
                <Box
                    sx={{
                        typography: "h3",
                        pt: 6,
                        pb: 2,
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    {isHistoryViewer && `${t("common.ChangesHistory")}: `}
                    {documentNameToLabel(fileType)}
                    {treeNode?.Value ??
                        parentField?.[getElementInfos("valueField")]}
                    {!toggleInfo && editingRights && !isHistoryViewer && (
                        <IconButton
                            sx={{ ml: 1 }}
                            onClick={_e => setToggleInfo(!toggleInfo)}
                        >
                            <BasicTooltip
                                title={`${t("view.document.HelpFileUpload")}`}
                            >
                                <HelpIcon color="primary" />
                            </BasicTooltip>
                        </IconButton>
                    )}
                </Box>
                <BulkUploadInstructions
                    editingRights={editingRights}
                    toggleInfo={toggleInfo}
                    setToggleInfo={(e) => setToggleInfo(e)}
                    isDragActive={isDragActive}
                    isDragReject={isDragReject}
                    fileType={fileType}
                    fileRejections={fileRejections}
                />
            </Box>
            {isArrayLength(media) && (
                <MediaChunksUploaderDialog
                    media={media}
                    setMedia={setMedia}
                    token={token}
                    setUploadedFile={e => setUploadedFile(e)}
                    otEntityId={treeNode?.OTEntityID ?? otEntityID}
                    forceGridRefresh={forceGridRefresh}
                    setForceGridRefresh={setForceGridRefresh}
                    OTEntityTreeID={treeNode?.ID ?? otEntityTreeID}
                    currentDocument={currentDocument}
                    mediaIsUploaded={mediaIsUploaded}
                    setSkipDocument={e => setSkipDocument(e)}
                    fileType={fileType}
                />
            )}
            <Box
                sx={{
                    typography: "h2",
                    display: "flex",
                    justifyContent: "center",
                    width: "auto",
                }}
            >
                <input {...getInputProps()} />
            </Box>
            {/* Will open only when several files are uploaded (>1) */}
            {openBatchMetadataDialog && (
                <BatchMetadataDialog
                    openBatchMetadataDialog={openBatchMetadataDialog}
                    setOpenBatchMetadataDialog={e =>
                        setOpenBatchMetadataDialog(e)
                    }
                    otEntityId={treeNode?.OTEntityID ?? otEntityID}
                    isEddFile={isEddFile}
                    triggerBatchMetadataDialog={triggerBatchMetadataDialog}
                    batchMetadaObject={batchMetadaObject}
                    setBatchMetadataObject={e => setBatchMetadataObject(e)}
                    setMedia={e => setMedia(e)}
                    numberOfDocuments={numberOfDocuments}
                />
            )}
            <EditMediaDialog
                openEditDialog={openEditDialog}
                setOpenEditDialog={setOpenEditDialog}
                treeNode={treeNode}
                media={media}
                setMedia={e => setMedia(e)}
                token={token}
                element={element}
                setForceGridRefresh={setForceGridRefresh}
                mediaMetadata={mediaMetadata}
                otEntityId={treeNode?.OTEntityID ?? otEntityID}
                setMediaMetadata={e => {
                    setMediaMetadata(e);
                }}
                setCurrentDocument={e => setCurrentDocument(e)}
                isEddFile={isEddFile}
                triggerBatchMetadataDialog={triggerBatchMetadataDialog}
            />
            {errorMessage!=="" && toastr.error(errorMessage)}
            <TranslationDialog
                label={label}
                open={openTranslateDialog}
                setOpen={setOpenTranslateDialog}
                rowParams={rowParams}
                baseURL={baseURL}
            />
            <MoveDialog
                openMoveDialog={openMoveDialog}
                setOpenMoveDialog={setOpenMoveDialog}
                OTEntityID={treeNode?.OTEntityID}
                onDialogClose={handleCancelMoveClick}
            />
            {/* {hasDocuments || (element && parentField?.State) ? ( */}
                <DataGridComponent
                    apiRef={apiRef}
                    baseURL={
                        isHistoryViewer
                            ? `${"Document"}/history/${historyItem}`
                            : "Document"
                    }
                    cellFocusField={cellFocusField}
                    getDetailPanelContent={getDetailPanelContent}
                    paramURL={
                        isHistoryViewer
                            ? ""
                            : nodeDocumentParamURL ?? getElementInfos("url")
                    }
                    label={label}
                    gridSpecificColumns={gridSpecificColumns}
                    specificActionsColumns={specificActionsColumns}
                    isDeleteDisabled={
                        !editingRights ||
                        readingRightsOnly ||
                        inSelection ||
                        isHistoryViewer
                    }
                    isEditDisabled={true}
                    initialState={{
                        sorting: {
                            sortModel: [
                                isHistoryViewer
                                    ? { field: "LastUpdate", sort: "desc" }
                                    : { field: "CreatedOn", sort: "desc" },
                            ],
                        },
                    }}
                    editingRights={editingRights}
                    toolbar={
                        isHistoryViewer
                            ? { add: false }
                            : {
                                  newAction: editingRights && (
                                      <>
                                          {isUploadDisabled ? null : (
                                              <ButtonToolbar
                                                  type="button"
                                                  titleTooltip={t(
                                                      "view.document.AddDocument"
                                                  )}
                                                  disabled={inSelection}
                                                  title={t("common.Add")}
                                                  startIcon={
                                                      <UploadFileOutlinedIcon />
                                                  }
                                                  specialaction={{
                                                      ...getRootProps({}),
                                                  }}
                                                  onClick={open}
                                              />
                                          )}
                                          <ButtonToolbar
                                                titleTooltip={t(
                                                    "view.eddFiles.getTemplate"
                                                )}
                                                title={t("view.eddFiles.getTemplate")}
                                                startIcon={<DownloadOutlinedIcon />}
                                                onClick={handleDownload}
                                            />
                                          {/* If we are in the case where document folder is a document node on the tree, we can move elsewhere (node)  */}
                                      </>
                                  ),
                                  add: false,
                              }
                    }
                    visitedRow={currentDocument}
                    components={{
                        DetailPanelExpandIcon: ChevronRightIcon,
                        DetailPanelCollapseIcon: ExpandMoreIcon,
                    }}
                    forceGridRefresh={forceGridRefresh}
                    hideActionColumn={
                        (isEddFile && isReaderOfEntity) || isHistoryViewer
                    }
                    checkboxSelection={inSelection}
                    columnVisibilityModel={{
                        IsPrimaryEDDFile: isEddFile,
                        actions: !inSelection,
                    }}
                    selectionModel={selection}
                    onSelectionModelChange={newSelectionModel => {
                        setSelection(newSelectionModel);
                    }}
                    withBackButton={isHistoryViewer}
                />
            {/* ) : null} */}
        </Box>
    );
};
export default BulkUploadGrid;
