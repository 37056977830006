import { Box, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import React, { useState, useEffect, useRef } from "react";
import { GlobalTheme } from "../../themes/GlobalTheme";
import LanguageSelector from "../Widgets/LanguageSelector";
import VersionJSON from "../../common/sharedSettings.json";
import { useDispatch, useSelector } from "react-redux";
import { loadTranslations } from "../../js/redux/actions/i18n";
import translations from "../../l10n/translations";
import { t } from "react-i18nify";
import { OTAppBar } from "../Widgets/AppBar";

const version = VersionJSON.Version;
const AppUnderMaintenance = () => {
    const locale = useSelector(state => state.i18n.locale);

    const appBarRef = useRef(null);

    const [language, setLanguage] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setLanguage(locale);
    }, [locale]);

    useEffect(() => {
        if (locale) {
            dispatch(loadTranslations(translations));
        }
    }, [dispatch, locale]);

    return (
        <ThemeProvider theme={GlobalTheme}>
            <OTAppBar
                ref={appBarRef}
                UpperAppBar={
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            ml: { xs: 2 },
                        }}
                    >
                        <Box sx={{ typography: "body2", mr: 2 }}>
                            v. {version}
                        </Box>
                        <LanguageSelector />
                    </Box>
                }
                LowerAppBar={
                    <Box
                        sx={{
                            width: 1,
                            height: 60,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Grid item sx={{ flexGrow: 1 }} />
                        <Box sx={{ typography: "subtitleMajor", mr: 2 }}>
                            {t("view.newUser.Welcome")}
                        </Box>
                    </Box>
                }
            />
            {language ? <Grid
                container
                component="main"
                p={1}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: 4
                }}
            >
                <img src={require("../../assets/images/maintenance.png")} width="250" height="260" alt=""/>
                <Box sx={{ typography:"h3", mt:4 }}>
                    {t("common.ApplicationDown")}
                </Box>
            </Grid> : null}
        </ThemeProvider>
    );
};

export default AppUnderMaintenance;
