import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { fetchData, requestWithSelectedObjects } from "../../js/utils/backend";
import { useSelector } from "react-redux";
import {
    getCleanGeometries,
    arcgisPerStationType,
} from "./cst_ArcGIS";
import { BasicMap } from "./MapCustomInputs";
import { t } from "react-i18nify";
import StationMapResult from "../DataGrid/StationMapResultGrid";
import { mainRed } from "../../themes/GlobalTheme";

const GenericDataGridMap = ({ title, toolbarComponent, selection = [] }) => {
    const token = useSelector(state => state.auth.Token);
    const locale = useSelector(state => state.i18n.locale);
    const [heightMap, setHeightMap] = useState(500);
    const { GenericGridTypeParent, elementID } = useParams();
    const [entity, setEntity] = useState();
    const [layerList, setLayerList] = useState([]);
    const [legend, setLegend] = useState([]);
    const [mapIsLoaded, setMapIsLoaded] = useState(false);

    const mapFormatFromStationType = arcgisPerStationType.find(
        e => e.route === GenericGridTypeParent
    );

    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        const newHeight = e.clientY - document.body.offsetTop - 224;
        if (newHeight > 200) {
            setHeightMap(newHeight);
        }
    }, []);

    useEffect(() => {
        let isSubscribed = true;
        const fetching = async () => {
            const entityFetched = await fetchData(
                "OTEntity/map",
                token,
                "ID",
                elementID
            );
            if (isSubscribed) {
                setEntity(entityFetched.data);
            }
        };
        elementID && fetching();
        return () => (isSubscribed = false);
    }, [token, elementID]);

    useEffect(() => {
        const fetching = async () => {
            const stationsSelected = await requestWithSelectedObjects(
                GenericGridTypeParent,
                selection,
                token
            );

            const selectionLayer = {
                layerProperties: {
                    title: t("map.selectedStations"),
                    id: "selectedStations",
                    visible: true,
                },
                graphics: {
                    geometries: getCleanGeometries(stationsSelected, e => {
                        return {
                            ...mapFormatFromStationType.subTypesGraphicShaper(
                                e
                            ),
                            attributes: {
                                Name: e.Name,
                                Description: `${t(
                                    "map.coordinates"
                                )}: ${e.PointString || e.PolygonString}`,
                            },
                        };
                    }),
                    graphicShape: mapFormatFromStationType.graphicShape,
                },
            };

            // Legend
            setLegend([
                {
                    groupLegendLabel: t("map.selectedStations"),
                    layersLegend: mapFormatFromStationType.legend || [
                        {
                            label: t(`field.${mapFormatFromStationType.route}`),
                            symbol: mapFormatFromStationType.graphicShape,
                        },
                    ],
                },
            ]);

            setLayerList([selectionLayer]);
        };
        entity && fetching();
        // eslint-disable-next-line
    }, [elementID, entity, locale]);

    return (
        <Grid p={5}>
            {mapIsLoaded && (
                <>
                    <Box
                        sx={{
                            typography: "h3",
                            pt: 1,
                            pb: 2,
                            display: "flex",
                            alignItems: "center",
                            height: "26px",
                        }}
                    >
                        {title ?? entity?.Name}
                    </Box>
                    {toolbarComponent && (
                        <Box
                            mt={6}
                            sx={{
                                border: "1px solid rgba(224, 224, 224, 1)",
                                display: "flex",
                            }}
                        >
                            {toolbarComponent}
                        </Box>
                    )}
                </>
            )}
            <Box sx={{ height: `${heightMap}px` }}>
                <BasicMap
                    layerList={layerList}
                    legend={legend}
                    center={entity?.Extent}
                    mapServerServices={entity?.MapserviceList}
                    basemap={entity?.Basemap}
                    mapServerLink={entity?.GisServer}
                    mapName={entity?.Name}
                    setMapIsLoaded={e => {
                        setMapIsLoaded(e);
                    }}
                    groupFilterSelection={[
                        "AllStationsCustomLayer",
                        "StationGroup",
                    ]}
                    hasDrawingButton
                    hasCommentButton
                    hasSaveButton
                    hasMeasurementTools
                />
            </Box>
            {mapIsLoaded && (
                <>
                    <Box
                        sx={{
                            height: "8px",
                            cursor: "row-resize",
                            "&:hover": {
                                boxSizing: "border-box",
                                borderTop: `2px solid ${mainRed}`,
                                borderBottom: `2px solid ${mainRed}`,
                            },
                        }}
                        draggable={true}
                        onMouseDown={e => handleMouseDown(e)}
                    />
                    <StationMapResult
                        bodyOfRequest={{
                            selection,
                        }}
                    />
                </>
            )}
        </Grid>
    );
};

export default GenericDataGridMap;
