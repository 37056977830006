// Warning: each custom input need to be adapted with React-hook-form v7 (begin by VTextfield...)
import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { t } from "react-i18nify";
import { BasicTooltip } from "../StyledComponents";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Fab } from "@mui/material";
import { useSelector } from "react-redux";
import { historyPush } from "../../../js/utils/genericMethods";

export const BasicButton = props => {
    return (
        <Button variant="contained" color="primary" sx={{ m: 1 }} {...props} />
    );
};
export const ButtonNewElement = ({
    originalPath,
    titleTooltip = "Create a new element.",
    title = t("common.New"),
    ...props
}) => {
    const location = useLocation();
    const history = useHistory();

    return (
        <BasicButton
            startIcon={
                <BasicTooltip title={titleTooltip}>
                    <AddCircleIcon />
                </BasicTooltip>
            }
            onClick={() => {
                historyPush(history, `${originalPath ?? location.pathname}/create/new`);
            }}
            {...props}
        >
            {title}
        </BasicButton>
    );
};
export const ButtonEditElement = ({
    originalPath,
    titleTooltip = "Edit the element.",
    title = t("common.Edit"),
    ID,
    ...props
}) => {
    const location = useLocation();
    const history = useHistory();
    const { UrlID } = useParams();
    ID = ID ?? UrlID;
    return (
        <BasicButton
            startIcon={
                <BasicTooltip title={titleTooltip}>
                    <EditIcon />
                </BasicTooltip>
            }
            onClick={() => {
                UrlID
                    ? historyPush(history, `/entity-page/edit/${ID}`)
                    : historyPush(history,
                        `${originalPath ?? location.pathname}/edit/${ID}`
                    );
            }}
            {...props}
        >
            {title}
        </BasicButton>
    );
};

export const ButtonToolbar = ({
    titleTooltip,
    title,
    startIcon,
    onClick,
    ...props
}) => {
    return (
        <BasicButton
            startIcon={
                <BasicTooltip title={titleTooltip}>{startIcon}</BasicTooltip>
            }
            onClick={onClick}
            color="secondary"
            variant="text"
            size="small"
            sx={{
                minWidth: "64px",
                display: "inline-flex",
                lineHeight: "1.75",
                padding: "4px 5px",
            }}
            {...props}
        >
            {title}
        </BasicButton>
    );
};

export const BasicFab = React.forwardRef(({ children, ...props }, ref) => {
    const settingsMenuWidth = useSelector(
        state => state.userPref.SettingsMenuWidth
    );

    return (
        <Fab
            color="secondary"
            size="large"
            type="button"
            ref={ref}
            sx={{
                m: 3,
                display: "flex",
                position: "fixed",
                bottom: 0,
                right: settingsMenuWidth ? `${settingsMenuWidth}px` : 0,
            }}
            {...props}
        >
            {children}
        </Fab>
    );
});
