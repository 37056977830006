import React, { useCallback, useState } from "react";
import {
    GridActionsCellItem,
    GridAddIcon,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import * as r from "../../js/constants/routes";
import { SelectURLGrid } from "../Widgets/Custom Inputs/Select";
import { requiredCell } from "../../js/utils/inputValidations";
import DataGridComponent from "../Widgets/DataGridComponent";
import { useSelector, useDispatch } from "react-redux";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import { TranslationDialog } from "./cst_DataGrid";
import { t } from "react-i18nify";
import { BasicTooltip } from "../Widgets/StyledComponents";
import { updateHighlightedRows } from "../../js/redux/actions/userPref";
import { useLocation } from "react-router-dom";
import { EnglishValueDialog, NewRefDialog } from "./ReferencesDataGrid";
import { ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";

const CountryGrid = ({ baseURL = r.country.route, label }) => {
    const apiRef = useGridApiRef();
    const cellFocusField = "Code";
    const { CompanyManager, HoldingManager } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const location = useLocation();
    const currentLocation = location.pathname;
    const editingRights = CompanyManager || HoldingManager;

    const locale = useSelector(state => state.i18n.locale);
    const [propsEnglishValue, setOpenEnglishValue] = useState(false);
    const beforeCommitRow = useCallback(
        row => {
            if (row.isNew && locale !== "ENG") {
                return new Promise((resolve, reject) => {
                    setOpenEnglishValue({
                        open: true,
                        setOpen: setOpenEnglishValue,
                        resolve,
                        reject,
                        row,
                        label,
                    });
                }).then(englishValue => ({
                    ...row,
                    EnglishValue: englishValue,
                }));
            }
            return row;
        },
        [setOpenEnglishValue, locale, label]
    );

    const gridSpecificColumns = [
        {
            field: "Code",
            headerName: t("field.Code"),
            width: 150,
            editable: true,
            sortable: true,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(params, apiRef, "Code", "Code");
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "Value",
            headerName: t("field.Name"),
            width: 300,
            flex: 1,
            editable: true,
            sortable: true,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(params, apiRef, "Name", "Value");
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "Region_select",
            headerName: t("field.Region"),
            flex: 0.75,
            editable: true,
            sortable: false,
            valueFormatter: params => params.value?.Value,
            renderEditCell: params => (
                <SelectURLGrid
                    fieldName={"RegionID"}
                    URL={r.region.route}
                    {...params}
                />
            ),
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "Region",
                    "Region_select"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "RegionID",
            sortable: false,
            valueGetter: params => params.row?.Region_select?.ObjectID,
        },
    ];

    const [open, setOpen] = useState(false);
    const [rowParams, setRowParams] = useState();
    const [addRequest, setAddRequest] = useState(false);

    const specificActionsColumns = (params, editing, isLocalLoading) => [
        <>
            {(CompanyManager || HoldingManager) && (
                <GridActionsCellItem
                    label={t("common.Translate")}
                    icon={
                        <BasicTooltip title={t("common.Translate")}>
                            <LanguageIcon />
                        </BasicTooltip>
                    }
                    onClick={() => {
                        dispatch(
                            updateHighlightedRows({
                                CurrentLocation: currentLocation,
                                VisitedRowID: params.row.ID,
                            })
                        );
                        setOpen(true);
                        setRowParams(params.row);
                    }}
                    disabled={editing || isLocalLoading}
                />
            )}
        </>,
    ];
    return (
        <>
            <TranslationDialog
                label={label}
                open={open}
                setOpen={setOpen}
                rowParams={rowParams}
                baseURL={baseURL}
            />
            <DataGridComponent
                apiRef={apiRef}
                baseURL={baseURL}
                cellFocusField={cellFocusField}
                columnVisibilityModel={{
                    RegionID: false,
                }}
                label={label}
                toolbar={{
                    newAction: !CompanyManager && !HoldingManager && (
                        <>
                            {!CompanyManager && !HoldingManager && (
                                <ButtonToolbar
                                    titleTooltip={t("common.addNewRef")}
                                    title={t("common.addNewRef")}
                                    startIcon={<GridAddIcon />}
                                    onClick={() => {
                                        setAddRequest({ node: {} });
                                    }}
                                />
                            )}
                        </>
                    ),
                }}
                editingRights={editingRights}
                gridSpecificColumns={gridSpecificColumns}
                specificActionsColumns={specificActionsColumns}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "Code", sort: "asc" }],
                    },
                }}
                beforeCommitRow={beforeCommitRow}
            />
            <NewRefDialog
                reference={"Countries"}
                editNewRefOpen={addRequest}
                seteditNewRefOpen={setAddRequest}
            />
            <EnglishValueDialog {...propsEnglishValue} />
        </>
    );
};

export default CountryGrid;
