import React, { useState } from "react";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-pro";
import { requiredCell, validateEmail } from "../../js/utils/inputValidations";
import DataGridComponent, {
    rowDisableDelete,
    conditionalEditDisabling,
} from "../Widgets/DataGridComponent";
import * as r from "../../js/constants/routes";

import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FilterToolbar } from "../Widgets/Custom Inputs/Select";
import { userState } from "../../js/constants/selectOptions";
import DiagramIcon from "@mui/icons-material/TransformOutlined";
import { ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import { userDiagramRoute } from "../../routers/MenuRouter";
import Axios from "axios";
import * as userDiagram from "../../diagrams/userDiagram.json";
import { t } from "react-i18nify";
import { BasicTooltip } from "../Widgets/StyledComponents";
import { historyPush } from "../../js/utils/genericMethods";
import UserAssignementEditionPanel from "../Views/Edition panels/UserAssignementEditionPanel";
import { useCallback } from "react";
import { Dialog } from "@mui/material";

/** CommentComponent */
const AllUsersGrid = ({
    label = t("grid.title.AllUsers"),
    baseURL,
}) => {
    const apiRef = useGridApiRef();
    const cellFocusField = "IGG";
    const history = useHistory();
    const userID = useSelector(state => state.auth.UserID);
    const token = useSelector(state => state.auth.Token);
    const [visitedRow, setVisitedRow] = useState();
    const [selectedUserState, setSelectedUserState] = useState(null);
    const [forceGridRefresh, setForceGridRefresh] = useState(false);

    const { CompanyManager, HoldingManager } = useSelector(state => state.auth);

    const gridSpecificColumns = [
        {
            field: "IGG",
            headerName: "IGG",
            flex: 1,
            editable: true,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(params, apiRef, "IGG", "IGG");
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "Firstname",
            headerName: t("field.Firstname"),
            flex: 1,
            editable: true,
        },
        {
            field: "Lastname",
            headerName: t("field.Lastname"),
            flex: 1,
            editable: true,
        },
        {
            field: "EMail",
            headerName: "EMail",
            flex: 1,
            editable: true,
            preProcessEditCellProps: params => {
                const hasError = validateEmail(
                    params,
                    apiRef,
                    "EMail",
                    "EMail"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "State",
            headerName: t("field.State"),
            flex: 0.5,
            editable: false,
            valueGetter: params => {
                return (t(`diagram.${params.value}`) ?? t("diagram.Valid"))
            }
        },
    ];

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [userName, setUserName] = useState("");
    const [userIGG, setUserIGG] = useState("");

    const cancelAction = useCallback(
        () => setOpenEditDialog(false),
        [setOpenEditDialog]
    );
    
    const diagramStates = userDiagram.default.States;
    const diagramTransitions = userDiagram.default.Transitions;

    const editingRights = CompanyManager || HoldingManager
    const specificActionsColumns = (params, _editing, isLocalLoading) => [
        // MEMO : duplicated code with ExternalUserGrid, remember to refactor the onClick and uniformize somehow with better transition handling
        <>
            {editingRights &&
                (params.row.State === "Removed" ||
                    apiRef.current.state.rows.idRowsLookup[params.id].State ===
                    "Removed") && (
                    <GridActionsCellItem
                        icon={
                            <BasicTooltip title={t("common.Add")}>
                                <PersonAddAltOutlinedIcon />
                            </BasicTooltip>
                        }
                        label={t("common.Add")}
                        className="textPrimary"
                        onClick={() => {
                            const state = diagramStates.find(
                                e => e.Name === "Removed"
                            );
                            const availableTransitions =
                                diagramTransitions.filter(
                                    e => e.From === state.Name
                                );
                            const nextTransition = availableTransitions[0].Name;
                            const config = {
                                headers: {
                                    Authorization: "Bearer " + token,
                                },
                            };
                            Axios.post(
                                `${baseURL}/transition/${params.id}/${nextTransition}`,
                                {},
                                config
                            ).then(() => {
                                setForceGridRefresh(fgr => !fgr);
                                setVisitedRow(params.id);
                            });
                        }}
                        color="inherit"
                        disabled={isLocalLoading}
                    />
                )}
            {editingRights &&
                (params.row.State === "New" ||
                    apiRef.current.state.rows.idRowsLookup[params.id].State ===
                    "New") && (
                    <GridActionsCellItem
                        icon={
                            <BasicTooltip title={t("common.Assign")}>
                                <ArrowForwardIcon />
                            </BasicTooltip>
                        }
                        label={t("common.Assign")}
                        className="textPrimary"
                        onClick={() => {
                            setUserName(params.row.Firstname+' '+params.row.Lastname)
                            setUserIGG(params.row.IGG)
                            setOpenEditDialog(
                                true
                            );
                            // setSubmitMode("add");
                        }}
                        color="inherit"
                        disabled={isLocalLoading}
                    />
                )}
            {editingRights &&
                !conditionalEditDisabling(params.row) &&
                !rowDisableDelete(params.row, userID, "ID") &&
                apiRef.current.getRowMode(params.id) !== "edit" && (
                    <GridActionsCellItem
                        icon={
                            <BasicTooltip title={t("common.Remove")}>
                                <HighlightOffIcon />
                            </BasicTooltip>
                        }
                        label={t("common.Remove")}
                        className="textPrimary"
                        onClick={() => {
                            const state = diagramStates.find(
                                e => (e.Name === "Valid" || e.Name === "New")
                            );
                            console.log(diagramTransitions)
                            const availableTransitions =
                                diagramTransitions.filter(
                                    e => e.From === state.Name
                                );
                            const nextTransition = availableTransitions[1].Name;
                            const config = {
                                headers: {
                                    Authorization: "Bearer " + token,
                                },
                            };
                            Axios.post(
                                `${baseURL}/transition/${params.id}/${nextTransition}`,
                                {},
                                config
                            ).then(() => {
                                setForceGridRefresh(fgr => !fgr);
                                setVisitedRow(params.id);
                            });
                        }}
                        color="inherit"
                        disabled={isLocalLoading}
                    />
                )}
        </>,
    ];

    return (
        <>
            <DataGridComponent
                apiRef={apiRef}
                baseURL={baseURL}
                cellFocusField={cellFocusField}
                label={label}
                gridSpecificColumns={gridSpecificColumns}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "State", sort: "asc" }],
                    },
                }}
                toolbar={{
                    newAction: (
                        <ButtonToolbar
                            titleTooltip={t("common.AccessWorkflow")}
                            title={t("common.Workflow")}
                            startIcon={<DiagramIcon />}
                            onClick={() => historyPush(history, userDiagramRoute)}
                        />
                    ),
                    newFilter: (
                        <FilterToolbar
                            options={userState.map(e => ({
                                ...e,
                                label: t(`diagram.${e.label}`)
                            }))}
                            placeholder={t("placeholder.UserState")}
                            value={selectedUserState}
                            onChange={(e, value) =>
                                value
                                    ? setSelectedUserState(value.value)
                                    : setSelectedUserState("")
                            }
                        />
                    ),
                }}
                paramURL={
                    selectedUserState
                        ? `filter[State]=${selectedUserState}&${r.filter.allUsersFilter}`
                        : r.filter.allUsersFilter
                } // paramURL={r.filter.externalFilter}//
                forceGridRefresh={forceGridRefresh}
                isDeleteDisabled={true}
                isEditDisabled={true}
                specificActionsColumns={specificActionsColumns}
                visitedRow={visitedRow}
                editingRights={editingRights}
            />
            {openEditDialog ? (
                <Dialog open={Boolean(openEditDialog)} sx={{ w: 1 }} fullWidth>
                    <UserAssignementEditionPanel
                        setForceGridRefresh={setForceGridRefresh}
                        defaultMode={"INTERNALUSER"}
                        isInPannel
                        backAction={cancelAction}
                        userName={userName}
                        userIGG={userIGG}
                    />
                </Dialog>
            ) : null}
        </>
    );
};

export default AllUsersGrid;
