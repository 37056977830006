import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { t } from "react-i18nify";
import { requiredCell } from "../../js/utils/inputValidations";
import DataGridComponent from "../Widgets/DataGridComponent";
import { useDispatch, useSelector } from "react-redux";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import { TranslationDialog } from "./cst_DataGrid";
import { BasicTooltip } from "../Widgets/StyledComponents";
import { updateHighlightedRows } from "../../js/redux/actions/userPref";
import { useLocation } from "react-router-dom";

const LookupHintGrid = ({ label = t("grid.title.LookupHints"), baseURL }) => {
    const apiRef = useGridApiRef();
    const cellFocusField = "Key";
    const dispatch = useDispatch();
    const location = useLocation();
    const currentLocation = location.pathname;

    const { CompanyManager, HoldingManager } = useSelector(state => state.auth);
    const gridSpecificColumns = [
        {
            field: "Ref_TableName",
            headerName: t("field.LookUpTableName"),
            width: 200,
            editable: false,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(
                    params,
                    apiRef,
                    "Ref_TableName",
                    "Ref_TableName"
                );
                return { ...params.props, error: hasError };
            },
        },
        {
            field: "Value",
            headerName: t("field.Value"),
            width: 200,
            flex: 1,
            editable: true,
            preProcessEditCellProps: params => {
                const hasError = requiredCell(params, apiRef, "Value", "Value");
                return { ...params.props, error: hasError };
            },
        },
    ];

    const specificActionsColumns = (params, _editing, isLocalLoading) => [
        <>
            {(CompanyManager || HoldingManager) && (
                <GridActionsCellItem
                    label={t("common.Translate")}
                    icon={
                        <BasicTooltip title={t("common.Translate")}>
                            <LanguageIcon />
                        </BasicTooltip>
                    }
                    onClick={() => {
                        dispatch(
                            updateHighlightedRows({
                                CurrentLocation: currentLocation,
                                VisitedRowID: params.row.ID,
                            })
                        );
                        setOpen(true);
                        setRowParams(params.row);
                    }}
                    disabled={_editing || isLocalLoading}
                />
            )}
        </>,
    ];

    const [open, setOpen] = useState(false);
    const [rowParams, setRowParams] = useState();

    return (
        <>
            <TranslationDialog
                label={label}
                open={open}
                setOpen={setOpen}
                rowParams={rowParams}
                baseURL={baseURL}
            />
            <DataGridComponent
                apiRef={apiRef}
                baseURL={baseURL}
                cellFocusField={cellFocusField}
                label={label}
                gridSpecificColumns={gridSpecificColumns}
                specificActionsColumns={specificActionsColumns}
                isDeleteDisabled={true}
                toolbar={{
                    toolbarFilteringToolsOnly: true,
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "Ref_TableName", sort: "asc" }],
                    },
                }}
            />
        </>
    );
};

export default LookupHintGrid;
