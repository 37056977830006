import { Box } from "@mui/system";
//import { drawerWidth } from "./CustomNavigation";
import React from "react";
import { DialogActions, Grid, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";

export const ContentViewport = ({ children, treeMenuOpen, prefsMenuOpen, appBarRef }) => {

    const drawerWidth = useSelector(state => state.userPref.TreeMenuWidth)

    return (
        <Paper
            id="contentViewport"
            component="main"
            sx={{
                m: 1,
                minHeight: theme => `calc(100vh - ${(appBarRef?.current?.offsetHeight ?? 125)}px - ${theme.spacing(
                    2
                )})`,
                width: theme =>
                    treeMenuOpen && prefsMenuOpen
                        ? `calc(100% - ${drawerWidth}px - 320px -  ${theme.spacing(
                            2
                        )})`
                        : treeMenuOpen
                            ? `calc(100% - ${drawerWidth}px -  ${theme.spacing(2)})`
                            : prefsMenuOpen ? `calc(100% - 320px - ${theme.spacing(2)})` : `calc(100% - ${theme.spacing(2)})`,
                ml: theme =>
                    treeMenuOpen
                        ? `calc(${drawerWidth}px + ${theme.spacing(1)})`
                        : 1,
                mr: theme =>
                    prefsMenuOpen
                        ? `calc(${drawerWidth}px + ${theme.spacing(1)})`
                        : 1,
                zIndex: theme => theme.zIndex.drawer - 1,
            }}
        >
            {children}
        </Paper>
    );
};

export const InProgress = ({ children }) => {
    return (
        <Paper
            sx={{
                display: "flex",
                alignItems: "center",
                background:
                    "repeating-linear-gradient(-60deg, #ebdb61, #ebdb61 10px, #EEE 10px, #EEE 20px)",
            }}
            elevation={0}
        >
            <Box px={1}>{children} </Box>
        </Paper>
    );
};

export const PageSection = ({ children, ...props }) => {
    return (
        <Paper
            elevation={3}
            sx={{
                p: 2,
                border: theme => `1px solid ${theme.palette.neutral.main}`,
                borderRadius: "5px",
                m: 1,
                textAlign: "justify",
                ...props.sx,
            }}
        >
            {children}
        </Paper>
    );
};
export const PanelInfo = ({ info }) => {
    return (
        <Grid
            sx={{
                display: "flex",
                height: "calc(100vh - 140px)",
                justifyContent: "center",
                alignItems: "center",
                width: 1,
            }}
        >
            <Grid item>
                <Typography sx={{ fontSize: 20, mx: 5, textAlign: "center" }}>
                    {info}
                </Typography>
            </Grid>
        </Grid>
    );
};

export const ActionGridToolbar = ({ children }) => {
    return (
        <Grid
            item
            container
            mx={5}
            sx={{
                display: "flex",
                justifyContent: "flex-start",
                p: 1,

                borderTop: theme => `1px solid ${theme.palette.neutral.dark}`,
                borderBottom: theme =>
                    `1px solid ${theme.palette.neutral.dark}`,
                typography: "bodyMajor",
                "& button": {
                    mr: 1,
                    flexShrink: 0,
                },
            }}
        >
            <Grid
                item
                sx={{
                    display: "inline-flex",
                    width: 72,
                    flexShrink: 0,
                    alignItems: "center",
                    boxSizing: "inherit",
                    typography: "bodyMajor",
                }}
            >
                Actions
            </Grid>
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    overflowX: "inherit",
                }}
            >
                {children}
            </Box>
        </Grid>
    );
};

export const FilterGridToolbar = ({ children }) => {
    return (
        <Grid
            item
            container
            mx={5}
            mt={1}
            sx={{
                display: "flex",
                justifyContent: "flex-start",
                p: 1,

                borderBottom:
                    theme => `1px solid ${theme.palette.neutral.dark}`,
                "& button": {
                    mr: 1,
                    flexShrink: 0,
                },
            }}
        >
            <Grid
                item
                sx={{
                    display: "inline-flex",
                    width: 72,
                    flexShrink: 0,
                    alignItems: "center",
                    boxSizing: "inherit",
                    typography: "bodyMajor",
                }}
            >
                Filters
            </Grid>
            <Grid
                item
                xs
                sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    overflowX: "inherit",
                }}
            >
                {children}
            </Grid>
        </Grid>
    );
};




export const DialogActionsForm = ({ children }) => {
    return (
        <Paper elevation={1} sx={{ position: "sticky", zIndex: 1500, bottom: 0, backgroundColor: "white", pr: 2 }}>
            <DialogActions>
                {children}
            </DialogActions>
        </Paper>
    )
}