export const AUTHENTICATED = "AUTHENTICATED";
export const UNAUTHENTICATED = "UNAUTHENTICATED";
export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR";
export const UNHELPFULTOKEN = "UNHELPFULTOKEN";
export const VERSIONCHANGE = "VERSIONCHANGE";
export const REFRESHTOKEN = "REFRESHTOKEN";
export const CONNECTEDSIGNALR = "CONNECTEDSIGNALR";
export const CONSOLELOG = "CONSOLELOG";

export const ENTERED = "ENTERED";
export const RESETERROR = "RESETERROR";
export const TABALIVE = "TABALIVE";

export const LOAD_TRANSLATIONS = "LOAD_TRANSLATIONS";
export const SET_LOCALE = "SET_LOCALE";

// userPref's
export const CURRENTENTITY = "CURRENTENTITY";
export const TREEMENUWIDTH = "TREEMENUWIDTH";
export const SETTINGSMENUWIDTH = "SETTINGSMENUWIDTH";
export const UPDATEENTITYTREE = "UPDATEENTITYTREE";
export const UPDATESPECIFICNODE = "UPDATESPECIFICNODE";
export const QUICKSEARCHFILTER = "QUICKSEARCHFILTER";
export const TREEVIEWNODEEXPAND = "TREEVIEWNODEEXPAND";
export const UPDATEHIGHLIGHTEDROWS = "UPDATEHIGHLIGHTEDROWS";
export const MAPPREF = "MAPPREF";
export const INITIALIZEPREFS = "INITIALIZEPREFS";
export const QUICKSEARCH = "QUICKSEARCH";
export const ISFETCHING = "ISFETCHING";
export const NEXTFOLLOWEDUSERROUTE = "NEXTFOLLOWEDUSERROUTE"; // Should be renamed in : NEXTFOLLOWEDUSERROUTE 
export const NEWNOTIFCOUNT = "NEWNOTIFCOUNT";

// bookmarks
export const MENUBOOKMARKED = "MENUBOOKMARKED";
export const MENUUNBOOKMARKED = "MENUUNBOOKMARKED";

// filtering
export const SEARCHTEXTCHANGED = "SEARCHTEXTCHANGED";


export const HIDENEWFEATURESPOPUP = "HIDENEWFEATURESPOPUP";


export const UPDATESHEETNAME = "UPDATESHEETNAME";

export const UPDATEDATAVALIDATIONPROGRESS = "UPDATEDATAVALIDATIONPROGRESS";

export const UPDATEENTITIESLIST = "UPDATEENTITIESLIST";