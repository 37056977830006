import React, { useCallback, useEffect, useState } from "react";
import { TreeView } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, CircularProgress, IconButton, styled, Typography } from "@mui/material";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { t } from "react-i18nify";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import {
    menuUnbookmarked,
    updateSpecificNodeEntityTree,
} from "../../js/redux/actions/userPref";
import { useAxiosConfig, useDebounceEffect } from "../../js/utils/customHooks";
import BookmarkRemoveOutlinedIcon from "@mui/icons-material/BookmarkRemoveOutlined";
import { BasicTooltip } from "./StyledComponents";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useEditionRightsPerEntityAccess } from "../../js/utils/customHooks";

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.selected}`]: {
        //borderRadius: "8px",
        // background: "linear-gradient(90deg, rgba(237,0,0,0.25) 0%, rgba(255,255,255,1) 100%)",
        // width: "max-content",
    }
}));

const DisplayingBookmarks = ({ element }) => {
    const [affichageEtoile, setAffichageEtoile] = useState();
    const dispatch = useDispatch();
    function entreeSouris() {
        setAffichageEtoile(element.Label);
    }
    function sortieSouris() {
        setAffichageEtoile(!element.Label);
    }
    const deferEntree = useDebounceEffect(entreeSouris, 100);
    const deferSortie = useDebounceEffect(sortieSouris, 100);

    function removeBookmark(e) {
        e.stopPropagation();
        dispatch(
            menuUnbookmarked({
                ID: element.ID,
                Label: element.Label,
                Route: element.route,
                DirectParentLabel: element.DirectParentLabel,
            })
        );
    }
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                margin: "4px 0px",
            }}
            onMouseLeave={deferSortie}
            onMouseEnter={deferEntree}
        >
            <Typography variant="bodyMajor" sx={{ fontSize: 11, opacity: 0.8 }}>
                {element.DirectParentLabel}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2"> {element.Label}</Typography>
                {affichageEtoile ? (
                    <BasicTooltip title={t("tree.RemoveFromBookmarks")}>
                        <IconButton
                            sx={{
                                p: 0,
                                m: "0px 0px 0px 4px",
                                position: "absolute",
                                right: 24,
                                bottom: 8,
                                zIndex: 9999,
                            }}
                            onClick={e => removeBookmark(e)}
                        >
                            <BookmarkRemoveOutlinedIcon
                                sx={{ height: 20, width: 20 }}
                            />
                        </IconButton>
                    </BasicTooltip>
                ) : null}
            </Box>
        </Box>
    );
};

const LoadingNode = ({ route, GenerateNodes, dataMapper, parentNode }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [nodeLoaded, setNodeLoaded] = useState([]);
    const dispatch = useDispatch();
    const nodeToRefreshID = useSelector(
        state => state.userPref.nodeToRefreshID
    );

    const updatedEntityTree = useSelector(
        state => state.userPref.LastEntityTreeUpdate
    );

    const config = useAxiosConfig();

    const fetch = useCallback(
        async isSubscribed => {
            try {
                const response = await Axios.get(route, config);
                if (!isSubscribed) return;
                if (!response.data) return;
                if (response.data.map)
                    setNodeLoaded(
                        response.data.map(e => ({
                            ...dataMapper(e),
                            parentNode,
                        }))
                    );
                else {
                    setNodeLoaded(dataMapper(response.data));
                }
            } catch (e) {
                console.error(e);
            }
            setIsLoading(false);
        },
        [config, route, dataMapper, parentNode]
    );

    useEffect(() => {
        let isSubscribed = true;
        if (isLoading) fetch(isSubscribed);
        return () => (isSubscribed = false);
    }, [isLoading, fetch]);

    useEffect(() => {
        if (nodeToRefreshID === parentNode.ID) {
            setIsLoading(true);
            dispatch(updateSpecificNodeEntityTree(null));
        }
    }, [nodeToRefreshID, parentNode, dispatch]);

    useEffect(() => {
        setIsLoading(true);
    }, [updatedEntityTree]);

    return isLoading ? (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                mt: 1,
                pr: 3,
            }}
        >
            <CircularProgress size={12} color={"secondary"} />
        </Box>
    ) : (
        GenerateNodes(nodeLoaded)
    );
};

const Children = ({ parentNode, childrendField, GenerateNodesFunction }) => {
    if (Boolean(parentNode.loadingRoute)) {
        return (
            <LoadingNode
                route={parentNode.loadingRoute}
                dataMapper={parentNode.dataMapper}
                GenerateNodes={GenerateNodesFunction}
                parentNode={parentNode}
            />
        );
    }
    return GenerateNodesFunction(parentNode[childrendField]);
};


const EmptyStationGroupRenderNode = ({
    element,
    label,
    onClick,
    onContextMenu
}) => {
    return (
        <TreeItem
            id={element}
            nodeId={element}
            key={element}
            label={
                <BasicTooltip title={t("tree.StationGroupNodeEmpty")} >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="body2" sx={{ color: "#B7CBD3" }}>
                            {label}
                        </Typography>
                        <AddCircleOutlineOutlinedIcon sx={{ position: "relative", left: "5%", width: "18px", height: "18px" }} color="secondary" />

                    </Box>
                </BasicTooltip>
            }
            onClick={onClick}
            onContextMenu={onContextMenu}
        />
    )
}
/**
 * Generic component to display a tree
 * @typedef {any} props
 * @prop {any[]} props.JSONData - Data of the tree.
 * @prop {string} props.childrendField - name of the children field.
 * @prop {string} props.labelField - name of the label field.
 * @prop {string} props.idField  - name of the label field.
 * @prop {callback} props.onClick - callabck when onClick envent is caught on the node
 * @prop {callback} props.onContextMenu callabck when onContextMenu envent is caught on the node
 */
const JSONtoTreeView = ({
    JSONData = [],
    childrendField = "children",
    labelField = "label",
    idField = "ID",
    renderLabel = (elem, __i) =>
        elem.isBookmark ? (
            <DisplayingBookmarks element={elem} />
        ) : (
            <Typography variant="body2">{elem[labelField]}</Typography>
        ),
    onClick = (__node, __event) => {
        /* Execution of parent's methods */
        /* Callabck when onClick envent is caught on the node */
    },
    onContextMenu = (__node, __event) => {
        /* Execution of parent's handleContextMenu() */
        /* Callabck when onContextMenu envent is caught on the node */
    },
    defaultSelected,
    ...props
}) => {

    let { isMoveDialog } = props
    const editingRights = useEditionRightsPerEntityAccess(JSONData[0]["OTEntityID"], [
        "Contributor",
    ]);

    const GenerateNodes = useCallback(
        (data = []) => {
            return data && data.length ? (
                <>
                    {data.map((e, i) => {
                        const isEmptyStationGroup = e.NodeType === "OTEntityTreeTemplateStationGroupNodeType" && !e.HasChildren
                        if (isEmptyStationGroup) {
                            /** If it's a move dialog for document, there's no reason to show the Station Group node with '+' button */
                            if (isMoveDialog || !editingRights) {
                                return null
                            } else
                            return (
                                <EmptyStationGroupRenderNode
                                    key={"empty-station-group-node"}
                                    element={e[idField]}
                                    label={e[labelField]}
                                    onClick={ev => {
                                        ev.stopPropagation();
                                        ev.preventDefault();
                                        onContextMenu(e, ev);
                                    }}
                                    onContextMenu={ev => {
                                        ev.stopPropagation();
                                        ev.preventDefault();
                                        onContextMenu(e, ev);
                                    }}
                                />
                            )
                        } else {
                            /* A random key is provided because the same node can also be in Bookmarks. And key is not used, just for DOM structure purpose. */
                            return (
                                <StyledTreeItem
                                    id={e[idField]}
                                    nodeId={e[idField]}
                                    key={e[idField]}
                                    label={
                                        e.renderLabel
                                            ? e.renderLabel(e, i)
                                            : renderLabel(e, i)
                                    }
                                    onClick={ev => {
                                        onClick(e, ev);
                                    }}
                                    onContextMenu={ev => {
                                        ev.stopPropagation();
                                        ev.preventDefault();
                                        onContextMenu(e, ev);
                                    }}
                                >
                                    {e.loadingRoute ||
                                        (e[childrendField] &&
                                            e[childrendField].length > 0) ? (
                                        <Children
                                            parentNode={e}
                                            GenerateNodesFunction={GenerateNodes}
                                            childrendField={childrendField}
                                        />
                                    ) : null}
                                </StyledTreeItem>
                            )
                        }
                    })}
                </>
            ) : (
                <Typography
                    color="textSecondary"
                    style={{ textAlign: "center" }}
                >
                    {t("placeholder.NoEntry")}
                </Typography>
            );
        },
        [childrendField, idField, renderLabel, onClick, onContextMenu, isMoveDialog, labelField, editingRights]
    );

    return (
        <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            selected={[defaultSelected]}
            sx={{ fontSize: "4px", listStyleType: "none", width: 1 }}
            {...props}
        >
            {GenerateNodes(JSONData)}
        </TreeView>
    );
};

export default JSONtoTreeView;
