import React, { useCallback } from "react";
import { Box, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setLocale } from "../../js/redux/actions/i18n";
import { t } from "react-i18nify";
import Axios from "axios";
import { useAxiosConfig } from "../../js/utils/customHooks";
import { oTUser } from "../../js/constants/routes";

const LanguageSelector = () => {
    const locale = useSelector(state => state.i18n.locale);
    const dispatch = useDispatch();
    const config = useAxiosConfig();
    const changeLanguage = useCallback(
        async e => {
            await Axios({
                method: "PUT",
                url: `${oTUser}/changeLanguage`,
                data: e.target.value,
                headers: {
                    ...config.headers,
                    "Content-Type": "application/json",
                },
            })
                .then(function (response) {
                    dispatch(setLocale(e.target.value));
                })
                .catch(function (error) {
                    console.error(error);
                });
        },
        [dispatch, config.headers]
    );

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
            }}
        >
            <Select
                value={locale}
                variant="standard"
                disableUnderline={true}
                onChange={e => changeLanguage(e)}
                label="Language"
                displayEmpty
                color="secondary"
                sx={{
                    color: theme => theme.palette.primary.contrastText,
                    fontSize: 12,
                }}
            >
                <MenuItem value="ENG">{t("languages.English")}</MenuItem>
                <MenuItem value="FRA">{t("languages.French")}</MenuItem>
                <MenuItem value="NLD">{t("languages.Dutch")}</MenuItem>
                <MenuItem value="DEU">{t("languages.German")}</MenuItem>
                <MenuItem value="SPA">{t("languages.Spanish")}</MenuItem>
                <MenuItem value="POR">{t("languages.Portuguese")}</MenuItem>
            </Select>
        </Box>
    );
};

export default LanguageSelector;
