import { deDE, enUS, esES, frFR, nlNL, ptPT } from "@mui/material/locale";

export const booleanOptions = [
    { value: true, label: "true" },
    { value: false, label: "false" },
];

export function itsABool(value) {
    if (value === Boolean(false)) {
        return true;
    } else if (value === true) {
        return true;
    } else {
        return false;
    }
}

export const frequencyQuery = [
    { value: "Monday", label: "enum.frequencyQuery.Monday" },
    { value: "Friday", label: "enum.frequencyQuery.Friday" },
    { value: "BeginningMonth", label: "enum.frequencyQuery.BeginningMonth" }, //
    { value: "EndMonth", label: "enum.frequencyQuery.EndMonth" },
];
/** Beware, as label we give only the complete key of translation, the function to translate, with t(), should be provided at component level (where <VSelect/> is called)  */
export const languagesOptions = [
    {
        value: "ENG",
        label: "languages.English",
        isoAlpha3: "en",
        Material: enUS,
    },
    {
        value: "FRA",
        label: "languages.French",
        isoAlpha3: "fr",
        Material: frFR,
    },
    {
        value: "NLD",
        label: "languages.Dutch",
        isoAlpha3: "nl",
        Material: nlNL,
    },
    {
        value: "DEU",
        label: "languages.German",
        isoAlpha3: "de",
        Material: deDE,
    },
    {
        value: "SPA",
        label: "languages.Spanish",
        isoAlpha3: "es",
        Material: esES,
    },
    {
        value: "POR",
        label: "languages.Portuguese",
        isoAlpha3: "pt-PT",
        Material: ptPT,
    },
];
/** When managing status of user, in Entity Users view */
export const entityUserSecurityType = [
    { value: "Contributor", label: "select.Contributor" },
    { value: "Reader", label: "select.Reader" },
];
export const entityUserSecurityTypeExternal = [
    { value: "External", label: "External" },
];

/** When user is making a request and asks user status */
export const requestedAccessOptions = [
    { value: "CONTRIBUTOR", label: "Contributor" },
    { value: "READER", label: "Reader" },
];

export const requestStatesOptions = [
    { value: "Requested", label: "Requested" },
    { value: "Rejected", label: "Rejected" },
    { value: "Validated", label: "Validated" },
];

/** In the editor, the user can add only one of the following nodes : TextType (document) and StudyType (study) */
export const entityTemplateNodeTypes = [ // adapt in backend (without MapType option)
    { value: "TextType", label: "tree.TextType" },
    { value: "StudyType", label: "tree.StudyType" },
];
export const userState = [
    { value: "VALID", label: "Valid" },
    { value: "REMOVED", label: "Removed" },
];

export const eddFileState = [
    { value: "Draft", label: "Draft" },
    { value: "Submitted", label: "Submitted" },
    { value: "Rejected", label: "Rejected" },
    { value: "Validated", label: "Validated" },
];

export const optionsDocumentCategory = [
    { value: "Public", label: "field.Public" },
    { value: "Confidential", label: "field.Confidential" },
];

export const entityNodeTypesWithTemplate = [
    {
        value: "OTEntityTreeTemplateTextNodeType",
        label: "Text Type - Entity Template",
    },
    {
        value: "OTEntityTreeTemplateStudyNodeType",
        label: "Study Type - Entity Template",
    },
    {
        value: "OTEntityTreeTemplateMapNodeType",
        label: "Map Type - Entity Template",
    },
    {
        value: "OTEntityTreeTemplateStationNodeType",
        label: "Station Type - Entity Template",
    },
    {
        value: "StudyTreeTemplateTextNodeType",
        label: "Text Type - Study Template",
    },
    {
        value: "StudyNodeType",
        label: "Study Type",
    },
    {
        value: "FolderNodeType",
        label: "Folder Type",
    },
    {
        value: "OTEntityTreeTemplateStationGroupNodeType",
        label: "Station Group Type",
    },
    {
        value: "DocumentSetNodeType",
        label: "Document Set Type",
    },
    {
        value: "DocumentNodeType",
        label: "Document Type",
    },
];
export const entityNodeTypes = [
    {
        value: "StudyNodeType",
        label: "Study Type",
    },
    {
        value: "FolderNodeType",
        label: "Folder Type",
    },
    {
        value: "OTEntityTreeTemplateStationGroupNodeType",
        label: "Station Group Type",
    },
    {
        value: "DocumentSetNodeType",
        label: "Document Set Type",
    },
    {
        value: "DocumentNodeType",
        label: "Document Type",
    },
];
export const studyNodeTypes = [{ value: "TextType", label: "Text Type" }];

export const querySharingTypes = [
    { value: "OneTsigane", label: "common.OneTsigane"},
    { value: "Entity", label: "field.Entity"},
    { value: "Private", label: "common.Private"},
];

export const basemapsArcgisOptions = [
    {
        label: "satellite",
        value: "satellite",
    },
    {
        label: "hybrid",
        value: "hybrid",
    },
    {
        label: "oceans",
        value: "oceans",
    },
    {
        label: "national-geographic",
        value: "national-geographic",
    },
    {
        label: "terrain",
        value: "terrain",
    },
    {
        label: "osm",
        value: "osm",
    },
    {
        label: "dark-gray-vector",
        value: "dark-gray-vector",
    },
    {
        label: "gray-vector",
        value: "gray-vector",
    },
    {
        label: "streets-vector",
        value: "streets-vector",
    },
    {
        label: "streets-night-vector",
        value: "streets-night-vector",
    },
    {
        label: "streets-relief-vector",
        value: "streets-relief-vector",
    },
    {
        label: "streets-navigation-vector",
        value: "streets-navigation-vector",
    },
    {
        label: "topo-vector",
        value: "topo-vector",
    },
];
