import React from "react";
import { Grid } from "@mui/material";
import { t } from "react-i18nify";
import { Box } from "@mui/system";
import { Redirect, useParams } from "react-router-dom";
import * as r from "../../../js/constants/routes";
import { StepQueryBuilder } from "../QueryBuilder/QueryBuilder";
import { FormStepper } from "../../Widgets/CustomStepper";
import StepLimitDefinitionAndSelection from "./StepLimitDefinitionAndSelection";
import { useEditionRightsPerUserStatus } from "../../../js/utils/customHooks";

/** Form Stepper to create or edit a limit. Consists of two steps:
 * StepLimitDefinitionAndSelection (selection of an entity) and StepQueryBuilder (defining the set of rules for the entity) */
const EditionPanelLimitStepper = ({ baseURL = r.limit.route }) => {
    const { mode } = useParams();
    const edition = mode === "edit";
    const editingRights = useEditionRightsPerUserStatus([
        "Contributor",
        "EntityManager",
    ]);
    const fieldName = "EntityLimits";
    const steps = [
        {
            name: t("view.limit.limitSelection"),
            form: <StepLimitDefinitionAndSelection fieldName={fieldName} />,
        },
        {
            name: t("view.query.conditions.conditions"),
            form: <StepQueryBuilder fieldName={fieldName} />,
        },
    ];

    return !editingRights ? (
        <Redirect to={"/home"} />
    ) : (
        <Grid m={1} p={2} sx={{ minHeight: "calc(100vh - 140px)" }}>
            <Box
                sx={{
                    typography: "h3",
                    pt: 6,
                    pl: 4,
                    pb: 2,
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {edition
                    ? `${t("view.limit.stepper.EditLimit")}:`
                    : t("view.limit.stepper.CreateNewLimit")}
            </Box>
            <Grid mx={4} sx={{ height: "100%" }}>
                <FormStepper
                    steps={steps}
                    baseURL={baseURL}
                    currentLocation={r.limits}
                />
            </Grid>
        </Grid>
    );
};

export default EditionPanelLimitStepper;
