import React, { useState, useEffect } from "react";
import { alpha } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import {
    Box,
    IconButton,
    InputBase,
    Popper,
    Paper,
    FormControl,
    FormControlLabel,
    Checkbox,
    Divider,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import FilterIcon from "@mui/icons-material/FilterList";
import { Select } from "./Custom Inputs/Select";
import { useDispatch, useSelector } from "react-redux";
import { BasicTooltip } from "./StyledComponents";
import {
    updateQuickSearch,
    updateQuickSearchFilter,
} from "../../js/redux/actions/userPref";
import { useHistory } from "react-router-dom";
import { t } from "react-i18nify";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { randomID } from "../../routers/MenuRouter";
import { historyPush } from "../../js/utils/genericMethods";

const defaultSearchCategories = [
    { label: "Entities", value: "Entities", checked: true },
    { label: "Documents", value: "Documents", checked: true },
    { label: "Stations", value: "Stations", checked: true },
    { label: "Zones", value: "Zones", checked: true },
    { label: "Samplings", value: "Samplings", checked: true },
    { label: "Branches", value: "Branches", checked: true },
];

const maxResultOptions = [
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "20", value: 20 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
];

const SearchBar = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const searchInput = useSelector(state => state.userPref.QuickSearch);
    const [searchText, setSearchText] = useState(searchInput);
    const history = useHistory();
    const quickSearchFilter = useSelector(
        state => state.userPref.QuickSearchFilter
    );
    const openPopper = Boolean(anchorEl);
    const id = openPopper ? "simple-popper" : undefined;

    const handlePopper = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const [searchCategories, setSearchCategories] = useState(
        quickSearchFilter?.SearchCategories
            ? quickSearchFilter?.SearchCategories
            : defaultSearchCategories
    );
    function updateCategories(e) {
        const updated = searchCategories?.map(obj => {
            if (obj.label === e.target.name) {
                return { ...obj, checked: e.target.checked };
            } else {
                return obj;
            }
        });
        return setSearchCategories(updated);
    }

    const [maxResultCount, setMaxResultCount] = useState(
        quickSearchFilter?.MaxResultCount
            ? quickSearchFilter.MaxResultCount
            : maxResultOptions[4]
    );
    function updateMaxResultCount(event, data) {
        setMaxResultCount(data);
    }

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed && searchCategories && maxResultCount) {
            const quickSearchFilter = {
                SearchCategories: searchCategories,
                MaxResultCount: maxResultCount,
            };
            dispatch(updateQuickSearchFilter(quickSearchFilter));
        }

        return () => (isSubscribed = false);
    }, [searchCategories, maxResultCount, dispatch]);

    const handleSearch = event => {
        dispatch(updateQuickSearch(event.target.value));
        setSearchText(event.target.value);
    };

    function executeSearch(e) {
        if (e.key === "Enter" && searchText) {
            return historyPush(history, `/searchResults/${searchText}/${randomID()}`);
        }
    }

    const handlePopperClicAway = () => {
        setAnchorEl(null);
    };

    return (
        <Box
            component="div"
            sx={{
                position: "relative",
                borderRadius: "20px",
                border: 1,
                borderColor: alpha(theme.palette.primary.main, 0.15),
                "&:hover": {
                    borderColor: "primary.dark",
                },
                margin: "8px 16px 8px 8px",
                width: { xs: "100%", md: 340 },
            }}
        >
            <Box
                component="div"
                sx={{
                    height: 1,
                    position: "absolute",
                    pointerEvents: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                }}
            ></Box>
            <InputBase
                value={searchText ?? ""}
                onChange={handleSearch}
                onKeyDown={executeSearch}
                fullWidth
                sx={{
                    color: "inherit",
                    "& .MuiInputBase-input": {
                        padding: theme.spacing(1, 1, 1, 0),
                        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
                        transition: theme.transitions.create("width"),
                    },
                }}
                endAdornment={
                    <>
                        <BasicTooltip
                            title={t("common.QuickSearchFilter")}
                            placement="top"
                        >
                            <IconButton onClick={e => handlePopper(e)}>
                                <FilterIcon />
                            </IconButton>
                        </BasicTooltip>
                        <Divider
                            orientation="vertical"
                            variant="fullWidth"
                            sx={{
                                height: 32,
                                m: "0px 8px",
                            }}
                        />
                        <BasicTooltip
                            title={t("common.GetResults")}
                            placement="top"
                        >
                            <IconButton
                                onClick={e =>
                                    searchText &&
                                    historyPush(history,
                                        `/searchResults/${searchText}/${randomID()}`
                                    )
                                }
                            >
                                <SearchIcon />
                            </IconButton>
                        </BasicTooltip>
                    </>
                }
                placeholder={`${t("common.Search")}...`}
                inputProps={{ "aria-label": "search" }}
            />

            <Popper
                open={openPopper}
                anchorEl={anchorEl}
                sx={{ zIndex: 9999 }}
                placement="bottom"
                id={id}
            >
                <ClickAwayListener onClickAway={() => handlePopperClicAway()}>
                    <Paper
                        elevation={6}
                        sx={{
                            borderRadius: "0px 0px 8px 8px",
                            margin: "0px 66px 0px 0px",
                            padding: 2,
                            width: 180,
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    typography: "body2",
                                    textTransform: "uppercase",
                                }}
                            >
                                {t("common.QuickSearchInto")}
                            </Box>
                            <Box>
                                {searchCategories?.map((item, index) => {
                                    return (
                                        <FormControl
                                            key={index}
                                            sx={{ width: 160 }}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name={item.label}
                                                        onChange={e =>
                                                            updateCategories(e)
                                                        }
                                                        checked={item.checked}
                                                    />
                                                }
                                                label={t(
                                                    `grid.title.${item.label}`
                                                )}
                                            />
                                        </FormControl>
                                    );
                                })}
                            </Box>
                            <Select
                                defaultValue={maxResultCount}
                                sx={{ mt: 2 }}
                                onChange={(event, data) =>
                                    updateMaxResultCount(event, data)
                                }
                                label={t("field.ResultsSize")}
                                options={maxResultOptions}
                            />
                        </Box>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </Box>
    );
};

export default SearchBar;
