import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Axios from "axios";
import * as cst from "../../../js/constants/Misc/Misc_Axios";
import PageviewIcon from "@mui/icons-material/Pageview";
import DownloadIcon from "@mui/icons-material/Download";
import {
    IconButton,
    Grid,
    Box,
    Typography,
    LinearProgress,
    FormHelperText,
    InputLabel,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import DeletePicture from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { checkFileUploaded } from "../../../js/utils/inputValidations";
import { BasicTooltip } from "../StyledComponents";
import { useFormContext } from "react-hook-form";
import { readFileAsync } from "../../../js/utils/genericMethods";

const urlPdfImage = "url(https://upload.wikimedia.org/wikipedia/commons/8/8f/Adobe-24943.svg)";

const classes = {
    DropzoneLegende: theme => ({
        marginTop: `${theme.spacing(1)}px`,
    }),
    OrderingArrowBox: theme => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginRight: -theme.spacing(3),
        marginLeft: -theme.spacing(1),
        marginTop: -theme.spacing(2),
        marginBottom: -theme.spacing(2),
    }),
    ItemDropzone: {
        display: "block",
        flexGrow: 1,
    },
    dropzoneBox: {
        borderRadius: "4px",
        border: "1px solid #bbba",
        "&:focus": {
            border: "5px solid rgba(189, 189, 189, 1)",
        },
        height: 1,
        width: 1,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "&:hover": {
            border: "1px solid black",
        },
    },
    dropzone: {
        minHeight: "126px",
        height: 1,
        backgroundRepeat: "no-repeat",
        width: 1,
        backgroundSize: "contain",
        backgroundPosition: "center center",
    },
    dropzoneMsg: theme => ({
        color: "hsl(0, 0%, 50%)",
        padding: `${theme.spacing(6)} ${theme.spacing(1)}`,
        textAlign: "center",
    }),
    dropzoneTileImage: (fileType, url) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundSize: "cover",
        width: 1,
        backgroundImage:
            fileType === "pdf" ? urlPdfImage : fileType === "img" && url,
    }),
};

export const Dropzone = ({
    onChange = () => {
        /* Execution of parent MultiDropzone's function) */
    },
    label,
    value,
    fileType,
    isMulti,
    disabled = false,
    //isError = false, NOT USED !?
    deleting = false,
}) => {
    const fileUploaded = checkFileUploaded(value);

    const url = fileUploaded ? `url(${cst.imageSmallSize}/${value})` : "none";

    const token = useSelector(state => state.auth.Token);

    const [loading, setLoading] = useState(false);

    const onUploaded = useCallback(
        newGuidImg => {
            onChange(newGuidImg);
        },
        [onChange]
    );

    function uploadURL(typeOfFile, fileName) {
        if (typeOfFile === "img") {
            return `/image/upload/${fileName}`;
        } else if (typeOfFile === "pdf") {
            return `/pdf/upload/${fileName}`;
        }
    }

    function downloadURL(typeOfFile, _value) {
        if (typeOfFile === "img") {
            return `/image/${_value}`;
        } else if (typeOfFile === "pdf") {
            return `/pdf/${_value}`;
        }
    }

    function validationtypeOfFile(typeOfFile) {
        if (typeOfFile === "img") {
            return { "image/*": [".jpeg", ".png"] };
        } else if (typeOfFile === "pdf") {
            return { "application/*": [".pdf"] };
        }
    }

    const onDrop = useCallback(
        async acceptedFiles => {
            let sortedArray = null;
            sortedArray = acceptedFiles.sort((a, b) =>
                a.name.toString().localeCompare(b.name)
            );
            setLoading(true);
            for (const file of sortedArray) {
                try {
                    const binaryFile = await readFileAsync(file);
                    let fileName = file.name;
                    let url = uploadURL(fileType, fileName);
                    let data = binaryFile;
                    let config = {
                        headers: {
                            "Content-Type": "application/octet-stream",
                            Authorization: "Bearer " + token,
                        },
                    };
                    let response = await Axios.post(url, data, config);
                    onUploaded(response.data);
                } catch (error) {
                    console.error(error);
                    toastr.error(
                        "Impossible to upload the file. Please try again later"
                    );
                    setLoading(false);
                }
            }
            setLoading(false);
        },
        [onUploaded, token, fileType]
    );

    const { getRootProps, getInputProps, isDragActive, isDragReject } =
        useDropzone({
            onDrop,
            onUploaded,
            label: label,
            multiple: isMulti,
            accept: validationtypeOfFile(fileType),
        });

    const allowedExtensions = Object.values(validationtypeOfFile(fileType))[0];

    return (
        <>
            {label && <Box sx={classes.label}>{label}</Box>}
            <Box sx={classes.dropzoneBox}>
                {disabled ? (
                    <Box sx={classes.dropzoneTileImage(fileType, url)} />
                ) : (
                    <Box
                        {...getRootProps({
                            onClick: evt => evt.preventDefault(),
                        })}
                        sx={classes.dropzoneTileImage(fileType, url)}
                    >
                        <input {...getInputProps()} />
                        <Typography variant="body2" sx={classes.dropzoneMsg}>
                            {!isDragActive &&
                                !loading &&
                                !fileUploaded &&
                                `Drag a file here to upload it.`}
                            {isDragActive && !isDragReject && "Drag the file"}
                            {isDragReject &&
                                `Sorry, the file was not accepted. (permission: ${allowedExtensions}) `}
                        </Typography>

                        <IconButton disabled>
                            {!fileUploaded && (
                                <AddCircleIcon fontSize="large" />
                            )}
                        </IconButton>

                        {loading && <LinearProgress variant="query" />}
                    </Box>
                )}
                {fileUploaded && (
                    <Box>
                        <BasicTooltip title="Download the file">
                            <Link
                                to={downloadURL(fileType, value)}
                                download
                                target="_blank"
                                underline="none"
                            >
                                <IconButton>
                                    <DownloadIcon />
                                </IconButton>
                            </Link>
                        </BasicTooltip>
                        {fileType === "pdf" && (
                            <BasicTooltip title="Visualize the file">
                                <Link
                                    to={"/pdf/" + value}
                                    target="_blank"
                                    underline="none"
                                >
                                    <IconButton>
                                        <PageviewIcon />
                                    </IconButton>
                                </Link>
                            </BasicTooltip>
                        )}

                        {deleting && (
                            <BasicTooltip title="Delete the file">
                                <IconButton onClick={() => onChange(null)}>
                                    <DeletePicture />
                                </IconButton>
                            </BasicTooltip>
                        )}
                    </Box>
                )}
            </Box>
        </>
    );
};

export const MultiDropzone = ({
    value,
    onChange,
    fileType,
    disabled = false,
    isError = false,
}) => {
    const handleChangeDropzone = (index, tile) => {
        const updatedValue = [...value];
        if (index >= 0) {
            if (tile === null || tile === undefined) {
                updatedValue.splice(index, 1);
            } else {
                updatedValue[index] = tile;
            }
        } else {
            updatedValue.push(tile);
        }
        onChange(updatedValue);
    };

    const [, setRefresh] = useState(false);

    function swapPictures(picturesArray, fromIndex, toIndex) {
        const element = picturesArray[fromIndex];
        picturesArray.splice(fromIndex, 1);
        picturesArray.splice(toIndex, 0, element);
    }

    let arrLength = value && value.length;
    let isEndOfIndex = arrLength - 1;

    useEffect(() => {
        // Ensure unique images are managed properly
        const uniqueValue = Array.from(new Set(value));
        if (uniqueValue.length !== value.length) {
            onChange(uniqueValue);
        }
    }, [value, onChange]);

    return (
        <>
            <Grid item container spacing={1} key={"dropzone-tile"}>
                {disabled ? null : (
                    <Grid
                        sx={{ width: "calc(100% + 8px)" }}
                        item
                        key={"new-dropzone-tile"}
                    >
                        <Dropzone
                            isMulti={true}
                            onChange={t => handleChangeDropzone(-1, t)}
                            fileType={fileType}
                            isError={isError}
                        />
                    </Grid>
                )}
                {value &&
                    value.map((tile, index) => (
                        <Grid
                            key={`drop_${index}`}
                            container
                            item
                            xs={12}
                            sm={6}
                            sx={{
                                display: "flex",
                                height: "fit-content",
                                mt: 1,
                            }}
                        >
                            <Grid item xs={10}>
                                <Box sx={classes.ItemDropzone}>
                                    <Dropzone
                                        disabled={disabled}
                                        isMulti={false}
                                        value={tile}
                                        onChange={t =>
                                            handleChangeDropzone(index, t)
                                        }
                                        fileType={fileType}
                                        deleting={!disabled}
                                    />
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={2}
                                key={index + "arrows"}
                                sx={classes.OrderingArrowBox}
                            >
                                {index !== isEndOfIndex ? (
                                    <>
                                        <IconButton
                                            onClick={() => {
                                                swapPictures(
                                                    value,
                                                    index,
                                                    index + 1
                                                );
                                                setRefresh(refresh => !refresh);
                                            }}
                                        >
                                            <ArrowRightIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                swapPictures(
                                                    value,
                                                    index + 1,
                                                    index
                                                );
                                                setRefresh(refresh => !refresh);
                                            }}
                                        >
                                            <ArrowLeftIcon fontSize="small" />
                                        </IconButton>
                                    </>
                                ) : null}
                            </Grid>
                        </Grid>
                    ))}
            </Grid>
        </>
    );
};

export const VMultiDropzone = ({ fieldName, label, fileType }) => {
    const methods = useFormContext();
    const { watch, setValue, errors } = methods;
    const value = watch(fieldName, []) ?? [];
    const [isInitialized] = useState(false);

    const isError = false;
    const errorMessage = isError ? errors[fieldName].message : "";

    return (
        <Box initialized={isInitialized.toString()} sx={{ width: 1, mt: 1 }}>
            {label && (
                <InputLabel error={isError} shrink={true}>
                    {label}
                </InputLabel>
            )}
            <MultiDropzone
                isError={isError}
                value={value}
                onChange={(newValue) => {
                    setValue(fieldName, newValue, {
                        shouldDirty: true,
                        shouldValidate: true,
                    });
                    //onChange(index, guid);
                }}
                fileType={fileType}
            />
            {value === undefined || isError ? (
                <FormHelperText error={isError}>{errorMessage}</FormHelperText>
            ) : null}
        </Box>
    );
};
