import { t } from "react-i18nify";
import { toastr } from "react-redux-toastr";
import { DateOnlyToJSDate, invalidDates } from "../utils/genericMethods";

export const regexTimeOnly = /^([01]\d|2[0-3]):([0-5]\d)$/
export const regexPhoneNumber = /^(\+?\d{1,3}[- ]?)?(\(?\d{3}\)?|[0-9]{3})[- ]?\d{2,5}[- ]?\d{2,5}[- ]?\d{2,5}$/;
export const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const regexGuid = /^[a-z0-9]{8}-([a-z0-9]{4}-){3}[a-z0-9]{12}$/;

export const nCharValidation = (n, montrerN = true) => {
    return {
        maxLength: {
            value: n,
            message: montrerN
                ? `This field cannot excess ${n} characters`
                : "This field has too many characters",
        },
    };
};

export const trimWhiteSpaceValidation = {
    validate: value => {
        return value.trim().length > 0 || t("input.validation.required");
    },
};

export const validationNchar = (n, montrerN = true) => {
    return {
        maxLength: {
            value: n,
            message: montrerN
                ? `This field cannot exceed ${n} characters.`
                : "The maximum number of characters in this fiels has been exceeded.",
        },
    };
};

export const validationAsNumber = () => {
    return {
        valueAsNumber: true,
    };
};
export function checkFileUploaded(value) {
    if (!value || value === "00000000-0000-0000-0000-000000000000") {
        return null;
    } else {
        return value;
    }
}

const someCellHasFocus = (params, api) => {
    return Object.keys(api?.current?.getEditRowsModel()[params.id]).some(
        e => api.current.getCellParams(params.id, e).hasFocus
    );
};
const cellHasFocus = (params, field, api) => {
    return api.current.getCellParams(params.id, field).hasFocus;
};

const displayError = (result, headerName, message, toClean = true) => {
    if (result)
        toastr.error(headerName, message, {
            disableCloseButtonFocus: true,
        });
    else if (toClean) {
        toastr.clean();
    }
};

export const requiredCell = (params, api, headerName = "", field) => {
    const value = params.props.value;
    const hasFocus = cellHasFocus(params, field, api);
    const someHasFocus = someCellHasFocus(params, api);
    if (hasFocus || !someHasFocus) {
        const result =
            value === null ||
            value === undefined ||
            (typeof value === "string" && value.trim().length === 0);
        displayError(
            result,
            headerName,
            "This field is mandatory",
            someHasFocus
        );
        return result;
    }
};
export const validatePhoneNumber = (params, api, headerName = "", field) => {
    const value = params.props.value;
    const hasFocus = cellHasFocus(params, field, api);
    const someHasFocus = someCellHasFocus(params, api);
    if (hasFocus || !someHasFocus) {
        const result = !regexPhoneNumber.test(String(value));
        displayError(
            result,
            headerName,
            "Please enter a valid phone number",
            someHasFocus
        );
        return result;
    }
};

export const cellValidationsMerger = validations => {
    return validations.reduce((a, e) => a() || e());
};

export const validateNCharCell = (
    params,
    api,
    headerName = "",
    field,
    nChar
) => {
    const value = params.props.value;
    const hasFocus = cellHasFocus(params, field, api);
    const someHasFocus = someCellHasFocus(params, api);
    if (hasFocus || !someHasFocus) {
        const result = value.length > nChar;
        displayError(
            result,
            headerName,
            t("input.validation.NumberChar") + nChar,
            someHasFocus
        );
        return result;
    }
};
export const validateEmail = (params, api, headerName = "", field) => {
    const value = params.props.value;
    const hasFocus = cellHasFocus(params, field, api);
    const someHasFocus = someCellHasFocus(params, api);
    if (hasFocus || !someHasFocus) {
        const result = !regexEmail.test(String(value));
        displayError(
            result,
            headerName,
            "Please enter a email adress",
            someHasFocus
        );
        return result;
    }
};

export const dateRangeValidation = (
    params,
    api,
    headerName = "",
    start,
    end
) => {
    const value = params?.props?.value;
    const model = api?.current?.getEditRowsModel();
    const row = model[params.id];
    const startValue = row[start].value;
    const endValue = row[end].value;
    const hasFocus = cellHasFocus(
        params,
        startValue === value ? start : end,
        api
    );
    const someHasFocus = someCellHasFocus(params, api);

    const endDate = invalidDates.includes(endValue) ? null : new Date(endValue);
    const startDate = invalidDates.includes(startValue)
        ? null
        : new Date(startValue);

    const rangeCheck = endDate < startDate;
    const result = endDate && startDate ? rangeCheck : true;
    /* If a date is not specified, we won't trigger the range check */
    if (!endDate || !startDate) {
        return false;
        /* Otherwise, we focus on the current input changed and display range error to the user.  */
    } else if (hasFocus || !someHasFocus) {
        displayError(
            result,
            headerName,
            t("input.validation.DateRangeValidation"),
            someHasFocus
        );
        return result;
    }
};
export const dateOnlyRangeValidation = (
    params,
    api,
    headerName = "",
    start,
    end
) => {
    const value = params?.props?.value;
    const model = api?.current?.getEditRowsModel();
    const row = model[params.id];
    const startValue = row[start].value;
    const endValue = row[end].value;
    const hasFocus = cellHasFocus(
        params,
        startValue === value ? start : end,
        api
    );
    const someHasFocus = someCellHasFocus(params, api);

    const endDate = invalidDates.includes(endValue)
        ? null
        : DateOnlyToJSDate(endValue);
    const startDate = invalidDates.includes(startValue)
        ? null
        : DateOnlyToJSDate(startValue);

    const rangeCheck = endDate < startDate;
    const result = endDate && startDate ? rangeCheck : true;
    /* If a date is not specified, we won't trigger the range check */
    if (!endDate || !startDate) {
        return false;
        /* Otherwise, we focus on the current input changed and display range error to the user.  */
    } else if (hasFocus || !someHasFocus) {
        displayError(
            result,
            headerName,
            t("input.validation.DateRangeValidation"),
            someHasFocus
        );
        return result;
    }
};
