export const unitCategories = {
    NONE: "enum.unitCategories.NONE",
    CONDUCTANCE: "enum.unitCategories.CONDUCTANCE",
    CONDUCTIVITY: "enum.unitCategories.CONDUCTIVITY",
    FLAMMABILITY: "enum.unitCategories.FLAMMABILITY",
    HIGHT_DEPTH: "enum.unitCategories.HIGHT_DEPTH",
    LENGTH: "enum.unitCategories.LENGTH",
    MASS: "enum.unitCategories.MASS",
    MASS_CONCENTRATIONS: "enum.unitCategories.MASS_CONCENTRATIONS",
    MASS_FRACTIONS: "enum.unitCategories.MASS_FRACTIONS",
    MICROBIAL_CELLS_CONCENTRATION:
        "enum.unitCategories.MICROBIAL_CELLS_CONCENTRATION",
    PARTS_PER_MASS: "enum.unitCategories.PARTS_PER_MASS",
    PARTS_PER_VOLUME: "enum.unitCategories.PARTS_PER_VOLUME",
    PERCENTAGE: "enum.unitCategories.PERCENTAGE",
    PRESSURE: "enum.unitCategories.PRESSURE",
    SPEED: "enum.unitCategories.SPEED",
    TEMPERATURE: "enum.unitCategories.TEMPERATURE",
    VOLTAGE: "enum.unitCategories.VOLTAGE",
    VOLUME: "enum.unitCategories.VOLUME",
    VOLUME_FLOW_RATE: "enum.unitCategories.VOLUME_FLOW_RATE",
    WATER_HARDNESS: "enum.unitCategories.WATER_HARDNESS",
    SPECIFIC_VOLUME: "enum.unitCategories.SPECIFIC_VOLUME",
    SOUND_INTENSITY: "enum.unitCategories.SOUND_INTENSITY",
    RADIOACTIVITY_DESINTEGRATION_RATE: "enum.unitCategories.RADIOACTIVITY_DESINTEGRATION_RATE",
    RADIOACTIVITY_PARTICLE_FLUX: "enum.unitCategories.RADIOACTIVITY_PARTICLE_FLUX"
};
