import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import {
    createStateSyncMiddleware,
    withReduxStateSync,
} from "redux-state-sync";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import rootReducer from "../reducers/index";
import signalRMiddleware from "./signalRMiddleWare";
import {
    I18n,
    Translate,
    Localize,
    setTranslationsGetter,
    setLocaleGetter,
    forceComponentsUpdate,
} from "react-i18nify";
export { I18n, Translate, Localize, forceComponentsUpdate };

const persistConfig = {
    key: "OneTsigane",
    storage,
};

const syncConfig = {
    blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
    channel: "oneTsigane_state_sync",
};

const middlewares = [
    thunk,
    createStateSyncMiddleware(syncConfig),
    signalRMiddleware,
];

/** Adds persistence to the store, and cache the state in the local storate of the user's browser. DOC*/
const persistedReducer = persistReducer(
    persistConfig,
    withReduxStateSync(rootReducer)
);

export const store = createStore(
    persistedReducer,
    compose(
        applyMiddleware(...middlewares),
        window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : f => f
    )
);


// i18n
function syncTranslationWithStore(store) {
    setTranslationsGetter(() => store.getState().i18n.translations || {});
    setLocaleGetter(() => store.getState().i18n.locale || '');
}
//
syncTranslationWithStore(store);

export const persistor = persistStore(store);
