import * as actionTypes from "../actionTypes";
import { store } from "../store";

export const currentEntity = CurrentEntity => dispatch => {
    dispatch({
        type: actionTypes.CURRENTENTITY,
        payload: {
            CurrentEntity,
        },
    });
};

export const treeMenuWidth = TreeMenuWidth => dispatch => {
    dispatch({
        type: actionTypes.TREEMENUWIDTH,
        payload: {
            TreeMenuWidth,
        },
    });
};

export const settingsMenuWidth = SettingsMenuWidth => dispatch => {
    dispatch({
        type: actionTypes.SETTINGSMENUWIDTH,
        payload: {
            SettingsMenuWidth,
        },
    });
};

export const updateEntityTree = () => dispatch => {
    dispatch({
        type: actionTypes.UPDATEENTITYTREE,
    });
};

export const updateSpecificNodeEntityTree = nodeToRefreshID => dispatch => {
    dispatch({
        type: actionTypes.UPDATESPECIFICNODE,
        payload: {
            nodeToRefreshID,
        },
    });
};

export const updateQuickSearchFilter = QuickSearchFilter => dispatch => {
    dispatch({
        type: actionTypes.QUICKSEARCHFILTER,
        payload: {
            QuickSearchFilter,
        },
    });
};

export const updateQuickSearch = quickSearch => dispatch => {
    dispatch({
        type: actionTypes.QUICKSEARCH,
        payload: quickSearch,
    });
};

/** Programatically expand the treeview until the reach of the given node (via the nodeID's suite) DOC*/
export const updateTreeViewNodeExpand = TreeViewNodeExpand => dispatch => {
    dispatch({
        type: actionTypes.TREEVIEWNODEEXPAND,
        payload: {
            TreeViewNodeExpand,
        },
    });
};

export const updateHighlightedRows = VisitedRow => dispatch => {
    dispatch({
        type: actionTypes.UPDATEHIGHLIGHTEDROWS,
        payload: {
            [VisitedRow.CurrentLocation]: VisitedRow.VisitedRowID,
        },
    });
};

/** Bookmark actions for child nodes of the tree menu : add and remove (we'll check the route as the id) */
export const menuBookmarked = TreeNode => dispatch => {
    const token = store.getState().auth.Token;
    dispatch({
        type: actionTypes.MENUBOOKMARKED,
        payload: { TreeNode, token },
    });
};
export const menuUnbookmarked = TreeNode => dispatch => {
    const token = store.getState().auth.Token;
    dispatch({
        type: actionTypes.MENUUNBOOKMARKED,
        payload: { TreeNode, token },
    });
};

export const mapPref = (map, mapState) => async dispatch => {
    const token = store.getState().auth.Token;
    dispatch({
        type: actionTypes.MAPPREF,
        payload: {
            map,
            mapState,
            token,
        },
    });
};
export const initializePref = (MapPrefs, BookmarkPrefs) => dispatch => {
    dispatch({
        type: actionTypes.INITIALIZEPREFS,
        payload: {
            MapPrefs,
            BookmarkPrefs,
        },
    });
};

export const isFetching = status => dispatch => {
    dispatch({
        type: actionTypes.ISFETCHING,
        payload: status,
    });
};


export const nextRoute = (nextFollowedUserRoute) => dispatch => {
    dispatch({
        type: actionTypes.NEXTFOLLOWEDUSERROUTE,
        payload: nextFollowedUserRoute
        ,
    })
}
export const resetNotifCount = () => dispatch => {
    dispatch({
        type: actionTypes.NEWNOTIFCOUNT,
        payload: { Value: 0 },
    });
};
export const updateEddDataValidationProgress = (progress) => dispatch => {
    dispatch({
        type: actionTypes.UPDATEDATAVALIDATIONPROGRESS,
        payload: { Progress: progress}
    })
}
export const updateSheetName = (sheetName) => dispatch => {
    dispatch({
        type: actionTypes.UPDATESHEETNAME,
        payload: { SheetName: sheetName}
    })
}