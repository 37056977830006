import React, { useEffect, useState } from "react";
import {
    Checkbox,
    Divider,
    FormHelperText,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useAxiosConfig } from "../../../js/utils/customHooks";
import Axios from "axios";
import { ScreenLoading } from "../../Widgets/CustomLoadings";
import { useFormContext } from "react-hook-form";
import { t } from "react-i18nify";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import { BasicTooltip } from "../../Widgets/StyledComponents";
import { isArrayLength } from "../../../js/utils/genericMethods";

const FieldSelection = ({ EntityType, fieldName, validation }) => {
    if (!Boolean(EntityType)) {
        throw Error("No EntityType provided");
    }
    const methods = useFormContext();
    const { watch, setValue, register, trigger } = methods;

    const [label, setLabel] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const config = useAxiosConfig();
    const [list, setList] = useState();
    const listValueChecked = watch(fieldName) ?? [];

    useEffect(() => {
        register(fieldName, validation);
    }, [fieldName, register, validation]);

    const locale = useSelector(state => state.i18n.locale);
    useEffect(() => {
        let isSubscribed = true;
        let url = `/grid/${EntityType}`;
        const fetch = async () => {
            const response = await Axios.get(url, config);
            if (isSubscribed) {
                setList(
                    response.data?.Fields?.sort(
                        (a, b) => a?.DisplayOrder - b?.DisplayOrder
                    )
                );
                setLabel(response.data.Label);
                setIsLoading(false);
            }
        };
        fetch();

        return () => (isSubscribed = false);
    }, [config, EntityType, locale]);

    const addingNewSelection = newField => {
        const indexWhereToAdd = listValueChecked.findLastIndex(
            e =>
                list.findIndex(y => y.Fieldname === e) <
                list.findIndex(y => y.Fieldname === newField)
        );

        listValueChecked.splice(
            indexWhereToAdd === -1 ? 0 : indexWhereToAdd + 1,
            0,
            newField
        );

        return listValueChecked;
    };

    return isLoading ? (
        <ScreenLoading />
    ) : (
        <>
            <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                {label}
            </Typography>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: 100 }}>
                                <BasicTooltip
                                    title={
                                        listValueChecked.length === list.length
                                            ? t("common.DeselectAll")
                                            : t("common.SelectAll")
                                    }
                                >
                                    <Checkbox
                                        color="primary"
                                        indeterminate={
                                            isArrayLength(listValueChecked) &&
                                            listValueChecked.length <
                                                list.length
                                        }
                                        checked={
                                            listValueChecked.length ===
                                            list.length
                                        }
                                        onChange={e => {
                                            setValue(
                                                fieldName,
                                                e.target.checked
                                                    ? list.map(e => e.Fieldname)
                                                    : []
                                            );
                                            trigger(fieldName);
                                        }}
                                    />
                                </BasicTooltip>
                            </TableCell>
                            <TableCell sx={{ width: 200 }}>
                                {t("view.query.outputSelection.Fieldname")}
                            </TableCell>
                            <TableCell sx={{ width: 200 }}>
                                {t("view.query.outputSelection.Label")}
                            </TableCell>
                            <TableCell sx={{ width: 600 }}>
                                {t("view.query.outputSelection.Description")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((row, index) => (
                            row.Fieldname !== "Result" &&
                            <TableRow
                                key={row.Fieldname}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                                role="checkbox"
                            >
                                <TableCell sx={{ width: 100 }}>
                                    <Checkbox
                                        size="small"
                                        checked={listValueChecked.includes(
                                            row.Fieldname
                                        )}
                                        onChange={() => {
                                            setValue(
                                                fieldName,
                                                listValueChecked.includes(
                                                    row.Fieldname
                                                )
                                                    ? listValueChecked.filter(
                                                          e =>
                                                              e !==
                                                              row.Fieldname
                                                      )
                                                    : addingNewSelection(
                                                          row.Fieldname
                                                      )
                                            );
                                            trigger(fieldName);
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={{ width: 200 }}>
                                    {row.Fieldname==="ResultText" ? "Result" : row.Fieldname}
                                </TableCell>
                                <TableCell sx={{ width: 200 }}>
                                    {row.Label==="Result text" ? "Result" : row.Label}
                                </TableCell>
                                <TableCell sx={{ width: 600 }}>
                                    {row.Description} 
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

/** Step of a FormStepper:  Used to define a selection of fields per entity selected in a previous step of the stepper. All selectable options are fetched based on the entity name, available in the FormState of the FormStepper. */
const StepFieldSelection = ({ fieldName }) => {
    const methods = useFormContext();
    const { watch, formState } = methods;
    const entities = watch(fieldName);
    return (
        <Grid item xs={12}>
            {Object.entries(entities)
                .sort((a, b) => {
                    return a[1].Order - b[1].Order;
                })
                .map(([key, value], i) => {
                    const error =
                        formState.errors[fieldName]?.[key]?.SelectedFields
                            ?.message;
                    return (
                        <Box
                            key={"Select" + key + i}
                            sx={
                                error && {
                                    p: 1,
                                    border: "1px solid red",
                                    borderRadius: "6px",
                                }
                            }
                        >
                            <FieldSelection
                                EntityType={key}
                                fieldName={`${fieldName}.${key}.SelectedFields`}
                                validation={{
                                    required: t("input.validation.required"),
                                }}
                            />
                            <FormHelperText error={true} sx={{ ml: 2 }}>
                                {error}
                            </FormHelperText>
                            {i !== entities.length - 1 && (
                                <Divider sx={{ marginBottom: 2 }} />
                            )}
                        </Box>
                    );
                })}
        </Grid>
    );
};

export default StepFieldSelection;
