import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { fetchData } from "../../js/utils/backend";
import { useSelector } from "react-redux";
import { graphicShaper } from "./cst_ArcGIS";
import { BasicMap } from "./MapCustomInputs";
import { t } from "react-i18nify";

const ZonesMap = ({
    title,
    toolbarComponent,
    OTEntityID,
    StudyID,
    typeZone,
}) => {
    const token = useSelector(state => state.auth.Token);
    const [entity, setEntity] = useState();
    const [layerList, setLayerList] = useState([]);
    const [legend, setLegend] = useState([]);

    useEffect(() => {
        let isSubscribed = true;
        const fetching = async () => {
            const entityFetched = await fetchData(
                "OTEntity/map",
                token,
                "ID",
                OTEntityID
            );
            if (isSubscribed) {
                setEntity(entityFetched.data);
            }
        };
        OTEntityID && fetching();
        return () => (isSubscribed = false);
    }, [token, OTEntityID]);

    useEffect(() => {
        const fetching = async () => {
            const zoneFetched = await fetchData(
                "zone",
                token,
                "Select",
                null,
                typeZone === "entity"
                    ? `&filter[OTEntityID]=${OTEntityID}`
                    : `&filter[StudyId]=${StudyID}`
            );
            const rawZone = zoneFetched.data;
            const zones = rawZone.filter(e => e.Extent.GeoJSON);
            const zoneLayers = zones.map(e => ({
                layerProperties: {
                    title: e.Value,
                    id: `Zone${e.Value}`,
                },
                graphics: {
                    geometries: [
                        {
                            ...e,
                            graphicShape: graphicShaper(e.HexColor),
                        },
                    ],
                    attributes: {
                        Name: e.Value,
                        Description: `${t("field.Code")}: ${e.Code}`,
                    },
                },
            }));
            const groupTitle = `${t("map.zonesOf")} ${entity?.Name}`;
            const zoneGroupLayer = {
                layerType: "group",
                order: entity?.MapserviceList?.length + 1,
                groupProperties: {
                    title: groupTitle,
                    id: `Zones${entity?.Name}`,
                },
                layers: zoneLayers,
            };
            setLegend([
                {
                    groupLegendLabel: groupTitle,
                    layersLegend: zones.map(e => ({
                        label: e.Code,
                        symbol: {
                            ...graphicShaper(e.HexColor),
                            style: "square",
                        },
                    })),
                },
            ]);
            setLayerList([zoneGroupLayer]);
        };
        entity && fetching();
    }, [OTEntityID, token, entity, StudyID, typeZone]);

    // TODO check token here
    return (
        <Grid m={1} sx={{ height: "calc(100vh - 140px)" }}>
            <Box
                sx={{
                    typography: "h3",
                    pt: 6,
                    pl: 4,
                    pb: 2,
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {title}
            </Box>
            {toolbarComponent && (
                <Box
                    mx={4}
                    mt={6}
                    sx={{
                        border: "1px solid rgba(224, 224, 224, 1)",
                        display: "flex",
                    }}
                >
                    {toolbarComponent}
                </Box>
            )}
            <Box pl={4} pr={4} sx={{ height: "450px" }}>
                <BasicMap
                    layerList={layerList}
                    center={entity?.Extent}
                    mapServerServices={entity?.MapserviceList}
                    mapServerLink={entity?.GisServer}
                    basemap={entity?.Basemap}
                    mapName={entity?.Name}
                    legend={legend}
                />
            </Box>
        </Grid>
    );
};

export default ZonesMap;
