import { rangeCheck } from "./FloatFieldDefinition";
import { t } from "react-i18nify";

export function checkInteger(fieldDescr, value, isMandatory) {
    let regexIntegerPattern = /^-?[0-9]+$/;
    let isInteger = regexIntegerPattern.test(parseInt(value));
    if (isInteger) {
        let checkRange = fieldDescr.CheckRange;
        let minValue = value >= fieldDescr.MinValue;
        let maxValue = value <= fieldDescr.MaxValue;
        if (isMandatory) {
            if (Boolean(value) || value === 0) {
                return rangeCheck(checkRange, minValue, maxValue);
            } else if (!Boolean(value)) {
                return t("input.validation.required");
            }
        } else if (!isMandatory) {
            return rangeCheck(checkRange, minValue, maxValue);
        }
    } else {
        return false;
    }
}
