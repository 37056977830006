import React, { useCallback, useEffect, useState } from "react";
import {
    GridActionsCellItem,
    GridAddIcon,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { BasicDialog, StateChip } from "./cst_DataGrid";
import {
    dateTimeToString,
    truncateString,
} from "../../js/utils/genericMethods";
import * as requestDiagram from "../../diagrams/requestDiagram.json";
import DataGridComponent from "../Widgets/DataGridComponent";
import { BasicTooltip } from "../Widgets/StyledComponents";
import { Dialog, IconButton } from "@mui/material";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import { useSelector } from "react-redux";
import { t } from "react-i18nify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import * as r from "../../js/constants/routes";
import { deleteModel } from "../../js/utils/backend";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { ButtonToolbar } from "../Widgets/Custom Inputs/Buttons";
import NewRequestPanel from "../Views/Edition panels/NewRequestPanel";
import { NewRefRead } from "../Widgets/ReadOnlyComponents";

const UserRequestsGrid = ({
    label = t("grid.title.MyRequests"),
    baseURL = r.request,
    defaultforceGridRefresh,
}) => {
    const apiRef = useGridApiRef();
    const token = useSelector(state => state.auth.Token);
    const { External } = useSelector(state => state.auth);
    const UserState = useSelector(state => state.auth.UserState);
    const [newReference, setNewReference] = useState();
    const [newReferenceDialogOpen, setNewReferenceDialogOpen] = useState(false);
    const [forceGridRefresh, setForceGridRefresh] = useState(
        defaultforceGridRefresh
    );

    useEffect(() => {
        setForceGridRefresh(defaultforceGridRefresh);
    }, [defaultforceGridRefresh]);

    const diagramStates = requestDiagram.default.States;

    const gridSpecificColumns = [
        {
            field: "NewEntity.Code",
            headerName: t("field.EntityCode"),
            valueGetter: params =>
                params.row.RequestType === "NEWREF"
                    ? "/"
                    : params.row.NewEntity?.Code,
            width: 140,
            editable: false,
        },
        {
            field: "NewEntity.Name",
            headerName: t("field.Name"),
            valueGetter: params =>
                params.row.RequestType === "NEWREF"
                    ? t("common.newRef")
                    : params.row.NewEntity?.Name,
            width: 140,
            editable: false,
        },
        {
            field: "State",
            headerName: t("field.State"),
            renderCell: params => {
                const state = diagramStates.find(e => e.Name === params.value);
                return <StateChip state={state} variant="outlined" />;
            },
            width: 150,
            editable: false,
        },
        {
            field: "RequestDate",
            headerName: t("field.RequestDate"),
            valueFormatter: params =>
                dateTimeToString(params.value, "DateTime"),
            width: 150,
            editable: false,
        },
        {
            field: "RequestType",
            headerName: t("field.RequestType"),
            valueFormatter: params => t(`view.request.${params.value}`),
            width: 150,
            editable: false,
        },
        {
            field: "RequestedAccess",
            headerName: t("field.RequestedAccess"),
            width: 150,
            editable: false,
            valueGetter: params =>
                params.row.RequestType === "NEWENTITY" ||
                params.row.RequestType === "NEWREF"
                    ? "/"
                    : t(`enum.rights.${params.row.RequestedAccess}`),
        },
        {
            field: "Requestor_select",
            headerName: t("field.RequestorIGG"),
            valueFormatter: params => params.value.IGG,
            width: 150,
            editable: false,
        },
        {
            field: "Note",
            headerName: t("field.Note"),
            valueFormatter: params => truncateString(params.value, 60),
            width: 200,
            flex: 1,
            editable: false,
        },
        {
            field: "Observation",
            headerName: t("field.Observation"),
            renderCell: params => {
                if (params?.row?.Observation) {
                    return (
                        <BasicTooltip title={params.row.Observation}>
                            <IconButton disableRipple>
                                <MessageOutlinedIcon
                                    sx={{ width: 18, height: 18 }}
                                />
                            </IconButton>
                        </BasicTooltip>
                    );
                }
            },
            width: 120,
            editable: false,
        },
    ];

    const specificActionsColumns = (params, _editing, isLocalLoading) => [
        <>
            {params.row.RequestType === "NEWREF" && (
                <GridActionsCellItem
                    icon={
                        <BasicTooltip title={t("common.SeeMore")}>
                            <VisibilityIcon />
                        </BasicTooltip>
                    }
                    label={t("common.Edit")}
                    className="textPrimary"
                    onClick={() => {
                        setNewReferenceDialogOpen(true);
                        setNewReference(params.row.RequestNewRef);
                    }}
                    color="inherit"
                    disabled={isLocalLoading}
                />
            )}
            {params?.row?.State !== "Validated" && (
                <GridActionsCellItem
                    key={`DeleteRequest${params.id}`}
                    icon={<DeleteIcon />}
                    onClick={() => deleteRow(params.id)}
                    label="Delete"
                    disabled={isLocalLoading}
                />
            )}
        </>,
    ];

    const deleteRow = useCallback(
        async id => {
            try {
                if (id !== "NEW") {
                    await deleteModel(id, token, baseURL);
                }
                apiRef.current.updateRows([{ ID: id, _action: "delete" }]);
            } catch (error) {
                console.error(error);
            }
        },
        [baseURL, token, apiRef]
    );

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const cancelAction = useCallback(
        () => setOpenEditDialog(false),
        [setOpenEditDialog]
    );

    useEffect(() => {
        if (!openEditDialog) setForceGridRefresh(fgr => !fgr);
    }, [openEditDialog]);

    return (
        <>
            <DataGridComponent
                apiRef={apiRef}
                editingRights={false}
                baseURL={baseURL}
                paramURL="filter[MyRequests]=true"
                label={label}
                specificActionsColumns={specificActionsColumns}
                gridSpecificColumns={gridSpecificColumns}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "RequestDate", sort: "desc" }],
                    },
                }}
                forceGridRefresh={forceGridRefresh}
                toolbar={{
                    add: null,
                    newAction: External
                        ? null
                        : UserState !== "New" && (
                              <ButtonToolbar
                                  titleTooltip={t("common.Add")}
                                  title={t("common.Add")}
                                  startIcon={<GridAddIcon />}
                                  onClick={() => {
                                      setOpenEditDialog(true);
                                  }}
                              />
                          ),
                }}
                requestUrl="myrequests"
            />
            {openEditDialog ? (
                <Dialog open={Boolean(openEditDialog)} sx={{ w: 1 }} fullWidth>
                    <NewRequestPanel
                        setForceGridRefresh={setForceGridRefresh}
                        defaultMode={"ADDUSER"}
                        isInPannel
                        backAction={cancelAction}
                    />
                </Dialog>
            ) : null}
            <BasicDialog
                Content={() => <NewRefRead reference={newReference} />}
                label={t("common.newRef")}
                open={newReferenceDialogOpen}
                closeDialog={() => setNewReferenceDialogOpen(false)}
            />
        </>
    );
};

export default UserRequestsGrid;
