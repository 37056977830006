import React from "react";
import { Grid, CircularProgress, Typography } from "@mui/material";
import { t } from "react-i18nify";

export const ScreenLoading = ({title=t("common.Loading"),...props}) => {
    return (
        <Grid
            sx={{
                display: "flex",
                height: "calc(100vh - 140px)",
                justifyContent: "center",
                alignItems: "center",
                width: 1,
                ...props.sx,
            }}
            {...props}
        >
            <Grid
                item
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <CircularProgress
                    size={100}
                    sx={{
                        top: 0,
                        mb: 2,
                    }}
                    color={"secondary"}
                />
                <Typography sx={{ mt: 2, fontSize: 16 }}>{title}</Typography>
            </Grid>
        </Grid>
    );
};
