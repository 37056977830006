import { nextRoute } from "../redux/actions/userPref";
import { store } from "../redux/store";

/** historyPush with nextroutertodispatch; needed to save next route in case if refreshing token happens, so we can redirect back to the next user path */
export const historyPush = (history, route, historyState = {}) => {
    // give also history in props.
    const pushWithNextRouteSaved = async () => {
        const nextFollowedUserRoute = {
            ClientHistory: history,
            NextClientRoute: route,
        };
        /** The passed object contains methods (= history), so it needs to be serialized, otherwise the folowing error occurs : << Failed to execute 'postMessage()' on 'BroadcastChannel': function (dispatch) >> . More info about Broadcast Channel API :  https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API */
        const routeToClone = JSON.parse(JSON.stringify(nextFollowedUserRoute));
        await store.dispatch(nextRoute(routeToClone));
        return history.push(route, historyState);
    };
    return pushWithNextRouteSaved();
};

export async function readFileAsync(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });
}

export const invalidDates = [
    "0001-01-01T00:00:00Z",
    "0001-01-01T00:00:00.000Z",
    "9999-12-31T23:59:59.999Z",
    "1970-01-01T00:00:00.000Z",
    "9999-12-31T23:59:59.9999999Z",
    "0001-01-01T00:00:00",
    undefined,
    null, // car une date peut être nullable suite à un edit
];
export const invalidDatesOnly = [
    "01/01/0001",
    undefined,
    null, // car une date peut être nullable suite à un edit
];

/** Generic method to handle date display (on grids mainly) */
export const dateTimeToString = (date, type) => {
    if (invalidDates.includes(date)) {
        return "-";
    }
    const result = new Date(date);

    if (type === "Date" || !type) {
        return result.toLocaleDateString(navigator.language);
    } else if (type === "DateTime") {
        return result.toLocaleString(navigator.language);
    }
};

/** Return the date only in the right locale format but in UTC (without any user's timezone considered) */
export const dateOnlyToString = date => {
    if (invalidDatesOnly.includes(date)) {
        return "-";
    }
    return DateOnlyToJSDate(date).toLocaleDateString(navigator.language);
};

/* export const DateOnlyToJSDate = stringDateOnly => {
    if (invalidDatesOnly.includes(stringDateOnly)) {
        return null;
    }
    let date = new Date(stringDateOnly?.split("/").reverse().join("-"));
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
}; */

export const DateOnlyToJSDate = stringDateOnly => {
    // Check if stringDateOnly is a string and not included in invalidDatesOnly
    if (typeof stringDateOnly !== 'string' || invalidDatesOnly.includes(stringDateOnly)) {
        return null;
    }

    // Split the date string by "/", reverse the array, and join with "-" to form "YYYY-MM-DD"
    let dateParts = stringDateOnly.split("/");
    if (dateParts.length !== 3) {
        return null; // Invalid date format
    }
    let formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

    // Create a new Date object using the formatted date string
    let date = new Date(formattedDate);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return null;
    }

    // Get the timezone offset in milliseconds and adjust the date
    let userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
};

export function truncateString(str, num) {
    if (!str) {
        return null;
    } else {
        return str.length <= num ? str : str.slice(0, num) + "...";
    }
}

/** Method used in context of all custom date inputs (= DatePicker), on which the date format pattern and mask should reflect the user's navigator locale. */
export function getDateFormatPattern(navigatorLocale) {
    let options = { year: "numeric", month: "2-digit", day: "2-digit" };
    // formatToParts() returns array of object breaking down the locales dateformat
    // [ 
    //  {type: "month", value: "02"},
    //  {type: "literal", value: "/"},
    //  {type: "day", value: "15"},
    //  {type: "literal", value: "/"},
    //  {type: "year", value: "2023"},
    // ]
    let formatter = new Intl.DateTimeFormat(navigatorLocale, options).formatToParts();
    let result = formatter.map(function (e) {
        switch (e.type) {
            case 'month':
                return 'MM';
            case 'day':
                return 'dd';
            case 'year':
                return 'yyyy';
            default:
                return e.value;
        };
    }).join('');

    // e.g for Belgium, it returns dd/MM/yyyy
    return result
}

const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

export function niceBytes(x) {
    let l = 0,
        n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
        n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
}

export function isEmpty(object) {
    for (const property in object) {
        return false;
    }
    return true;
}

/** A valid length property for an Array is any integer value on the interval [0, 2^32-1]; to avoid smell code & complexity */
export const isArrayLength = array => {
    return array?.length > 0;
};

export const toMiliSeconds = (hrs, min, sec) =>
    (hrs * 60 * 60 + min * 60 + sec) * 1000;

export const stationGeneralToType = str =>
    str?.replace("OneTsigane.Entities.", "");

/** Method to handle form values when resetting */
export function mergeFormObjects(fetched, defaultValues) {
    let fetchedValues = { ...fetched };
    for (let key in defaultValues) {
        if (fetchedValues[key] === undefined || fetchedValues[key] === null)
            fetchedValues[key] = defaultValues[key];
    }
    return fetchedValues;
}

/** Check file extension to determine if i'ts readable in the browser, so a preview can eventually be showed (in a new tab).  */
export function showPreview(fileName) {
    const extension = fileName?.match(/\.([^.]+)$/)[1];    
    const extensionsToPreview = ["jpeg", "jpg", "png", "svg", "pdf"]
    let hasPreview = extensionsToPreview.some(ext => ext.toLocaleLowerCase() === extension.toLocaleLowerCase())
    return hasPreview
}