import { Box, DialogContent, Grid, IconButton, Typography, Link } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import React, { useState, useEffect, useRef } from "react";
import { GlobalTheme } from "../../themes/GlobalTheme";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import UserMenu from "../Account/UserMenu";
import SearchBar from "../Widgets/SearchBar";
import LanguageSelector from "../Widgets/LanguageSelector";
import VersionJSON from "../../common/sharedSettings.json";
import TreeMenu from "../Widgets/TreeMenu";
import PrefsMenu from "../Widgets/PrefsMenu";
import { ContentViewport, DialogActionsForm } from "../Widgets/CustomSurfaces";
import MenuRouter, { possibleNodePaths } from "../../routers/MenuRouter";
import SelectURL from "../Widgets/Custom Inputs/Select";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadTranslations } from "../../js/redux/actions/i18n";
import translations from "../../l10n/translations";
import {
    updateEntityTree,
    updateTreeViewNodeExpand,
} from "../../js/redux/actions/userPref";
import { OTAppBar } from "../Widgets/AppBar";
import { historyPush } from "../../js/utils/genericMethods";
import { BasicTooltip } from "../Widgets/StyledComponents";
import { t } from "react-i18nify";
import { DrawerOpener } from "./CommonLayout";
import NotificationMenu from "../Account/NotificationMenu";
import { fetchTraverseExtendTreePathNodes } from "../TreeEditors/EntityTree";
import { Dialog } from "@mui/material";
import { BasicButton } from "../Widgets/Custom Inputs/Buttons";
import Axios from "axios";
import * as r from "../../js/constants/routes";
import { hideNewFeaturesPopUp } from "../../js/redux/actions/auth";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Link as RouterLink } from 'react-router-dom';

const version = VersionJSON.Version;

const MainLayout = () => {
    const appBarRef = useRef(null);

    const [treeMenuOpen, setTreeMenuOpen] = useState(true);
    const entity = useSelector(state => state.userPref.CurrentEntity);
    const history = useHistory();
    const dispatch = useDispatch();
    const handleTreeMenuOpen = () => {
        setTreeMenuOpen(true);
    };
    const handleTreeMenuClose = () => {
        setTreeMenuOpen(false);
    };
    const locale = useSelector(state => state.i18n.locale);

    const [features, setFeatures] = useState(null);
    const [prefsMenuOpen, setPrefsMenuOpen] = useState(false);
    const handlePrefsMenuClick = () => {
        setPrefsMenuOpen(!prefsMenuOpen);
    };
    const handlePrefsMenuClose = () => {
        setPrefsMenuOpen(false);
    };

    const [language, setLanguage] = useState();

    useEffect(() => {
        if (language) {
            dispatch(loadTranslations(translations));
        }
    }, [dispatch, language]);

    useEffect(() => {
        setLanguage(locale);
        dispatch(updateEntityTree());
    }, [locale, dispatch]);



    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            dispatch(updateTreeViewNodeExpand([entity?.ObjectID]));
        }
    }, [entity, dispatch]);

    const onChangeEntitySelection = (_event, data) => {
        dispatch(updateTreeViewNodeExpand([data.ObjectID]));
        return historyPush(history, `/entity-page/${data.ObjectID}`);
    };

        ////////////////////////////////// PROGRAMMATIC NODES EXPANSION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    /** Attempt to match current route with a list of routes containing a node id (in order to open treemenu to that specific node. */
    const isConnected = useSelector(state => state.auth.IsConnected);
    const userId = useSelector(state => state.auth.UserID);
    const isExternalUser = useSelector(state => state.auth.External);
    const [displayNewFeaturesPopUp,setDisplayNewFeaturesPopUp] = useState(useSelector(state => state.auth.DisplayNewFeaturesPopUp));
    const token = useSelector(state => state.auth.Token);
    const match = useRouteMatch(possibleNodePaths?.map(e => e))
    const location = useLocation();
    const config = {
        headers: {
            Authorization: "Bearer " + token,
        },
    };
    
    useEffect(() => {
        let config = {
            headers: { Authorization: "Bearer " + token },
        };
        const fetching = async () => {
            const response = await Axios.get('NewFeaturesMessage?orderBy=CreatedOn', config);
            setFeatures(response.data.Data);
        }
        fetching();
    }, [token]);

    useEffect(() => {
        let isSubscribed = true
        if (isSubscribed && isConnected) {
            let isWorkOrder = match?.params?.GenericGridTypeParent === "WorkOrder"
            let isStationGroup = match?.params?.elementType === "StationGroup"
            let isQueryViewReporting = match?.params?.GenericGridTypeParent === "query-view-reporting"
            // external tree should be a custom other func; 'WorkOrder', 'StationGroup', 'query-view-reporting' are specific nodes to handle (need to get an nodeID from the three to retrieve all otentitytreepath)
            if (isExternalUser || isWorkOrder || isStationGroup || isQueryViewReporting ) {
                return null 
            } else if (!isExternalUser && match?.params?.nodeID) {
                if (match.params.GenericGridTypeParent === "Study") {
                    fetchTraverseExtendTreePathNodes(match?.params?.nodeID, token, dispatch, "otentitystudypath")
                } else {
                    fetchTraverseExtendTreePathNodes(match?.params?.nodeID, token, dispatch)
                }
            }
        }
        return () => (isSubscribed = false);
        // eslint-disable-next-line
    }, [location])
    //////////////////////////////////  PROGRAMMATIC NODES EXPANSION  \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

    return (
        <ThemeProvider theme={GlobalTheme}>
            <OTAppBar
                ref={appBarRef}
                UpperAppBar={
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            ml: { xs: 2 },
                        }}
                    >
                        <Box sx={{ typography: "body2", mr: 2 }}>
                            v. {version}
                        </Box>
                        <LanguageSelector />
                        <UserMenu username={null} />
                        <NotificationMenu />
                        <Box>
                            <BasicTooltip title={t("common.SettingsPanel")}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open right or prefs menu drawer"
                                    onClick={handlePrefsMenuClick}
                                    edge="start"
                                    sx={{
                                        mr: 1,
                                    }}
                                >
                                    <SettingsIcon />
                                </IconButton>
                            </BasicTooltip>
                        </Box>
                    </Box>
                }
                LowerAppBar={
                    <Box
                        sx={{
                            width: 1,
                            height: 60,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ width: 300, mx: 1 }}>
                            <SelectURL
                                variant="standard"
                                value={entity}
                                URL={"OTEntity"}
                                getOptionLabel={option => option.Name ?? ""}
                                onChange={(event, data) =>
                                    onChangeEntitySelection(event, data)
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.ObjectID === value.ObjectID
                                }
                                disableClearable
                            />
                        </Box>
                        <Grid item sx={{ flexGrow: 1 }} />
                        <HelpOutlineIcon sx={{ typography:"subtitleMajor", marginRight:"2px" }} />
                        <Link component={RouterLink} to="/help" sx={{ marginBottom:"2px", typography:"body1", textDecoration:"none", mr:3}}>
                            <strong>{t("common.Help")}</strong>
                        </Link>
                        <SearchBar />
                    </Box>
                }
            />
            {displayNewFeaturesPopUp ? (<Dialog open={Boolean(displayNewFeaturesPopUp)} sx={{ w: 1 }} fullWidth>
               <DialogContent>
                {features && ((locale ==='ENG') && 
                    (<><Typography>
                    <Box sx={{ typography:"bodyMajor"}}>{t("common.featuresmessage")}</Box><br />
                    </Typography> 
                    <Typography>
                    {features[0].English}
                    </Typography></>))}
                {features && ((locale ==='FRA') && 
                    (<><Typography>
                    <Box sx={{ typography:"bodyMajor"}}>{t("common.featuresmessage")}</Box><br />
                    </Typography> 
                    <Typography>
                    {features[0].French}
                    </Typography></>))}
                </DialogContent>
                <DialogActionsForm>
                    <BasicButton
                        type="button"
                        style={{
                            position: "relative",
                            margin: 4,
                        }}
                        onClick={async () => {
                            var response = await Axios.post(
                                `${r.oTUser}/DisableNewFeaturesPopUp/${userId}`,
                                {},
                                config);
                            if(response!==null){
                                setDisplayNewFeaturesPopUp(false);
                                dispatch(hideNewFeaturesPopUp());
                            }
                        }}
                    >
                        {t("common.Close")}
                    </BasicButton>
                </DialogActionsForm>
            </Dialog>) : null}
            <DrawerOpener
                treeMenuOpen={treeMenuOpen}
                handleTreeMenuClose={handleTreeMenuClose}
                handleTreeMenuOpen={handleTreeMenuOpen}
            />

            {/* Left drawer display the business tree view, with favs. DOC*/}
            <TreeMenu
                handleTreeMenuClose={handleTreeMenuClose}
                treeMenuOpen={treeMenuOpen}
            />
            {/* Right drawer display the app settings and user prefs. DOC*/}
            <PrefsMenu
                handlePrefsMenuClose={handlePrefsMenuClose}
                prefsMenuOpen={prefsMenuOpen}
            />
            <ContentViewport
                treeMenuOpen={treeMenuOpen}
                prefsMenuOpen={prefsMenuOpen}
                appBarRef={appBarRef}
            >
                {/* Rendering & route-based content */}
                <MenuRouter />
            </ContentViewport>
        </ThemeProvider>
    );
};

export default MainLayout;
