import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { fetchData } from "../../../js/utils/backend";
import { useFormContext, Controller } from "react-hook-form";
import { Checkbox, ListItem, MenuItem } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterListOutlined";
import {
    deativateAutocompletion,
    HiddenField,
    scrollToFieldname,
} from "./CustomInputs";
import ClearIcon from "@mui/icons-material/Clear";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { uuidv4 } from "../../../js/redux/actions/syncTab";
import { Box } from "@mui/system";
import { isArrayLength } from "../../../js/utils/genericMethods";

const listBoxStyle = { maxHeight: "24vh" };

const SelectURL = ({
    URL,
    selectRoute = "Select",
    label,
    error,
    variant = "outlined",
    disableUnderline = false,
    ID = null,
    paramURL,
    ...props
}) => {
    const [open, setOpen] = useState(false);
    const [opened, setOpened] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [research, setResearch] = useState("");
    const token = useSelector(state => state.auth.Token);
    const isError = error?.message ? true : false || error === true;

    useEffect(() => {
        const fetching = async () => {
            setLoading(true);
            const optionsFetched = await fetchData(
                URL,
                token,
                selectRoute,
                ID,
                paramURL,
                research
            );
            setOptions(optionsFetched.data);
            setLoading(false);
        };
        opened && fetching();
    }, [URL, token, selectRoute, opened, ID, paramURL, research]);

    return (
        <Autocomplete
            ListboxProps={{
                style: listBoxStyle,
            }}
            disabled={props.disabled}
            id={uuidv4()}
            open={open}
            onInputChange={(event, value) => {
                setResearch(value);
            }}
            onOpen={() => {
                setOpen(true);
                setOpened(true);
                setResearch("");
            }}
            onClose={() => {
                setOpen(false);
            }}
            // filterSelectedOptions
            groupBy={option => option.firstLetter}
            getOptionLabel={option =>
                Boolean(option.Name) ? option.Name : option.Value
            }
            options={options}
            loading={loading}
            renderOption={(props, option) => {
                return (
                    <ListItem {...props} key={option.ObjectID}>
                        {option.Name && option.Name !== ""
                            ? option.Name
                            : option.Value}
                    </ListItem>
                );
            }}
            isOptionEqualToValue={(option, value) =>
                option.ObjectID === value.ObjectID
            }
            renderInput={params => (
                <TextField
                    {...params}
                    color="primary"
                    size="small"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                        disableunderline: disableUnderline.toString(),
                    }}
                    inputProps={{
                        ...params.inputProps,
                        ...deativateAutocompletion,
                    }}
                    variant={variant}
                    error={isError}
                    helperText={
                        props.disabled ? props.disabledMessage : error?.message
                    }
                />
            )}
            {...props}
        />
    );
};
export default SelectURL;

/** Controlled select, with pair value/label objects options provided, to use in a fully controlled form (with <FormContext {...methods}>)  */
export const VSelect = ({
    options,
    label,
    fieldName,
    validation,
    variant = "outlined",
    defaultValue,
    disableClearable = false,
    ...props
}) => {
    const methods = useFormContext();
    const { control, setValue, watch } = methods;

    const handleChange = (event, data) => {
        setValue(fieldName, data?.value, {
            shouldDirty: true,
            shouldValidate: true,
        });
        return data?.label;
    };

    return (
        <Controller
            control={control}
            name={fieldName}
            rules={validation}
            defaultValue={defaultValue}
            render={({ field, fieldState }) => {
                delete field.ref;
                return (
                    <Autocomplete
                        {...field}
                        disableClearable={disableClearable}
                        ListboxProps={{
                            style: listBoxStyle,
                        }}
                        isOptionEqualToValue={(option, value) =>
                            option.value === value
                        }
                        getOptionLabel={option => {
                            if (typeof option === "object") {
                                return option?.label;
                            } else if (typeof option === "string") {
                                let temp = options.filter(el => {
                                    if (el.value === option) {
                                        return el?.label;
                                    }
                                    return "";
                                });
                                if (temp[0]?.label) {
                                    return temp[0]?.label;
                                } else {
                                    return "";
                                }
                            }
                        }}
                        onInputChange={(event, value, reason) => {
                            if (reason === "clear") {
                                setValue(fieldName, "", {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                });
                            } else if (reason === "reset") {
                                let temp = options.filter(el => {
                                    if (el.label === value) {
                                        return el.value;
                                    } else {
                                        return "";
                                    }
                                });
                                if (watch(fieldName)) {
                                    setValue(fieldName, temp[0]?.value, {
                                        shouldDirty: true,
                                        shouldValidate: true,
                                    });
                                } else if (!watch(fieldName || !value)) {
                                    setValue(fieldName, "", {
                                        shouldDirty: true,
                                        shouldValidate: true,
                                    });
                                }
                            }
                        }}
                        id={uuidv4()}
                        options={options}
                        size="small"
                        onChange={(event, data) => handleChange(event, data)}
                        clearIcon={
                            watch(fieldName) &&
                            !disableClearable && <ClearIcon fontSize="small" />
                        }
                        renderInput={params => {
                            if (!watch(fieldName)) {
                                params.inputProps.value = "";
                            }
                            return (
                                <TextField
                                    {...params}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label={label}
                                    variant={variant}
                                    error={
                                        fieldState?.error?.message
                                            ? true
                                            : false
                                    }
                                    helperText={fieldState?.error?.message}
                                />
                            );
                        }}
                        {...props}
                    />
                );
            }}
        />
    );
};

/** Default reusable select */
export const Select = ({
    value,
    options,
    error,
    variant = "outlined",
    noLabel,
    getOptionLabel,
    disableUnderline = false,
    label = "",
    ref,
    ...props
}) => {
    return (
        <Autocomplete
            ListboxProps={{
                style: listBoxStyle,
            }}
            isOptionEqualToValue={(option, value) =>
                option.value === value.value
            }
            getOptionLabel={option => {
                if (typeof option === "object") {
                    return option.label;
                } else if (typeof option === "string") {
                    let temp = options.filter(el => {
                        if (el.value === option) {
                            return el.label;
                        } else {
                            return "";
                        }
                    });
                    return temp[0]?.label;
                }
            }}
            id={uuidv4()}
            options={options}
            size="small"
            renderInput={params => {
                return (
                    <TextField
                        {...params}
                        label={noLabel ? "" : label}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant={variant}
                        error={error}
                    />
                );
            }}
            {...props}
        />
    );
};

/** A Select used in forms, options are fetched on user interaction (or search) on a given url + param url DOC*/
export const VSelectURL = ({
    URL,
    label,
    fieldName,
    validation,
    defaultValue = null,
    selectRoute = "Select",
    paramURL,
    selectorContent,
    key,
    ...props
}) => {
    const methods = useFormContext();
    const { control, setValue, formState } = methods;

    useEffect(() => {
        if (defaultValue !== null){
            setValue(fieldName, defaultValue.ObjectID);
        } 
    }, [defaultValue, fieldName, setValue]);

    const handleChange = (event, data) => {
        setValue(fieldName, data?.ObjectID, {
            shouldDirty: true,
            shouldValidate: true,
        });
        if (selectorContent) {
            selectorContent(data);
        }
    };

    const inputRef = useRef(null);
    scrollToFieldname(formState, fieldName);

    return (
        <Box component="div" ref={inputRef} name={fieldName}>
            <Controller
                rules={validation}
                name={fieldName}
                control={control}
                defaultValue={defaultValue}
                render={({ field, fieldState }) => {
                    return (
                        <SelectURL
                            key={key}
                            label={label}
                            selectRoute={selectRoute}
                            URL={URL}
                            paramURL={paramURL}
                            onChange={(event, data) =>
                                handleChange(event, data)
                            }
                            error={fieldState.error}
                            variant="outlined"
                            defaultValue={defaultValue}
                            {...props}
                        />
                    );
                }}
            />
        </Box>
    );
};

/** Selet used in forms, and the input can be populated with one or several choosen options. DOC*/
export const VMultiSelectURL = ({
    URL,
    label,
    fieldName,
    validation,
    selectRoute = "Select",
    paramURL,
    defaultValue = [],
}) => {
    const methods = useFormContext();
    const { control, setValue } = methods;

    const handleChange = (event, data) => {
        const IDs = data.map(e => e.ObjectID);
        setValue(fieldName, IDs, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    return (
        <Controller
            rules={validation}
            name={fieldName}
            control={control}
            defaultValue={defaultValue}
            render={({ _field, fieldState }) => (
                <SelectURL
                    multiple
                    defaultValue={defaultValue}
                    selectRoute={selectRoute}
                    label={label}
                    URL={URL}
                    paramURL={paramURL}
                    variant="outlined"
                    onChange={(event, data) => handleChange(event, data)}
                    error={fieldState?.error}
                />
            )}
        />
    );
};

/** : Inline (in-row) grid select, the options are fetched. DOC*/
export const SelectURLGrid = ({
    URL,
    fieldName,
    selectRoute = "Select",
    onChange = (_event, _data) => {
        /*  */
    },
    paramURL,
    ...props
}) => {
    const { id, value, api, field } = props;

    const handleChange = async (event, data) => {
        onChange(event, data);
        const model = api.getEditRowsModel();
        const other = model[id];
        api.setEditRowsModel(
            {
                [id]: {
                    ...other,
                    [field]: { value: data },
                    [fieldName]: { value: data?.ObjectID },
                },
            },
            event
        );
    };
    return (
        <SelectURL
            value={value ?? null}
            URL={URL}
            selectRoute={selectRoute}
            paramURL={paramURL}
            onChange={(event, data) => handleChange(event, data)}
            sx={{ width: 1, px: 1, hight: 1 }}
            error={props.error}
            variant="standard"
            disableUnderline
            {...props}
        />
    );
};

/** Inline (in-row) grid select, the options are provided from a JS object. DOC*/
export const SelectGrid = ({ options, getOptionLabel, disabled, ...props }) => {
    const { id, api, field } = props;
    const handleChange = async (event, data) => {
        const model = api?.getEditRowsModel();
        const other = model[id];
        api?.setEditRowsModel(
            {
                [id]: {
                    ...other,
                    [field]: { ...data },
                },
            },
            event
        );
    };
    return (
        <Select
            onChange={(event, data) => handleChange(event, data)}
            options={options}
            getOptionLabel={getOptionLabel}
            sx={{ width: 1, px: 1 }}
            error={props.error}
            disabled={disabled}
            variant="standard"
            disableUnderline
            noLabel={true}
            {...props}
        >
            {options.map((i, ix) => {
                return <MenuItem key={i.label} value={i.value}>{i.label}</MenuItem>;
            })}
        </Select>
    );
};

/** A select used to filter results of data grid, usually it's placed on the custom toolbar above the grid. DOC*/
export const FilterToolbar = ({ placeholder, ...props }) => {
    return (
        <Autocomplete
            ListboxProps={{
                style: listBoxStyle,
            }}
            groupBy={option => option.firstLetter}
            filterSelectedOptions
            sx={{
                maxWidth: "180px",
                minWidth: "100px",
                p: 1,
            }}
            fullWidth
            renderInput={params => (
                <TextField
                    {...params}
                    placeholder={placeholder}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <FilterListIcon
                                fontSize="small"
                                sx={{ mb: "2px", color: "red" }}
                            />
                        ),
                    }}
                    color="primary"
                    size="small"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        ...params.inputProps,
                        ...deativateAutocompletion,
                    }}
                />
            )}
            {...props}
        />
    );
};

/** Reusable component, to choose rows in a displayed grid, used by VSelectAdvanced in the form to submit the rows list. DOC*/
export const SelectAdvanced = ({
    selectAction,
    error,
    grid,
    paramURL,
    defaultValue = [],
    duplicateToAvoid,
    ...props
}) => {
    const apiRef = useGridApiRef();
    const [page, setPage] = useState(0);
    const [selection, setSelection] = useState(defaultValue);
    const [duplicates, setDuplicates] = useState([]);

    const onSelectionModelChange = useCallback(
        newSelectionModel => {
            if (selectAction) selectAction(newSelectionModel);
            if (duplicateToAvoid && Object.keys(apiRef.current).length !== 0) {
                const valuesOfDuplicatesToAvoid = newSelectionModel
                    .map(e => {
                        if (apiRef.current.getRow(e)) {
                            return apiRef.current.getRow(e)[duplicateToAvoid];
                        }
                        return null;
                    })
                    .filter(e => e);
                setDuplicates(x => {
                    x[page] = valuesOfDuplicatesToAvoid;
                    return x;
                });
            }
            setSelection(newSelectionModel);
        },
        [selectAction, duplicateToAvoid, apiRef, page]
    );

    useEffect(() => {
        if (!duplicates[page]) {
            setDuplicates(x => {
                x[page] = x[page - 1];
                return x;
            });
        }
    // eslint-disable-next-line
    }, [page]);

    const GridWithActions = {
        ...grid,
        props: {
            ...grid.props,
            apiRef,
            displaySelectAllCheckbox: duplicateToAvoid ? "none" : "block",
            viaSelector: true,
            onSelectionModelChange,
            checkboxSelection: true,
            editingRights: false,
            isEditDisabled: true,
            isActionDisabled: true,
            isDeleteDisabled: true,
            columnVisibilityModel: {
                actions: false,
            },
            disableTitle: true,
            isStandalone: false,
            doOnPageChange: setPage,
            selectionModel: selection,
            rowSelectability: params => {
                if (isArrayLength(selection)) {
                    return (
                        selection.includes(params.row.ID) ||
                        !duplicates[page]?.includes(
                            params.row[duplicateToAvoid]
                        )
                    );
                } else {
                    return true;
                }
            },
            paramURL,
            ...props,
        },
    };

    return GridWithActions;
};

/** A SelectAdvanced component that can be used in a form. DOC*/
export const VSelectAdvanced = ({
    label,
    fieldName,
    validation,
    defaultValue,
    emptyOnOpen,
    paramURL,
    ...props
}) => {
    const methods = useFormContext();
    const { setValue, getValues } = methods;
    const handleChange = useCallback(
        data => {
            setValue(fieldName, data, {
                shouldDirty: true,
                shouldValidate: true,
            });
        },
        [fieldName, setValue]
    );
    let localDefaultValue = useMemo(
        () => defaultValue ?? getValues(fieldName) ?? [],
        [fieldName, defaultValue, getValues]
    );
    localDefaultValue = emptyOnOpen ? [] : localDefaultValue;

    const MemoSelectAdvanced = useMemo(() => SelectAdvanced, []);

    return (
        <>
            <HiddenField
                fieldName={fieldName}
                validation={validation}
                defaultValue={localDefaultValue}
            />
            <MemoSelectAdvanced
                defaultValue={localDefaultValue}
                selectAction={handleChange}
                paramURL={paramURL}
                {...props}
            />
        </>
    );
};

/** A select with fetched options, that can be used anywhere in a toolbar (above a data grid). DOC*/
export const FilterToolbarURL = ({
    url,
    selectRoute = "Select",
    ID = null,
    paramURL,
    placeholder = "",
    ...props
}) => {
    const [options, setOptions] = useState([]);
    const token = useSelector(state => state.auth.Token);

    useEffect(() => {
        let isSubscribed = true;
        const fetching = async () => {
            const optionsFetched = await fetchData(
                url,
                token,
                selectRoute,
                ID,
                paramURL
            );
            if (isSubscribed && optionsFetched) {
                setOptions(optionsFetched.data);
            }
        };

        fetching();
        return () => (isSubscribed = false);
    }, [url, token, selectRoute, ID, paramURL]);

    return (
        <FilterToolbar
            isOptionEqualToValue={(option, value) =>
                option.ObjectID === value.ObjectID
            }
            filterSelectedOptions={false}
            multiple
            id="checkboxes"
            options={options}
            disableCloseOnSelect
            getOptionLabel={option => option.Value}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.Value}
                </li>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    placeholder={placeholder}
                    variant="outlined"
                    color="primary"
                    size="small"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <>
                                <FilterListIcon
                                    fontSize="small"
                                    sx={{ mx: "4px", color: "red" }}
                                />
                                {params.InputProps.startAdornment}
                            </>
                        ),
                    }}
                    inputProps={{
                        ...params.inputProps,
                        ...deativateAutocompletion,
                    }}
                />
            )}
            {...props}
        />
    );
};
