import React, { useEffect }  from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useState } from "react";
import { BasicButton } from "./Custom Inputs/Buttons";
import { t } from "react-i18nify";
import SaveIcon from "@mui/icons-material/Save";
import { useHistory, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { addNewModel, editModel, fetchData } from "../../js/utils/backend";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { ScreenLoading } from "../Widgets/CustomLoadings";
import { updateHighlightedRows } from "../../js/redux/actions/userPref";
import { SessionVerificationDialog } from "../Layouts/CommonLayout";
import { useCheckAndRevalidateToken } from "../../js/utils/customHooks";

export const FormStepper = ({
    steps,
    baseURL,
    baseURLParams = "",
    currentLocation,
}) => {
    const history = useHistory();
    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const token = useSelector(state => state.auth.Token);
    const { handleSubmit, formState, trigger, reset } = methods;
    const dispatch = useDispatch();

    const [activeStep, setActiveStep] = useState(0);
    const { mode, UrlID } = useParams();
    const edition = mode === "edit";
    const [loading, setLoading] = useState(edition);
    const [nextPage, setnextPage] = useState(false);

    const manageSubmit = async data => {
        if (!formState.isSubmitting) {
            history.replace(history.location.pathname);
            if (edition) {
                await editModel({ ID: UrlID, ...data }, UrlID, token, baseURL);
                dispatch(
                    updateHighlightedRows({
                        CurrentLocation: currentLocation,
                        VisitedRowID: UrlID,
                    })
                );
            } else {
                await addNewModel(
                    { ...data },
                    token,
                    baseURL + "?" + baseURLParams
                ).then(response =>
                    dispatch(
                        updateHighlightedRows({
                            CurrentLocation: currentLocation,
                            VisitedRowID: response.data.ObjectID,
                        })
                    )
                );
            }
            history.goBack();
        }
    };

    useEffect(() => {
        if (nextPage && Object.keys(formState.errors).length === 0) {
            setActiveStep(a => a + 1);
        }
        setnextPage(false);
    }, [nextPage, formState.errors]);

    useEffect(() => {
        let isSubscribed = true;
        const fetching = async () => {
            const queryFetched = await fetchData(baseURL, token, "ID", UrlID);
            if (isSubscribed) {
                reset(queryFetched.data);
                setLoading(false);
            }
        };
        edition && fetching();
        return () => (isSubscribed = false);
    }, [token, UrlID, baseURL, edition, reset]);

    ////////////////////////////////// TOKEN REVALIDATION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    const [sessionVerification, setSessionVerification] = useState(false)
    useCheckAndRevalidateToken(setSessionVerification, [formState.isSubmitted])
    ////////////////////////////////// TOKEN REVALIDATION \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

    return loading ? (
        <ScreenLoading />
    ) : (
            <FormProvider {...methods}>
                {sessionVerification ? <SessionVerificationDialog sessionVerification={sessionVerification} /> : null}
                <form onSubmit={handleSubmit(manageSubmit)}>
                <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={activeStep} sx={{ padding: 1 }}>
                        {steps.map(step => (
                            <Step key={step.name} {...step.stepProps}>
                                <StepLabel>{step.name}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <React.Fragment>
                        <Box
                            m={1}
                            p={2}
                            sx={{
                                gap: 2,
                                border: theme =>
                                    `1px solid ${theme.palette.neutral.dark}`,
                                borderRadius: "10px",
                            }}
                        >
                            <Grid container spacing={2}>
                                {steps[activeStep].form}
                            </Grid>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "row0" }}>
                            <BasicButton onClick={() => history.goBack()}>
                                {t("common.Cancel")}
                            </BasicButton>
                            <Box sx={{ flex: "1 1 auto" }} />
                            <BasicButton
                                disabled={activeStep === 0}
                                onClick={() => {
                                    setActiveStep(activeStep - 1);
                                }}
                            >
                                {t("common.Back")}
                            </BasicButton>
                            {activeStep === steps.length - 1 ? (
                                <BasicButton
                                    type={"submit"}
                                    startIcon={<SaveIcon />}
                                >
                                    {t("common.Save")}
                                </BasicButton>
                            ) : (
                                <BasicButton
                                    onClick={async e => {
                                        e.preventDefault();
                                        await trigger();
                                        if (
                                            Boolean(
                                                steps[activeStep].formEditOnNext
                                            )
                                        ) {
                                            steps[activeStep].formEditOnNext(
                                                methods
                                            );
                                        }
                                        setnextPage(true);
                                    }}
                                >
                                    {t("common.Next")}
                                </BasicButton>
                            )}
                        </Box>
                    </React.Fragment>
                </Box>
            </form>
        </FormProvider>
    );
};
