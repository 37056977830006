import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { t } from "react-i18nify";
import { FormProvider, useForm } from "react-hook-form";
import EditionPanelGlobal from "./EditionPanelGlobal";
import VRichTextEditor from "../../Widgets/Custom Inputs/RichTextEditor";
import { VMultiDropzone } from "../../Widgets/Custom Inputs/Dropzone";
import { ScreenLoading } from "../../Widgets/CustomLoadings";
import { fetchData } from "../../../js/utils/backend";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PageSection } from "../../Widgets/CustomSurfaces";
import { SubTitle } from "../../Widgets/StyledComponents";

/** Page view where the user can see main data about accessible entities */
const EntityEditionPanel = ({ route = "OTEntity" }) => {
    const methods = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const { UrlID } = useParams();
    const { reset } = methods;
    const token = useSelector(state => state.auth.Token);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetching = async () => {
            const response = await fetchData(route, token, "ID", UrlID);
            reset({
                ...response.data,
                BranchesID: response.data.Branch_select.map(e => e?.ObjectID),
                CountryID: response.data.Country_select.ObjectID,
            });
            setLoading(false);
        };
        fetching();
    }, [reset, route, token, UrlID]);

    return loading ? (
        <ScreenLoading />
    ) : (
        <FormProvider {...methods}>
            <EditionPanelGlobal
                route={route}
            >
                <Grid
                    item
                    container
                    sx={{
                        height: 1,
                        p: 1,
                    }}
                >
                    <Grid
                        item
                        container
                        xs={4}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <PageSection sx={{ height: "100%" }}>
                            <SubTitle label={t("view.entityPage.Photo")} />
                            <VMultiDropzone
                                fieldName="Images"
                                fileType="img"
                                validation={{
                                    required: {
                                        value: true,
                                        message: t("input.validation.required"),
                                    },
                                }}
                            />
                        </PageSection>
                    </Grid>
                    <Grid item xs={8}>
                        <PageSection>
                            <SubTitle label={t("view.entityPage.Location")} />
                            <VRichTextEditor
                                fieldName="EntityLocation"
                                isShort={false}
                            />
                        </PageSection>
                        <PageSection>
                            <SubTitle label={t("view.entityPage.History")} />
                            <VRichTextEditor
                                fieldName="EntityHistory"
                                isShort={false}
                            />
                        </PageSection>
                        <PageSection>
                            <SubTitle label={t("view.entityPage.Operations")} />
                            <VRichTextEditor
                                fieldName="EntityOperations"
                                isShort={false}
                            />
                        </PageSection>
                    </Grid>
                </Grid>
            </EditionPanelGlobal>
        </FormProvider>
    );
};

export default EntityEditionPanel;
